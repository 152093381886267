import { KEY_CODE } from 'constants/Static';
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { ISelectOption } from 'types';

const SelectOption = (props: ISelectOption) => {
  const {
    handler,
    placeholder,
    options,
    value,
    disabled,
    isSearchable,
    classNamePrefix,
    className,
    onKeyDown,
    autoFocus,
    customStylesSelect,
    refDlContainer,
    inputBorder,
    menuBorder,
    styledScrollBar,
    uid,
  } = props;

  const [menuPositionY, setMenuPositionY] = useState(0);
  const [hideOnScroll, setHideOnScroll] = useState(false);
  const elementRef = useRef() as React.MutableRefObject<HTMLImageElement>;
  const selectRef = useRef() as any;
  useEffect(() => {
    if (refDlContainer) {
      refDlContainer.current?.addEventListener('scroll', () => {
        setHideOnScroll(true);
      }, { passive: true });
      return (
        refDlContainer.current?.removeEventListener('scroll', () => {
          setHideOnScroll(false);
        }, { passive: true })
      );
    }
  }, []);

  const defaultTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: 'none',
      primary50: '#e6e6e6',
      primary: '#ccc',
    },
  });

  const customStyles = customStylesSelect ? customStylesSelect.customStyles : {
    IndicatorsContainer: (base: any) => ({
      ...base,
      height: '100%',
      display: 'none',
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      pointerEvents: 'none',
      display: 'none',
      backgroundColor: 'unset',
    }),
    option: (base: any, state: any) => ({
      ...base,
      '&:hover': {
        backgroundColor: state.isFocused ? '#e0e0e0' : 'none',
        cursor: 'pointer',
      },
    }),
    control: (base: any, state: any) => ({
      ...base,
      minHeight: '100%',
      height: '100%',
      width: '15rem',
      borderRadius: state.selectProps.inputType === 'circle' ? '1rem' : '0',
    }),

    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: state.selectProps.menuBorder === 'circle' ? '1rem' : '0'
    }),
    menuList: (base: any, state: any) => ({
      ...base,
      borderRadius: state.selectProps.menuBorder === 'circle' ? '1rem' : '0'
    }),
    container: (base: any, state: any) => ({
      ...base,
      height: '100%',
      borderRadius: state.selectProps.menuBorder === 'circle' ? '1rem' : '0',
    }),
    input: (base: any) => ({
      ...base,
      height: 'inherit',
      margin: 1,
      padding: 0
    }),
    item: (base: any, state: any) => ({
      ...base,
      height: state.selectProps.menuBorder === 'circle' ? '1rem' : '',
      minHeight: '100%',
    }),
    singleValue: (base: any) => ({
      ...base,
      maxWidth: '80%',
    }),
  };

  const onPressEnter = (e: any) => {
    if (e.keyCode === KEY_CODE.ENTER || e.keyCode === KEY_CODE.SPACE) {
      selectRef.current.onMenuOpen();
    }
  };

  return (
    <div
      className="select-container"
      ref={elementRef}
    >
      <div
        className="select-item"
        onClick={(e) => {
          setMenuPositionY(e.pageY - e.nativeEvent.offsetY);
          setHideOnScroll(false);
        }}
      >
        <Select
          key={uid}
          menuPlacement="auto"
          menuPosition="fixed"
          ref={selectRef}
          value={value}
          styles={customStyles}
          onChange={(selectedOption: any) => { handler(selectedOption); }}
          placeholder={placeholder}
          options={options}
          isDisabled={disabled}
          isSearchable={isSearchable}
          classNamePrefix={classNamePrefix}
          onKeyDown={onKeyDown || onPressEnter}
          autoFocus={autoFocus}
          className={`select-element_${className || 'valid'} ${styledScrollBar}`}
          theme={(theme) => defaultTheme(theme)}
          menuBackgroundColor="white"
          offsetY={menuPositionY}
          closeMenuOnSelect
          closeMenuOnScroll={() => hideOnScroll}
          inputType={ inputBorder }
          menuBorder={ menuBorder }
        />
      </div>
    </div>
  );
};

export default SelectOption;
