export enum DataSetActions {
  setDataSet = 'SET_DATA_SET',
}
export interface IDataSet {
  check: string;
  customer: string;
  application: string;
  requiredFieldsType: string;
  isSimplified: boolean;
  isDefault: boolean;
  agentConfigId: number;
}

export type TDataSetActions = { type: DataSetActions.setDataSet; value: IDataSet[] };
