import { ICheckResult, TCheckResultActions } from 'store/features/checkResult/types';
import { IZipContent, TZipContentActions } from 'store/features/zipContent/types';
import { IUserInfo, TUserActions } from 'store/features/user/types';
import { IDataSet, TDataSetActions } from 'store/features/dataSets/types';
import { ICommon, TCommonActions } from 'store/features/common/types';
import { IPopupInfo, TPopupInfoActions } from 'store/features/popupInfo/types';
import { ICustomerDataCheck } from 'store/features/customerDataCheck/types';
import { ISpecimenState, TSpecimenActions } from 'store/features/specimen/types';

export enum rootActions {
  reset = 'RESET',
  resetSession = 'RESET_SESSION'
}

export interface IRootState {
  checkResult?: ICheckResult;
  common?: ICommon;
  dataSets?: IDataSet[];
  defaultAgentConfig?: number;
  zipContent?: IZipContent;
  userInfo?: IUserInfo;
  popupInfo?: IPopupInfo;
  specimen?: ISpecimenState;
  customerDataCheck: ICustomerDataCheck;
}

export type TRootActions =
  TCheckResultActions |
  TZipContentActions |
  TUserActions |
  TDataSetActions |
  TCommonActions |
  TPopupInfoActions |
  TSpecimenActions |
  { type: rootActions.reset; value: null } |
  { type: rootActions.resetSession; value: null };
