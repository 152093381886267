import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  getMainComponent,
  getFooterButtonData,
  shouldRenderComponent,
  getSummaryHeading,
} from 'helper/tools/summary';
import SummaryComponent from 'components/summary/SummaryComponent';
import SummaryFooter from 'components/summary/SummaryFooter';
import DenyModal from 'components/finalModal/DenyModal';
import AcceptModal from 'components/finalModal/AcceptModal';
import * as commonActions from 'store/features/common/actions';
import { getXMLData } from 'helper/xmlParser';
import DragonDropList from 'components/dragonDropList/DragonDropList';
import { IRootState } from 'store/types';
import { getNextPage } from 'helper/navigation';
import { CompleteTransaction } from 'helper/api/route';
import RoutePaths from 'constants/RoutePaths';
import { PATH } from 'constants/Static';
import { parseRoute } from 'util/common';
import { ICheckResult } from 'store/features/checkResult/types';
import { IZipContent } from 'store/features/zipContent/types';
import { detectForcedRejection } from 'helper/tools/quickCheck';
import { generateManualCheckFailReasons } from 'util/helperSDKVerification';
import { EHttpStatusCode, ETransactionState } from 'enums';

const Summary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const state = useSelector((rootState: IRootState) => rootState);
  const subDocsData = state.zipContent?.subDocsData || [];
  const checkResult = state.checkResult as ICheckResult;
  const { trCode = '' } = state.zipContent as IZipContent;
  const { filesData = [] } = checkResult;
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [sortedData, setSortedData] = useState(
    generateManualCheckFailReasons(checkResult, subDocsData)
  );
  const [isSuccessStatus, setIsSuccessStatus] = useState('success');
  const [loader, setLoader] = useState(false);
  const nextPathname = getNextPage(state, location.pathname);

  const isLastPage = (nextPathname === PATH.DASHBOARD);
  const checkRoutes = parseRoute(location.pathname);
  const nextSubRoute = parseRoute(nextPathname).checkType;
  const { checkSubType } = checkRoutes;
  const {
    link,
    span1Text,
    buttonText,
  } = getFooterButtonData(nextSubRoute, location.pathname, nextPathname);

  const summaryFooterProps = {
    span1Text,
    link,
    buttonText,
    isLoading: loader,
    reject: detectForcedRejection(filesData) && isLastPage,
    clickHandler: () => {
      setLoader(true);
      setShowAcceptModal(!showAcceptModal);
      const xmlData: any = getXMLData(state, ETransactionState.reviewed);
      CompleteTransaction(xmlData, trCode)
        .then(() => {
          dispatch(commonActions.setResetCode(false));
          setIsSuccessStatus('success');
          setLoader(false);
        })
        .catch((error) => {
          if (error.response.status === EHttpStatusCode.conflict) {
            dispatch(commonActions.setResetCode(false));
            setLoader(false);
            return history.replace(PATH.DASHBOARD);
          }
          setIsSuccessStatus('fail');
          setLoader(false);
        });
    },
    denyClickHandler: () => {
      setShowDenyModal(!showDenyModal);
    },
  };

  const screenEnabled = (route: string, currentIndex: number): boolean => {
    const foundedIndex = Object.keys(RoutePaths).findIndex(
      (routeItem) => (checkSubType === routeItem)
    );
    return !!(currentIndex <= foundedIndex && shouldRenderComponent(state, route));
  };

  return (
    <>
      <div className="summary-check-table-container">
        <h3>{t(getSummaryHeading(checkSubType))}</h3>
        <div className="summary-item-title-container">
          {Object.keys(RoutePaths).map((route: string, index: number) => {
            return (
              <React.Fragment key={index} >
                {screenEnabled(route, index) && (
                  <SummaryComponent
                    summaryResult={getMainComponent(state, route)}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
        <SummaryFooter {...summaryFooterProps} />
        <DenyModal
          show={showDenyModal}
          setShow={setShowDenyModal}
          Child={showDenyModal ? (
            <DragonDropList
              sortedData={sortedData}
              setSortedData={setSortedData}
            />
          ) : []}
          sortedFailsList={sortedData}
        />
        {!loader && (
          <AcceptModal
            show={showAcceptModal}
            setShow={setShowAcceptModal}
            isSuccessStatus={isSuccessStatus}
            backdrop="static"
          />
        )}
      </div>
    </>
  );
};

export default Summary;
