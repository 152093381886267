import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ZoomIn } from 'images/zoom-in.svg';
import { ReactComponent as ZoomOut } from 'images/zoom-out.svg';
import { ReactComponent as Images } from 'images/images.svg';

const AddressModalContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="image-slider-header">
        <p className="scrub-text-style">{t('reg.comparation.modal.title')}</p>
      </div>
      <div className="info-section">
        <div className="instructions-line">
          <div>
            <div className="icons-container">
              <ZoomIn />
              <ZoomOut />
            </div>
            <div className="text-container">
              {t('reg.comparation.modal.zoom')}
            </div>
          </div>
          <div>
            <div className="icons-container">
              <Images />
            </div>
            <div className="text-container">
              {t('reg.comparation.modal.user-data-check.feature-2')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressModalContent;