import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BackButton } from 'images/right.svg';
import { ReactComponent as ShieldIconSuccess } from 'images/shield-default.svg';
import { ReactComponent as ShieldIcon } from 'images/shield.svg';

interface ISummaryTitleProps {
  title: string;
  iconMid: 'success' | 'wrong';
  description?: string;
}

const SummaryTitle = (props: ISummaryTitleProps) => {
  const { title, description, iconMid } = props;
  const { t } = useTranslation();
  return (
    <div className="section-block-accordion">
      <div className="check-summary-block">
        <div className="section-block-section row">
          <span className="section-block-section-info">{t(title)}</span>
          <span className="section-block-section-info-icon">
            <div className="failed-section">
              { description ?
                <>
                  {iconMid === 'success' ?
                    <ShieldIconSuccess className="status-icon" />
                    :
                    <ShieldIcon className="status-icon" />}
                  <span className={`
                    ${iconMid === 'success' ? 'success' : 'danger'} title-description`}>
                    {t(description)}
                  </span>
                </> : <></>
              }
              <BackButton className="right-end-icon" />
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SummaryTitle;