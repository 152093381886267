import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  IFieldData,
  TFieldProps,
  ICheckResult,
  TFieldZoneProps,
} from 'store/features/checkResult/types';
import FieldsData from 'constants/FieldsData.json';
import { IRootState } from 'store/types';
import { EField, EZone } from 'enums';
import ToolTipField from 'components/toolTipField/ToolTipField';
import { ReactComponent as RightIcon } from 'images/arrow-right.svg';
import { ReactComponent as LeftIcon } from 'images/arrow-left.svg';
import * as checkResultActions from 'store/features/checkResult/actions';
import * as commonActions from 'store/features/common/actions';
import {
  getPackageInfo,
  updateFieldValue,
  updateDataValidation,
} from 'helper/tools/dataCheck';
import DLTable from 'components/drivingField/table/DLTable';
import { fieldValueValidated, valuesIsSame } from 'helper/validation';

const RightMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const filesSliderPath = state.common?.filesSliderPath || '';
  const { dataValidation = [] } = checkResult;

  const {
    field,
    packageIndex,
    zone: currentZone
  } = useMemo(() => getPackageInfo(filesSliderPath), [filesSliderPath]);
  const { zones, fields } = dataValidation[packageIndex];

  const copyValue = (fields: TFieldProps, field: string, zone: EZone, value: string) => {
    const { extractedValue, optional } = fields[field][zone];
    if (value && fieldValueValidated(field, zone, value, optional)) {
      const [targetZone] = zones.filter(item => item !== zone);
      const updatedFields = updateFieldValue(fields, field, zone, {
        value,
        modified: !valuesIsSame(extractedValue, value, field),
        equals: true,
      });
      const updatedTargetFields = updateFieldValue(updatedFields, field, targetZone, {
        equals: true,
      });
      const updatedData = updateDataValidation(dataValidation, packageIndex, {
        fields: updatedTargetFields,
      });
      dispatch(checkResultActions.setDataValidation(updatedData));
    }
  };

  const changeFieldLocation = (field: string, zone: EZone) => {
    dispatch(commonActions.setActiveSlidePath(`${packageIndex}/${field}/${zone}`));
  }

  const [leftZone, rightZone] = zones;
  const shouldRenderDrivingTable = Object.keys(fields).includes(EField.drivingCategory);

  const detectBackgroundStyle = (data: IFieldData) => {
    if (data.modified) {
      return 'right-menu-modified-background';
    }
    return 'right-menu-default-background';
  };

  const detectTextColor = (data: IFieldData) => {
    let style = '';
    if (!data.exists || data.disabled) {
      style += 'right-menu-read-only-text-color';
    } else if (data.exists) {
      if (data.equals) {
        style += ' right-menu-matching-text-color';
      } else {
        style += ' right-menu-different-text-color';
      }
    }
    return style;
  };

  const detectCopyStyle = (leftZone: EZone, rightZone: EZone) => (
    `arrow-copy-button-${leftZone}-${rightZone}`
  )

  const renderFieldData = (fieldName: string, data: IFieldData, zone: EZone) => {
    const backgroundStyle = detectBackgroundStyle(data);
    const textStyle = detectTextColor(data);
    const activeField = fieldName === field && zone === currentZone ? 'active-field' : '';
    return <td
      className={`${backgroundStyle} ${textStyle} dcrmt-row-item ${activeField}`}
      onClick={() => !data.disabled && changeFieldLocation(fieldName, zone)}
    >
      {data.exists ? <ToolTipField
        tooltipValue={data?.value || ''}
        placement="top"
      >
        <span>{data?.value || ''}</span>
      </ToolTipField> : <span>{data?.value || ''}</span>
      }
    </td>
  }

  const renderCopyButton = (fieldName: string, data: TFieldZoneProps) => {
    const copyStyle = detectCopyStyle(leftZone, rightZone);
    const copyStatuses = { left: false, right: false };
    if (leftZone === EZone.viz && rightZone === EZone.mrz
      && (data[leftZone].exists && !data[leftZone].disabled) &&
      (data[rightZone].exists && !data[rightZone].disabled)
    ) {
      copyStatuses.left = true;
      copyStatuses.right = true;
    }
    if (leftZone === EZone.viz && [EZone.nfc, EZone.barcode].includes(rightZone)
      && ((data[leftZone].exists && !data[leftZone].disabled) &&
      (data[rightZone].exists && !data[rightZone].disabled))
    ) {
      copyStatuses.left = true;
    }
    return <td key={fieldName}>
      <div className='dcrmt-row-copy-icons'>
        {copyStatuses.left && <LeftIcon
          className={`${copyStyle} arrow-icon`}
          onClick={() => copyValue(
            dataValidation[packageIndex].fields,
            fieldName,
            leftZone,
            data[rightZone].value,
          )}
        />}
      </div>
      <div className='dcrmt-row-copy-icons'>
        {copyStatuses.right && <RightIcon
          className={`${copyStyle} arrow-icon`}
          onClick={() => copyValue(
            dataValidation[packageIndex].fields,
            fieldName,
            rightZone,
            data[leftZone].value,
          )}
        />}
      </div>
    </td>
  }

  return <div className="data-check-right-menu-content">
    <div className="data-check-right-menu-heading">
      <p> {t('reg.data-check.left-menu.heading')} </p>
    </div>
    <table className='data-check-right-menu-table'>
      <thead>
        <tr>
          <th className='dcrmt-head-field-name'>
            {t('reg.data-check.right-menu.field-name-title')}
          </th>
          <th>{t(`reg.data-check.right-menu.field-name-zone-${leftZone}`)}</th>
          {rightZone ? <th className='dcrmt-head-copy-name'></th> : <></>}
          {rightZone ?
            <th className='dcrmt-head-target-zone-name'>
              {t(`reg.data-check.right-menu.field-name-zone-${rightZone}`)}
            </th> : <></>
          }
        </tr>
      </thead>
      <tbody>
        {
          Object.keys(dataValidation[packageIndex].fields).map((fieldName, rowIndex) => {
            const collection = dataValidation[packageIndex].fields[fieldName];
            const fieldTitle = FieldsData.find(
              ({ name }) => name === fieldName
            )?.title || fieldName;
            return (
              <tr key={rowIndex}>
                <td className={'dcrmt-row-item dcrmt-row-field'}>
                  <ToolTipField
                    tooltipValue={t(fieldTitle)}
                    placement="top"
                  >
                    <span>{t(fieldTitle)}</span>
                  </ToolTipField>
                </td>
                {renderFieldData(fieldName, collection[leftZone], leftZone)}
                {rightZone && (
                  <>
                    {renderCopyButton(fieldName, collection)}
                    {renderFieldData(fieldName, collection[rightZone], rightZone)}
                  </>
                )}
              </tr>
            )
          })
        }
      </tbody>
    </table>
    {shouldRenderDrivingTable && <DLTable packageIndex={packageIndex} />}
  </div>;
};

export default RightMenu;
