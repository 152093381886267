import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Files from 'pages/quickCheck/files/Files';
import RightMenu from 'pages/quickCheck/files/RightMenu';
import DocumentTypeRightMenu from 'pages/quickCheck/files/DocumentTypeRightMenu';
import CompareDocuments from 'pages/quickCheck/files/CompareDocuments';
import Footer from 'components/footer/Footer';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import InfoBar from 'components/infoBar/InfoBar';
import { IRootState } from 'store/types';
import { Questions } from 'store/features/common/types';
import { ICheckResult, IFilesData } from 'store/features/checkResult/types';
import * as checkResultActions from 'store/features/checkResult/actions';
import * as commonActions from 'store/features/common/actions';
import {
  getFilesData,
  getNext,
} from 'helper/tools/quickCheck';
import { getActiveImagePath, getFlowMode } from 'helper/tools/commonHelper';
import Loader from 'components/loader/Loader';
import { EDirection, EPage } from 'enums';
import BurgerMenu from 'components/burgerMenu/BurgerMenu';
import { SUPPORTED_EXTRA_DETAILS } from 'constants/Static';

const FilesComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const subDocsData = state.zipContent?.subDocsData || [];
  const datasets = state.dataSets || [];
  const question = (state.common?.question as Questions) || Questions.countryDocType;
  const page = HeaderButtons[EPage.quickCheck].files[question];
  const filesSliderPath = state.common?.filesSliderPath || '';
  const prevPagePath = state.common?.prevPagePath || '';
  const isSimplified = getFlowMode(datasets);
  const { filesData = [] } = checkResult;

  useEffect(() => {
    if (!checkResult?.filesData.length) {
      const fetchInitialContent = async () => {
        const initialFilesData: IFilesData[][] = await getFilesData(subDocsData);
        const extraDetailAllowed = (item: IFilesData) => (
          SUPPORTED_EXTRA_DETAILS.includes(`${item.documentType}-${item.country}`)
            ? !!item.extraDetail : true
        );
        const allCountryDocTypeDetected = initialFilesData.every((subDoc: IFilesData[]) => (
          subDoc.every((item) => item.country && item.documentType && extraDetailAllowed(item))
        ));
        if (allCountryDocTypeDetected && isSimplified) {
          dispatch(commonActions.setQuestion(Questions.documentPage));
        }
        dispatch(checkResultActions.setFilesData(initialFilesData));
      }
      fetchInitialContent();
    }
    if (!filesSliderPath && filesData.length) {
      if (prevPagePath) {
        const lastIndex = filesData.length - 1;
        const nextPath = getNext(
          filesData,
          lastIndex,
          filesData[lastIndex].length,
          EDirection.left,
          Questions.quality,
        );
        const { subDocIndex, imageIndex } = getActiveImagePath(filesSliderPath);
        const currentData = filesData[subDocIndex][imageIndex];
        const question = currentData.reviewResult.key || Questions.quality;
        dispatch(commonActions.setQuestion(question));
        dispatch(commonActions.setActiveSlidePath(nextPath));
      } else {
        const nextPath = getNext(filesData, 0, -1, EDirection.right, question);
        dispatch(commonActions.setActiveSlidePath(nextPath));
      }
    }
  }, [filesSliderPath, filesData]);

  if (!filesData.length || !filesSliderPath) {
    return <div className="loader-container"><Loader /></div>;
  }

  const regularRightMenu = question !== Questions.countryDocType &&
    question !== Questions.comparing;

  return (
    <>
      <InfoBar />
      <>
        <BurgerMenu />
        <span className="files-heading">{t(page?.heading || '')}</span>
      </>
      <div className="check-content">
        <div className='extra-space-menu-container' />
        {question === Questions.comparing ? <CompareDocuments /> : <Files />}
        {regularRightMenu ? <RightMenu /> : <DocumentTypeRightMenu />}
      </div>
      <div className="files-answer-component">
        <Footer NestedComponent={page?.answerComponent} />
      </div>
    </>
  );
};

export default FilesComponent;
