export enum UserActions {
  setUser = 'SET_USER',
}

export interface IUserInfo {
  name: string;
  email: string;
}

export type TUserActions = { type: UserActions.setUser; value: IUserInfo };
