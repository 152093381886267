import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FieldsTable from 'components/fieldsTable/FieldsTable';
import Footer from 'components/footer/Footer';
import InfoBar from 'components/infoBar/InfoBar';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import { getSdDataCheckList, getTableStructure } from 'helper/tools/customerDataCheck';
import { Questions, TQuestion } from 'store/features/common/types';
import { IRootState } from 'store/types';
import { EPage } from 'enums';
import BurgerMenu from 'components/burgerMenu/BurgerMenu';

const SDComponent = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const question: TQuestion = state.common?.question || Questions.approve;
  const page = HeaderButtons[EPage.userDataCheck]['self-declaration'][question];
  const itemIndex = Number(state.common?.filesSliderPath || 0);

  const {
    selfDeclaration = {},
    extractedData = [],
  } = useMemo(() => (getSdDataCheckList(state)), []);

  const extractedResult = extractedData?.[itemIndex]?.data || {};

  const comparedData = useMemo(() => (
    getTableStructure(extractedResult, selfDeclaration)
  ), [itemIndex]);

  const formattedSdData = useMemo(() => getTableStructure(selfDeclaration), []);

  return (
    <>
      <InfoBar />
      <>
        <BurgerMenu />
        <h3>{t(page?.heading || '')}</h3>
      </>
      <div className="check-content">
        <div className='extra-space-menu-container' />
        <div className="sd-container">
          <FieldsTable
            dataList={formattedSdData}
            headerName={'reg.info-bar.user-data-check.self-declaration-data-heading'}
            translateSource={'self-declaration'}
          />
        </div>
        <div className="right-menu-container">
          <FieldsTable
            dataList={comparedData}
            headerName={'reg.info-bar.user-data-check.extracted-data-heading'}
            activeType='warning'
            translateSource={'extracted-data'}
          />
        </div>
      </div>
      <Footer NestedComponent={page?.answerComponent} />
    </>
  );
};

export default SDComponent;