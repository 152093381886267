import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BigModal from 'components/bigModal/BigModal';
import { IRootState } from 'store/types';
import { PATH } from 'constants/Static';
import { IZipContent } from 'store/features/zipContent/types';

const { DASHBOARD } = PATH;

const AcceptModal = (props: any) => {
  const { show, setShow, isSuccessStatus, backdrop, setOnBackClick } = props;
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const name = state.userInfo?.name || '';
  const { trCode = '' } = state.zipContent as IZipContent;
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  const elementRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (elementRef !== null && elementRef.current !== null) {
      elementRef.current.focus();
    }
  });

  const hideModal = () => {
    setShow(!show);
  };

  const setModalBackClick = () => {
    if (setOnBackClick) {
      setOnBackClick();
    } else {
      setShow(!show);
    }
  };

  const ConfirmAssessment = () => {
    setLoader(true);
    if (!loader) {
      if (isSuccessStatus === 'success') {
        setLoader(false);
        history.replace(DASHBOARD);
      } else {
        setShow(!show);
        setLoader(false);
      }
    }
  };

  return (
    <>
      <BigModal
        isModalNewAttack={false}
        title={isSuccessStatus === 'success' ?
          `${t('reg.modal.accept.thankYou')}
           ${name} ${t('reg.modal.accept.forReviewing')} ${trCode}` :
          <>
            <p>
              {t('data-check-accept-modal-fail-message')}
            </p>
            <p>
              {t('data-check-accept-modal-please-try-again')}
            </p> </>
        }
        submitButtonText={
          isSuccessStatus === 'success'
            ? 'data-check-accept-modal-close' : 'reg.summary.data-check.accept-modal.back'}
        bodyButton
        footerStyle="modal-footer-join"
        onHide={() => hideModal()}
        onBackClick={() => setModalBackClick()}
        onSuccess={() => ConfirmAssessment()}
        show={show}
        isLoading={loader}
        isDisabled={loader}
        isSuccessStatus={isSuccessStatus}
        backdrop={backdrop}
      />
    </>
  );
};

export default AcceptModal;
