import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Footer from 'components/footer/Footer';
import InfoBar from 'components/infoBar/InfoBar';
import RightMenu from 'components/menuSide/RightMenu';
import List from 'components/menuSide/List';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import DocumentScan from 'pages/customerDataCheck/documentScan/DocumentScan';
import { Questions, TQuestion } from 'store/features/common/types';
import { IRootState } from 'store/types';
import { EPage } from 'enums';
import BurgerMenu from 'components/burgerMenu/BurgerMenu';

const DocumentScanComponent = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const rightMenuProps: Array<React.ReactNode> = [];
  const rightMenuContent: Array<React.ReactNode> = [];
  const question: TQuestion = state.common?.question || Questions.approve;
  const page = HeaderButtons[EPage.userDataCheck]['document-scan'][question];

  rightMenuContent.push(
    <p>
      <b>
        {t('user-data-check.document-scan.document')}
      </b>
      - {t('user-data-check.document-scan.scan')}
    </p>
  );
  rightMenuProps.push(<List
    header={t('user-data-check.document-scan.rightMenu.header')}
    content={rightMenuContent}
    key={t('user-data-check.document-scan.rightMenu.header') + 'documentScan'}
  />);

  return (
    <>
      <InfoBar />
      <>
        <BurgerMenu />
        <h3>{t(page?.heading || '')}</h3>
      </>
      <div className="check-content">
        <div className='extra-space-menu-container' />
        <DocumentScan />
        <RightMenu children={rightMenuProps} />
      </div>
      <Footer NestedComponent={page?.answerComponent} />
    </>
  );
};

export default DocumentScanComponent;