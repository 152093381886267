import React from 'react';

const RightMenu = (props: {children: Array<React.ReactNode>}) => {
  const { children } = props;

  return (
    <div className="right-menu-container">
      <div className='right-menu-list-container'>
        {children.map(
          (item: React.ReactNode, index: number) =>
            <React.Fragment key={index}>{item}</React.Fragment>)}
      </div>
    </div>
  );
};

export default RightMenu;
