import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import AccordionField from 'components/accordionField/AccordionField';
import { SUMMARY_STATUS } from 'constants/Static';
import { getImageFromBase64 } from 'util/common';
import { ReactComponent as FailedIcon } from 'images/close.svg';
import { ReactComponent as CorrectIcon } from 'images/correct.svg';
import { ReactComponent as DocComparison } from 'images/doc-comparison.svg';
import { ReactComponent as Folder } from 'images/folder.svg';
import { ReactComponent as Pencil } from 'images/pencil.svg';
import { ReactComponent as FaceFailed } from 'images/icon-fraud-user.svg';
import NoImage from 'images/no-image.png';
import { IRootState } from 'store/types';
import {
  getFormattedValue,
  getFieldTranslationKey,
} from 'helper/tools/commonHelper';
import { ICustomerDataImage, IZipContent } from 'store/features/zipContent/types';
import { EField } from 'enums';
import { IDataValidation, IFieldData } from 'store/features/checkResult/types';
import DLTable from 'components/drivingField/table/DLTable';
import DrawDataValidation from 'components/summary/content/DrawDataValidation';
import DrawFilledDataWithImages from 'components/summary/content/DrawDataImages';
import DrawDataTemplates from 'components/summary/content/DrawDataTemplates';
import { drivingCategoryIsEmpty } from 'helper/validation';

interface ISummaryItemProps {
  data: any;
}

const SummaryItem = (props: ISummaryItemProps) => {
  const state = useSelector((rootState: IRootState) => rootState);
  const {
    zipContent,
  } = state;
  const { customerData = {} } = zipContent as IZipContent;
  const additionalDocs: ICustomerDataImage[] = customerData?.additionalDocImages;
  const utilityImages: ICustomerDataImage[] = customerData?.utilityImages;
  const { video, avatar, subDocsData = [] } = zipContent as IZipContent;
  const { entryName = '' } = video;
  const { data } = props;

  const getIconByKey = (status: string) => {
    switch (status) {
    case SUMMARY_STATUS.SUCCESS:
      return <CorrectIcon className={`right-end-icon warning ${status}`} />;
    case SUMMARY_STATUS.DANGER:
      return <FailedIcon className={`right-end-icon danger ${status}`} />;
    case SUMMARY_STATUS.WARNING:
      return <FailedIcon className={`right-end-icon unsure ${status}`} />;
    case SUMMARY_STATUS.MODIFIED:
      return <Pencil />;
    case 'docComparison':
      return <DocComparison />;
    case 'docType':
    case 'fraudCheck':
      return <Folder />;
    case 'faceFailed':
      return <FaceFailed className={`right-end-icon ${status}`} />;
    }
  };

  const GetFileData = (file: any) => {
    const { t } = useTranslation();
    switch (file?.type) {
    case 'page':
      return file.images.map((imageData: any, pageIndex: number) => {
        const { imageIndex, subDocIndex, title, quality, status } = imageData;
        const image = Object.values(subDocsData[subDocIndex].files[imageIndex])[0];
        return (
          <Fragment key={imageIndex}>
            <DrawFilledDataWithImages
              imageTitle={title}
              image={getImageFromBase64(image)}
              options={[quality]}
              pageIndex={pageIndex}
              rightBlockTitleStyle={'right-text'}
              status={status}
              icon={getIconByKey(status)}
            />
          </Fragment>
        );
      }
      );
    case 'face':
      return (
        <Fragment key={uuid()}>
          <DrawFilledDataWithImages
            subDocsData={subDocsData}
            pageIndex={0}
            avatarImage={entryName && avatar ? getImageFromBase64(avatar) : NoImage}
            optionTitleStyle={'face-title'}
            optionTitle={t('reg.summary.reasons-text')}
            options={file?.reasons}
            type={file.type}
            status={file.status}
            rightBlockTitleStyle={'failed-reasons-messages'}
          />
        </Fragment>
      );
    case 'fraudCheck':
      return file.images.map((imageData: any, pageIndex: number) => {
        const { imageIndex, subDocIndex, title, reasons, status } = imageData;
        const image: string = Object.values(subDocsData[subDocIndex].files[imageIndex])[0];
        return (
          <Fragment key={uuid()}>
            <DrawFilledDataWithImages
              imageTitle={title}
              image={getImageFromBase64(image)}
              pageIndex={pageIndex}
              rightBlockTitleStyle={'failed-reasons-messages'}
              options={reasons}
              status={status}
              icon={getIconByKey(status)}
            />
          </Fragment>
        );
      }
      );
    case 'dataList':
      return file?.images?.map((imageData: IDataValidation) => {
        return Object.keys(imageData.fields).map((field: string, fieldIndex: number) => {
          const [firstZone, secondZone] = imageData.zones;
          let firstData = {} as IFieldData;
          let secondData = {} as IFieldData;
          if (firstZone) {
            firstData = imageData.fields[field][firstZone] || {};
          }
          if (secondZone) {
            secondData = imageData.fields[field][secondZone] || {};
          }
          return (
            <Fragment key={uuid()}>
              <DrawDataValidation
                pageIndex={`${fieldIndex}_firstZone`}
                firstData={{
                  value: firstData.value,
                  status: firstData?.exists ?
                    firstData?.modified ? SUMMARY_STATUS.MODIFIED : SUMMARY_STATUS.SUCCESS : '',
                }}
                secondData={{
                  value: secondData.value,
                  status: secondData.exists ?
                    secondData?.modified ? SUMMARY_STATUS.MODIFIED : SUMMARY_STATUS.SUCCESS : '',
                }}
                icon={getIconByKey(firstData?.modified || secondData?.modified
                  ? SUMMARY_STATUS.MODIFIED : SUMMARY_STATUS.SUCCESS
                )}
                status={firstData?.modified || secondData?.modified
                  ? SUMMARY_STATUS.MODIFIED : SUMMARY_STATUS.SUCCESS
                }
                field={field}
                key={uuid()}
              />
              {field === EField.drivingCategory &&
                !drivingCategoryIsEmpty(imageData.fields[field][firstZone].dlValue || [])
                && <DLTable packageIndex={file.packageIndex} key={fieldIndex}/>
              }
            </Fragment>
          );
        });
      });
    case 'selfDeclaration':
      return (
        <div key={uuid()}>
          {Object.entries(file?.page).map(([field, value]: any[], index: number) => {
            return (
              <Fragment key={index}>
                <DrawDataTemplates
                  pageIndex={index}
                  fieldTitle={t(getFieldTranslationKey(field, 'self-declaration'))}
                  value={getFormattedValue(field, value, t)}
                />
              </Fragment>
            );
          })}
        </div>
      );
    case 'address': {
      const image = utilityImages.find(
        (dataImage: ICustomerDataImage) => dataImage.imageName === file?.image?.imageName
      )?.base64 || '';
      return (
        <Fragment key={file.imageIndex}>
          <DrawFilledDataWithImages
            image={getImageFromBase64(image || NoImage)}
            rightBlockTitleStyle={'failed-reasons-messages'}
            options={file.reasons}
            status={file.status}
            icon={getIconByKey(file.status)}
          />
        </Fragment>
      );
    }
    case 'documentScan':
    {
      const selectedImage = additionalDocs.find(
        (doc: ICustomerDataImage) => doc.imageName === file.selectedImageName)?.base64;
      return (
        <Fragment key={uuid()}>
          <DrawFilledDataWithImages
            image={getImageFromBase64(selectedImage || NoImage)}
            rightBlockTitleStyle={'failed-reasons-messages'}
            options={file.reasons}
            status={file.status || SUMMARY_STATUS.SUCCESS}
            icon={getIconByKey(file.status)}
          />
        </Fragment>
      );
    }
    }
  };

  const renderDocumentTypeData = (files: any) => {
    return files.pages.map((page: any) => {
      return (
        <div key={uuid()}>
          <AccordionField
            title={page.title}
            body={GetFileData(page)}
            icon={getIconByKey(page.status)}
            statusText={page.statusText}
            eventKey={uuid()}
          />
        </div>
      );
    });
  };

  const renderFraudCheckData = (files: any) => {
    return (
      <div key={uuid()}>
        <AccordionField
          title={'summary.fraud-check.document.title'}
          body={GetFileData(files)}
          eventKey={uuid()}
          icon={getIconByKey(files.status)}
        />
      </div>
    );
  };

  const renderDocComparisonData = (docs: any) => {
    return docs?.pages.map((doc: any) => {
      return (
        <div key={uuid()}>
          <AccordionField
            title={doc.title}
            body={GetFileData(doc)}
            icon={getIconByKey(doc.icon)}
            statusText={doc.statusText}
            eventKey={uuid()}
          />
        </div>
      );
    });
  };

  const renderData = (fileData: any) => {
    switch (fileData.type) {
    case 'fraudCheck':
      return renderFraudCheckData(fileData);
    case 'docType':
      return renderDocumentTypeData(fileData);
    case 'liveness':
    case 'address':
    case 'documentScan':
    case 'selfDeclaration':
      return GetFileData(fileData?.pages[0]);
    case 'docComparison':
      return renderDocComparisonData(fileData);
    default:
      return <></>;
    }
  };

  return (
    <div className="section-block">
      {
        Object.keys(data).map((item: string) => {
          return <div key={uuid()}>
            <AccordionField
              title={data[item].title}
              body={renderData(data[item])}
              eventIcon={getIconByKey(data[item].type)}
              statusText={data[item].statusText}
              icon={getIconByKey(data[item]?.statusText?.status)}
              status={data[item].status}
              eventKey={uuid()}
              key={uuid()}
            />
          </div>;
        })
      }
    </div>
  );
};

export default SummaryItem;
