import React from 'react';
import { useSelector } from 'react-redux';

import { getImageFromBase64 } from 'util/common';
import FaceItem from 'pages/quickCheck/face/FaceItem';
import { IRootState } from 'store/types';
import VideoPlayer from 'components/videoPlayer/VideoPlayer';
import { IZipContent } from 'store/features/zipContent/types';
import { PATH } from 'constants/Static';

const Face = () => {
  const state = useSelector((rootState: IRootState) => rootState);
  const { trCode = '' } = state.zipContent as IZipContent;
  const subDocsData = state.zipContent?.subDocsData || [];
  const { video = { entryName: '' } } = state.zipContent || {};
  const { entryName = '', } = video;

  return (
    <div className='face-images-container'>
      {subDocsData.map(((subDoc, index) => {
        return <FaceItem
          key={`faceItem_${index}`}
          image={subDoc?.avatar ? getImageFromBase64(subDoc.avatar) : ''}
          label={`Document photo ${String.fromCharCode(index + 65)}`}
        />;
      }))}
      <div className="video-player-section">
        <VideoPlayer entryName={entryName} trCode={trCode} path={PATH.QUICK_CHECK_FACE} />
      </div>
    </div>
  );
};

export default Face;
