import { getLocalStorageData } from 'util/storageHelper';
import { IPopupInfo } from 'store/features/popupInfo/types';

export default (): IPopupInfo => {
  const livenessPopupShowAgain: string | null = getLocalStorageData('livenessPopupShowAgain');
  const fraudPagesPopupShowAgain: string | null =
    getLocalStorageData('fraudPagesPopupShowAgain');
  const documentScanPopupShowAgain: string | null =
    getLocalStorageData('documentScanPopupShowAgain');
  const addressPopupShowAgain: string | null = getLocalStorageData('addressPopupShowAgain');

  return {
    livenessPopupShowAgain: !!(
      !livenessPopupShowAgain || livenessPopupShowAgain === 'true'
    ),
    fraudPagesPopupShowAgain: !!(
      !fraudPagesPopupShowAgain || fraudPagesPopupShowAgain === 'true'
    ),
    documentScanPopupShowAgain: !!(
      !documentScanPopupShowAgain || documentScanPopupShowAgain === 'true'
    ),
    addressPopupShowAgain: !!(
      !addressPopupShowAgain || addressPopupShowAgain === 'true'
    ),
  };
};
