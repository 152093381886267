import produce from 'immer';

import {
  IPopupInfo,
  TPopupInfoActions,
  PopupInfoActions,
} from 'store/features/popupInfo/types';
import getInitialState from 'store/features/popupInfo/initialState';
import { setLocalStorageDataItem } from 'util/storageHelper';

export const popupInfoReducer = (
  state: IPopupInfo = getInitialState(),
  action: TPopupInfoActions,
) => {
  const nextState = produce(state, (draftState: IPopupInfo) => {
    switch (action.type) {
    case PopupInfoActions.setLivenessPopupShowAgain:
      draftState.livenessPopupShowAgain = action.value;
      setLocalStorageDataItem('livenessPopupShowAgain', action.value);
      break;
    case PopupInfoActions.setFraudPagesPopupShowAgain:
      draftState.fraudPagesPopupShowAgain = action.value;
      setLocalStorageDataItem('fraudPagesPopupShowAgain', action.value);
      break;
    case PopupInfoActions.setDocumentScanPopupShowAgain:
      draftState.documentScanPopupShowAgain = action.value;
      setLocalStorageDataItem('documentScanPopupShowAgain', action.value);
      break;
    case PopupInfoActions.setAddressPopupShowAgain:
      draftState.addressPopupShowAgain = action.value;
      setLocalStorageDataItem('addressPopupShowAgain', action.value);
      break;
    }
    return draftState;
  });

  return nextState;
};
