import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { batchActions } from 'redux-batched-actions';

import Answers from 'components/footer/Answers';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import { ANSWERS_KEYS, BUTTON_STYLE, PATH } from 'constants/Static';
import { IRootState } from 'store/types';
import { getNextPage, getQuestion, getPreviousPage } from 'helper/navigation';
import * as commonActions from 'store/features/common/actions';
import * as checkResultActions from 'store/features/checkResult/actions';
import { Questions } from 'store/features/common/types';
import { IAnswerOption } from 'types';
import { EPage } from 'enums';
import { ICheckResult } from 'store/features/checkResult/types';
import { updatedExpireDateData } from 'helper/tools/quickCheck';


const CompareDate = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const page = HeaderButtons[EPage.quickCheck].expiryDate[Questions.comparing];
  const filesSliderPath = Number(state.common?.filesSliderPath) || 0;
  const { expiryDateData = [], } = checkResult;
  const { reviewResult } = expiryDateData[filesSliderPath];

  const submitAnswer = (answer: IAnswerOption) => {
    const updatedResult = updatedExpireDateData(expiryDateData, filesSliderPath, {
      reviewResult: {
        quality: answer.text,
        key: answer.key
      }
    });
    dispatch(checkResultActions.setExpiryDateData(updatedResult));
    if (answer.key === ANSWERS_KEYS.YES) {
      if (filesSliderPath === expiryDateData.length - 1) {
        const next = getNextPage(state, location.pathname);
        const nextQuestion = getQuestion(next);
        batchActions([
          dispatch(commonActions.setQuestion(nextQuestion)),
          dispatch(commonActions.setActiveSlidePath('')),
          dispatch(commonActions.setPrevPagePath('')),
        ]);
        history.replace(next);
      } else {
        dispatch(commonActions.setActiveSlidePath(String(filesSliderPath + 1)));
      }
    }
    if (answer.key === ANSWERS_KEYS.NO) {
      dispatch(commonActions.setForcedRejectedInfo({
        isForcedRejected: true,
        location: location.pathname,
        question: Questions.comparing,
      }));
      history.replace(PATH.SUMMARY_QUICK_CHECK);
    }
  };

  const back = () => {
    if (filesSliderPath === 0) {
      const previousPage = getPreviousPage(state, location.pathname);
      const previousQuestion = getQuestion(previousPage);
      batchActions([
        dispatch(commonActions.setQuestion(previousQuestion)),
        dispatch(commonActions.setPrevPagePath(location.pathname)),
        dispatch(commonActions.setActiveSlidePath('')),
        dispatch(checkResultActions.setExpiryDateData([])),
      ]);
      history.replace(previousPage);
    } else {
      dispatch(commonActions.setActiveSlidePath(String(filesSliderPath - 1)));
    }
  };

  return (
    <Answers
      back={back}
      answers={page?.answers}
      activeIndexes={[
        page?.answers.findIndex((item: IAnswerOption) => item.text === reviewResult.quality)
      ]}
      submitAnswer={submitAnswer}
      styled={BUTTON_STYLE.OUTLINE_PRIMARY}
      changeLocation={true}
    />
  );
};

export default CompareDate;