import React from 'react';
import { Provider } from 'react-redux';
import Routes from 'routing/Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'App.scss';
import { Helmet } from 'react-helmet';
import { ErrorBoundary } from 'react-error-boundary';
import { getUrlParams } from 'util/common';
import store from 'store';
import ErrorFallback from 'components/errorBoundary/ErrorFallback';
import { Error } from 'helper/api/route';
import LanguageProvider from 'LanguageProvider';

const errorHandler = (error: Error, info: { componentStack: string }) => {
  const message = `${error.message} ${info.componentStack.split('\n')[1].trim()}`;
  Error(message);
};

const getCrowdinContent = () => {
  const translate: boolean = getUrlParams().translate === 'true';
  if (process.env.REACT_APP_ENV !== 'CROWDIN' || !translate) return '';
  const crowdinProjectID = 'pxl-check';
  (window as any)._jipt = [];
  (window as any)._jipt.push(['project', crowdinProjectID]);
  return (
    <Helmet defer={false}>
      <script type="text/javascript" src="//cdn.crowdin.com/jipt/jipt.js" />
    </Helmet>
  );
};

const App = () => (
  <Provider store={store}>
    <div className="App">
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
        { getCrowdinContent() }
        <LanguageProvider>
          <Routes />
        </LanguageProvider>
      </ErrorBoundary>
    </div>
  </Provider>
);

export default App;
