import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ICheckResult, IDocumentData } from 'store/features/checkResult/types';
import { getPackagePosition } from 'helper/tools/fraudCheck';
import { Questions } from 'store/features/common/types';
import { IRootState } from 'store/types';

const Compare = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const { documentData = [] } = checkResult;
  const prevPagePath = state.common?.prevPagePath || '';
  const filesSliderPath = state.common?.filesSliderPath || '';

  const { packageIndex } = useMemo(() => (
    getPackagePosition(filesSliderPath, prevPagePath, documentData)
  ), [filesSliderPath, prevPagePath]);

  const images = documentData[packageIndex].filter(({ question }) => (
    question === Questions.imageSource
  ));

  return (
    <div className="template-container">
      {
        images.map((currentData: IDocumentData) => {
          const { front = '', back = '' } = currentData.specimen || {};
          const specimenImageStyle = front && back ? '' : 'half-width';
          return <div className="document-template-image-container" key={currentData.imageIndex}>
            {(!currentData.hasSpecimen) ? (
              <span className="template-not-available">
                {t('reg.document.template.noTemplate')}
              </span>) : (
              <>
                <div className="image-container-doc">
                  <div className="images-container">
                    {back && (
                      <div className="image-item">
                        <img
                          className={`image-style ${specimenImageStyle}`}
                          alt="mrz"
                          src={back}
                        />
                      </div>
                    )}
                    {front && (
                      <div className="image-item">
                        <img
                          className={`image-style ${specimenImageStyle}`}
                          alt="viz"
                          src={front}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="label-container">
                  {currentData.specimen?.duid?.split('-')[2] || ''}
                </div>
              </>
            )}
          </div>;
        })
      }
    </div>
  );
};

export default Compare;