import React, { useEffect, useRef, useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useTranslation } from 'react-i18next';

import { convertCoordinatesToPixels, getImageFromBase64 } from 'util/common';
import Loader from 'components/loader/Loader';
import { ReactComponent as ZoomIn } from 'images/zoom-in.svg';
import { ReactComponent as ZoomOut } from 'images/zoom-out.svg';
import { ReactComponent as Rotating } from 'images/rotating.svg';
import { ReactComponent as Highlight } from 'images/rectangle.svg';
import { ReactComponent as Images } from 'images/images.svg';
import ToolTipField from 'components/toolTipField/ToolTipField';
import Modal from 'components/modal/Modal';
import { ETools, EUnits } from 'enums';
import { ICoordinates } from 'types';

interface IImageWrapper {
  imageSrc: any;
  coordinates?: ICoordinates;
  onClickChangeImage?: any,
  hasAvailableImages?: boolean;
  modalProps?: any[],
  tools: ETools[]
}

const ImageWrapper = (props: IImageWrapper) => {
  const {
    tools,
    imageSrc,
    coordinates,
    modalProps = [],
    onClickChangeImage,
    hasAvailableImages = false,
  } = props;
  const { t } = useTranslation();
  const imageRef = useRef(imageSrc) as React.MutableRefObject<HTMLImageElement>;
  const containerRef = useRef() as React.MutableRefObject<HTMLImageElement>;
  const [imgContainerHeight, setImgContainerHeight] = useState(0);
  const [imgContainerWidth, setImgContainerWidth] = useState(0);
  const highLiteEnabled = coordinates && !!Object.keys(coordinates).length;
  const [showRectangle, setShowRectangle] = useState(highLiteEnabled);
  const [degree, setDegree] = useState(0);
  const [imageSize, setImageSize] = useState({ width: 0, hight: 0 });
  const [isFilesLoaded, setFilesLoaded] = useState(false);

  const [position, setPosition] = useState({
    place: {
      width: 0,
      height: 0,
      left: 0,
      top: 0,
    },
  });

  const setupZoom = (rest: any) => {
    rest.doubleClick.disabled = true;
    rest.pan.paddingSize = 0;
    rest.options.minScale = 1;
    rest.options.maxScale = 3;
  };

  const rotateImage = (hasOpenedModal: boolean) => {
    if (hasOpenedModal) return;
    setDegree((degree + 90) % 360);
    setShowRectangle(false);
  };

  const drawRectangle = () => {
    const { top, left } = imageRef.current.getBoundingClientRect();
    const { top: parentTop, left: parentLeft } = containerRef.current.getBoundingClientRect();
    const difCoordinateTop = parentTop - top;
    const difCoordinateLeft = parentLeft - left;
    setShowRectangle(false);
    if (highLiteEnabled) {
      const { naturalWidth, naturalHeight } = imageRef.current;
      const data = convertCoordinatesToPixels(coordinates, { naturalWidth, naturalHeight });
      const { x, y, width, height } = data;
      const absoluteLeft = (imageRef.current.offsetWidth * x) / (
        imageRef.current.naturalWidth);
      const absoluteTop = (imageRef.current.offsetHeight * y) / (
        imageRef.current.naturalHeight);
      const absoluteWidth = (imageRef.current.offsetWidth * width) / (
        imageRef.current.naturalWidth);
      const absoluteHeight = (imageRef.current.offsetHeight * height) / (
        imageRef.current.naturalHeight);
      setPosition({
        ...position,
        place: {
          width: absoluteWidth,
          height: absoluteHeight,
          left: absoluteLeft - difCoordinateLeft,
          top: absoluteTop - difCoordinateTop,
        },
      });
      setShowRectangle(true);
    } else {
      setPosition({
        ...position,
        place: {
          width: 0,
          height: 0,
          left: 0,
          top: 0,
        },
      });
    }
  };

  const onLoad = (
    setScale: Function,
    setPositionX: Function,
    setPositionY: Function,
    setDefaultState: Function
  ) => {
    setDegree(0);
    setDefaultState();
    const imageSizeRotate = {
      width: imageRef.current.clientWidth,
      hight: imageRef.current.clientHeight
    };
    setImageSize(imageSizeRotate);
    const scale = 1;
    const animationTime = 0;
    const animationType = 'easeOut';
    setPositionX(0, animationTime, animationType);
    setPositionY(0, animationTime, animationType);
    setScale(scale, animationTime, animationType);
    setFilesLoaded(true);
  };

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const {
        height: containerHeight,
        width: containerWidth,
      } = containerRef.current.getBoundingClientRect();
      setImgContainerHeight(containerHeight);
      setImgContainerWidth(containerWidth);
    }
  }, [degree, containerRef]);

  useEffect(() => {
    if (highLiteEnabled && isFilesLoaded) {
      drawRectangle();
    } else {
      setPosition({
        ...position,
        place: {
          width: 0,
          height: 0,
          left: 0,
          top: 0,
        },
      });
    }
  }, [isFilesLoaded, coordinates, imageRef?.current?.currentSrc]);

  const enabled = () => Object.values(position.place).every((measurements) => measurements);

  const renderModals = () => (
    !!modalProps.length && modalProps.map(
      (items: any, index: number) => (<Modal {...items} key={index}/>)
    )
  );

  const imageLoaded = imageSrc && imgContainerHeight && isFilesLoaded;

  return (
    <div className="wrapper-transform-component">
      <TransformWrapper
        defaultScale={1}
        zoomIn={{
          step: 15
        }}
        zoomOut={{
          step: 15
        }}
      >
        {({
          zoomIn,
          zoomOut,
          wheel,
          setScale,
          setPositionX,
          setPositionY,
          setDefaultState,
          ...rest
        }: any) => {
          wheel.disabled = true;
          setupZoom(rest);
          const hasOpenedModal = modalProps.some((item: any) => item.isModalShowing);
          const disabledZoomIn = (rest.scale === rest.options.maxScale) ? 'is-disabled'
            : hasOpenedModal ? 'rotate-disabled' : '';
          const disabledZoomOut = (rest.scale === rest.options.minScale) ? 'is-disabled'
            : hasOpenedModal ? 'rotate-disabled' : '';
          return (
            <div className='transform-component'>
              <div className="wrapper-image-container" ref={containerRef}>
                {!imageLoaded && <div className="loader-container"><Loader /></div>}
                <TransformComponent>
                  <img
                    style={(degree === 90 || degree === 270) && imageSize.hight ?
                      {
                        transform: `rotate(${degree}deg)`,
                        maxWidth: imgContainerHeight,
                        maxHeight: imgContainerWidth,
                      } :
                      { transform: `rotate(${degree}deg)`, maxWidth: '100%', maxHeight: '100%' }
                    }
                    src={getImageFromBase64(imageSrc)}
                    onLoad={() => onLoad(setScale, setPositionX, setPositionY, setDefaultState)}
                    ref={imageRef}
                    loading="lazy"
                    alt="transform-component"
                  />
                  {
                    showRectangle && enabled()
                    && (
                      <div
                        style={{
                          width: `${position.place.width}${EUnits.pixel}`,
                          height: `${position.place.height}${EUnits.pixel}`,
                          top: `${position.place.top}${EUnits.pixel}`,
                          left: `${position.place.left}${EUnits.pixel}`,
                          position: 'absolute',
                          zIndex: 999999999,
                        }}
                        className="high-light-style"
                      />
                    )
                  }
                </TransformComponent>
                {renderModals()}
              </div>
              <div className="tools">
                {
                  tools.includes(ETools.zoomIn) && <div
                    className={`zoom ${disabledZoomOut}`}
                    onClick={!hasOpenedModal && zoomOut}
                  >
                    <ZoomOut />
                  </div>
                }
                {
                  tools.includes(ETools.zoomOut) && <div
                    className={`zoom ${disabledZoomIn}`}
                    onClick={!hasOpenedModal && zoomIn}
                  >
                    <ZoomIn />
                  </div>
                }
                {
                  tools.includes(ETools.rotate) && <div
                    className={`rotate ${hasOpenedModal ? 'rotate-disabled' : ''}`}
                    onClick={() => rotateImage(hasOpenedModal)}
                  >
                    <Rotating />
                  </div>
                }
                {tools.includes(ETools.highLite) && (
                  <div
                    className={`highlight-container ${!enabled()
                      ? 'highlight-icon-disabled' : ''} `}
                    onClick={() => enabled() && setShowRectangle(!showRectangle)}
                  >
                    <Highlight
                      className={`highlight-icon ${!enabled() ? 'highlight-icon-disabled' : ''}`}
                    />
                  </div>
                )}
                {tools.includes(ETools.images) && (hasAvailableImages ?
                  (<div className="change-template-icon" onClick={onClickChangeImage}>
                    <Images />
                  </div>) : (
                    <ToolTipField
                      tooltipValue={t('reg.page.no-available-images')}
                      placement="top"
                    >
                      <div className="icon-tools rotate-disabled">
                        <Images />
                      </div>
                    </ToolTipField>
                  ))
                }
              </div>
            </div>
          );
        }}
      </TransformWrapper>
    </div>
  );
};

export default ImageWrapper;
