import {
  IAppConfig,
  CommonActions,
  IInstructionUrl,
  IDocRequiredFields,
  IDashboardActionValue,
} from 'store/features/common/types';

export const setResetCode = (value: boolean) => ({
  type: CommonActions.setResetCode,
  value,
});

export const setReviewState = (value: boolean) => ({
  type: CommonActions.setReviewState,
  value,
});

export const setQuestion = (value: string) => ({
  type: CommonActions.setQuestion,
  value,
});

export const setDocRequiredFields = (value: IDocRequiredFields) => ({
  type: CommonActions.setDocRequiredFields,
  value,
});

export const setInstruction = (value: IInstructionUrl) => ({
  type: CommonActions.setInstruction,
  value,
});

export const setForcedRejectedInfo = (value: object) => ({
  type: CommonActions.setForcedRejectedInfo,
  value,
});

export const setActiveSlidePath = (value: string) => ({
  type: CommonActions.setActiveSlidePath,
  value,
});

export const setDefaultTransactionCode = (value: string) => ({
  type: CommonActions.setDefaultTransactionCode,
  value,
});

export const setPrevPagePath = (value: string) => ({
  type: CommonActions.setPrevPagePath,
  value,
});

export const setDashboardData = (value: IDashboardActionValue) => ({
  type: CommonActions.setDashboardData,
  value,
});

export const setDashboardMode = (value: boolean) => ({
  type: CommonActions.setDashboardMode,
  value,
});

export const setDashboardTableLoading = (value: boolean) => ({
  type: CommonActions.setDashboardTableLoading,
  value,
});

export const resetDashboardTablePage = (value: boolean) => ({
  type: CommonActions.resetDashboardTablePage,
  value,
});

export const setAppConfig = (value: IAppConfig) => ({
  type: CommonActions.setAppConfig,
  value,
});
