import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';
import { v4 as uuid } from 'uuid';

import { ReactComponent as BackButton } from 'images/right.svg';
import ButtonTypes from 'components/buttonTypes/ButtonTypes';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import Countries from 'constants/Countries.json';
import DocumentTypes from 'constants/DocumentTypes.json';
import SelectOption from 'components/select/SelectOption';
import {
  SOMETHING,
  ANSWERS_KEYS,
  BUTTON_STYLE,
  DEFAULT_SLIDER_PATH,
} from 'constants/Static';
import * as commonActions from 'store/features/common/actions';
import * as checkResultActions from 'store/features/checkResult/actions';
import { IRootState } from 'store/types';
import { Questions } from 'store/features/common/types';
import { PATH } from 'constants/Static';
import BigModal from 'components/bigModal/BigModal';
import SingleButton from 'components/buttonTypes/SingleButton';
import { IAnswerOption, IOptionType, ISelectOption } from 'types';
import { GetSpecimens } from 'helper/api/route';
import { ICheckResult } from 'store/features/checkResult/types';
import {
  getCountryValue,
  getNext,
  updatedFilesData,
  getExtraDetailValue,
  getDocumentTypeValue,
  collectExtraDetailOptions,
  detectForcedRejection,
  areAllUnknownDocument,
  checkPackageDocumentDifference,
} from 'helper/tools/quickCheck';
import { getActiveImagePath, isUnknownDocument } from 'helper/tools/commonHelper';
import { ISpecimen } from 'store/features/specimen/types';
import { EDirection, EDocumentType, EPage } from 'enums';
import Loader from 'components/loader/Loader';

const { SUMMARY_QUICK_CHECK } = PATH;

const DocumentCountry = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const page = HeaderButtons[EPage.quickCheck].files[Questions.countryDocType];
  const checkResult = state.checkResult as ICheckResult;
  const { filesData = [] } = checkResult;
  const subDocsData = state.zipContent?.subDocsData || [];
  const filesSliderPath = state.common?.filesSliderPath || '';
  const {
    subDocIndex,
    imageIndex,
  } = useMemo(() => getActiveImagePath(filesSliderPath), [filesSliderPath]);
  const { country, extraDetail, documentType } = filesData[subDocIndex][imageIndex];
  const { documentTypeData } = subDocsData[subDocIndex];
  const [countryValue, setCountry] = useState(country || documentTypeData.documentCountry);
  const [docTypeValue, setDocType] = useState(documentType || documentTypeData.documentType);
  const [extraDetailValue, setExtraDetail] = useState(extraDetail || '');
  const [specimens, setSpecimens] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isSpecimenLoaded, setIsSpecimenLoaded] = useState(false);

  useEffect(() => {
    setCountry(country);
    setDocType(documentType);
    setExtraDetail(extraDetail);
  }, [filesSliderPath]);

  useEffect(() => {
    setIsSpecimenLoaded(false);
    try {
      if (!isUnknownDocument(filesData[subDocIndex][imageIndex]) && docTypeValue && countryValue) {
        (async () => {
          const { data } = await GetSpecimens(docTypeValue, countryValue.toLowerCase());
          const specimens = data.filter((item: ISpecimen) => item.extraDetail);
          if (extraDetailValue && !getExtraDetailValue(specimens, extraDetailValue, t)) {
            setExtraDetail('');
          }
          setSpecimens(specimens);
          setIsSpecimenLoaded(true);
        })();
      } else {
        setSpecimens([]);
        setIsSpecimenLoaded(true);
      }
    } catch {
      setSpecimens([]);
      setIsSpecimenLoaded(true);
    }
  }, [countryValue, docTypeValue, extraDetailValue]);

  const submitAnswer = (answer: IAnswerOption) => {
    if (answer.key === ANSWERS_KEYS.NEXT) {
      const updatedResult = updatedFilesData(filesData, subDocIndex, [imageIndex], {
        country: countryValue,
        documentType: docTypeValue,
        extraDetail: specimens.length ? extraDetailValue : '',
      });
      dispatch(checkResultActions.setFilesData(updatedResult));
      const path = getNext(filesData, subDocIndex, imageIndex, EDirection.right);
      if (path) {
        dispatch(commonActions.setActiveSlidePath(path));
      } else {
        const areAllUnknown = areAllUnknownDocument(updatedResult[subDocIndex]);
        if (areAllUnknown) {
          return forceReject();
        }
        const hasSameDocumentData = checkPackageDocumentDifference(updatedResult[subDocIndex]);
        if (hasSameDocumentData) {
          const nextPath = getNext(filesData, 0, -1, EDirection.right, Questions.documentPage);
          batchActions([
            dispatch(commonActions.setActiveSlidePath(nextPath || DEFAULT_SLIDER_PATH)),
            dispatch(commonActions.setQuestion(Questions.documentPage)),
          ]);
        } else {
          dispatch(commonActions.setQuestion(Questions.comparing));
        }
      }
    } else if (answer.key === ANSWERS_KEYS.NOT_IN_LIST) {
      setShowModal(true);
    }
  };

  const back = () => {
    const previousPath = getNext(filesData, subDocIndex, imageIndex, EDirection.left);
    if (previousPath) {
      dispatch(commonActions.setActiveSlidePath(previousPath));
    } else {
      history.replace(PATH.DASHBOARD);
    }
  };

  const forceReject = () => {
    batchActions([
      dispatch(commonActions.setForcedRejectedInfo({
        isForcedRejected: true,
        location: location.pathname,
        question: Questions.countryDocType,
        filesSliderPath,
      })),
      dispatch(commonActions.setActiveSlidePath('')),
      dispatch(commonActions.setPrevPagePath('')),
    ]);
    return history.replace(SUMMARY_QUICK_CHECK);
  };

  const onAccept = () => {
    const updatedResult = updatedFilesData(filesData, subDocIndex, [imageIndex], {
      country: SOMETHING,
      documentType: EDocumentType.something,
      extraDetail: '',
    });
    dispatch(checkResultActions.setFilesData(updatedResult));
    if (detectForcedRejection(updatedResult)) {
      return forceReject();
    }
    const nextPath = getNext(filesData, subDocIndex, imageIndex, EDirection.right);
    if (nextPath) {
      dispatch(commonActions.setActiveSlidePath(nextPath));
    } else {
      const hasSameDocumentData = checkPackageDocumentDifference(updatedResult[subDocIndex]);
      if (hasSameDocumentData) {
        dispatch(commonActions.setActiveSlidePath(DEFAULT_SLIDER_PATH));
        dispatch(commonActions.setQuestion(Questions.documentPage));
      } else {
        dispatch(commonActions.setQuestion(Questions.comparing));
      }
    }
    setShowModal(false);
  };

  const countryProps: ISelectOption = {
    value: getCountryValue(countryValue, t),
    handler: (selectedOption: IOptionType) => setCountry(selectedOption.value),
    uid: uuid(),
    placeholder: t('field.documentCountry.title'),
    options: Countries.map((option) => ({
      label: `${t(option.label)} (${option.value.toUpperCase()})`,
      value: option.value,
    })),
    menuBorder: 'circle',
    inputBorder: 'circle',
    styledScrollBar: 'cicle-select-scroll-bar',
  };

  const documentTypeProps: ISelectOption = {
    value: getDocumentTypeValue(docTypeValue, t),
    handler: (selectedOption: IOptionType) => setDocType(selectedOption.value as EDocumentType),
    uid: uuid(),
    placeholder: t('field.documentType.title'),
    options: DocumentTypes.map((docType: any) => ({
      label: t(docType.label),
      value: docType.value,
    })),
    menuBorder: 'circle',
    inputBorder: 'circle',
    styledScrollBar: 'cicle-select-scroll-bar',
  };

  const extraDetailProps: ISelectOption = {
    value: getExtraDetailValue(specimens, extraDetailValue, t),
    handler: (selectedOption: IOptionType) => setExtraDetail(selectedOption.value),
    uid: uuid(),
    placeholder: t('Extra Detail'),
    options: collectExtraDetailOptions(specimens, t, true),
    menuBorder: 'circle',
    inputBorder: 'circle',
    styledScrollBar: 'cicle-select-scroll-bar',
  };

  const modalProps = {
    isModalNewAttack: false,
    isFromDocCountry: true,
    title: `${t('reg.files.countryDocType.not-in-list.modal.title')}`,
    subTitle: `${t('reg.files.countryDocType.not-in-list.modal.sub-title')}`,
    submitButtonText: `${'reg.files.countryDocType.not-in-list.modal.accept'}`,
    cancelButtonText: `${'reg.files.countryDocType.not-in-list.modal.back'}`,
    rejectButtonText: `${'reg.files.countryDocType.not-in-list.modal.reject'}`,
    bodyButton: true,
    footerStyle: 'modal-footer-join',
    onHide: () => setShowModal(false),
    onSuccess: () => onAccept(),
    onReject: () => forceReject(),
    show: showModal,
    isDisabled: false,
  };

  const isDisabled = (
    (!countryValue || !docTypeValue || (specimens.length && !extraDetailValue)) ||
    (countryValue === SOMETHING && docTypeValue !== EDocumentType.something) ||
    (countryValue !== SOMETHING && docTypeValue === EDocumentType.something)
  ) ? [(page?.answers || []).length - 1] : [];

  if (!isSpecimenLoaded) {
    return <div className="loader-container"><Loader /></div>;
  }

  return (
    <div className="buttons-component">
      {<div className="back-button">
        <SingleButton
          textButton={'reg.files.back'}
          onClick={back}
          variant={BUTTON_STYLE.OUTLINE_PRIMARY}
          radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
          LeftIcon={BackButton}
        />
      </div>}
      <div className="main-buttons">
        <SelectOption {...countryProps} />
        <SelectOption {...documentTypeProps} />
        {!!specimens.length && <SelectOption {...extraDetailProps} />}
        <ButtonTypes
          answers={page?.answers}
          activeIndexes={[0, 1]}
          handleClick={submitAnswer}
          radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
          styled={BUTTON_STYLE.OUTLINE_SECONDARY}
          disabled={isDisabled}
        />
      </div>
      <BigModal {...modalProps} />
    </div>
  );
};

export default DocumentCountry;
