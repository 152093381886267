import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'store/types';
import ImageWrapper from 'components/imageWrapper/ImageWrapper';
import { ICheckResult } from 'store/features/checkResult/types';
import AvailableModalBody from 'components/modal/modalContent/AvailableImagesContent';
import * as checkResultActions from 'store/features/checkResult/actions';
import { updatedExpireDateData } from 'helper/tools/quickCheck';
import { ETools } from 'enums';
import { TFileImage } from 'store/features/zipContent/types';

const ExpiryDateComparison = () => {
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const subDocsData = state.zipContent?.subDocsData || [];
  const { expiryDateData = [] } = checkResult;
  const filesSliderPath = Number(state.common?.filesSliderPath) || 0;
  const { subDocIndex, imageIndex, availableImageIndexes } = expiryDateData[filesSliderPath];
  const image: string = Object.values(subDocsData[subDocIndex].files[imageIndex])[0];
  const imageName = Object.keys(subDocsData[subDocIndex].files[imageIndex])[0]
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [isAvailableModalShowing, setAvailableModalShowing] = useState(false);

  const onImageSelect = (imageName: string) => {
    const imageIndex = subDocsData[subDocIndex].files.findIndex(
      (item: TFileImage) => Object.keys(item)[0] === imageName
    )
    const updatedResult = updatedExpireDateData(expiryDateData, filesSliderPath, {
      imageIndex,
    });
    dispatch(checkResultActions.setExpiryDateData(updatedResult));
  };

  const hideModal = () => {
    if (!isAvailableModalShowing) {
      setIsModalShowing(false);
    } else {
      setAvailableModalShowing(false);
    }
  };

  const images = subDocsData[subDocIndex].files.filter((item: TFileImage, index) => (
    availableImageIndexes.includes(index))
  );

  const getAvailableBody = () => {
    return <AvailableModalBody
      onSelectImage={onImageSelect}
      images={images}
      selected={imageName}
    />;
  };

  const showAvModal = () => {
    if (isModalShowing) return;
    setAvailableModalShowing(true);
  };

  const modalProps = [{
    ModalBody: getAvailableBody(),
    setIsModalShowing: hideModal,
    isModalShowing: isAvailableModalShowing,
  }];

  return (
    <div className="main-container">
      <ImageWrapper
        imageSrc={image}
        onClickChangeImage={showAvModal}
        hasAvailableImages={availableImageIndexes.length > 1}
        modalProps={modalProps}
        tools={[ETools.zoomIn, ETools.zoomOut, ETools.rotate, ETools.images]}
      />
    </div>
  );
};

export default ExpiryDateComparison;
