import React from 'react';

import ToolTipField from 'components/toolTipField/ToolTipField';
import { EField } from 'enums';
import FieldsData from 'constants/FieldsData.json';
import { IFieldsData } from 'types';
import { useTranslation } from 'react-i18next';

const DrawDataValidation = (props: any) => {
  const { t } = useTranslation();
  const {
    pageIndex = '',
    firstData,
    secondData,
    icon = '',
    status = '',
    field = '',
  } = props;
  const fieldData = FieldsData.find((fd: IFieldsData) => fd.name === field);

  return (
    <div className="accordion-sub-body-row" key={`accSubBody_${pageIndex}`}>
      <div className="data-list-item">
        <div className="text-section">
          <div className="text-area">
            <ToolTipField
              tooltipValue={t(fieldData?.title || field)}
              placement="top"
              className="field-text"
            >
              <span className="field-text">{t(fieldData?.title || field)}</span>
            </ToolTipField>
          </div>
          <div className="text-area">
            <ToolTipField tooltipValue={firstData.value} placement="top">
              <span
                className={`field-text ${
                  field !== EField.drivingCategory && firstData.status
                }`}
              >
                {firstData.value || ''}
              </span>
            </ToolTipField>
          </div>
          <div className="text-area">
            <ToolTipField tooltipValue={secondData.value} placement="top">
              <span
                className={`field-text ${
                  field !== EField.drivingCategory && secondData.status
                }`}
              >
                {secondData.value}
              </span>
            </ToolTipField>
          </div>
        </div>
        {icon && <span className={`field-icon ${status}`}>{icon}</span>}
      </div>
    </div>
  );
};

export default DrawDataValidation;