import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { HeaderButtons } from 'constants/CheckTypeButtons';
import InfoBar from 'components/infoBar/InfoBar';
import Footer from 'components/footer/Footer';
import Check from 'pages/dataCheck/validation/Check';
import RightMenu from 'pages/dataCheck/validation/RightMenu';
import { IRootState } from 'store/types';
import { Questions } from 'store/features/common/types';
import { EDirection, EPage } from 'enums';
import { ICheckResult, IDataValidation } from 'store/features/checkResult/types';
import { fetchCompareResult, getDataValidation, navigateToNextTab } from 'helper/tools/dataCheck';
import * as checkResultActions from 'store/features/checkResult/actions';
import * as commonActions from 'store/features/common/actions';
import Loader from 'components/loader/Loader';
import BurgerMenu from 'components/burgerMenu/BurgerMenu';

const CheckComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const subDocsData = state.zipContent?.subDocsData || [];
  const prevPagePath = state.common?.prevPagePath || '';
  const filesSliderPath = state.common?.filesSliderPath || '';
  const checkResult = state.checkResult as ICheckResult;
  const { dataValidation = [] } = checkResult;
  const { docRequiredFields } = state?.common || {};
  const page = HeaderButtons[EPage.dataCheck]['document-data-validation'][Questions.fields];
  const [isPageReady, setPageReady] = useState(false);

  useEffect(() => {
    if (!checkResult?.dataValidation.length) {
      const fetchInitialContent = async (initialData: IDataValidation[]) => {
        const updatedFields = await fetchCompareResult(initialData);
        const updatedData = initialData.reduce((acc: IDataValidation[], item, index) => {
          acc.push({ ...item, fields: updatedFields[index] });
          return acc;
        }, []);
        dispatch(checkResultActions.setDataValidation(updatedData));
      }
      const initialData = getDataValidation(checkResult, subDocsData, docRequiredFields);
      fetchInitialContent(initialData);
      const currentPath = navigateToNextTab(initialData, '', EDirection.right, prevPagePath);
      dispatch(commonActions.setActiveSlidePath(currentPath));
      setPageReady(true);
    } else if (!filesSliderPath) {
      const currentPath = navigateToNextTab(dataValidation, '', EDirection.left, prevPagePath);
      dispatch(commonActions.setActiveSlidePath(currentPath));
      setPageReady(true);
    } else {
      setPageReady(true);
    }
  }, [filesSliderPath]);

  if (!isPageReady || !dataValidation.length || !filesSliderPath) {
    return <div className="loader-container"><Loader /></div>;
  }

  return (<>
    <InfoBar />
    <>
      <BurgerMenu />
      <h3>{t(page?.heading || '')}</h3>
    </>
    <div className="validation-check-content">
      <Check />
      <div className="right-menu-container">
        <RightMenu />
      </div>
    </div>
    <Footer NestedComponent={page?.answerComponent} />
  </>);
};

export default CheckComponent;
