import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import {
  GetZipContent,
  SearchTransactionByCode,
  GetRequiredFields,
  UpdateTransactionState,
} from 'helper/api/route';
import SwitchDatasets from 'components/dashboardModal/SwitchDatasets';
import SearchTransaction from 'components/dashboardModal/SearchTransaction';
import NewTransactionModal from 'components/dashboardModal/NewTransactionModal';
import { getStorageData } from 'util/storageHelper';
import { extractXMLData, getSubDocsData } from 'util/stateHelper';
import { PATH } from 'constants/Static';
import NewTransactions from 'pages/dashboard/NewTransactions';
import * as rootActions from 'store/actions';
import * as commonActions from 'store/features/common/actions';
import * as zipContentActions from 'store/features/zipContent/actions';
import { IRootState } from 'store/types';
import { IStyledText } from 'types';
import {
  getFlowMode,
  getErrorMessage,
  hasDocumentForCheck,
  getDefaultDataset,
  hasDefaultProps,
} from 'helper/tools/commonHelper';
import TransactionsTable from 'pages/dashboard/TransactionsTable';
import { Questions } from 'store/features/common/types';
import { IDataSet } from 'store/features/dataSets/types';
import { ETransactionState } from 'enums';

const {
  DASHBOARD,
  DATASET,
  TA_BOARDING,
  QUICK_CHECK_FILES,
  FRAUD_CHECK_LIVENESS,
} = PATH;

const Dashboard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const datasets: IDataSet[] = state.dataSets || [];
  const [isWaiting, setIsWaiting] = useState(false);
  const [isSearchModalShowing, setSearchModalIsShowing] = useState(false);
  const [isSwitchModalShowing, setSwitchModalIsShowing] = useState(false);
  const [isErrorModalShowing, setErrorModalIsShowing] = useState(false);
  const [message, setMessage] = useState<Array<IStyledText>>([]);
  const [transactionCode, setTransactionCode] = useState('');
  const defaultDataset: IDataSet = getDefaultDataset(datasets);
  const [isUpdateInProgress, setIsUpdate] = useState(true);
  const isSimplified = getFlowMode(datasets);
  const storageData: string | null = getStorageData('state');

  const goToTaBoarding = (value: string) => {
    dispatch(zipContentActions.setZipContentLoader(true));
    setIsWaiting(true);
    GetZipContent(value).then((response) => {
      const { subDocs, commonData } = response.data;
      const packageIsValid = Object.values(subDocs).every(
        (item: any) => Object.keys(item.dataXML).length
      );
      const hasImages = Object.values(subDocs).some((subDocData: any) => {
        return subDocData.files.length;
      });
      if (packageIsValid && (commonData.video?.url || hasImages)) {
        Promise.all(extractXMLData(subDocs)).then((data) => {
          const modifiedResponseData = { ...response.data };
          for (let index = 0; index < data.length; index++) {
            modifiedResponseData.subDocs[index].dataXML = data[index];
          }
          batchActions([
            dispatch(zipContentActions.setZipContent(modifiedResponseData)),
            dispatch(commonActions.setResetCode(true)),
          ]);
          setIsWaiting(false);
          dispatch(zipContentActions.setZipContentLoader(false));
          const {
            subDocs: packageData = {},
            commonData: common = {},
          } = modifiedResponseData;
          const { url: videoURL = '' } = common.video;
          const subDocsData = getSubDocsData(packageData);
          if (hasDocumentForCheck(subDocsData) || videoURL) {
            GetRequiredFields(defaultDataset.requiredFieldsType).then((res) => {
              const { data: docRequiredFields } = res;
              dispatch(commonActions.setDocRequiredFields(docRequiredFields));
            });
            if (isSimplified) {
              dispatch(commonActions.setReviewState(true));
              if (hasDocumentForCheck(subDocsData)) {
                return history.replace(QUICK_CHECK_FILES);
              }
              dispatch(commonActions.setQuestion(Questions.quality));
              return history.replace(FRAUD_CHECK_LIVENESS);
            }
            history.replace(TA_BOARDING);
          } else {
            setErrorModalIsShowing(true);
            setMessage([
              { text: t('messages.transaction') },
              { text: value, type: 'bold' },
              { text: t('messages.noContent') },
            ]);
            UpdateTransactionState({ code: value, state: ETransactionState.inError });
          }
        });
      } else if (value){
        setErrorModalIsShowing(true);
        UpdateTransactionState({ code: value, state: ETransactionState.inError });
        setMessage([
          { text: t('messages.inError') },
        ]);
        dispatch(zipContentActions.setZipContentLoader(false));
      }
    }).catch((err) => {
      const errorText = getErrorMessage(err, value);
      setErrorModalIsShowing(true);
      setMessage(errorText);
      dispatch(zipContentActions.setZipContentLoader(false));
      setIsWaiting(false);
    });
  };

  const getTransactionDataByCode = (value: string) => {
    dispatch(commonActions.setDefaultTransactionCode(''));
    if (value) {
      SearchTransactionByCode(value).then((response) => {
        const { state: transactionState } = response.data;
        switch (transactionState) {
        case ETransactionState.needReview: {
          goToTaBoarding(value);
          break;
        }
        case ETransactionState.reviewed:
        case ETransactionState.checkCompleted:
        case ETransactionState.pxlCheckError:
        {
          setErrorModalIsShowing(true);
          setMessage([
            { text: t('messages.transaction') },
            { text: value, type: 'bold' },
            { text: t('messages.reviewed') },
          ]);
          break;
        }
        case ETransactionState.inReview: {
          setErrorModalIsShowing(true);
          setMessage([
            { text: t('messages.transaction') },
            { text: value, type: 'bold' },
            { text: t('messages.inReview') },
          ]);
          break;
        }
        case ETransactionState.deleted: {
          setErrorModalIsShowing(true);
          setMessage([
            { text: t('messages.deleted') },
          ]);
          break;
        }
        case ETransactionState.completed: {
          setErrorModalIsShowing(true);
          setMessage([
            { text: t('messages.transaction') },
            { text: value, type: 'bold' },
            { text: t('messages.completed') },
          ]);
          break;
        }
        case ETransactionState.inError: {
          setErrorModalIsShowing(true);
          setMessage([
            { text: t('messages.inError') },
          ]);
          break;
        }
        default: {
          setErrorModalIsShowing(true);
          setMessage([
            { text: t('messages.transaction') },
            { text: value, type: 'bold' },
            { text: t('messages.notFound') },
          ]);
          break;
        }
        }
      }).catch((error) => {
        setErrorModalIsShowing(true);
        const errorText = getErrorMessage(error, value);
        setMessage(errorText);
      });
    }
  };

  useEffect(() => {
    const stateData = storageData ? JSON.parse(storageData) : {};
    const { resetCode, trCode } = stateData;
    if (resetCode && trCode) {
      setIsUpdate(true);
      UpdateTransactionState(
        { code: trCode, state: ETransactionState.needReview },
      )
        .finally(() => setIsUpdate(false));
    } else setIsUpdate(false);
    if (storageData) {
      dispatch(rootActions.resetSession());
    }
  }, []);

  useEffect(() => {
    if (hasDefaultProps(datasets)) {
      history.replace(DASHBOARD);
    } else {
      history.replace(DATASET);
    }
  }, [datasets]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tid = urlParams.get('tid');
    if (tid && !isUpdateInProgress) {
      setTransactionCode(tid);
      getTransactionDataByCode(tid);
    }
  }, [isUpdateInProgress]);

  return (
    <div className="dashboard">
      <div className="dashboard-container">
        <div
          className={`overlap-area ${!(isSearchModalShowing
            || isSwitchModalShowing || isErrorModalShowing
          ) ? 'overlap_disable' : ''}`}
        />
        {isSearchModalShowing && (
          <SearchTransaction
            close={setSearchModalIsShowing}
            title={t('reg.dashboard.modal.search.transaction')}
          />
        )}
        {isSwitchModalShowing && (
          <SwitchDatasets
            close={setSwitchModalIsShowing}
            title={t('reg.dashboard.modal.available.accounts')}
          />
        )}
        {isErrorModalShowing && (
          <NewTransactionModal
            close={setErrorModalIsShowing}
            message={message}
            code={transactionCode}
          />
        )}
        <NewTransactions
          setSearchModalIsShowing={setSearchModalIsShowing}
          setSwitchModalIsShowing={setSwitchModalIsShowing}
          setErrorModalIsShowing={setErrorModalIsShowing}
          setMessage={setMessage}
          setIsWaiting={setIsWaiting}
          isWaiting={isWaiting}
        />
        <TransactionsTable historyData={storageData} />
      </div>
    </div>
  );
};

export default Dashboard;
