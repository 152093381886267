import React, {
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import { BUTTON_STYLE, ANSWERS_KEYS, COMPARE_LIST } from 'constants/Static';
import { IFieldsData } from 'types';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import DrivingField from 'components/drivingField/DrivingField';
import { IRootState } from 'store/types';
import SingleButton from 'components/buttonTypes/SingleButton';
import { ReactComponent as BackButton } from 'images/right.svg';
import { Questions } from 'store/features/common/types';
import FieldsData from 'constants/FieldsData.json';
import { EDirection, EField, EPage, EZone } from 'enums';
import FieldItem from 'components/field/FieldItem';
import {
  getPackageInfo,
  updateFieldValue,
  navigateToNextTab,
  updateDataValidation,
} from 'helper/tools/dataCheck';
import { ICheckResult } from 'store/features/checkResult/types';
import * as checkResultActions from 'store/features/checkResult/actions';
import * as commonActions from 'store/features/common/actions';
import { getNextPage, getPreviousPage, getQuestion } from 'helper/navigation';
import { CompareFields } from 'helper/api/route';
import { fieldValueValidated, fullSchemaValidated, valuesIsSame } from 'helper/validation';

const CheckFields = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const { dataValidation = [], filesData = [] } = checkResult;
  const filesSliderPath = state.common?.filesSliderPath || '';
  const page = HeaderButtons[EPage.dataCheck]['document-data-validation'][Questions.fields];
  const {
    zone,
    field,
    packageIndex,
  } = useMemo(() => getPackageInfo(filesSliderPath), [filesSliderPath]);
  const { fields, subDocIndex, imageIndex, zones } = dataValidation[packageIndex];
  const { value, extractedValue, optional } = fields[field][zone];
  const { documentType, country } = filesData[subDocIndex][imageIndex];
  const [fieldValue, setFieldValue] = useState(value);

  const validated = useMemo(() => (
    fieldValueValidated(field, zone, fieldValue, optional)
  ), [fieldValue, filesSliderPath]);

  const allValidated = useMemo(() => (
    fullSchemaValidated(dataValidation[packageIndex], field, zone)
  ), [filesSliderPath]);

  useEffect(() => setFieldValue(value), [filesSliderPath, dataValidation]);

  const saveCurrentData = () => {
    const [targetZone] = zones.filter(item => item !== zone);
    const { exists: dstExists = false, value: dstValue = '' } = fields[field]?.[targetZone] || {};
    if (zones.length === 2 && dstExists && dstValue && COMPARE_LIST.includes(field as EField)) {
      const unicodeString = zone === EZone.viz ? fieldValue : dstValue;
      const asciiString = zone !== EZone.viz ? fieldValue : dstValue;
      CompareFields({[field]: { unicodeString, asciiString }}).then((response) => {
        const { equals } = response.data[field];
        const updatedFields = updateFieldValue(fields, field, zone, {
          value: fieldValue,
          modified: !valuesIsSame(extractedValue, fieldValue, field),
          equals,
        });
        const updatedTargetFields = updateFieldValue(updatedFields, field, targetZone, {
          equals,
        });
        const updatedData = updateDataValidation(dataValidation, packageIndex, {
          fields: updatedTargetFields,
        });
        dispatch(checkResultActions.setDataValidation(updatedData));
      });
    } else if (field !== EField.drivingCategory) {
      const equals = dstExists ? valuesIsSame(fieldValue, dstValue, field) : true;
      const updatedFields = updateFieldValue(fields, field, zone, {
        value: fieldValue,
        modified: !valuesIsSame(extractedValue, fieldValue, field),
        equals
      });
      const updatedTargetFields = updateFieldValue(updatedFields, field, targetZone, {
        equals,
      });
      const updatedData = updateDataValidation(dataValidation, packageIndex, {
        fields: updatedTargetFields,
      });
      dispatch(checkResultActions.setDataValidation(updatedData));
    }
  };

  const clickHandlerIsCorrect = () => {
    saveCurrentData();
    const nextPath = navigateToNextTab(dataValidation, filesSliderPath, EDirection.right);
    if (!nextPath || nextPath === filesSliderPath) {
      dispatch(commonActions.setActiveSlidePath(''));
      return history.replace(getNextPage(state, location.pathname));
    } else {
      dispatch(commonActions.setActiveSlidePath(nextPath));
    }
  };

  const back = () => {
    const nextPath = navigateToNextTab(dataValidation, filesSliderPath, EDirection.left);
    if (!nextPath || nextPath === filesSliderPath) {
      const previousPage = getPreviousPage(state, location.pathname);
      const previousQuestion = getQuestion(previousPage);
      batchActions([
        dispatch(commonActions.setQuestion(previousQuestion)),
        dispatch(commonActions.setPrevPagePath(location.pathname)),
        dispatch(commonActions.setActiveSlidePath('')),
        dispatch(checkResultActions.setDataValidation([])),
      ]);
      return history.replace(previousPage);
    } else {
      dispatch(commonActions.setActiveSlidePath(nextPath));
    }
  };

  const clickHandlerConfirmAll = () => {
    saveCurrentData();
    dispatch(commonActions.setActiveSlidePath(''));
    return history.replace(getNextPage(state, location.pathname));
  };

  const getSingleButtonProps = (key: string, text: string) => {
    switch (key) {
    case ANSWERS_KEYS.IS_CORRECT:
      return <SingleButton
        textButton={text}
        isActive={validated}
        onClick={clickHandlerIsCorrect}
        isDisabled={!validated}
        radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
        variant={BUTTON_STYLE.OUTLINE_SECONDARY}
      />;
    case ANSWERS_KEYS.CONFIRM_ALL:
      return <SingleButton
        textButton={text}
        isActive={validated && allValidated}
        onClick={clickHandlerConfirmAll}
        isDisabled={!(validated && allValidated)}
        radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
        variant={BUTTON_STYLE.OUTLINE_SECONDARY}
      />;
    }
  };

  const fieldData = FieldsData.find((fd: IFieldsData) => fd.name === field);

  return (
    <div className="check-field-answers">
      {
        field !== EField.drivingCategory
          ? (
            <div className="check-answer-section">
              <div className="check-field-label-section">
                <div className="filed-name">
                  <span className="label">{t(fieldData?.title || field)}</span>
                </div>
                <div className="info-name">
                  <span className="info">{t(fieldData?.info || '')}</span>
                </div>
                <div className="error-name">
                  <span className="error">
                    {fieldValue && !validated ? t(fieldData?.errorMessage || '') : ''}
                  </span>
                </div>
              </div>
              <div className="field-section">
                <div className="buttons-component">
                  <SingleButton
                    textButton={'reg.files.back'}
                    onClick={back}
                    LeftIcon={BackButton}
                    variant={BUTTON_STYLE.OUTLINE_PRIMARY}
                    radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
                  />
                  <div className="main-buttons">
                    <FieldItem
                      field={field}
                      fieldValue={fieldValue}
                      setFieldValue={setFieldValue}
                      validated={validated}
                      documentType={documentType}
                      country={country}
                    />
                    <div className="buttons-section" >
                      {page?.answers?.map((answer: any) => {
                        return <React.Fragment key={answer.key}>
                          {getSingleButtonProps(answer.key, answer.text)}
                        </React.Fragment>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : <DrivingField
            clickHandlerIsCorrect={clickHandlerIsCorrect}
            onClickHandlerConfirmAll={clickHandlerConfirmAll}
            back={back}
            allValidated={allValidated}
          />
      }
    </div>
  );
};

export default CheckFields;
