import produce from 'immer';

import {
  IZipContent,
  TZipContentActions,
  ZipContentActions,
} from 'store/features/zipContent/types';
import getInitialState from 'store/features/zipContent/initialState';
import { locateZipContent } from 'util/stateHelper';
import { setStorageDataItemByKey } from 'util/storageHelper';

export const zipContentReducer = (
  state: IZipContent = getInitialState(),
  action: TZipContentActions,
) => {
  const nextState = produce(state, (draftState: IZipContent) => {
    switch (action.type) {
    case ZipContentActions.setZipContent:
      locateZipContent(action.value, draftState);
      setStorageDataItemByKey('trCode', draftState.trCode);
      break;
    case ZipContentActions.setZipContentLoader:
      draftState.zipContentLoaded = action.value;
      break;
    }
    return draftState;
  });

  return nextState;
};
