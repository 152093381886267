import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Switch from 'pages/dashboard/TransactionsTable/Switch';
import Pagination from 'pages/dashboard/TransactionsTable/Pagination';
import Table from 'components/table/Table';
import { IRootState } from 'store/types';
import columns from 'constants/TableColumns';
import { getDefaultDataset, toTitleCase } from 'helper/tools/commonHelper';
import { GetHistory, GetActiveTransactions } from 'helper/api/route';
import * as commonActions from 'store/features/common/actions';
import { translateColumnsHeaders } from 'helper/tools/commonHelper';
import { IDataSet } from 'store/features/dataSets/types';

const TransactionsTable = ({ historyData }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const datasets = state.dataSets || [];
  const defaultDataset: IDataSet = getDefaultDataset(datasets);
  const dashboard = state?.common?.dashboard;
  const showActive = dashboard?.showActive;
  const loading = dashboard?.loading;
  const data = showActive ? dashboard?.active : dashboard?.closed;
  const tableColumns = showActive ? columns?.active : columns?.closed;
  const tableData = data?.items || [];
  const page = historyData ? 1 : data?.page || 1;
  const size = state.common?.appConfig?.dashboardTableRowCount || 20;
  const [filteredData, setFilteredData] = useState(tableData);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => setSearchValue(''), [page, showActive]);

  useEffect(() => {
    const newData = tableData.filter((item: Record<string, any>) => (
      Object.values(item).some((value: string) => (
        typeof value === 'string' && value?.toLowerCase().includes(searchValue?.toLowerCase())
      ))
    ));
    searchValue.length ? setFilteredData(newData) : setFilteredData(tableData);
  }, [searchValue, tableData]);

  useEffect(() => {
    if (showActive) {
      dispatch(commonActions.setDashboardTableLoading(true));
      GetActiveTransactions(page, size)
        .then((response: any) => {
          const { data: responseData } = response;
          const { items } = responseData;
          const formattedItems = items?.map((item: any) => {
            const { date, status } = item;
            return {
              ...item,
              status: toTitleCase(status),
              date: moment.unix(date).format('YYYY.MM.DD'),
            };
          });
          dispatch(commonActions.setDashboardData({
            active: {
              ...responseData,
              items: formattedItems
            }
          }));
        })
        .finally(() => dispatch(commonActions.setDashboardTableLoading(false)));
    }
    if (!showActive) {
      dispatch(commonActions.setDashboardTableLoading(true));
      GetHistory(page, size)
        .then((response: any) => {
          const { data: responseData } = response;
          const { items } = responseData;
          const formattedItems = items?.map((item: any) => {
            const { reviewDate, date, status } = item;
            return {
              ...item,
              status: toTitleCase(status),
              date: moment.unix(date).format('YYYY.MM.DD'),
              reviewDate: moment.unix(reviewDate).format('YYYY.MM.DD'),
            };
          });
          dispatch(commonActions.setDashboardData({
            closed: {
              ...responseData, items: formattedItems
            }
          }));
        })
        .finally(() => dispatch(commonActions.setDashboardTableLoading(false)));
    }
  }, [defaultDataset.agentConfigId, showActive, page, size]);

  return (
    <div className='transactions-table'>
      <Table
        data={filteredData}
        showSearch={true}
        loading={loading}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        columns={translateColumnsHeaders(tableColumns, t)}
        customHeader={<Switch />}
        customFooter={<Pagination />}
      />
    </div>
  );
};

export default TransactionsTable;