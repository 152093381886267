import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as View } from 'images/view.svg';
import { ReactComponent as ZoomIn } from 'images/zoom-in.svg';
import { ReactComponent as ZoomOut } from 'images/zoom-out.svg';
import { ReactComponent as Images } from 'images/images.svg';
import slide from 'images/slide.svg';

const FraudPagesContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="image-slider-header">
        <p className="scrub-text-style">{t('reg.comparation.modal.title')}</p>
      </div>
      <div className="info-section">
        <div className="steps-line">
          <div className="text-part">
            <p className="step-text-style">{t('reg.comparation.modal.hint')}</p>
          </div>
          <div className="icon-part">
            <div className="slide-style">
              <img src={slide} className="slide" alt="slide" />
            </div>
          </div>
        </div>
        <div className="instructions-line">
          <div>
            <div className="icons-container">
              <ZoomIn />
              <ZoomOut />
            </div>
            <div className="text-container">
              {t('reg.comparation.modal.zoom')}
            </div>
          </div>
          <div>
            <div className="icons-container">
              <View />
            </div>
            <div className="text-container">
              {t('reg.comparation.modal.toggle')}
            </div>
          </div>
          <div>
            <div className="icons-container">
              <Images />
            </div>
            <div className="text-container">
              {t('reg.comparation.modal.images')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FraudPagesContent;