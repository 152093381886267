import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import RightMenu from 'components/menuSide/RightMenu';
import List from 'components/menuSide/List';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import InfoBar from 'components/infoBar/InfoBar';
import Footer from 'components/footer/Footer';
import DataComparison from 'pages/quickCheck/data/DataComparison';
import { IRootState } from 'store/types';
import { Questions } from 'store/features/common/types';
import { EPage } from 'enums';
import BurgerMenu from 'components/burgerMenu/BurgerMenu';

const DataComponent = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const subDocsData = state.zipContent?.subDocsData || [];
  const page = HeaderButtons[EPage.quickCheck].data[Questions.comparing];

  const rightMenuProps: Array<React.ReactNode> = [];
  const rightMenuContent: Array<React.ReactNode> = [];

  const dataXml = subDocsData.map((subDoc) => {
    if (subDoc?.dataXML?.documentScan?.['viz']
      || subDoc?.dataXML?.documentScan?.['mrz']
    ) {
      return subDoc.dataXML.documentScan;
    }
    return 0;
  });

  dataXml.forEach((item) => {
    if (item !== 0) {
      if (item?.['mrz']) {
        rightMenuContent.push(<p> {t('reg.data.right.page-2')}  </p>);
      }
      if (item?.['viz']) {
        rightMenuContent.push(<p> {t('reg.data.right.page-1')}  </p>);
      }
    }
  });

  rightMenuProps.push(
    <List
      header={t('reg.data.list-header')}
      content={rightMenuContent}
      key={t('reg.data.list-header') + 'face'}
    />
  );

  return (
    <>
      <InfoBar />
      <>
        <BurgerMenu />
        <h3>{t(page?.heading || '')}</h3>
      </>
      <div className="check-content">
        <div className='extra-space-menu-container' />
        <DataComparison />
        <RightMenu children={rightMenuProps} />
      </div>
      <Footer NestedComponent={page?.answerComponent} />
    </>
  );
};

export default DataComponent;