import { PopupInfoActions } from 'store/features/popupInfo/types';

export const setLivenessPopupShowAgain = (value: boolean) => ({
  type: PopupInfoActions.setLivenessPopupShowAgain,
  value,
});

export const setFraudPagesPopupShowAgain = (value: boolean) => ({
  type: PopupInfoActions.setFraudPagesPopupShowAgain,
  value,
});

export const setDocumentScanPopupShowAgain = (value: boolean) => ({
  type: PopupInfoActions.setDocumentScanPopupShowAgain,
  value,
});

export const setAddressPopupShowAgain = (value: boolean) => ({
  type: PopupInfoActions.setAddressPopupShowAgain,
  value,
});
