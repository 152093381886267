import {
  IResultValue,
  IExtractedDataValue,
  CustomerDataCheckActions,
} from 'store/features/customerDataCheck/types';

export const setCDCResult = (value: IResultValue) => ({
  type: CustomerDataCheckActions.setCDCResult,
  value,
});

export const setExtractedResult = (value: IExtractedDataValue) => ({
  type: CustomerDataCheckActions.setExtractedResult,
  value,
});

export const setSelectedImage = (value: any) => ({
  type: CustomerDataCheckActions.setSelectedImage,
  value,
});

export const setAdditionalImage = (value: any) => ({
  type: CustomerDataCheckActions.setAdditionalImage,
  value,
});
