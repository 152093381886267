import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Footer from 'components/footer/Footer';
import InfoBar from 'components/infoBar/InfoBar';
import RightMenu from 'components/menuSide/RightMenu';
import List from 'components/menuSide/List';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import { IRootState } from 'store/types';
import ExpiryDateComparison from 'pages/quickCheck/expiryDate/ExpiryDateComparison';
import { EPage } from 'enums';
import { IViews, Questions } from 'store/features/common/types';
import { ICheckResult } from 'store/features/checkResult/types';
import { getExpireDateActivePath, getExpireDateData } from 'helper/tools/quickCheck';
import * as checkResultActions from 'store/features/checkResult/actions';
import * as commonActions from 'store/features/common/actions';
import Loader from 'components/loader/Loader';
import BurgerMenu from 'components/burgerMenu/BurgerMenu';

const ExpiryDate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const page = HeaderButtons[EPage.quickCheck].expiryDate[Questions.comparing];
  const state = useSelector((rootState: IRootState) => rootState);
  const appConfig = state.common?.appConfig || {};
  const views = appConfig?.views as IViews;
  const subDocsData = state.zipContent?.subDocsData || [];
  const checkResult = state.checkResult as ICheckResult;
  const { expiryDateData = [], filesData = [] } = checkResult;
  const filesSliderPath = state.common?.filesSliderPath || '';
  const prevPagePath = state.common?.prevPagePath || '';
  const rightMenuProps: Array<React.ReactNode> = [];
  const rightMenuContent: Array<React.ReactNode> = [];

  useEffect(() => {
    if (!filesSliderPath) {
      const currentItem = getExpireDateActivePath(expiryDateData, filesSliderPath, prevPagePath);
      dispatch(commonActions.setActiveSlidePath(String(currentItem)));
    }
    if (!expiryDateData.length) {
      const initialData = getExpireDateData(subDocsData, filesData, views);
      dispatch(checkResultActions.setExpiryDateData(initialData));
    }
  }, [filesSliderPath]);

  rightMenuContent.push(
    <p>
      <b>{t('reg.date.list-document-photo')}</b>{t('reg.date.list-item-text-document')}
    </p>
  );

  rightMenuProps.push(
    <List
      header={t('reg.date.list-header')}
      content={rightMenuContent}
      key={t('reg.date.list-header') + 'date'}
    />
  );

  if (!expiryDateData.length || !filesSliderPath) {
    return <div className="loader-container"><Loader /></div>;
  }

  return (
    <>
      <InfoBar />
      <>
        <BurgerMenu />
        <h3>{t(page?.heading || '')}</h3>
      </>
      <div className="check-content">
        <div className='extra-space-menu-container' />
        <ExpiryDateComparison />
        <RightMenu children={rightMenuProps} />
      </div>
      {<Footer NestedComponent={page?.answerComponent} />}
    </>
  );
};

export default ExpiryDate;