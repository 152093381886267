import { EImageSide, EZone } from 'enums';

export enum CommonActions {
  setResetCode = 'SET_RESET_CODE',
  setReviewState = 'SET_REVIEW_STATE',
  setQuestion = 'SET_QUESTION',
  setDocRequiredFields = 'SET_DOC_REQUIRED_FIELDS',
  setForcedRejectedInfo = 'SET_FORCED_REJECTED_INFO',
  setActiveSlidePath = 'SET_ACTIVE_SLIDE_PATH',
  setPrevPagePath = 'SET_IS_FROM_DOCUMENT_QUALITY',
  setInstruction = 'SET_INSTRUCTION',
  setDefaultTransactionCode = 'SET_DEFAULT_TRANSACTION_CODE',
  setDashboardData = 'SET_DASHBOARD_DATA',
  setDashboardMode = 'SET_DASHBOARD_MODE',
  setDashboardTableLoading = 'SET_DASHBOARD_TABLE_LOADING',
  resetDashboardTablePage = 'RESET_DASHBOARD_TABLE_PAGE',
  setAppConfig = 'SET_APP_CONFIG',
}

export type TDrivingCategoryFields = 'value' | 'expiryDate' | 'issuingDate' | 'restrictions';

export type TDrivingCategory = {
  [key in TDrivingCategoryFields]: {
    reviewedValue: string;
    extractedValue: string;
    optional: boolean;
  };
}

export enum Questions {
  documentPage = 'documentPage',
  countryDocType = 'countryDocType',
  fraud = 'fraud',
  unsure = 'unsure',
  quality = 'quality',
  fields = 'fields',
  idRecording = 'idRecording',
  idRecordingFraud = 'idRecordingFraud',
  idRecordingUnsure = 'idRecordingUnsure',
  comparing = 'comparing',
  select = 'select',
  notFound = 'notFound',
  imageSource = 'imageSource',
  approve = 'approve',
  reject = 'reject',
}

export type TQuestion = keyof typeof Questions;

export interface IDashboardTable {
  items: Array<any>;
  page: number;
  size: number;
  totalItems: number;
}

export interface IDashboardActionValue {
  [key: string]: IDashboardTable;
}

export interface IBrand {
  name: string;
  title: string;
}
export interface IUrls {
  instruction: IInstruction;
}

export interface IInstruction {
  en: string;
  de: string;
}
export interface ILang {
  switchable: boolean;
  langs: Array<string>;
  default: string;
}

export interface IViews {
  date: boolean;
}

export interface IAppConfig {
  brand?: IBrand;
  urls?: IUrls;
  lang?: ILang;
  views?: IViews;
  isSimplified?: boolean;
  dashboardTableRowCount?: number;
  siteKey?: string;
}

export interface IInstructionUrl {
  en: string;
  de: string;
}

export interface ICommon {
  resetCode: boolean;
  isReviewState: boolean;
  question: TQuestion;
  filesSliderPath: string;
  docRequiredFields: any;
  forcedRejectedInfo: IForcedRejectedInfo;
  prevPagePath: string;
  instructionUrl: IInstructionUrl;
  defaultTransactionCode: string | undefined;
  dashboard: {
    showActive: boolean;
    loading: boolean;
    active: IDashboardTable;
    closed: IDashboardTable;
  };
  appConfig: IAppConfig;
}

export interface IForcedRejectedInfo {
  question: string;
  location: string;
  isForcedRejected: boolean;
  filesSliderPath: string;
}

export type TRequiredZone = {
  [zone in EZone]: {
    [field in string]: EImageSide.back | EImageSide.front
  }
}

export type IDocRequiredFields = {
  [key in string]: TRequiredZone;
}

export type TCommonActions =
  { type: CommonActions.setResetCode; value: boolean } |
  { type: CommonActions.setReviewState; value: boolean } |
  { type: CommonActions.setQuestion; value: TQuestion } |
  { type: CommonActions.setActiveSlidePath; value: string } |
  { type: CommonActions.setDocRequiredFields; value: IDocRequiredFields } |
  { type: CommonActions.setForcedRejectedInfo; value: IForcedRejectedInfo } |
  { type: CommonActions.setPrevPagePath; value: string } |
  { type: CommonActions.setInstruction; value: IInstructionUrl } |
  { type: CommonActions.setDefaultTransactionCode; value: string } |
  { type: CommonActions.setDashboardData; value: IDashboardActionValue } |
  { type: CommonActions.setDashboardMode; value: boolean } |
  { type: CommonActions.setDashboardTableLoading; value: boolean } |
  { type: CommonActions.resetDashboardTablePage; value: boolean } |
  { type: CommonActions.setAppConfig; value: IAppConfig };
