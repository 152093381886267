import moment from 'moment';
import { t } from 'i18n/i18n.config';

import { SOMETHING } from 'constants/Static';
import DocumentTypes from 'constants/DocumentTypes.json';
import { IRootState } from 'store/types';
import { ISubDocData } from 'store/features/zipContent/types';
import { IDataSet } from 'store/features/dataSets/types';
import { IUserAgent } from 'types';
import { ICheckResult, IFilesData } from 'store/features/checkResult/types';
import { EDocumentType, EField, EHttpStatusCode } from 'enums';

const getFormattedValue = (fieldType: string, fieldValue: string, translate: Function) => {
  if (!fieldValue) return '';
  if (fieldType === EField.expirationDate
    || fieldType === EField.dateOfBirth
    || fieldType === EField.dateOfIssue
    || fieldType === EField.birthdate
  ) {
    return moment(fieldValue, 'YYYY-MM-DD').format('DD.MM.YYYY');
  }
  if (fieldType === EField.documentType) {
    const docType = DocumentTypes.find((option) => option.value === fieldValue);
    if (docType?.label) return translate(docType.label);
  }
  return fieldValue;
};

const translateColumnsHeaders = (
  columns: any,
  translate: Function,
) => (columns.map((item: any) => {
  const updatedColumns = item.columns?.map((element: any) => (
    {
      ...element,
      Header: translate(element.Header),
    }
  )) || [];
  return {
    ...item,
    Header: translate(item.Header),
    ...(item.columns && { columns: updatedColumns }),
  };
}));

const translateDocTypeCountry = (list: string[], translate: Function) => (
  list.reduce((result: string, word: string, index: number) => {
    result += translate(word);
    if (index !== list.length - 1) {
      result += ' ';
    }
    return result;
  }, '')
);

const hasDocumentForCheck = (subDocsData: ISubDocData[]) => (
  Boolean(subDocsData.some((subDoc: ISubDocData) => (
    subDoc.files && subDoc.files.length
  ))));

const isUnknownDocument = (image: IFilesData) => (
  image.documentType === EDocumentType.something && image.country === SOMETHING
);

const getExpiryDate = (state: IRootState) => {
  const { checkResult, common } = state;
  const filesSliderPath = Number(common?.filesSliderPath) || 0;
  const { expiryDateData = [] } = checkResult as ICheckResult;
  const { expirationDate } = expiryDateData[filesSliderPath];
  return moment(expirationDate).format('DD.MM.YYYY');
};

const getErrorMessage = (error: any, value: string) => {
  switch (error?.response?.status) {
  case EHttpStatusCode.notFound:
    return value ? [{ text: t('reg.dashboard.modal.no-transaction') }]
      : [{ text: t('messages.transaction') },
        { text: value, type: 'bold' },
        { text: t('messages.notFound') }];
  case EHttpStatusCode.unauthorized:
  case EHttpStatusCode.forbidden:
    return [{ text: t('messages.unauthorized-access') }];
  case EHttpStatusCode.conflict:
    return [
      { text: t('messages.transaction') },
      { text: value, type: 'bobld' },
      { text: t('messages.inReview') },
    ]
  default:
    return [{ text: t('messages.something-went-wrong') }];
  }
};

const toTitleCase = (input = '') => (
  input.split('_').map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ')
);

const getFlowMode = (datasets: IDataSet[]) => (
  datasets?.find((dataset: IDataSet) => (dataset.isDefault))?.isSimplified || false
);

const getCoordinates = (subDocData: ISubDocData, name: string) => {
  const { IDFieldCoordinates = {} } = subDocData.dataXML.documentScan || {};
  if (Object.keys(IDFieldCoordinates).length) {
    const findItem = IDFieldCoordinates?.box.find((item: any) => item.$.Name === name) || '';
    if (findItem) {
      const indexFindItem = IDFieldCoordinates.box.indexOf(findItem);
      const xmlBox = IDFieldCoordinates.box[indexFindItem].$;
      const {
        origin_x: x,
        origin_y: y,
        Rect_W: width,
        Rect_H: height,
      } = xmlBox;
      return {
        x, y, width, height, unit: 'px',
      };
    }
    return {};
  }
  return {};
};

const getFieldTranslationKey = (fieldName: string, translateSource: string) => {
  switch (translateSource) {
  case 'data-check':
    return `field.${fieldName}.title`;
  case 'extracted-data':
  case 'self-declaration':
    return `user-data-check.${translateSource}.field.${fieldName}.title`;
  default:
    return '';
  }
};

const getUserAgent = (): IUserAgent => {
  const ua = navigator.userAgent;
  const isChrome = (/Chrome/i.test(ua));
  const isFirefox = (/Firefox/i.test(ua));
  const isSafari = (/Safari/i.test(ua)) && !(/Chrome/i.test(ua)) && !(/CriOS/i.test(ua));
  return { isChrome, isFirefox, isSafari };
};

const getDefaultDataset = (datasets: IDataSet[]) => (
  datasets?.find((dataset: IDataSet) => dataset.isDefault) || {
    check: '',
    customer: '',
    isSimplified: false,
    application: '',
    isDefault: true,
    requiredFieldsType: '',
    agentConfigId: 1,
  }
);

const hasDefaultProps = (datasets: IDataSet[]) => !!getDefaultDataset(datasets)?.agentConfigId;

const getActiveImagePath = (filesSliderPath: string) => {
  const [subDocIndex = 0, imageIndex = 0] = filesSliderPath.split('/');
  return {
    subDocIndex: subDocIndex ? Number(subDocIndex) : 0,
    imageIndex: imageIndex ? Number(imageIndex) : 0,
  };
};

export {
  toTitleCase,
  getFlowMode,
  getUserAgent,
  getExpiryDate,
  getCoordinates,
  getErrorMessage,
  hasDefaultProps,
  getDefaultDataset,
  isUnknownDocument,
  getFormattedValue,
  getActiveImagePath,
  hasDocumentForCheck,
  getFieldTranslationKey,
  translateDocTypeCountry,
  translateColumnsHeaders,
};
