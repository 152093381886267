import React, { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import { HeaderButtons } from 'constants/CheckTypeButtons';
import {
  ANSWERS_KEYS,
  BUTTON_STYLE,
} from 'constants/Static';
import Answers from 'components/footer/Answers';
import { IRootState } from 'store/types';
import { Questions, TQuestion } from 'store/features/common/types';
import { ICheckResult } from 'store/features/checkResult/types';
import {
  getUpdatedData,
  navigateToNextTab,
  getPackagePosition,
  differentSpecimensSelected,
} from 'helper/tools/fraudCheck';
import * as checkResultActions from 'store/features/checkResult/actions';
import * as commonActions from 'store/features/common/actions';
import * as specimenActions from 'store/features/specimen/actions';
import { EDirection, EPage } from 'enums';
import { getNextPage, getPreviousPage, getQuestion } from 'helper/navigation';
import { IAnswerOption } from 'types';

const PagesQuality = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const question: TQuestion = state.common?.question || Questions.imageSource;
  const page = HeaderButtons[EPage.fraudCheck].document[question];
  const prevPagePath = state.common?.prevPagePath || '';
  const filesSliderPath = state.common?.filesSliderPath || '';
  const checkResult = state.checkResult as ICheckResult;
  const { documentData = [] } = checkResult;

  const {
    itemIndex,
    packageIndex,
  } = useMemo(() => (
    getPackagePosition(filesSliderPath, prevPagePath, documentData)
  ), [filesSliderPath, prevPagePath]);
  const { reviewResult } = documentData[packageIndex][itemIndex];

  const submitAnswer = (answerItem: IAnswerOption) => {
    let answerOptions = {
      quality: answerItem.text,
    };
    if (reviewResult?.key === answerItem.key) {
      answerOptions = { ...reviewResult, quality: answerItem.text};
    }
    const updateDocumentData = getUpdatedData(documentData, packageIndex, itemIndex, {
      reviewResult: answerOptions,
    });
    dispatch(checkResultActions.setDocumentData(updateDocumentData));
    if (answerItem.key === ANSWERS_KEYS.LOOKS_REAL) {
      const nextData = navigateToNextTab(documentData, packageIndex, itemIndex, EDirection.right);
      if (!Object.keys(nextData).length) {
        if (differentSpecimensSelected(documentData[packageIndex])) {
          dispatch(commonActions.setQuestion(Questions.comparing));
        } else {
          const nextQuestion = getQuestion(location.pathname);
          batchActions([
            dispatch(commonActions.setQuestion(nextQuestion)),
            dispatch(commonActions.setActiveSlidePath('')),
          ]);
          return history.replace(getNextPage(state, location.pathname));
        }
      } else {
        batchActions([
          dispatch(specimenActions.setSpecimens([])),
          dispatch(commonActions.setQuestion(nextData.item?.question || Questions.imageSource)),
          dispatch(commonActions.setActiveSlidePath(nextData.path || '')),
        ]);
      }
    } else {
      dispatch(commonActions.setQuestion(answerItem.key));
    }
  };

  const back = () => {
    const previousData = navigateToNextTab(documentData, packageIndex, itemIndex, EDirection.left);
    if (!Object.keys(previousData).length) {
      const previousPage = getPreviousPage(state, location.pathname);
      const previousQuestion = getQuestion(previousPage);
      batchActions([
        dispatch(commonActions.setQuestion(previousQuestion)),
        dispatch(commonActions.setPrevPagePath(location.pathname)),
        dispatch(commonActions.setActiveSlidePath('')),
        dispatch(specimenActions.setSpecimens([])),
        dispatch(checkResultActions.setDocumentData([])),
      ]);
      return history.replace(previousPage);
    }
    batchActions([
      dispatch(commonActions.setQuestion(previousData.item?.question || Questions.imageSource)),
      dispatch(commonActions.setActiveSlidePath(previousData.path || '')),
    ]);
  };

  return (
    <Answers
      back={back}
      answers={page?.answers}
      activeIndexes={[
        page?.answers.findIndex((item: IAnswerOption) => item.text === reviewResult.quality)
      ]}
      submitAnswer={submitAnswer}
      styled={BUTTON_STYLE.OUTLINE_PRIMARY}
    />
  );
};

export default PagesQuality;
