import React from 'react';

import ButtonTypes from 'components/buttonTypes/ButtonTypes';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';
import { ReactComponent as BackButton } from 'images/right.svg';
import Loader from 'components/loader/Loader';

const Answers = (props: any) => {
  const {
    back,
    styled,
    answers,
    disabled,
    submitAnswer,
    activeIndexes,
    isLoading,
  } = props;

  return (
    <div className="buttons-component">
      <div className="back-button">
        <SingleButton
          textButton={'reg.files.back'}
          onClick={back}
          variant={BUTTON_STYLE.OUTLINE_PRIMARY}
          radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
          LeftIcon={BackButton}
          isDisabled={isLoading}
        />
      </div>
      <div className="main-buttons">
        <ButtonTypes
          answers={answers}
          styled={styled}
          activeIndexes={activeIndexes}
          handleClick={submitAnswer}
          disabled={disabled}
        />
        {isLoading && <Loader />}
      </div>
    </div>
  );
};

export default Answers;
