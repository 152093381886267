import React from 'react';
import { useTranslation } from 'react-i18next';

import ListFromResources from 'components/accordionMenu/ListFromResources';

const DataContent = () => {
  const { t } = useTranslation();
  const leftSideContent = [
    [t('reg.data.left-menu.task-help.message1')],
    [
      t('reg.data.left-menu.task-help.message2'),
      {
        text: t('reg.data.left-menu.task-help.message3'),
        type: 'link',
        url: '',
      }
    ],
    [t('reg.data.left-menu.task-help.message4')]
  ];

  return (
    <ListFromResources resource={leftSideContent} />
  );
};

export default DataContent;
