import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { IStyledText } from 'types';
import { parseRoute } from 'util/common';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import StyledText from 'components/styledText/StyledText';
import { IRootState } from 'store/types';
import { Questions, TQuestion } from 'store/features/common/types';
import { EPage, EZone } from 'enums';

interface ICheckType {
  checkType: string;
  checkSubType: string;
  checkSubSubType: string;
}

const shouldNoRenderHeader = (check: ICheckType) => {
  const { checkType, checkSubType, checkSubSubType } = check;
  return checkType === 'data-check'
    && (checkSubType === EZone.mrz || checkSubType === EZone.viz)
    && checkSubSubType === Questions.fields;
};

const Footer = (
  props: {
    NestedComponent?: FC<{ data?: any }>;
    customData?: IStyledText[];
    data?: any;
  }
) => {
  const { t } = useTranslation();
  const location = useLocation();
  const checkRoutes = parseRoute(location.pathname);
  const state = useSelector((rootState: IRootState) => rootState);
  const question: TQuestion = state.common?.question || Questions.documentPage;
  const { NestedComponent, customData = [], data } = props;
  const checking: ICheckType = { ...checkRoutes, checkSubSubType: question };
  const isHeader = !shouldNoRenderHeader(checking);

  const getHeader = (check: ICheckType, custom: IStyledText[] = []) => {
    const { checkType, checkSubType, checkSubSubType } = check;
    const checkData = HeaderButtons[checkType as EPage][checkSubType][checkSubSubType as Questions];
    const {
      question: currentQuestion = '',
      description = () => [{}],
    } = checkData || {};
    const questionData = description(state);
    const resultData = [...questionData, ...custom];
    return (
      currentQuestion ? <div className="question-container">
        <h4 className="header-text">{t(currentQuestion)}</h4>
        {!!questionData.length && <StyledText data={resultData} />}
      </div> : <></>
    );
  };

  return (
    <div className="answers-container">
      <div className="nested">
        {isHeader && getHeader(checking, customData)}
        <div className="answers">
          {NestedComponent && <NestedComponent data={data}/>}
        </div>
      </div>
    </div>
  );
};

export default Footer;
