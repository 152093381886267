import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from 'components/table/Table';
import columns from 'constants/TableColumns';
import { SetDefaultDataset } from 'helper/api/route';
import { IRootState } from 'store/types';
import * as dataSetActions from 'store/features/dataSets/actions';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';
import { translateColumnsHeaders } from 'helper/tools/commonHelper';
import { IDataSet } from 'store/features/dataSets/types';

const Dataset = (props: { close: Function }) => {
  const { t } = useTranslation();
  const { close } = props;
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const datasets = state.dataSets || [];

  const buttonClickHandler = (selectedRowData: any) => {
    const { agentConfigId } = selectedRowData;
    SetDefaultDataset(agentConfigId).then((response) => {
      const { data } = response;
      const { configData = [] } = data;
      dispatch(dataSetActions.setDataSet(configData));
      close(false);
    });
  };


  const newData = datasets?.map((dataset: IDataSet) => {
    const isSelected = Boolean(dataset.isDefault);
    return {
      ...dataset,
      switchTo: !isSelected ? <SingleButton {...{
        textButton: 'reg.dataset.button.switch-to',
        isActive: isSelected,
        onClick: () => buttonClickHandler(dataset),
        radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
        variant: BUTTON_STYLE.SECONDARY,

      }} /> : '',
      isActive: isSelected
    };
  });

  return (
    <>
      <Table data={newData} columns={translateColumnsHeaders(columns.switchDataset, t)} />
    </>
  );
};

export default Dataset;
