import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import { HeaderButtons } from 'constants/CheckTypeButtons';
import { ANSWERS_KEYS, BUTTON_STYLE, PATH } from 'constants/Static';
import Answers from 'components/footer/Answers';
import * as commonActions from 'store/features/common/actions';
import { IRootState } from 'store/types';
import { getQuestion, getNextPage } from 'helper/navigation';
import { hasDocumentForCheck } from 'helper/tools/commonHelper';
import * as checkResultActions from 'store/features/checkResult/actions';
import { Questions } from 'store/features/common/types';
import { ICheckResult } from 'store/features/checkResult/types';
import { EPage } from 'enums';
import { IAnswerOption } from 'types';

const VideoQuality = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const subDocsData = state.zipContent?.subDocsData || [];
  const page = HeaderButtons[EPage.fraudCheck].liveness[Questions.quality];
  const { livenessData } = checkResult as ICheckResult;

  const submitAnswer = (answer: IAnswerOption) => {
    let answerOptions = {
      quality: answer.text,
    };
    if (livenessData?.key === answer.key) {
      answerOptions = { ...livenessData, quality: answer.text };
    }
    dispatch(checkResultActions.setLivenessData(answerOptions));
    if (answer.key === ANSWERS_KEYS.LOOKS_REAL) {
      const next = getNextPage(state, location.pathname);
      const nextQuestion = getQuestion(next);
      batchActions([
        dispatch(commonActions.setQuestion(nextQuestion)),
        dispatch(commonActions.setPrevPagePath('')),
        dispatch(commonActions.setActiveSlidePath('')),
      ]);
      history.replace(next);
    } else {
      dispatch(commonActions.setQuestion(answer.key));
    }
  };

  const back = () => {
    if (!hasDocumentForCheck(subDocsData)) {
      return history.replace(PATH.DASHBOARD);
    }
    return history.replace(PATH.SUMMARY_QUICK_CHECK);
  };

  return (
    <Answers
      back={back}
      answers={page?.answers}
      activeIndexes={[
        page?.answers.findIndex((item: IAnswerOption) => item.text === livenessData.quality)
      ]}
      submitAnswer={submitAnswer}
      styled={BUTTON_STYLE.OUTLINE_PRIMARY}
    />
  );
};

export default VideoQuality;
