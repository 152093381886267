import React from 'react';

import { ReactComponent as C1E } from 'images/DrivingLicense/DL-C1E.svg';
import { ReactComponent as A } from 'images/DrivingLicense/DL-A.svg';
import { ReactComponent as B } from 'images/DrivingLicense/DL-B.svg';
import { ReactComponent as B1 } from 'images/DrivingLicense/DL-B1.svg';
import { ReactComponent as BE } from 'images/DrivingLicense/DL-BE.svg';
import { ReactComponent as BTP } from 'images/DrivingLicense/DL-BTP.svg';
import { ReactComponent as CZV } from 'images/DrivingLicense/DL-CZV.svg';
import { ReactComponent as D1 } from 'images/DrivingLicense/DL-D1.svg';
import { ReactComponent as DE } from 'images/DrivingLicense/DL-DE.svg';
import { ReactComponent as M } from 'images/DrivingLicense/DL-M.svg';
import { ReactComponent as D1E } from 'images/DrivingLicense/DL-D1E.svg';
import { ReactComponent as D } from 'images/DrivingLicense/DL-D.svg';
import { ReactComponent as C1 } from 'images/DrivingLicense/DL-C1.svg';
import { ReactComponent as C } from 'images/DrivingLicense/DL-C.svg';
import { ReactComponent as CE } from 'images/DrivingLicense/DL-CE.svg';
import { ReactComponent as F } from 'images/DrivingLicense/DL-F.svg';
import { ReactComponent as G } from 'images/DrivingLicense/DL-G.svg';
import {
  ReactComponent as MINUS_A,
  ReactComponent as A1
} from 'images/DrivingLicense/DL-A1.svg';
import { useTranslation } from 'react-i18next';
import { EDrivingCategories } from 'enums';

const DrivingIcon = (props: { value: string }) => {
  const { t } = useTranslation();
  switch (props.value) {
  case EDrivingCategories.m:
    return <M />;
  case EDrivingCategories.c1e:
    return <C1E />;
  case EDrivingCategories.c1:
    return <C1 />;
  case EDrivingCategories.c:
    return <C />;
  case EDrivingCategories.ce:
    return <CE />;
  case EDrivingCategories.minusA:
    return <MINUS_A />;
  case EDrivingCategories.a:
    return <A />;
  case EDrivingCategories.a1:
    return <A1 />;
  case EDrivingCategories.b:
    return <B />;
  case EDrivingCategories.b1:
    return <B1 />;
  case EDrivingCategories.be:
    return <BE />;
  case EDrivingCategories.btp:
    return <BTP />;
  case EDrivingCategories.czv:
    return <CZV />;
  case EDrivingCategories.d1:
    return <D1 />;
  case EDrivingCategories.d:
    return <D />;
  case EDrivingCategories.de:
    return <DE />;
  case EDrivingCategories.d1e:
    return <D1E />;
  case EDrivingCategories.f:
    return <F />;
  case EDrivingCategories.g:
    return <G />;
  default:
    return <div className="icon-by-code">
      {t('reg.data-check.drivingCategory.icon-by-code')}</div>;
  }
};

export default DrivingIcon;