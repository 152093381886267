import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { IStyledText } from 'types';

const StyledText = (props: { data: any; isTranslated?: boolean }) => {
  const { t } = useTranslation();
  const { data = {}, isTranslated = false } = props;

  const description: Array<React.ReactNode> = data.reduce((
    desc: React.ReactNode[], item: IStyledText | string, index: number
  ) => {
    const isItemString: boolean = typeof item === 'string';
    let type = '';
    let url = '';
    let text: any = isItemString ? item : '';

    if (typeof item !== 'string') {
      text = item.text || '';
      type = item.type || '';
      url = item.url || '';
    }
    const redirect = (e: SyntheticEvent) => {
      e.preventDefault();
      window.open(url, '_blank');
    };

    switch (type) {
    case 'bold':
      desc.push(<span className="bold" key={index}>{isTranslated ? text : t(text)}</span>);
      break;
    case 'link':
      desc.push(
        <button
          className="link"
          onClick={redirect}
          key={index}
        >
          {isTranslated ? text : t(text)}
        </button>
      );
      break;
    default:
      desc.push(<span key={index}>{isTranslated ? text : t(text)}</span>);
      break;
    }
    return desc;
  }, []);

  return (
    <p className="description-text">
      {description?.map((el: any) => el)}
    </p>
  );
};

export default StyledText;
