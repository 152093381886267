import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import Modal from 'components/dashboardModal/Modal';
import ModalHeader from 'components/dashboardModal/ModalHeader';
import ModalBody from 'components/dashboardModal/ModalBody';
import { ReactComponent as CloseLogo } from 'images/close.svg';
import { ReactComponent as SearchIcon } from 'images/search.svg';
import {
  SearchTransactionByCode,
  GetZipContent,
  GetRequiredFields,
  UpdateTransactionState,
  GetInstruction,
} from 'helper/api/route';
import { extractXMLData, getSubDocsData } from 'util/stateHelper';
import Loader from 'components/loader/Loader';
import { KEY_CODE, PATH } from 'constants/Static';
import * as commonActions from 'store/features/common/actions';
import * as zipContentActions from 'store/features/zipContent/actions';
import { IRootState } from 'store/types';
import StyledText from 'components/styledText/StyledText';
import { IStyledText } from 'types';
import {
  getFlowMode,
  getErrorMessage,
  getDefaultDataset,
  hasDocumentForCheck,
} from 'helper/tools/commonHelper';
import { IDataSet } from 'store/features/dataSets/types';
import { ETransactionState } from 'enums';
import { ISubDocData } from 'store/features/zipContent/types';

const { TA_BOARDING, QUICK_CHECK_FILES } = PATH;

const SearchTransaction = (props: {
  close: Function;
  title: string;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const datasets: IDataSet[] = state.dataSets || [];
  const [searchInput, setSearchInput] = useState('');
  const [isTransactionFound, setIsTransactionFound] = useState(false);
  const [message, setMessage] = useState<Array<IStyledText>>([]);
  const [loader, setLoader] = useState(false);
  const defaultDataset: IDataSet = getDefaultDataset(datasets);
  const isSimplified = getFlowMode(datasets);
  const {
    close, title,
  } = props;

  const trimAndSearch = () => {
    setSearchInput(searchInput.trim());
    getTransactionDataByCode(searchInput.trim());
  };

  const getTransactionCode = () => {
    dispatch(zipContentActions.setZipContentLoader(true));
    GetZipContent(searchInput).then((response) => {
      const { subDocs, commonData } = response.data;
      const hasImages = Object.values(subDocs as ISubDocData[]).some((subDocData) => {
        return subDocData.files.length;
      });
      const packageIsValid = Object.values(subDocs as ISubDocData[]).every(
        (item) => Object.keys(item.dataXML).length
      );
      if (packageIsValid && (commonData.video?.url || hasImages)) {
        Promise.all(extractXMLData(subDocs)).then((data) => {
          const modifiedResponseData = { ...response.data };
          for (let index = 0; index < data.length; index++) {
            modifiedResponseData.subDocs[index].dataXML = data[index];
          }
          batchActions([
            dispatch(commonActions.setResetCode(true)),
            dispatch(zipContentActions.setZipContent(modifiedResponseData)),
          ]);
          setLoader(false);
          dispatch(zipContentActions.setZipContentLoader(false));
          const {
            subDocs: packageData = {},
            commonData: common = {},
          } = modifiedResponseData;
          const { url: videoURL = '' } = common.video;
          const subDocsData = getSubDocsData(packageData);
          if (hasDocumentForCheck(subDocsData) || videoURL) {
            GetRequiredFields(defaultDataset.requiredFieldsType).then((res) => {
              const { data: docRequiredFields } = res;
              dispatch(commonActions.setDocRequiredFields(docRequiredFields));
            });
            GetInstruction().then((res) => {
              const { data: instructionData } = res;
              dispatch(commonActions.setInstruction(instructionData));
            });
            if (isSimplified) {
              dispatch(commonActions.setReviewState(true));
              return history.replace(QUICK_CHECK_FILES);
            }
            history.replace(TA_BOARDING);
          } else {
            setIsTransactionFound(false);
            setMessage([
              { text: t('messages.transaction') },
              { text: searchInput, type: 'bold' },
              { text: t('messages.noContent') }
            ]);
            UpdateTransactionState({ code: searchInput, state: ETransactionState.inError });
          }
        });
      } else if (searchInput) {
        setIsTransactionFound(false);
        UpdateTransactionState({ code: searchInput, state: ETransactionState.inError });
        setMessage([{ text: t('messages.inError') }]);
        dispatch(zipContentActions.setZipContentLoader(false));
        setLoader(false);
      }
    }).catch((err) => {
      const errorText = getErrorMessage(err, searchInput);
      setIsTransactionFound(false);
      setMessage(errorText);
      dispatch(zipContentActions.setZipContentLoader(false));
      setLoader(false);
    });
  };

  const getTransactionDataByCode = (value: string, cb?: Function) => {
    if (value) {
      SearchTransactionByCode(value).then((response) => {
        const { state: transactionState } = response.data;
        switch (transactionState) {
        case ETransactionState.needReview: {
          setIsTransactionFound(true);
          if (cb) cb();
          break;
        }
        case ETransactionState.inReview: {
          setIsTransactionFound(false);
          setMessage([
            {text: t('messages.transaction')},
            {text: searchInput, type: 'bold'},
            {text: t('messages.inReview')}
          ]);
          setLoader(false);
          break;
        }
        case ETransactionState.reviewed:
        case ETransactionState.checkCompleted:
        case ETransactionState.pxlCheckError:
        {
          setIsTransactionFound(false);
          setMessage([
            {text: t('messages.transaction')},
            {text: searchInput, type: 'bold'},
            {text: t('messages.reviewed')}
          ]);
          break;
        }
        case ETransactionState.deleted: {
          setIsTransactionFound(false);
          setMessage([
            {text: t('messages.deleted')},
          ]);
          break;
        }
        case ETransactionState.completed: {
          setIsTransactionFound(false);
          setMessage([
            {text: t('messages.transaction')},
            {text: searchInput, type: 'bold'},
            {text: t('messages.completed')}
          ]);
          break;
        }
        case ETransactionState.inError: {
          setIsTransactionFound(false);
          setMessage([
            { text: t('messages.inError') },
          ]);
          break;
        }
        default: {
          setIsTransactionFound(false);
          setMessage([
            {text: t('messages.transaction')},
            {text: searchInput, type: 'bold'},
            {text: t('messages.notFound')}
          ]);
          break;
        }
        }
      }).catch((error) => {
        setIsTransactionFound(false);
        const errorText = getErrorMessage(error, value);
        setMessage(errorText);
      });
    }
  };

  const onReviewClick = () => {
    if (loader) return;
    setLoader(true);
    setSearchInput(searchInput.trim());
    getTransactionDataByCode(searchInput.trim(), getTransactionCode);
  };

  const keyPress: Function = (e: KeyboardEvent) => {
    if (isTransactionFound && e.keyCode === KEY_CODE.ENTER) {
      onReviewClick();
      return;
    }
    if (e.keyCode === KEY_CODE.ENTER) {
      trimAndSearch();
    }
  };

  const transactionCodeHandler = (e: any) => {
    setSearchInput(e.target.value);
    setIsTransactionFound(false);
    if (message.length) setMessage([]);
  };

  const SearchResultNotFound = () => (
    <div className="search-failed">
      <div>
        <div className="failed-text">
          <StyledText data={message} isTranslated={true} />
        </div>
      </div>
      <div
        className=" button try-again-button"
        role="button"
        onClick={() => setSearchInput('')}
        tabIndex={-1}
      >
        <span>{t('reg.search.result.try-again')}</span>
      </div>
    </div>
  );

  const enterPressed = (event: any, type: string) => {
    const code = event.keyCode || event.which;
    if (code === KEY_CODE.ENTER) {
      switch (type) {
      case 'close':
        close(false);
        break;
      case 'search':
        onReviewClick();
        break;
      default:
        break;
      }
    }
  };

  const SearchResultFound = () => (
    <div
      className="search-success"
      tabIndex={0}
      onKeyDown={(e) => enterPressed(e, 'search')}
    >
      <div className="review-date" />
      <div className="brand">
        <span>{defaultDataset.application || ''}</span>
      </div>
      <div className="client">
        <span>{defaultDataset.customer || ''}</span>
      </div>
      <div className="tid">
        <span>{searchInput}</span>
      </div>
      <div className="check">
        <span>{defaultDataset.check || ''}</span>
      </div>
      <div className="reasons" />
      <div className="review">
        <span
          onClick={onReviewClick}
          role="button"
          tabIndex={-1}
          className={`${loader ? 'search-success-review-button' : ''} text`}
        >
          {t('reg.search.result.review')}
        </span>
        {loader && <Loader />}
      </div>
    </div>
  );

  return (
    <Modal>
      <ModalHeader>
        <div className="dashboard-modal-title" tabIndex={-1}>
          <p>{title}</p>
          <div className="search-input">
            <input
              className="trcode-input"
              autoFocus
              type="text"
              inputMode="numeric"
              value={searchInput}
              onChange={transactionCodeHandler}
              onKeyDown={(e) => keyPress(e)}
            />
            <SearchIcon onClick={() => trimAndSearch()} />
          </div>
        </div>
        <div
          className=" button close-modal-button"
          role="button"
          onKeyDown={(e) => enterPressed(e, 'close')}
          onClick={() => close(false)}
          tabIndex={0}
        >
          <CloseLogo />
        </div>
      </ModalHeader>
      <ModalBody>
        {(searchInput && !isTransactionFound && message.length) ? <SearchResultNotFound /> : (
          searchInput && isTransactionFound && <SearchResultFound />)}
      </ModalBody>
    </Modal>
  );
};

export default SearchTransaction;
