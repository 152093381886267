import React from 'react';

export interface IListItemProps {
  header: string;
  content: Array<any>;
  selected?: string;
}

const List = (props: IListItemProps) => {
  const { header, content, selected } = props;

  return (
    <div className='list'>
      <span className='header'>{header}</span>
      <ul>
        {content.map((item: any, index: number) => {
          return <li
            key={`${item}-${index}`}
            className={`${item === selected ? 'selected' : ''}`}
          >
            {item}
          </li>;
        })}
      </ul>
    </div>
  );
};

export default List;
