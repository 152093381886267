import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Answers from 'components/footer/Answers';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import { BUTTON_STYLE, PATH } from 'constants/Static';
import { EPage } from 'enums';
import * as commonActions from 'store/features/common/actions';
import { Questions } from 'store/features/common/types';
import { getPackagePosition } from 'helper/tools/fraudCheck';
import { IRootState } from 'store/types';
import { ICheckResult } from 'store/features/checkResult/types';

const CompareSpecimenAnswers = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const page = HeaderButtons[EPage.fraudCheck].document[Questions.comparing];
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const prevPagePath = state.common?.prevPagePath || '';
  const filesSliderPath = state.common?.filesSliderPath || '';
  const { documentData = [] } = checkResult;

  const {
    itemIndex,
    packageIndex,
  } = useMemo(() => (
    getPackagePosition(filesSliderPath, prevPagePath, documentData)
  ), [filesSliderPath, prevPagePath]);

  const submitAnswer = () => {
    dispatch(commonActions.setForcedRejectedInfo({
      isForcedRejected: true,
      location: location.pathname,
      question: Questions.comparing,
    }));
    history.replace(PATH.SUMMARY_FRAUD_CHECK);
  };

  const back = () => {
    dispatch(commonActions.setQuestion(documentData[packageIndex][itemIndex].question));
  };

  return (
    <Answers
      back={back}
      answers={page?.answers}
      submitAnswer={submitAnswer}
      styled={BUTTON_STYLE.OUTLINE_PRIMARY}
      changeLocation={true}
    />
  );
};

export default CompareSpecimenAnswers;