import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Answers from 'components/footer/Answers';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import { ANSWERS_KEYS, BUTTON_STYLE } from 'constants/Static';
import * as commonActions from 'store/features/common/actions';
import * as customerDataCheckActions from 'store/features/customerDataCheck/actions';
import { IRootState } from 'store/types';
import { Questions } from 'store/features/common/types';
import { getSdDataCheckList, getTableStructure } from 'helper/tools/customerDataCheck';
import { getNextPage, getPreviousPage, getQuestion } from 'helper/navigation';
import { TScreenName } from 'store/features/customerDataCheck/types';
import { EPage } from 'enums';
import { IAnswerOption } from 'types';

const Approve = () => {
  const state = useSelector((rootState: IRootState) => rootState);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const page = HeaderButtons[EPage.userDataCheck]['self-declaration'][Questions.approve];
  const itemIndex = Number(state.common?.filesSliderPath || 0);

  const {
    selfDeclaration = {},
    extractedData = [],
  } = useMemo(() => getSdDataCheckList(state), []);

  const extractedResult = extractedData?.[itemIndex]?.data || {};

  const comparedData = useMemo(() => (
    getTableStructure(extractedResult, selfDeclaration)
  ), [itemIndex]);

  const subDocIndex = extractedData[itemIndex]?.subDocIndex || itemIndex;
  const combinedData = state?.customerDataCheck?.selfDeclaration?.extractedData;
  const lastAnswer = combinedData?.[subDocIndex]?.result;
  const foundedIndex = page?.answers.findIndex((answer) => answer.key === lastAnswer);
  const activeIndex = foundedIndex === -1 ? [] : [foundedIndex];

  const back = () => {
    if (itemIndex === 0) {
      const previous = getPreviousPage(state, location.pathname);
      const previousQuestion = getQuestion(previous);
      dispatch(commonActions.setQuestion(previousQuestion));
      dispatch(commonActions.setActiveSlidePath(''));
      dispatch(commonActions.setPrevPagePath(location.pathname));
      history.replace(previous);
    } else {
      const lastSubDocIndex = extractedData[itemIndex - 1].subDocIndex;
      if (combinedData?.[lastSubDocIndex]?.result === Questions.reject) {
        dispatch(commonActions.setQuestion(Questions.reject));
      }
      dispatch(commonActions.setActiveSlidePath(String(itemIndex - 1)));
    }
  };

  const submitAnswer = (answer: IAnswerOption) => {
    let nextIndex = 0;
    if (!state?.customerDataCheck?.selfDeclaration?.data) {
      dispatch(customerDataCheckActions.setCDCResult({
        key: TScreenName.selfDeclaration,
        list: [{ data: selfDeclaration }]
      }));
    }
    dispatch(customerDataCheckActions.setExtractedResult({
      data: comparedData,
      subDocIndex,
      result: answer.key
    }));
    if (answer.key === ANSWERS_KEYS.REJECTED) {
      return dispatch(commonActions.setQuestion(Questions.reject));
    }

    if (extractedData.length) {
      const lastIndex = extractedData.length - 1;
      if (itemIndex < lastIndex) {
        nextIndex = itemIndex + 1;
      } else {
        nextIndex = extractedData[lastIndex + 1]?.index;
      }

    }
    if (nextIndex) {
      dispatch(commonActions.setActiveSlidePath(String(nextIndex)));
      dispatch(commonActions.setQuestion(Questions.approve));
    } else {
      const next = getNextPage(state, location.pathname);
      const nextQuestion = getQuestion(next);
      dispatch(commonActions.setQuestion(nextQuestion));
      return history.replace(next);
    }
  };

  return (
    <Answers
      back={back}
      answers={page?.answers}
      activeIndexes={activeIndex}
      submitAnswer={submitAnswer}
      styled={BUTTON_STYLE.OUTLINE_PRIMARY}
    />
  );
};

export default Approve;
