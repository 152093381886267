import React from 'react';

const ModalBody = (props: {children: React.ReactNode; className?: string}) => {
  const { children, className } = props;
  return (
    <div className={`modal-body ${className}`}>
      {children}
    </div>
  );
};

export default ModalBody;
