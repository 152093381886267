import React, { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import { HeaderButtons } from 'constants/CheckTypeButtons';
import { ANSWERS_KEYS, BUTTON_STYLE, PATH } from 'constants/Static';
import Answers from 'components/footer/Answers';
import { IRootState } from 'store/types';
import { Questions } from 'store/features/common/types';
import { EDirection, EPage } from 'enums';
import { IAnswerOption } from 'types';
import { updatedFilesData, getNext, detectForcedRejection } from 'helper/tools/quickCheck';
import { ICheckResult } from 'store/features/checkResult/types';
import * as checkResultActions from 'store/features/checkResult/actions';
import * as commonActions from 'store/features/common/actions';
import { getNextPage, getQuestion } from 'helper/navigation';
import { getActiveImagePath } from 'helper/tools/commonHelper';

const DocumentQuality = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const state = useSelector((rootState: IRootState) => rootState);
  const page = HeaderButtons[EPage.quickCheck].files[Questions.quality];
  const checkResult = state.checkResult as ICheckResult;
  const { filesData = [] } = checkResult;
  const filesSliderPath = state.common?.filesSliderPath || '';
  const {
    subDocIndex,
    imageIndex,
  } = useMemo(() => getActiveImagePath(filesSliderPath), [filesSliderPath]);
  const { reviewResult } = filesData[subDocIndex][imageIndex];

  const forceReject = () => {
    batchActions([
      dispatch(commonActions.setForcedRejectedInfo({
        isForcedRejected: true,
        location: location.pathname,
        question: Questions.quality,
        filesSliderPath,
      })),
      dispatch(commonActions.setActiveSlidePath('')),
      dispatch(commonActions.setPrevPagePath('')),
    ]);
    return history.replace(PATH.SUMMARY_QUICK_CHECK);
  };

  const submitAnswer = (answer: IAnswerOption) => {
    let answerOptions = {
      quality: answer.text,
    };
    if (reviewResult?.key === answer.key) {
      answerOptions = { ...reviewResult, quality: answer.text };
    }
    const updatedResult = updatedFilesData(filesData, subDocIndex, [imageIndex], {
      reviewResult: answerOptions,
    });
    dispatch(checkResultActions.setFilesData(updatedResult));
    if (detectForcedRejection(updatedResult)) {
      return forceReject();
    }
    if (answer.key === ANSWERS_KEYS.GOOD_TO_USE) {
      const nextPath = getNext(
        filesData,
        subDocIndex,
        imageIndex,
        EDirection.right,
        Questions.quality,
      );
      if (nextPath) {
        dispatch(commonActions.setActiveSlidePath(nextPath));
      } else {
        const nextPage = getNextPage(state, location.pathname);
        const nextQuestion = getQuestion(nextPage);
        dispatch(commonActions.setQuestion(nextQuestion));
        return history.replace(nextPage);
      }
    } else {
      dispatch(commonActions.setQuestion(answer.key));
    }
  };

  const back = () => {
    const previousPath = getNext(
      filesData,
      subDocIndex,
      imageIndex,
      EDirection.left,
      Questions.quality,
    );
    if (previousPath) {
      dispatch(commonActions.setActiveSlidePath(previousPath));
    } else {
      const previousPath = getNext(
        filesData,
        filesData.length - 1,
        filesData[filesData.length - 1].length,
        EDirection.left,
        Questions.documentPage,
      );
      batchActions([
        dispatch(commonActions.setQuestion(Questions.documentPage)),
        dispatch(commonActions.setActiveSlidePath(previousPath)),
      ]);
    }
  };

  return (
    <Answers
      back={back}
      answers={page?.answers}
      activeIndexes={[
        page?.answers.findIndex((item: IAnswerOption) => item.text === reviewResult.quality)
      ]}
      submitAnswer={submitAnswer}
      styled={BUTTON_STYLE.OUTLINE_PRIMARY}
    />
  );
};

export default DocumentQuality;