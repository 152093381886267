import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ISingleButtonProps } from 'types';
import { ReactComponent as Logo } from 'images/pxlCheck.svg';
import SwitchLanguage from 'components/lang/SwitchLanguage';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE, LOCAL_STORAGE_LNG, PATH } from 'constants/Static';
import { GetAuthConfig } from 'helper/api/route';
import * as commonActions from 'store/features/common/actions';
import { clearLocalStoragePartly } from 'util/storageHelper';


const { SIGN_IN } = PATH;

const LogOut = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const loginClickHandler = () => {
    GetAuthConfig()
      .then(({ data = {} }) => {
        let { authURL } = data;
        if (authURL) {
          // This part is temporary and
          // will be removed in the future once correct redirection implemented
          const urlParams = new URLSearchParams(location.search);
          const tid = urlParams.get('tid');
          if (tid) {
            const url = new URL(authURL);
            const redirectURI = url.searchParams.get('redirect_uri');
            url.searchParams.delete('redirect_uri');
            authURL = `${url.href}&redirect_uri=${redirectURI}/?tid=${tid}`;
            dispatch(commonActions.setDefaultTransactionCode(tid));
          }
          clearLocalStoragePartly([LOCAL_STORAGE_LNG]);
          window.location.replace(authURL);
        } else {
          window.location.replace(SIGN_IN);
        }
      })
      .catch(() => {
        window.location.replace(SIGN_IN);
      });
  };

  const buttonPropsData: ISingleButtonProps = {
    isActive: true,
    onClick: loginClickHandler,
    textButton: 'sign-in.button',
    variant: BUTTON_STYLE.OUTLINE_SECONDARY,
    radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
  };

  return (
    <div className="main-content">
      <div className="container">
        <div className="logout-container">
          <div className="identity-container">
            <Logo />
            <span className="identity-text">{t('sign-in.header')}</span>
          </div>
          <div className='log-out-description-container'>
            <p> {t('log-out.description-text')}</p>
            <p> {t('log-out.description-option-text')}</p>
          </div>
          <div className="button-container">
            <SingleButton {...buttonPropsData} />
          </div>
        </div>
      </div>
      <SwitchLanguage />
    </div>
  );
};

export default LogOut;
