import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'store/types';
import { ISubDocData, TFileImage } from 'store/features/zipContent/types';
import AvailableModalBody from 'components/modal/modalContent/AvailableImagesContent';
import { ICheckResult } from 'store/features/checkResult/types';
import { getPackageInfo, updateDataValidation } from 'helper/tools/dataCheck';
import ImageWrapper from 'components/imageWrapper/ImageWrapper';
import * as checkResultActions from 'store/features/checkResult/actions';
import { ETools } from 'enums';

const Check = () => {
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const filesSliderPath = state.common?.filesSliderPath || '';
  const { dataValidation = [] } = checkResult;
  const subDocsData: ISubDocData[] = state.zipContent?.subDocsData || [];
  const [showFront, setShowFront] = useState(false);
  const [showBack, setShowBack] = useState(false);

  const { packageIndex } = useMemo(() => getPackageInfo(filesSliderPath), [filesSliderPath]);

  const { frontImage, backImage, subDocIndex } = dataValidation[packageIndex];

  const frontImageData = frontImage ? subDocsData[subDocIndex].files[frontImage?.index] : null;

  const backImageData = backImage ? subDocsData[subDocIndex].files[backImage?.index] : null;

  const onFrontImageSelect = (imageName: string) => {
    const index = subDocsData[subDocIndex].files.findIndex(
      (item: TFileImage) => Object.keys(item)[0] === imageName
    )
    const updatedData = updateDataValidation(dataValidation, packageIndex, {
      frontImage: {
        availableImageIndexes: frontImage?.availableImageIndexes,
        index,
      }
    });
    dispatch(checkResultActions.setDataValidation(updatedData));
  };

  const onBackImageSelect = (imageName: string) => {
    const index = subDocsData[subDocIndex].files.findIndex(
      (item: TFileImage) => Object.keys(item)[0] === imageName
    )
    const updatedData = updateDataValidation(dataValidation, packageIndex, {
      backImage: {
        availableImageIndexes: backImage?.availableImageIndexes,
        index,
      }
    });
    dispatch(checkResultActions.setDataValidation(updatedData));
  };

  const frontImages = subDocsData[subDocIndex].files.filter((item: TFileImage, index) => (
    frontImage?.availableImageIndexes.includes(index))
  );

  const backImages = subDocsData[subDocIndex].files.filter((item: TFileImage, index) => (
    backImage?.availableImageIndexes.includes(index))
  );

  const frontModalProps = [{
    ModalBody: <AvailableModalBody
      onSelectImage={onFrontImageSelect}
      images={frontImages}
      selected={frontImageData ? Object.keys(frontImageData)[0] : ''}
    />,
    setIsModalShowing: () => setShowFront(!showFront),
    isModalShowing: showFront,
  }];

  const backModalProps = [{
    ModalBody: <AvailableModalBody
      onSelectImage={onBackImageSelect}
      images={backImages}
      selected={backImageData ? Object.keys(backImageData)[0] : ''}
    />,
    setIsModalShowing: () => setShowBack(!showBack),
    isModalShowing: showBack,
  }];

  return (
    <div className="main-container">
      {frontImageData &&
        <div className='data-check-image-wrapping'>
          <ImageWrapper
            imageSrc={Object.values(frontImageData)[0]}
            onClickChangeImage={() => setShowFront(!showFront)}
            hasAvailableImages={!!frontImage?.availableImageIndexes.length}
            modalProps={frontModalProps}
            tools={[ETools.zoomIn, ETools.zoomOut, ETools.rotate, ETools.images]}
          />
        </div>
      }
      {backImageData &&
        <div className='data-check-image-wrapping'>
          <ImageWrapper
            imageSrc={Object.values(backImageData)[0]}
            onClickChangeImage={() => setShowBack(!showBack)}
            hasAvailableImages={!!backImage?.availableImageIndexes.length}
            modalProps={backModalProps}
            tools={[ETools.zoomIn, ETools.zoomOut, ETools.rotate, ETools.images]}
          />
        </div>
      }
    </div>
  );
};

export default Check;
