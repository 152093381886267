import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Back } from 'images/back.svg';
import { ReactComponent as Next } from 'images/next.svg';
import { getImageFromBase64 } from 'util/common';
import { IRootState } from 'store/types';
import { ICheckResult, IDocumentData } from 'store/features/checkResult/types';
import * as checkResultActions from 'store/features/checkResult/actions';
import { EDirection, EDocumentType, EImageSide } from 'enums';
import { ISpecimen, ISpecimenState } from 'store/features/specimen/types';
import {
  getUpdatedData,
  getImageFromPackage,
  getPackagePosition,
  getServiceUnavailableImage,
} from 'helper/tools/fraudCheck';
import { oneSideDocument } from 'helper/xmlParser';
import Loader from 'components/loader/Loader';
import { LOADING_DURATION } from 'constants/Static';
import { Questions } from 'store/features/common/types';

const Specimen = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const subDocsData = state.zipContent?.subDocsData || [];
  const checkResult = state.checkResult as ICheckResult;
  const { specimens = [] } = state.specimen as ISpecimenState;
  const { documentData = [], filesData = [] } = checkResult;
  const filesSliderPath = state.common?.filesSliderPath || '';
  const prevPagePath = state.common?.prevPagePath || '';
  const [specimenData, setSpecimenData] = useState({
    frontSpecimenLoaded: false,
    backSpecimenLoaded: false,
    frontUnavailable: false,
    backUnavailable: false,
  });

  const {
    itemIndex,
    packageIndex,
  } = useMemo(() => (
    getPackagePosition(filesSliderPath, prevPagePath, documentData)
  ), [filesSliderPath, prevPagePath]);
  const {
    imageIndex,
    subDocIndex,
    specimen = ({} as ISpecimen),
    hasSpecimen,
  } = documentData[packageIndex][itemIndex];
  const { back = '', front = '', duid } = specimen;

  const currentIndex = specimens.findIndex((item: ISpecimen) => item.duid === specimen.duid);
  const { documentType } = filesData[subDocIndex][imageIndex];

  useEffect(() => {
    setSpecimenData({
      frontSpecimenLoaded: false,
      backSpecimenLoaded: false,
      frontUnavailable: false,
      backUnavailable: false,
    });
    const frontSpecimenImage = new Image();
    const backSpecimenImage = new Image();
    frontSpecimenImage.src = front;
    backSpecimenImage.src = back;
    frontSpecimenImage.onload = () => {
      setSpecimenData(prevState => ({ ...prevState, frontSpecimenLoaded: true }));
    };
    frontSpecimenImage.onerror = () => {
      setSpecimenData(prevState => ({ ...prevState, frontUnavailable: true }));
    };
    backSpecimenImage.onload = () => {
      setSpecimenData(prevState => ({ ...prevState, backSpecimenLoaded: true }));
    };
    backSpecimenImage.onerror = () => {
      setSpecimenData(prevState => ({ ...prevState, backUnavailable: true }));
    };
    const interval = setInterval(() => {
      if (!specimenData.frontSpecimenLoaded) {
        setSpecimenData(prevState => ({ ...prevState, frontSpecimenLoaded: true }));
      }
      if (!specimenData.backSpecimenLoaded) {
        setSpecimenData(prevState => ({ ...prevState, backSpecimenLoaded: true }));
      }
    }, LOADING_DURATION);
    return () => clearTimeout(interval);
  }, [duid]);

  const changeSpecimen = (direction: EDirection) => {
    const index = currentIndex + direction < 0 ?
      specimens.length - 1 : (currentIndex + direction) % specimens.length;
    let updateDocumentData = getUpdatedData(documentData, packageIndex, itemIndex, {
      specimen: specimens[index],
    });
    const secondSideIndex = documentData[packageIndex].findIndex((item: IDocumentData, index) => (
      index > itemIndex && item.question === Questions.imageSource
    ));
    if (secondSideIndex !== -1) {
      updateDocumentData = getUpdatedData(updateDocumentData, packageIndex, secondSideIndex, {
        specimen: specimens[index]
      });
    }
    dispatch(checkResultActions.setDocumentData(updateDocumentData));
  }

  const frontImage = useMemo(() => (
    getImageFromPackage(documentData[packageIndex], subDocsData, EImageSide.front)
  ), [itemIndex]);

  const backImage = useMemo(() => (
    getImageFromPackage(documentData[packageIndex], subDocsData, EImageSide.back)
  ), [itemIndex]);

  const specimenImageStyle = front && back ? '' : 'half-width';

  const realImageStyle = oneSideDocument(documentType) ? 'half-width' : '';

  const specificSide = oneSideDocument(documentType) ?
    [EDocumentType.healthCard].includes(documentType) ? EImageSide.front : EImageSide.back : '';

  return <div className="template-container">
    <div className="document-template-image-container">
      <div className="image-container-doc">
        <div className="images-container">
          {(!specificSide || specificSide === EImageSide.front) && <div className="image-item">
            {backImage ? (
              <img
                className={`image-style ${realImageStyle}`}
                alt="back document"
                src={getImageFromBase64(backImage)}
              />
            ) : (
              <p className="no-image">
                {documentType === EDocumentType.paperPermit
                  ? t('reg.document.template.photoNotFound') :
                  t('reg.document.template.mrzNotFound')}
              </p>
            )}
          </div>}
          {(!specificSide || specificSide === EImageSide.back) && <div className="image-item">
            { frontImage ? (
              <img
                className={`image-style ${realImageStyle}`}
                alt="front document"
                src={getImageFromBase64(frontImage)}
              />
            ) : (
              <p className="no-image">
                {documentType === EDocumentType.paperPermit ?
                  t('reg.document.template.dataNotFound') :
                  t('reg.document.template.vizNotFound')}
              </p>
            )}
          </div>}
        </div>
      </div>
      <div className="label-container">{ t('reg.document.template.userDocument') }</div>
    </div>
    <div className="document-template-image-container">
      {!hasSpecimen ? (
        <span className="template-not-available">
          { t('reg.document.template.noTemplate') }
        </span>
      ) : (
        <>
          <div className="image-container-doc">
            {specimens.length > 1 && (
              <div
                className="left-arrow arrow-container"
                role="button"
                onClick={() => changeSpecimen(EDirection.left)}
                tabIndex={0}
              >
                <Back />
              </div>
            )}
            <div className="images-container">
              {back && (
                <div className="image-item">
                  {!specimenData.backSpecimenLoaded && (
                    <div className="loader-container"> <Loader /></div>
                  )}
                  <img
                    className={`image-style ${specimenImageStyle}`}
                    alt="back"
                    src={specimenData.backUnavailable ?
                      getServiceUnavailableImage(i18n.language) : back
                    }
                    onLoad={() => (
                      setSpecimenData(prevState => ({ ...prevState, backSpecimenLoaded: true }))
                    )}
                  />
                </div>
              )}
              {front && (
                <div className="image-item">
                  {!specimenData.frontSpecimenLoaded && (
                    <div className="loader-container"> <Loader /></div>
                  )}
                  <img
                  className={`image-style ${specimenImageStyle}`}
                  alt="front"
                  src={specimenData.frontUnavailable ?
                    getServiceUnavailableImage(i18n.language) : front
                  }
                  onLoad={() => (
                    setSpecimenData(prevState => ({ ...prevState, frontSpecimenLoaded: true }))
                  )}
                  />
                </div>
              )}
            </div>
            {specimens.length > 1 && (
              <div
                className="right-arrow arrow-container"
                role="button"
                onClick={() => changeSpecimen(EDirection.right)}
                tabIndex={0}
              >
                <Next />
              </div>
            )}
          </div>
          <div className="label-container">{duid?.split('-')[2] || ''}</div>
        </>
      )}
    </div>
  </div>;
};

export default Specimen;
