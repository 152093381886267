import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'store/types';
import { TFileImage, ICustomerDataImage, IZipContent } from 'store/features/zipContent/types';
import ImageWrapper from 'components/imageWrapper/ImageWrapper';
import AvailableModalBody from 'components/modal/modalContent/AvailableImagesContent';
import AddressModalContent from 'components/modal/modalContent/AddressModalContent';
import * as customerDataCheckActions from 'store/features/customerDataCheck/actions';
import { ETools } from 'enums';

const Address = () => {
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const {
    zipContent,
    customerDataCheck,
  } = state;
  const { customerData = {} } = zipContent as IZipContent;
  const savedImageName = customerDataCheck?.address?.image?.imageName || '';
  const utilityImages: ICustomerDataImage[] = customerData.utilityImages || [];
  const addressPopupShowAgain = state.popupInfo?.addressPopupShowAgain;
  const [isModalShowing, setIsModalShowing] = useState(addressPopupShowAgain);
  const [isAvailableModalShowing, setAvailableModalShowing] = useState(false);
  const coordinates = customerData?.coordinates;

  const [currentImageName, setCurrentImageName] = useState(
    savedImageName ||
    utilityImages.find((image: ICustomerDataImage) => !image.isCropped)?.imageName ||
    utilityImages[0].imageName
  );

  const image = useMemo(() => {
    const utilityImage = utilityImages.find(
      (dataImage: ICustomerDataImage) => dataImage.imageName === currentImageName
    );
    return utilityImage || { imageName: '', base64: '', isCropped: true };
  }, [currentImageName]);

  const { imageName = '', base64 = '', isCropped = true, } = image;

  const showAvModal = () => {
    if (isModalShowing) return;
    setAvailableModalShowing(true);
  };

  useEffect(() => {
    dispatch(
      customerDataCheckActions.setSelectedImage({
        imageName: imageName,
      })
    );
  }, [imageName]);

  const onImageSelect = (imageName: string) => {
    setCurrentImageName(imageName);
    dispatch(customerDataCheckActions.setSelectedImage({ imageName }));
  };

  const images: Array<TFileImage> = utilityImages.map(
    (value: ICustomerDataImage) => ({ [value.imageName]: value.base64 })
  );

  const getAvailableBody = () => {
    return <AvailableModalBody
      onSelectImage={onImageSelect}
      images={images}
      selected={imageName}
    />;
  };

  const hideModal = () => {
    if (!isAvailableModalShowing) {
      setIsModalShowing(false);
    } else {
      setAvailableModalShowing(false);
    }
  };

  const modalProps = [
    {
      ModalBody: getAvailableBody(),
      showAgainType: '',
      setIsModalShowing: hideModal,
      isModalShowing: isAvailableModalShowing,
    },
    {
      ModalBody: AddressModalContent,
      showAgainType: 'address',
      setIsModalShowing: setIsModalShowing,
      isModalShowing: addressPopupShowAgain && isModalShowing,
    },
  ];

  return (
    <div className="main-container">
      <ImageWrapper
        imageSrc={base64}
        onClickChangeImage={showAvModal}
        hasAvailableImages={images.length > 1}
        coordinates={!isCropped && Boolean(Object.keys(coordinates).length) ? coordinates : {}}
        modalProps={modalProps}
        tools={[ETools.zoomIn, ETools.zoomOut, ETools.rotate, ETools.highLite, ETools.images]}
      />
    </div>
  );
};

export default Address;
