import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FieldsTable from 'components/fieldsTable/FieldsTable';
import Footer from 'components/footer/Footer';
import InfoBar from 'components/infoBar/InfoBar';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import Address from 'pages/customerDataCheck/address/Address';
import { Questions, TQuestion } from 'store/features/common/types';
import { IRootState } from 'store/types';
import {
  formatOutputData,
  getExtractedData,
  getTableStructure,
} from 'helper/tools/customerDataCheck';
import { EPage } from 'enums';
import BurgerMenu from 'components/burgerMenu/BurgerMenu';
import { IZipContent } from 'store/features/zipContent/types';
import { ICheckResult } from 'store/features/checkResult/types';

const AddressComponent = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const {
    zipContent,
    checkResult = {} as ICheckResult,
  } = state;
  const { customerData = {} } = zipContent as IZipContent;
  const { outputJson = {} } = customerData;
  const { selfDeclaration = {} } = outputJson;
  const { address = {}, firstName = '', lastName = '' } = selfDeclaration;
  const question: TQuestion = state.common?.question || Questions.approve;
  const page = HeaderButtons[EPage.userDataCheck].address[question];

  const dataList = useMemo(() => (
    getTableStructure(formatOutputData({firstName, lastName, ...address}))
  ), []);

  const extractedData = useMemo(() => getTableStructure(getExtractedData(checkResult)), []);

  return (
    <>
      <InfoBar />
      <>
        <BurgerMenu />
        <h3>{t(page?.heading || '')}</h3>
      </>
      <div className="check-content">
        <div className='extra-space-menu-container' />
        <Address />
        <div className="right-menu-container">
          <FieldsTable
            dataList={extractedData}
            headerName={'user-data-check.address.right-menu.header2'}
            translateSource={'extracted-data'}
          />
          <FieldsTable
            dataList={dataList}
            headerName={'user-data-check.address.right-menu.header1'}
            translateSource={'self-declaration'}
          />
        </div>
      </div>
      <Footer NestedComponent={ page?.answerComponent } />
    </>
  );
};

export default AddressComponent;
