import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { batchActions } from 'redux-batched-actions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SignIn from 'pages/signin/SignIn';
import Dashboard from 'pages/dashboard/Dashboard';
import InfoBar from 'components/infoBar/InfoBar';
import TABoarding from 'pages/taBoard/TABoarding';
import DatasetContainer from 'pages/dataset/DatasetContainer';
import Summary from 'pages/summary/Summary';
import PublicRoute from 'routing/PublicRoute';
import PrivateRoute from 'routing/PrivateRoute';
import {
  isAuthorized,
  getURLHashParams,
  ensureURLAuthorization,
} from 'util/authService';
import ZipContentWrapper from 'ZipContentWrapper';
import RoutePaths from 'constants/RoutePaths';
import Loader from 'components/loader/Loader';
import { GetDatasets, GetDefaultConfigs } from 'helper/api/route';
import { getLocalStorageData, getStorageData } from 'util/storageHelper';
import { PATH } from 'constants/Static';
import * as dataSetActions from 'store/features/dataSets/actions';
import * as userActions from 'store/features/user/actions';
import * as commonActions from 'store/features/common/actions';
import { IRootState } from 'store/types';
import { IDataSet } from 'store/features/dataSets/types';
import LogOut from 'pages/logout/LogOut';
import {
  GetAuthConfig,
  UpdateTransactionState,
  SetDefaultDataset
} from 'helper/api/route';
import { hasDefaultProps } from 'helper/tools/commonHelper';
import { EPage, ETransactionState } from 'enums';
import ForgotPassword from 'pages/forgotPassword/ForgotPassword';
import NewPassword from 'pages/newPassword/NewPassword';

const {
  SIGN_IN,
  FORGOTTEN_PASSWORD,
  DASHBOARD,
  DATASET,
  TA_BOARDING,
  SUMMARY_QUICK_CHECK,
  SUMMARY_FRAUD_CHECK,
  SUMMARY_DATA_CHECK,
  SUMMARY_CUSTOMER_DATA_CHECK,
  LOG_OUT,
  SET_PASSWORD,
} = PATH;

const Routes = () => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const state: IRootState = useSelector((rootState: IRootState) => rootState);
  const defaultTransactionCode = state.common?.defaultTransactionCode || '';
  const datasets = state.dataSets || [];
  const appConfig = state.common?.appConfig || {};
  const location = useLocation();
  const [redirectUrl, setRedirectUrl] = useState('');
  const [authorizationEnsured, setAuthorizationEnsured] = useState(false);

  let isHashUpdated = false;

  const resetTransactionCode = () => {
    const storageState: string = getStorageData('state') || '';
    const stateData: any = storageState ? JSON.parse(storageState) : {};
    const { trCode = '' } = stateData;

    if (trCode) UpdateTransactionState({ code: trCode, state: ETransactionState.needReview });
  };

  const settingLanguage = () => {
    const urlParams = new URLSearchParams(location.search);
    const lang = urlParams.get('lang');
    if (lang && appConfig.lang?.langs.includes(lang)) {
      i18n.changeLanguage(lang);
      urlParams.delete('lang');
      history.replace({search: urlParams.toString()});
    }
  };

  const getDefaultUrl = () => {
    if (isHashUpdated) {
      isHashUpdated = false;
      return;
    }
    const interval = setInterval(() => {
      resetTransactionCode();
      if (isAuthorized()) {
        const defaultUrl = hasDefaultProps(datasets) ? DASHBOARD : DATASET;
        window.location.replace(defaultUrl);
      } else {
        window.location.replace(LOG_OUT);
      }
      clearInterval(interval);
    }, 0);
  };

  useEffect(() => {
    settingLanguage();
    if (!Object.keys(appConfig).length) {
      GetDefaultConfigs().then((response) => {
        dispatch(commonActions.setAppConfig(response.data));
      });
    }
    // setInterval added to avoid NS Error in firefox browser
    window.addEventListener('popstate', getDefaultUrl, false);
    return () => window.removeEventListener('popstate', () => ({}));
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tid = urlParams.get('tid');
    if (tid) {
      dispatch(commonActions.setDefaultTransactionCode(tid));
    }
    if (!isAuthorized()) return;
    if (!datasets.length) {
      GetDatasets().then((response: any) => {
        const { data } = response;
        const { configData = [] } = data;
        const config: any = configData.find((conf: IDataSet) => conf.isDefault) || {};
        const userFilteredData = {
          name: data.name,
          id: data.id,
          email: data.email,
        };
        batchActions([
          dispatch(dataSetActions.setDataSet(configData)),
          dispatch(userActions.setUser(userFilteredData)),
        ]);
        const queryParams = defaultTransactionCode ? `?tid=${defaultTransactionCode}` : '';
        if (config.isDefault) setRedirectUrl(`${DASHBOARD}${queryParams}`);
        else if (configData.length === 1) {
          const [ firstDataset ] = configData;
          const { agentConfigId } = firstDataset;
          setAuthorizationEnsured(false);
          SetDefaultDataset(agentConfigId)
            .then((updatedResponse) => {
              const { data: updatedData } = updatedResponse;
              const { configData: updatedConfigData = [] } = updatedData;
              dispatch(dataSetActions.setDataSet(updatedConfigData));
              history.replace({ pathname: `${DASHBOARD}${queryParams}` });
            })
            .finally(() => setAuthorizationEnsured(true));
        } else {
          setRedirectUrl(`${DATASET}${queryParams}`);
        }
      });
    } else if (!redirectUrl) {
      const defaultUrl = hasDefaultProps(datasets) ? DASHBOARD : DATASET;
      const queryParams = defaultTransactionCode ? `?tid=${defaultTransactionCode}` : '';
      setRedirectUrl(`${defaultUrl}${queryParams}`);
    }
  }, [!isAuthorized()]);

  const ensureAuthorization = () => {
    if (isAuthorized()) {
      setAuthorizationEnsured(true);
      return;
    }

    const urlHashParams = getURLHashParams();
    const { access_token: accessToken } = urlHashParams;
    if (accessToken) {
      isHashUpdated = true;
      ensureURLAuthorization(urlHashParams);
      setAuthorizationEnsured(true);
    } else {
      GetAuthConfig()
        .then(({ data = {} }) => {
          let { authURL } = data;
          if (authURL) {
            // This part is temporary and
            // will be removed in the future once correct redirection implemented
            const urlParams = new URLSearchParams(location.search);
            const tid = urlParams.get('tid');
            if (tid) {
              const url = new URL(authURL);
              const redirectURI = url.searchParams.get('redirect_uri');
              url.searchParams.delete('redirect_uri');
              authURL = `${url.href}&redirect_uri=${redirectURI}/?tid=${tid}`;
              dispatch(commonActions.setDefaultTransactionCode(tid));
            }
            const userInfo: string | null = getLocalStorageData('userInfo');
            window.location.replace(userInfo ? LOG_OUT : authURL);
          } else {
            setAuthorizationEnsured(true);
          }
        })
        .catch(() => {
          setAuthorizationEnsured(true);
        });
    }
  };

  useEffect(() => {
    if (location.pathname !== LOG_OUT) {
      ensureAuthorization();
    }
  }, []);

  if (!authorizationEnsured && location.pathname !== LOG_OUT) {
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  }

  return (
    <Switch>
      <PublicRoute exact path={SIGN_IN}>
        <SignIn />
      </PublicRoute>
      <PublicRoute exact path={FORGOTTEN_PASSWORD}>
        <ForgotPassword />
      </PublicRoute>
      <PublicRoute exact path={LOG_OUT}>
        <LogOut />
      </PublicRoute>
      <PublicRoute exact path={SET_PASSWORD}>
        <NewPassword />
      </PublicRoute>
      <PrivateRoute exact path={DASHBOARD}>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute exact path={DATASET}>
        <DatasetContainer />
      </PrivateRoute>
      <PrivateRoute exact path={TA_BOARDING}>
        <ZipContentWrapper>
          <InfoBar />
          <TABoarding />
        </ZipContentWrapper>
      </PrivateRoute>
      <PrivateRoute exact path={SUMMARY_QUICK_CHECK}>
        <ZipContentWrapper>
          <InfoBar />
          <Summary />
        </ZipContentWrapper>
      </PrivateRoute>
      <PrivateRoute exact path={SUMMARY_FRAUD_CHECK}>
        <ZipContentWrapper>
          <InfoBar />
          <Summary />
        </ZipContentWrapper>
      </PrivateRoute>
      <PrivateRoute exact path={SUMMARY_DATA_CHECK}>
        <ZipContentWrapper>
          <InfoBar />
          <Summary />
        </ZipContentWrapper>
      </PrivateRoute>
      <PrivateRoute exact path={SUMMARY_CUSTOMER_DATA_CHECK}>
        <ZipContentWrapper>
          <InfoBar />
          <Summary />
        </ZipContentWrapper>
      </PrivateRoute>
      {Object.keys(RoutePaths).map((route: string, index: number) => (
        RoutePaths[route as EPage].slice(1).map((subRoute: any) => (
          <PrivateRoute exact path={subRoute.pathname} key={index}>
            <ZipContentWrapper>
              <subRoute.component />
            </ZipContentWrapper>
          </PrivateRoute>
        ))
      ))}
      <Route
        exact
        render={() => (!isAuthorized() ?
          <Redirect to={SIGN_IN} push={false} /> :
          redirectUrl ?
            <Redirect to={redirectUrl} push={true} /> :
            <div className="loader-container"><Loader /></div>
        )}
      />
    </Switch>
  );
};

export default Routes;
