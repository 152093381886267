import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import { HeaderButtons } from 'constants/CheckTypeButtons';
import { IAnswerOption } from 'types';
import { ANSWERS_KEYS, BUTTON_STYLE, PATH } from 'constants/Static';
import Answers from 'components/footer/Answers';
import { IRootState } from 'store/types';
import { Questions } from 'store/features/common/types';
import { EDirection, EImageSide, EPage } from 'enums';
import { getActiveImagePath } from 'helper/tools/commonHelper';
import { updatedFilesData, getNext, detectForcedRejection } from 'helper/tools/quickCheck';
import { ICheckResult } from 'store/features/checkResult/types';
import * as checkResultActions from 'store/features/checkResult/actions';
import * as commonActions from 'store/features/common/actions';
import { useHistory, useLocation } from 'react-router-dom';

const DocumentPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const { filesData = [] } = checkResult;
  const filesSliderPath = state.common?.filesSliderPath || '';
  const {
    subDocIndex,
    imageIndex,
  } = useMemo(() => getActiveImagePath(filesSliderPath), [filesSliderPath]);
  const { imageSide, pageSideKey, documentType } = filesData[subDocIndex][imageIndex];
  const page = HeaderButtons[EPage.quickCheck].files[Questions.documentPage];
  const [selectedImageSide, setImageSide] = useState(imageSide);
  const [selectedPageSideKey, setPageSideKey] = useState(pageSideKey);

  useEffect(() => {
    setImageSide(imageSide);
    setPageSideKey(pageSideKey);
  }, [filesSliderPath]);

  const forceReject = () => {
    batchActions([
      dispatch(commonActions.setForcedRejectedInfo({
        isForcedRejected: true,
        location: location.pathname,
        question: Questions.documentPage,
        filesSliderPath,
      })),
      dispatch(commonActions.setActiveSlidePath('')),
      dispatch(commonActions.setPrevPagePath('')),
    ]);
    return history.replace(PATH.SUMMARY_QUICK_CHECK);
  };

  const submitAnswer = (answer: IAnswerOption) => {
    if (answer.key === ANSWERS_KEYS.CONFIRM) {
      const updatedResult = updatedFilesData(filesData, subDocIndex, [imageIndex], {
        imageSide: selectedImageSide,
        pageSideKey: selectedPageSideKey,
      });
      dispatch(checkResultActions.setFilesData(updatedResult));
      if (detectForcedRejection(updatedResult)) {
        return forceReject();
      }
      const nextPath = getNext(
        filesData,
        subDocIndex,
        imageIndex,
        EDirection.right,
        Questions.documentPage,
      );
      if (nextPath) {
        dispatch(commonActions.setActiveSlidePath(nextPath));
      } else {
        const nextPath = getNext(filesData, 0, -1, EDirection.right, Questions.quality);
        batchActions([
          dispatch(commonActions.setActiveSlidePath(nextPath)),
          dispatch(commonActions.setQuestion(Questions.quality)),
        ]);
      }
    } else {
      setImageSide(answer.key.split('-')[0] as EImageSide);
      setPageSideKey(answer.text);
    }
  };

  const back = () => {
    const previousPath = getNext(
      filesData,
      subDocIndex,
      imageIndex,
      EDirection.left,
    );
    if (previousPath) {
      dispatch(commonActions.setActiveSlidePath(previousPath));
    } else {
      const previousPath = getNext(
        filesData,
        filesData.length - 1,
        filesData[filesData.length - 1].length,
        EDirection.left,
      );
      batchActions([
        dispatch(commonActions.setQuestion(Questions.countryDocType)),
        dispatch(commonActions.setActiveSlidePath(previousPath)),
      ]);
    }
  };

  const filteredAnswers = page?.answers.filter((item: IAnswerOption) => (
    !item.allowedDocumentTypes?.length || item.allowedDocumentTypes.includes(documentType)
  ));

  const foundedIndex = filteredAnswers?.findIndex((answer: IAnswerOption) => (
    answer.text === selectedPageSideKey
  ));

  const activeIndexes = !selectedPageSideKey ? [(filteredAnswers || []).length - 1]
    : [foundedIndex, (filteredAnswers || []).length - 1];

  return (
    <Answers
      back={back}
      answers={filteredAnswers}
      activeIndexes={activeIndexes}
      submitAnswer={submitAnswer}
      disabled={!selectedPageSideKey ? [(filteredAnswers || []).length - 1] : []}
      styled={BUTTON_STYLE.OUTLINE_SECONDARY}
    />
  );
};

export default DocumentPage;