import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import RightMenu from 'pages/fraudCheck/pages/pageDetection/RightMenu';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import InfoBar from 'components/infoBar/InfoBar';
import Footer from 'components/footer/Footer';
import FraudPages from 'pages/fraudCheck/pages/pageDetection/FraudPages';
import { IRootState } from 'store/types';
import { Questions, TQuestion } from 'store/features/common/types';
import IdRecordingComponent from 'pages/fraudCheck/pages/pageDetection/IdRecording';
import { EPage } from 'enums';
import BurgerMenu from 'components/burgerMenu/BurgerMenu';

const PagesComponent = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const question: TQuestion = state.common?.question || Questions.imageSource;
  const page = HeaderButtons[EPage.fraudCheck].document[question];

  return (
    <>
      <InfoBar />
      <>
        <BurgerMenu />
        <h3>{t(page?.heading || '')}</h3>
      </>
      <div className="check-content">
        <div className='extra-space-menu-container' />
        {
          question.includes(Questions.idRecording) ? <IdRecordingComponent /> : <FraudPages />
        }
        <RightMenu />
      </div>
      <Footer NestedComponent={page?.answerComponent} />
    </>
  );
};

export default PagesComponent;
