import React from 'react';

import AccordionMenu from 'components/accordionMenu/AccordionMenu';

const LeftMenu = (props: { pathName: string }) => {
  const { pathName } = props;
  return (
    <div className="left-menu-container">
      <AccordionMenu pathName={pathName} />
    </div>
  );
};

export default LeftMenu;
