import { ZipContentActions, IZipContent } from 'store/features/zipContent/types';

export const setZipContent = (value: IZipContent) => ({
  type: ZipContentActions.setZipContent,
  value,
});

export const setZipContentLoader = (value: boolean) => ({
  type: ZipContentActions.setZipContentLoader,
  value,
});

