import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import * as popupInfoActions from 'store/features/popupInfo/actions';
import { ISingleButtonProps } from 'types';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';

const Modal = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    ModalBody,
    showAgainType,
    setIsModalShowing,
    isModalShowing,
  } = props;

  const showHideClassName = isModalShowing ? 'comp-modal display-block' : 'comp-modal display-none';

  const hideModal = () => {
    setIsModalShowing(false);
  };

  const bigButtonPropsData: ISingleButtonProps = {
    isActive: true,
    onClick: hideModal,
    textButton: 'reg.liveness.modal.ok',
    radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
    variant: BUTTON_STYLE.OUTLINE_SECONDARY,
  };

  const handleShowAgain = () => {
    switch (showAgainType) {
    case 'liveness':
      dispatch(popupInfoActions.setLivenessPopupShowAgain(false));
      break;
    case 'fraudPages':
      dispatch(popupInfoActions.setFraudPagesPopupShowAgain(false));
      break;
    case 'documentScan':
      dispatch(popupInfoActions.setDocumentScanPopupShowAgain(false));
      break;
    case 'address':
      dispatch(popupInfoActions.setAddressPopupShowAgain(false));
      break;
    default:
      setIsModalShowing(false);
    }
  };

  return (
    <>
      <div className={showHideClassName}>
        <div className="top-fix">
          <section className="modal-main">
            <div className="modal-head-section">
              {showAgainType ? <ModalBody /> : ModalBody}
            </div>
            <div className="button-section">
              <SingleButton {...bigButtonPropsData} />
              {showAgainType && <div className="textSide">
                <button
                  onClick={handleShowAgain}
                  className="dontShow"
                >
                  {t('reg.comparation.modal.dontShowAgain')}
                </button>
              </div>}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Modal;
