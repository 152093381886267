import React from 'react';
import { useTranslation } from 'react-i18next';
import DraggableList from 'react-draggable-lists';

import { ReactComponent as DragArrow } from 'images/Arrow-dragon-drop.svg';
import { IFailReason } from 'types';

interface IDragonDropListProps {
  sortedData: IFailReason[];
  setSortedData: Function;

}

const DragonDropList = (props: IDragonDropListProps) => {
  const { t } = useTranslation();
  const { sortedData, setSortedData } = props;
  const listItemHeight = 34;
  let mouseDownY = 0;

  const getIndexById = (id: number) => {
    let index = -1;
    sortedData.forEach((element: IFailReason, arrIndex: number) => {
      if (arrIndex === Number(id)) {
        index = arrIndex;
      }
    });
    return index;
  };

  const updateStateDragList = (currentIndex: number, movementCount: number) => {
    let swapedIndex = getIndexById(currentIndex);
    if (currentIndex >= 0) {
      if (movementCount >= 0) {
        if (currentIndex + movementCount > sortedData.length - 1) {
          swapedIndex = sortedData.length - 1;
        } else if (currentIndex + movementCount <= 0) {
          swapedIndex = 0;
        } else {
          swapedIndex = currentIndex + movementCount;
        }
      } else {
        if (movementCount < 0) {
          if (currentIndex + movementCount < 0) {
            swapedIndex = 0;
          } else {
            swapedIndex = currentIndex + movementCount;
          }
        }
      }
      const copylistData = sortedData;
      const buffer = copylistData[currentIndex];
      copylistData.splice(currentIndex, 1);
      copylistData.splice(swapedIndex, 0, buffer);
      setSortedData(copylistData);
    }
  };
  const handleMouseDown = ((e: any) => {
    mouseDownY = e.target.getBoundingClientRect().y;
  });
  const handleMouseUp = ((e: any) => {
    const currentDivId = e.currentTarget.id;
    const mousUpY = e.currentTarget.getBoundingClientRect().y;
    const differentTransform = mousUpY - mouseDownY;
    if (differentTransform < 0) {
      if (differentTransform + listItemHeight > 0) {
        updateStateDragList(currentDivId, Math.floor(differentTransform / listItemHeight));
      } else if (differentTransform + listItemHeight < 0) {
        updateStateDragList(currentDivId, Math.ceil(differentTransform / listItemHeight));
      }
    } else if (differentTransform > 0) {
      updateStateDragList(currentDivId, Math.floor(differentTransform / listItemHeight));
    }
  });

  if (!sortedData) {
    return null;
  }

  return (
    <div className="custom-dragon-drop-list drag-list-scroll-bar-style">
      <DraggableList
        width={480}
        height={36}
        rowSize={1}
      >
        {
          !!sortedData.length && sortedData.map((item: IFailReason, index: number) => {
            return (
              <div
                id={`${index}`}
                onMouseDown={(e) => {
                  handleMouseDown(e);
                }}
                onMouseUp={(e) => {
                  handleMouseUp(e);
                }}
                key={`list-item-${index}`}
                className="list-items"
                style={{ width: 480, height: listItemHeight}}
              >
                <div className="icon-message-container">
                  <DragArrow
                    id={`svg-${index}`}
                    className={'svg-default'}
                  />
                  <p>{t(item.text)}</p>
                </div>
                <div className="qfd-section-container">
                  <p className="qfd-section">{t(item.tab)}</p>
                </div>
              </div>
            );
          })
        }
      </DraggableList>
    </div>
  );
};

export default DragonDropList;
