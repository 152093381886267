import React, { useEffect } from 'react';
import {
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import TopHeader from 'components/header/TopHeader';
import { isAuthorized } from 'util/authService';
import { IRootState } from 'store/types';
import { PATH } from 'constants/Static';
import { getLocalStorageData } from 'util/storageHelper';
import { getDefaultDataset, hasDefaultProps } from 'helper/tools/commonHelper';
import { IDataSet } from 'store/features/dataSets/types';

const { LOG_OUT, SIGN_IN } = PATH;

const PrivateRoute = (props: any) => {
  const { children, path } = props;
  const state = useSelector((rootState: IRootState) => rootState);
  const datasets = state.dataSets || [];
  const defaultDataset: IDataSet = getDefaultDataset(datasets);
  const history = useHistory();
  const location = useLocation();
  const userInfo: string | null = getLocalStorageData('userInfo');

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tid = urlParams.get('tid');
    if (tid) {
      const search = urlParams.toString();
      if (!isAuthorized()) {
        history.replace(`${SIGN_IN}?${search}`);
      } else {
        if (hasDefaultProps(datasets)) {
          history.replace(`/dashboard?${search}`);
        } else {
          history.replace(`/dataset?${search}`);
        }
      }
    }
  }, [defaultDataset.agentConfigId]);

  return (
    <Route path={path} exact>
      {isAuthorized() ? (
        <>
          <TopHeader />
          <div className="content">
            {children}
          </div>
        </>
      ) : <Redirect to={ userInfo ? LOG_OUT : SIGN_IN } push={false} />}
    </Route>
  );
};

export default PrivateRoute;
