import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IRootState } from 'store/types';
import ImageWrapper from 'components/imageWrapper/ImageWrapper';
import { getActiveImagePath } from 'helper/tools/commonHelper';
import { ETools } from 'enums';

const Files = () => {
  const state = useSelector((rootState: IRootState) => rootState);
  const subDocsData = state.zipContent?.subDocsData || [];
  const filesSliderPath = state.common?.filesSliderPath || '';
  const {
    subDocIndex,
    imageIndex,
  } = useMemo(() => getActiveImagePath(filesSliderPath), [filesSliderPath]);
  const subDocument = subDocsData[subDocIndex].files[imageIndex];
  const imageName: string = Object.keys(subDocument)[0];
  const image: string = subDocument[imageName];

  return (
    <div className="main-container">
      <ImageWrapper
        imageSrc={image}
        tools={[ETools.zoomIn, ETools.zoomOut, ETools.rotate]}
      />
    </div>
  );
};

export default Files;
