import React from 'react';

const ModalHeader = (props: {children: React.ReactNode}) => {
  const { children } = props;
  return (
    <div className="modal-header">
      {children}
    </div>
  );
};

export default ModalHeader;
