import React, { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Countries from 'constants/Countries.json';
import DocumentTypes from 'constants/DocumentTypes.json';
import { getImageFromBase64 } from 'util/common';
import { IRootState } from 'store/types';
import { ICheckResult, IFilesData } from 'store/features/checkResult/types';
import { getActiveImagePath } from 'helper/tools/commonHelper';
import { EDocumentType } from 'enums';

const getCountryAndDocType = (country: string, documentType: string, t: TFunction) => {
  if ([documentType, country].includes(EDocumentType.something)) {
    return t('documentType.unknown-document');
  }
  const countryValue = Countries.find((el) => el.value === country);
  const documentTypeValue = DocumentTypes.find((el) => el.value === documentType);
  if (!countryValue || !documentTypeValue) return '';
  return `${t(countryValue?.label)} - ${t(documentTypeValue?.label)}`;
};

const DocumentTypeRightMenu = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const { filesData = [] } = checkResult;
  const subDocsData = state.zipContent?.subDocsData || [];
  const filesSliderPath = state.common?.filesSliderPath || '';
  const {
    subDocIndex,
    imageIndex,
  } = useMemo(() => getActiveImagePath(filesSliderPath), [filesSliderPath]);

  return (
    <>
      <div className="document-type-right-side-main">
        <div className="data-section">
          <p className='data-section-title'>{t('reg.files.right-menu.header')}</p>
          <p className='data-section-description'>{t('reg.files.right-menu.description')}</p>
          <div className='list-container'>
            {filesData.map((subDoc: IFilesData[], docIndex: number) => (
              <div key={`sub-doc-${docIndex}`}>
                {subDoc.map((item: IFilesData, imgIndex: number) => {
                  const { documentType, country } = item;
                  const img = Object.values(subDocsData[subDocIndex].files[imgIndex])[0];
                  const isActive = imageIndex === imgIndex && subDocIndex === docIndex;
                  return (
                    <React.Fragment key={`sub-doc-${docIndex}-image-${imgIndex}`}>
                      <div className={
                        `${isActive ? 'active-tab-background-color' : ''} list-item`}>
                        <div className={`${isActive ? 'image-gf' : ''} fl-image`}>
                          <img
                            src={getImageFromBase64(img)}
                            alt="fl-item"
                          />
                        </div>
                        <span className='country-info'>
                          {getCountryAndDocType(country, documentType, t)}
                        </span>
                      </div>
                    </React.Fragment>
                  );
                }
                )}
                <p className="space"></p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentTypeRightMenu;
