import React from 'react';

import ToolTipField from 'components/toolTipField/ToolTipField';
import { IDrawFilledDataProps } from 'types';
import { EField } from 'enums';

export const DrawFilledData = (props: IDrawFilledDataProps) => {
  const {
    pageIndex = '',
    fieldTitle = '',
    value = '',
    icon = '',
    status = '',
    fieldName = '',
  } = props;
  return (
    <div className="accordion-sub-body-row" key={`accSubBody_${pageIndex}`}>
      <div className="data-list-item">
        <div className="text-section">
          <div className="text-area">
            <ToolTipField
              tooltipValue={fieldTitle}
              placement="top"
              className="field-text"
            >
              <span className="field-text">{fieldTitle}</span>
            </ToolTipField>
          </div>
          <div className="text-area">
            <ToolTipField tooltipValue={value} placement="top">
              <span
                className={`field-text ${
                  fieldName !== EField.drivingCategory && status
                }`}
              >
                {value}
              </span>
            </ToolTipField>
          </div>
        </div>
        {icon && <span className={`field-icon ${status}`}>{icon}</span>}
      </div>
    </div>
  );
};

export default DrawFilledData;
