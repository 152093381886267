import {
  SUMMARY_STATUS,
  SUMMARY,
  ANSWERS_KEYS,
} from 'constants/Static';
import { hasDocumentForCheck } from 'helper/tools/commonHelper';
import Countries from 'constants/Countries.json';
import DocumentTypes from 'constants/DocumentTypes.json';
import { getIsLivenessFailedSDK } from 'util/helperSDKVerification';
import { ISubDocData, IZipContent } from 'store/features/zipContent/types';
import { hasFacePage } from 'helper/tools/quickCheck';
import { shouldRenderCDCSummary } from 'helper/tools/customerDataCheck';
import { EDocumentType, EImageSide, EPage } from 'enums';
import {
  IFilesData,
  ICheckResult,
  IDocumentData,
  IDataValidation,
} from 'store/features/checkResult/types';
import { IRootState } from 'store/types';
import { valuesIsSame } from 'helper/validation';

const getPagesData = (images: IFilesData[], subDocIndex: number) => {
  const result: any[] = [];
  images.forEach((imageItem: IFilesData, imageIndex: number) => {
    const { imageSide, reviewResult, pageSideKey } = imageItem;
    let summaryStatus = SUMMARY_STATUS.SUCCESS;
    let quality = '';
    if (![EImageSide.front, EImageSide.back].includes(imageSide)) {
      summaryStatus = SUMMARY_STATUS.DANGER;
      quality = 'reg.summary.other';
    } else if (reviewResult?.key) {
      if (reviewResult.key === 'fraud') {
        summaryStatus = SUMMARY_STATUS.DANGER;
      } else {
        summaryStatus = SUMMARY_STATUS.WARNING;
      }
      quality = reviewResult.quality;
    } else {
      quality = reviewResult.quality;
    }

    const index = result.findIndex((page: any) => page.title.texts[0] === pageSideKey);
    if (index === -1) {
      result.push({
        type: 'page',
        title: { texts: [pageSideKey] },
        status: SUMMARY_STATUS.SUCCESS,
        images: [
          {
            title: pageSideKey,
            imageIndex,
            subDocIndex,
            quality,
            status: summaryStatus,
          },
        ],
      });
    } else {
      result[index].images.push({
        title: pageSideKey,
        imageIndex,
        subDocIndex,
        quality,
        status: summaryStatus,
      });
    }
  });
  return result;
};

const getDataPagesData = (dataValidation: IDataValidation, packageIndex: number) => {
  return [{
    type: 'dataList',
    title: 'reg.summary.data-check.title',
    status: SUMMARY_STATUS.SUCCESS,
    packageIndex,
    images: [dataValidation],
  }];
};

const getCustomerDataPagesData = (customCheck: any, field: string) => {
  const result: any[] = [];
  if (field === 'selfDeclaration') {
    const { data } = customCheck;
    result.push({
      type: 'selfDeclaration',
      page: data,
    });
  }
  if (field === 'address') {
    const { data, image } = customCheck;
    const status = customCheck?.result === 'reject'
      ? SUMMARY_STATUS.DANGER
      : SUMMARY_STATUS.SUCCESS;
    result.push({
      type: 'address',
      status,
      reasons: status === SUMMARY_STATUS.DANGER && [
        'reg.summary.customerDataCheck.rejected',
      ],
      data,
      image,
    });
  }
  if (field === 'documentScan') {
    const status = customCheck?.result === 'reject'
      ? SUMMARY_STATUS.DANGER
      : SUMMARY_STATUS.SUCCESS;
    result.push({
      type: 'documentScan',
      status,
      selectedImageName: customCheck?.image?.imageName || '',
      reasons: status === SUMMARY_STATUS.DANGER && [
        'reg.summary.customerDataCheck.rejected',
      ],
    });
  }
  return result;
};

const getFraudPagesData = (documentData: IDocumentData[]) => (
  documentData?.reduce((result: any[], data: IDocumentData) => {
    const {
      reviewResult, imageIndex, subDocIndex, summaryTextKey,
    } = data;
    let status = SUMMARY_STATUS.SUCCESS;
    if (reviewResult.key) {
      if (reviewResult.key.includes(ANSWERS_KEYS.UNSURE)) {
        status = SUMMARY_STATUS.WARNING;
      } else {
        status = SUMMARY_STATUS.DANGER;
      }
    }
    result.push({
      imageIndex,
      subDocIndex,
      reasons: reviewResult?.reasons || [],
      status,
      title: summaryTextKey,
    });
    return result;
  }, []) || []
);

const getSubDocumentTitle = (images: IFilesData[]) => {
  if (images.length) {
    const doc = images?.find(
      (image: IFilesData) => image.documentType !== EDocumentType.something
    ) || {};
    const { documentType = '', country = '' } = doc as IFilesData;
    const isDataSame: boolean = images.every(
      (image: IFilesData) => (image.documentType === documentType
          && image.country === country)
        || image.documentType === EDocumentType.something,
    );
    if (isDataSame) {
      if (!documentType) return { texts: ['documentType.something'], status: '' };
      return getDocumentTypeLabel(documentType, country);
    }
  }
  return { texts: ['reg.summary.mismatch'], status: '' };
};

const getCustomerDataCheckTitlesByKeys = (key: string) => {
  switch (key) {
  case 'selfDeclaration':
    return 'reg.summary.customerDataCheck.field-1';
  case 'address':
    return 'reg.summary.customerDataCheck.field-2';
  case 'documentScan':
    return 'reg.summary.customerDataCheck.field-3';
  default:
    return '';
  }
};

const getQuickCheckSummaryProps = (state: IRootState) => {
  const result: any = {};
  const { zipContent, checkResult } = state;
  const { video, subDocsData = [] } = zipContent as IZipContent;
  const { entryName = '' } = video;
  const { filesData = [], faceComparingData } = checkResult as ICheckResult;
  result.title = 'reg.summary.quickCheck';
  result.data = {};
  let statusFace = SUMMARY_STATUS.SUCCESS;
  const facEReason: Array<string> = [];
  if (faceComparingData?.reasons) {
    statusFace = SUMMARY_STATUS.DANGER;
    faceComparingData.reasons.forEach((element: any) => {
      facEReason.push(element.value);
    });
  } else if (faceComparingData?.quality) {
    facEReason.push(faceComparingData.quality);
  } else {
    facEReason.push('reg.summary.faceVerification.result');
  }
  subDocsData.forEach((subDoc: ISubDocData, subDocIndex: number) => {
    const doc = `subDoc_${subDocIndex}`;
    result.data[doc] = {};
    result.data[doc].type = 'docType';
    result.data[doc].title = getSubDocumentTitle(filesData[subDocIndex]);
    result.data[doc].pages = getPagesData(filesData[subDocIndex], subDocIndex);
  });

  if (facEReason.length && hasFacePage(subDocsData, entryName)) {
    result.data.docComparison = {};
    result.data.docComparison.type = 'docComparison';
    result.data.docComparison.title = {
      texts: ['reg.summary.documentComparison'],
    };
    result.data.docComparison.status = SUMMARY_STATUS.SUCCESS;
    result.data.docComparison.pages = [
      {
        type: 'face',
        title: { texts: ['reg.summary.face'] },
        icon:
          statusFace === SUMMARY_STATUS.SUCCESS
            ? SUMMARY_STATUS.SUCCESS
            : 'faceFailed',
        status: statusFace,
        reasons: facEReason,
        ...(statusFace !== 'success' && {
          statusText: {
            texts: [facEReason.length, 'reg.summary.face.reasons'],
            status: statusFace,
          },
        }),
      },
    ];
  }
  return result;
};

const getFraudCheckSummaryProps = (state: IRootState) => {
  const result: any = {};
  const { zipContent, checkResult } = state;
  const { video, subDocsData = [] } = zipContent as IZipContent;
  const { filesData = [], livenessData, documentData } = checkResult as ICheckResult;
  const livenessReasons: Array<string> = [];
  let statusLiveness = SUMMARY_STATUS.SUCCESS;
  result.title = 'reg.summary.fraudCheck';
  const subDocIndexes = Object.keys(subDocsData);
  const isFailed = subDocIndexes.some(
    (index) => getIsLivenessFailedSDK(subDocsData[Number(index)].dataXML)
  );

  if (isFailed) {
    statusLiveness = SUMMARY_STATUS.SUCCESS;
  }
  result.description = isFailed ? 'reg.summary.fraudCheck.fail-reason' : '';
  result.iconMid = SUMMARY_STATUS.DANGER;
  result.data = {};
  const failedMessage: Array<string> = [];
  if (livenessData?.reasons) {
    livenessData.reasons.forEach((reason: string) => {
      livenessReasons.push(reason);
      statusLiveness = SUMMARY_STATUS.DANGER;
    });
    failedMessage.push(`reg.summary.reason.${livenessData.key}`);
    failedMessage.push(livenessReasons.length.toString());
    failedMessage.push('reg.summary.reasons');
  } else if (livenessData?.quality) {
    livenessReasons.push(livenessData?.quality);
  } else {
    livenessReasons.push('reg.summary.livenessCheck.result');
  }

  if (livenessReasons.length && video) {
    result.data.liveness = {};
    result.data.liveness.type = 'liveness';
    result.data.liveness.status = statusLiveness;
    result.data.liveness.statusText = {
      texts: failedMessage,
      status: statusLiveness,
    };
    result.data.liveness.title = { texts: ['reg.summary.liveness'] };
    result.data.liveness.pages = [
      {
        type: 'face',
        title: { texts: ['reg.summary.face'] },
        status: statusLiveness,
        icon: statusLiveness,
        reasons: livenessReasons,
      },
    ];
  }
  documentData.forEach((documentItem: IDocumentData[], index: number) => {
    const doc = `subDoc_${index}`;
    const fraudCheckData = getFraudPagesData(documentItem);
    let status = SUMMARY_STATUS.SUCCESS;
    fraudCheckData.forEach((item: any) => {
      if (item.status !== SUMMARY_STATUS.SUCCESS) {
        status = item.status;
      }
    });
    if (fraudCheckData.length) {
      const [{ subDocIndex }] = fraudCheckData
      result.data[doc] = {};
      result.data[doc].type = 'fraudCheck';
      result.data[doc].title = getSubDocumentTitle(filesData[subDocIndex]);
      result.data[doc].status = status;
      result.data[doc].images = fraudCheckData;
    }
  });
  return result;
};

const hasDiff = (dataValidation: IDataValidation[]) => (
  dataValidation?.some((subDoc: IDataValidation) => {
    let diff = false;
    Object.keys(subDoc.fields).forEach((field: string) => {
      const [firstZone, secondZone] = subDoc.zones;
      if (!valuesIsSame(
        subDoc.fields[field]?.[firstZone]?.value || '',
        subDoc.fields[field]?.[secondZone]?.value || '',
        field
      )) {
        diff = true;
      }
    });
    return diff;
  })
);

const getDataCheckSummaryProps = (state: IRootState) => {
  const result: any = {};
  const { checkResult } = state;
  const { dataValidation, filesData = [] } = checkResult as ICheckResult;
  result.iconMid = SUMMARY_STATUS.SUCCESS;
  result.title = 'reg.summary.dataCheck';
  result.description = hasDiff(dataValidation)
    ? 'reg.summary.dataCheck.fail-reason'
    : '';
  result.iconMid = SUMMARY_STATUS.DANGER;
  result.data = {};
  dataValidation.forEach((data: IDataValidation, packageIndex: number) => {
    const { subDocIndex } = data;
    const doc = `subDoc_${packageIndex}`;
    result.data[doc] = {};
    result.data[doc].type = 'docType';
    result.data[doc].title = getSubDocumentTitle(filesData[subDocIndex]);
    result.data[doc].pages = getDataPagesData(data, packageIndex);
  });
  return result;
};

const getCustomerDataCheckSummaryProps = (state: IRootState) => {
  const result: any = {};
  const { customerDataCheck = {} as any } = state;
  result.title = 'reg.summary.customerDataCheck';
  result.data = {};

  if (!Object.keys(customerDataCheck).length) {
    return;
  }
  Object.keys(customerDataCheck).forEach((field, index) => {
    result.data[index] = {};
    result.data[index].type = field;
    result.data[index].title = getCustomerDataCheckTitlesByKeys(field);
    result.data[index].pages = getCustomerDataPagesData(customerDataCheck[field], field);
    if (field === 'selfDeclaration') {
      const { extractedData = {} } = customerDataCheck[field];
      const status = Object.keys(extractedData).findIndex(
        (subDocIndex: string) => extractedData[subDocIndex]?.result === 'reject',
      ) !== -1
        ? SUMMARY_STATUS.DANGER
        : SUMMARY_STATUS.SUCCESS;
      result.data[index].statusText = status === SUMMARY_STATUS.DANGER
        ? {
          texts: ['reg.summary.customerDataCheck.rejected'],
          status: SUMMARY_STATUS.DANGER,
        }
        : {
          status: SUMMARY_STATUS.SUCCESS,
        };
    }
  });
  return result;
};

const getFooterButtonData = (
  checkType: string,
  currentPathname: string,
  nextPathname: string,
) => {
  switch (checkType) {
  case 'fraud-check':
    return {
      buttonText: 'reg.summary.stepFraudCheck',
      span1Text: 'reg.summary.moreInformation',
      link: nextPathname,
    };
  case 'data-check':
    return {
      buttonText: 'reg.summary.stepDataValidation',
      span1Text: 'reg.summary.moreInformation',
      link: nextPathname,
    };
  case 'user-data-check':
    return {
      buttonText: 'reg.summary.stepCustomerDataValidation',
      span1Text: 'reg.summary.moreInformation',
      link: nextPathname,
    };
  default:
    return {
      buttonText: 'reg.summary.stepVerified',
      span1Text: 'reg.summary.overallEvaluation',
      link: currentPathname,
    };
  }
};

const validateSummary = (pathname: string) => (
  !pathname.includes(SUMMARY)
);

const shouldRenderComponent = (state: IRootState, pathname: string) => {
  const { zipContent } = state;
  const { subDocsData = [] } = zipContent as IZipContent;
  switch (pathname) {
  case EPage.quickCheck:
    return hasDocumentForCheck(subDocsData);
  case EPage.fraudCheck:
    return true;
  case EPage.dataCheck:
    return true;
  case EPage.userDataCheck:
    return shouldRenderCDCSummary(state);
  default:
    return false;
  }
};

const getMainComponent = (state: IRootState, route: string) => {
  switch (route) {
  case EPage.quickCheck:
    return getQuickCheckSummaryProps(state);
  case EPage.fraudCheck:
    return getFraudCheckSummaryProps(state);
  case EPage.dataCheck:
    return getDataCheckSummaryProps(state);
  case EPage.userDataCheck:
    return getCustomerDataCheckSummaryProps(state);
  default:
    return null;
  }
};

const getSummaryHeading = (route: string) => {
  switch (route) {
  case EPage.quickCheck:
    return 'reg.summary.quick-check-heading';
  case EPage.fraudCheck:
    return 'reg.summary.fraud-check-heading';
  case EPage.dataCheck:
    return 'reg.summary.data-check-heading';
  case EPage.userDataCheck:
    return 'reg.summary.user-data-check-heading';
  default:
    return '';
  }
};

const getDocumentTypeLabel = (documentType: string, country: string) => {
  const docCountry = Countries.find((option) => option.value === country);
  const docType = DocumentTypes.find((option) => option.value === documentType);
  if (!docType?.label || !docCountry?.label) return '';
  return { texts: [docCountry.label, docType?.label], status: '' };
};

export {
  validateSummary,
  getMainComponent,
  getSummaryHeading,
  getFooterButtonData,
  shouldRenderComponent,
};