import React, { useCallback } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import { ReactComponent as Search } from 'images/search.svg';
import { ReactComponent as Switch } from 'images/redirect.svg';
import { ReactComponent as RefreshButtonIcon } from 'images/refresh.svg';
import {
  GetZipContent,
  GetRequiredFields,
  UpdateTransactionState,
  GetHistory,
  GetActiveTransactions,
} from 'helper/api/route';
import { extractXMLData, getSubDocsData } from 'util/stateHelper';
import Loader from 'components/loader/Loader';
import * as commonActions from 'store/features/common/actions';
import * as zipContentActions from 'store/features/zipContent/actions';
import { IRootState } from 'store/types';
import {
  getFlowMode,
  toTitleCase,
  getErrorMessage,
  hasDocumentForCheck,
  getDefaultDataset,
} from 'helper/tools/commonHelper';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE, KEY_CODE, PATH } from 'constants/Static';
import { Questions } from 'store/features/common/types';
import { IDataSet } from 'store/features/dataSets/types';
import { ETransactionState } from 'enums';

const {
  TA_BOARDING,
  QUICK_CHECK_FILES,
  FRAUD_CHECK_LIVENESS,
} = PATH;

const NewTransactions = (props: {
  setSearchModalIsShowing: Function;
  setSwitchModalIsShowing: Function;
  setErrorModalIsShowing: Function;
  setMessage: Function;
  setIsWaiting: Function;
  isWaiting: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const datasets = state.dataSets || [];
  const {
    setSearchModalIsShowing,
    setSwitchModalIsShowing,
    setErrorModalIsShowing,
    setMessage,
    setIsWaiting,
    isWaiting,
  } = props;
  const history = useHistory();
  const defaultDataset: IDataSet = getDefaultDataset(datasets);
  const {
    customer = '',
    application = '',
    requiredFieldsType = '',
  } = defaultDataset;
  const isSimplified = getFlowMode(datasets);
  const size = state.common?.appConfig.dashboardTableRowCount || 20;

  const dashboard = state?.common?.dashboard;
  const showActive = dashboard?.showActive;
  const loading = dashboard?.loading;
  const data = showActive ? dashboard?.active : dashboard?.closed;
  const page = data?.page || 1;

  const startReviewOnClick = () => {
    setIsWaiting(true);
    dispatch(zipContentActions.setZipContentLoader(true));
    GetZipContent().then((response) => {
      const {
        subDocs,
        code,
        commonData,
      } = response.data;
      const packageIsValid = Object.values(subDocs).every(
        (item: any) => Object.keys(item.dataXML).length
      );
      const hasImages = Object.values(subDocs).some((subDocData: any) => {
        return subDocData.files.length;
      });
      if (packageIsValid && (commonData.video?.videoURL || hasImages)) {
        Promise.all(extractXMLData(subDocs)).then((packageItem) => {
          const modifiedResponseData = { ...response.data };
          for (let index = 0; index < packageItem.length; index++) {
            modifiedResponseData.subDocs[index].dataXML = packageItem[index];
          }
          batchActions([
            dispatch(zipContentActions.setZipContent(modifiedResponseData)),
            dispatch(commonActions.setResetCode(true)),
          ]);
          setIsWaiting(false);
          dispatch(zipContentActions.setZipContentLoader(false));
          const {
            code: transactionCode = '',
            subDocs: packageData = {},
            commonData: common = {},
          } = modifiedResponseData;
          const { videoURL = '' } = common.video;
          const subDocsData = getSubDocsData(packageData);
          if (hasDocumentForCheck(subDocsData) || videoURL) {
            GetRequiredFields(requiredFieldsType).then((res) => {
              const { data: docRequiredFields } = res;
              dispatch(commonActions.setDocRequiredFields(docRequiredFields));
            });
            if (isSimplified) {
              dispatch(commonActions.setReviewState(true));
              if (hasDocumentForCheck(subDocsData)) {
                return history.replace(QUICK_CHECK_FILES);
              }
              dispatch(commonActions.setQuestion(Questions.quality));
              return history.replace(FRAUD_CHECK_LIVENESS);
            }
            history.replace(TA_BOARDING);
          } else {
            setErrorModalIsShowing(true);
            setMessage([
              { text: t('messages.transaction') },
              { text: transactionCode, type: 'bold' },
              { text: t('messages.noContent') },
            ]);
            UpdateTransactionState({ code: transactionCode, state: ETransactionState.inError });
          }
        });
      } else if (code){
        UpdateTransactionState({ code, state: ETransactionState.inError });
        startReviewOnClick();
      }
    }).catch((err) => {
      const errorText = getErrorMessage(err, '');
      setErrorModalIsShowing(true);
      setMessage(errorText);
      dispatch(zipContentActions.setZipContentLoader(false));
      setIsWaiting(false);
    });
  };

  const enterPressed = (event: any, type: string) => {
    const code = event.keyCode || event.which;
    if (code === KEY_CODE.ENTER) {
      switch (type) {
      case 'search':
        return setSearchModalIsShowing(true);
      case 'switch':
        return setSwitchModalIsShowing(true);
      default:
        break;
      }
    }
  };

  const onRefresh = useCallback(() => {
    if (showActive) {
      dispatch(commonActions.setDashboardTableLoading(true));
      GetActiveTransactions(page, size)
        .then((response: any) => {
          const { data: transactionData } = response;
          const { items } = transactionData;
          const formattedItems = items?.map((item: any) => {
            const { date, status } = item;
            return {
              ...item,
              status: toTitleCase(status),
              date: moment.unix(date).format('YYYY.MM.DD'),
            };
          });
          dispatch(commonActions.setDashboardData({
            active: {
              ...transactionData,
              items: formattedItems
            }
          }));
        })
        .finally(() => dispatch(commonActions.setDashboardTableLoading(false)));
    }

    if (!showActive) {
      dispatch(commonActions.setDashboardTableLoading(true));
      GetHistory(page, size)
        .then((response: any) => {
          const { data: historyData } = response;
          const { items } = historyData;
          const formattedItems = items?.map((item: any) => {
            const { reviewDate, date, status } = item;
            return {
              ...item,
              status: toTitleCase(status),
              date: moment.unix(date).format('YYYY.MM.DD'),
              reviewDate: moment.unix(reviewDate).format('YYYY.MM.DD'),
            };
          });
          dispatch(commonActions.setDashboardData({
            closed: { ...historyData, items: formattedItems }
          }));
        })
        .finally(() => dispatch(commonActions.setDashboardTableLoading(false)));
    }
  }, [page, size, defaultDataset.agentConfigId, showActive]);

  return (
    <div className="new-transactions-container">
      <div className="title">
        <h2>{t('reg.dashboard.new-transaction.transactions-dashboard')}</h2>
      </div>
      <div className="new-transaction-content">
        <div className="app-brand-container">
          <div className="new-transactions-text">
            <span>{application}</span>
          </div>
          <div className="new-transactions-text brand">
            <span>{customer}</span>
          </div>
        </div>
        <div className="button-container">
          <SingleButton
            isActive={true}
            onClick={startReviewOnClick}
            textButton={'reg.dashboard.new-transaction.start-review'}
            sizeButton={BUTTON_STYLE.BIG}
            isDisabled={isWaiting}
            radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
            variant={BUTTON_STYLE.OUTLINE_SECONDARY}
          />
          <div className="ta-new-transaction-loader-container">
            {isWaiting && (<Loader />)}
          </div>
        </div>
        <div className="refresh-button-container">
          <SingleButton
            onClick={onRefresh}
            textButton={'reg.dashboard.refresh-data'}
            sizeButton={BUTTON_STYLE.BIG}
            isDisabled={loading}
            radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
            variant={BUTTON_STYLE.OUTLINE_PRIMARY}
            LeftIcon={RefreshButtonIcon}
          />
        </div>
        <div className="action-button-container">
          <div
            className="new-transactions-search-button"
            role="button"
            tabIndex={0}
            onKeyDown={(e: any) => enterPressed(e, 'search')}
            onClick={() => setSearchModalIsShowing(true)}
          >
            <Search className="new-transactions-icon" />
            <div className="action-button-title">{t('reg.dashboard.search-transaction')}</div>
          </div>
          <div
            className="new-transactions-switch-button"
            role="button"
            tabIndex={0}
            onKeyDown={(e: any) => enterPressed(e, 'switch')}
            onClick={() => setSwitchModalIsShowing(true)}
          >
            <Switch className="new-transactions-icon" />
            <div className="action-button-title">{t('reg.dashboard.switch-application')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTransactions;
