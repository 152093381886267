import React, {
  useRef,
  useState,
  useEffect,
  useCallback
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getImageFromBase64 } from 'util/common';
import Modal from 'components/modal/Modal';
import AvailableModalBody from 'components/modal/modalContent/AvailableImagesContent';
import FraudPagesContent from 'components/modal/modalContent/FraudPagesContent';
import ToolTipField from 'components/toolTipField/ToolTipField';
import { ReactComponent as View } from 'images/view.svg';
import { ReactComponent as Template } from 'images/template.svg';
import { ReactComponent as ZoomIn } from 'images/zoom-in.svg';
import { ReactComponent as ZoomOut } from 'images/zoom-out.svg';
import { ReactComponent as Images } from 'images/images.svg';
import * as commonActions from 'store/features/common/actions';
import { IRootState } from 'store/types';
import Loader from 'components/loader/Loader';
import { Questions } from 'store/features/common/types';
import { TFileImage } from 'store/features/zipContent/types';
import { getServiceUnavailableImage } from 'helper/tools/fraudCheck';
import { LOADING_DURATION } from 'constants/Static';

interface IComparisonProps {
  specimenImage: string;
  setSelectedImage: Function;
  images: TFileImage[];
  imageData: TFileImage;
}

const Comparison = (props: IComparisonProps) => {
  const { specimenImage, setSelectedImage, images, imageData } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const fraudPagesPopupShowAgain = state.popupInfo?.fraudPagesPopupShowAgain;
  const [value, setValue] = useState(0);
  const [scale, setScale] = useState(1);
  const [step, setStep] = useState(0);
  const [showInstruction, setShowInstruction] = useState(true);
  const [showImages, setShowImages] = useState(false);
  const [showSpecimenImage, setShowSpecimenImage] = useState(false);
  const [specimenLoaded, setSpecimenLoaded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [specimenData, setSpecimenData] = useState({
    specimenLoaded: false,
    imageUnavailable: false,
  });
  const divisorRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const coverImageRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const coverContainerRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const firstImageRef = useRef() as React.MutableRefObject<HTMLImageElement>;
  const secondImageRef = useRef() as React.MutableRefObject<HTMLImageElement>;
  const [isOnMouseDown, setIsOnMouseDown] = useState(false);
  const [positionMouseDown, setPositionMouseDown] = useState({ x: 0, y: 0 });
  const [transformStateX, setTransformStateX] = useState(0);
  const [transformStateY, setTransformStateY] = useState(0);
  const image = Object.values(imageData)[0];

  const minScale = 0;
  const maxScale = 3;
  const scaleStep = 0.33;

  useEffect(() => {
    divisorRef.current.style.width = `${value}%`;
  });

  useEffect(() => {
    setValue(0);
    setImageLoaded(false);
    setShowSpecimenImage(false);
  }, [image]);

  useEffect(() => {
    setValue(0);
    setShowSpecimenImage(false);
    setSpecimenData({
      specimenLoaded: false,
      imageUnavailable: false,
    });
    const specimenImageData = new Image();
    specimenImageData.src = specimenImage;
    specimenImageData.onload = () => {
      setSpecimenData(prevState => ({ ...prevState, specimenLoaded: true }));
    };
    specimenImageData.onerror = () => {
      setSpecimenData(prevState => ({ ...prevState, imageUnavailable: true }));
    };
    const interval = setInterval(() => {
      if (!specimenData.specimenLoaded) {
        setSpecimenData(prevState => ({ ...prevState, specimenLoaded: true }));
      }
    }, LOADING_DURATION);
    return () => clearTimeout(interval);
  }, [specimenImage]);

  const changeSpecimenImageLayer = () => {
    if (showInstruction) return;
    setImageLoaded(false);
    setSpecimenLoaded(false);
    setShowSpecimenImage(!showSpecimenImage);
  };

  const getWidth = useCallback(() => (coverContainerRef.current.offsetWidth), [coverContainerRef]);

  const onImgLoad = () => {
    setTransformStateX(0);
    setTransformStateY(0);
    setStep(0);
    setScale(1);
    coverImageRef.current.style.width = `${getWidth()}px`;
    if (!fraudPagesPopupShowAgain) {
      setShowInstruction(false);
    }
    setImageLoaded(true);
  };

  const getTemplatePage = () => dispatch(commonActions.setQuestion(Questions.select));

  const onSpecimenImgLoad = () => {
    setTransformStateX(0);
    setTransformStateY(0);
    setStep(0);
    setScale(1);
    if (!fraudPagesPopupShowAgain) {
      setShowInstruction(false);
    }
    setSpecimenLoaded(true);
  };

  const hideModal = () => {
    if (!showImages) {
      setShowInstruction(false);
    } else {
      setShowImages(false);
    }
  };

  const showAvModal = () => {
    if (showInstruction) return;
    setShowImages(true);
  };

  const handleZoomIn = () => {
    if (showImages) return;
    if (step < maxScale) {
      setScale(scale + scaleStep);
      setStep(step + 1);
    }
  };

  const handleZoomOut = () => {
    if (showImages) return;
    if (step > minScale) {
      setScale(scale - scaleStep);
      setStep(step - 1);
    }
  };

  const handleMouseOut = () => {
    setIsOnMouseDown(false);
  };

  const handleMouseMovement = (e: any, imageRef: any) => {
    const {
      right: parentDivRight,
      left: parentDivLeft,
      top: parentDivTop,
      bottom: parentDivBottom,
    } = coverContainerRef.current.getBoundingClientRect();
    const {
      right: imageRight,
      left: imageLeft,
      top: imageTop,
      bottom: imageBottom,
    } = imageRef.current.getBoundingClientRect();

    const movementX = e.pageX;
    const movementY = e.pageY;
    let transformY = movementY - positionMouseDown.y;
    let transformX = movementX - positionMouseDown.x;

    if (imageTop + 25 > parentDivTop) {
      if (movementY < positionMouseDown.y) {
        transformY = movementY - positionMouseDown.y;
      } else {
        transformY = 0;
      }
    }

    if (imageLeft > parentDivLeft) {
      if (movementX < positionMouseDown.x) {
        transformX = movementX - positionMouseDown.x;
      } else {
        transformX = 0;
      }
    }

    if (imageRight < parentDivRight) {
      if (movementX > positionMouseDown.x) {
        transformX = movementX - positionMouseDown.x;
      } else {
        transformX = 0;
      }
    }

    if (imageBottom - 25 < parentDivBottom) {
      if (movementY > positionMouseDown.y) {
        transformY = movementY - positionMouseDown.y;
      } else {
        transformY = 0;
      }
    }

    if (transformX !== 0) {
      setTransformStateX(transformX);
    }
    if (transformY !== 0) {
      setTransformStateY(transformY);
    }
  };

  const handleMouseUp = () => setIsOnMouseDown(false);

  const handleMouseDown = (e: any) => {
    setPositionMouseDown({ x: e.pageX, y: e.pageY });
    setIsOnMouseDown(true);
  };

  const AvailableBody = (
    <AvailableModalBody
      onSelectImage={(imageName: string) => {
        setSelectedImage(imageName);
      }}
      images={images}
      selected={Object.keys(imageData)[0]}
    />
  );


  const getSpecimenImage = () => (
    specimenData.imageUnavailable ? getServiceUnavailableImage(i18n.language) : specimenImage
  );

  useEffect(() => {
    const resizeListener = () => {
      coverImageRef.current.style.width = `${getWidth()}px`;
    };
    // set resize listener
    window.addEventListener('resize', resizeListener, { passive: true });

    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return (
    <>
      <div className="comparison-main-section-reg">
        <div className="comparison-review">
          <div className="comparison-container-st">
            {(!specimenLoaded || !imageLoaded) && (
              <div className="loader-container"> <Loader /></div>
            )}
            <div
              className={`comparison ${specimenLoaded ? 'comparison-border' : ''}`}
              ref={coverContainerRef}
              onMouseOut={() => isOnMouseDown && handleMouseOut()}
            >
              <div className="figure">
                <div
                  onMouseUp={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleMouseUp();
                  }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleMouseDown(e);
                  }}
                  onMouseMove={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    isOnMouseDown && step
                      && handleMouseMovement(e, secondImageRef);
                  }}
                  className="first-image-container"
                >
                  <img
                    className="image-style"
                    src={showSpecimenImage ? getSpecimenImage() : getImageFromBase64(image)}
                    alt="realImage"
                    ref={secondImageRef}
                    onLoad={onImgLoad}
                    draggable
                    id="secondImage"
                    style={
                      !step
                        ? { transform: `translate(0,0) scale(${scale})` }
                        : { transform:
                          `translate(${transformStateX}px,${transformStateY}px)
                          scale(${scale})` }
                    }
                  />
                </div>
                <div
                  ref={divisorRef}
                  className="divisor"
                >
                  <div
                    className="image-cover"
                    ref={coverImageRef}
                    onMouseUp={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleMouseUp();
                    }}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleMouseDown(e);
                    }}
                    onMouseMove={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      isOnMouseDown && step
                        && handleMouseMovement(e, firstImageRef);
                    }}
                  >
                    <img
                      className="image-style"
                      src={showSpecimenImage ? getImageFromBase64(image) : getSpecimenImage()}
                      alt="template"
                      onLoad={onSpecimenImgLoad}
                      ref={firstImageRef}
                      id="firstImage"
                      style={
                        !step
                          ? { transform: `translate(0,0) scale(${scale})` }
                          : { transform:
                            `translate(${transformStateX}px,${transformStateY}px)
                            scale(${scale})` }
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <input
              type="range"
              min="0"
              max="100"
              className="inputRange"
              id="slider"
              value={value}
              onChange={(e: any) => setValue(e.target.value)}
              disabled={fraudPagesPopupShowAgain && showInstruction}
            />
          </div>
          <div className="tools">
            <div
              className={`zoom ${(step === minScale) ? 'is-disabled' : ''}
                ${showImages ? 'rotate-disabled' : ''}`}
              onClick={handleZoomOut}
            >
              <ZoomOut />
            </div>
            <div
              className={`zoom ${(step === maxScale) ? 'is-disabled' : ''}
                ${showImages ? 'rotate-disabled' : ''}`}
              onClick={handleZoomIn}
            >
              <ZoomIn />
            </div>
            <div className="eye-icon" onClick={changeSpecimenImageLayer}><View /></div>
            {
              images.length > 1 ?
                <div className="change-template-icon" onClick={showAvModal}><Images /></div>
                :
                <ToolTipField
                  tooltipValue={t('reg.page.no-available-images')}
                  placement="top"
                >
                  <div className="rotate-disabled">
                    <Images />
                  </div>
                </ToolTipField>
            }
            <div onClick={getTemplatePage} >
              <ToolTipField
                tooltipValue={t('reg.fraudCheck.document.answers.chooseTemplate')}
                placement="top"
              >
                <Template />
              </ToolTipField>
            </div>
          </div>
        </div>
        {((fraudPagesPopupShowAgain && showInstruction) || showImages)
          && (
            <Modal
              show
              ModalBody={showImages ? AvailableBody : FraudPagesContent}
              showAgainType={showImages ? '' : 'fraudPages'}
              setIsModalShowing={hideModal}
              isModalShowing={showInstruction || showImages}
            />
          )}
      </div>
    </>
  );
};

export default Comparison;