import React, { useCallback } from 'react';
import moment from 'moment';
import Pagination from 'react-bootstrap/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IRootState } from 'store/types';
import { getDefaultDataset, toTitleCase } from 'helper/tools/commonHelper';
import * as commonActions from 'store/features/common/actions';
import { GetHistory, GetActiveTransactions, ExportFile } from 'helper/api/route';
import { IDataSet } from 'store/features/dataSets/types';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';
import { ReactComponent as DownloadButtonIcon } from 'images/download.svg';
import { downloadFile } from 'util/common';
import { AxiosResponse } from 'axios';

const TablePagination = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const dashboard = state?.common?.dashboard;
  const showActive = dashboard?.showActive;
  const loading = dashboard?.loading;
  const data = showActive ? dashboard?.active : dashboard?.closed;
  const page = data?.page || 1;
  const size = state.common?.appConfig.dashboardTableRowCount || 20;
  const totalItems = data?.totalItems || 0;
  const totalPages = Math.ceil(totalItems / size) || 1;
  const datasets = state.dataSets || [];
  const defaultDataset: IDataSet = getDefaultDataset(datasets);

  const onChange = useCallback((newPage) => () => {
    if (showActive) {
      dispatch(commonActions.setDashboardTableLoading(true));
      GetActiveTransactions(newPage, size)
        .then((response: any) => {
          const { data: responseData } = response;
          const { items } = responseData;
          const formattedItems = items?.map((item: any) => {
            const { date, status } = item;
            return {
              ...item,
              status: toTitleCase(status),
              date: moment.unix(date).format('YYYY.MM.DD'),
            };
          });
          dispatch(commonActions.setDashboardData({
            active: {
              ...responseData,
              items: formattedItems
            }
          }));
        })
        .finally(() => dispatch(commonActions.setDashboardTableLoading(false)));
    }

    if (!showActive) {
      dispatch(commonActions.setDashboardTableLoading(true));
      GetHistory(newPage, size)
        .then((response: any) => {
          const { data: responseData } = response;
          const { items } = responseData;
          const formattedItems = items?.map((item: any) => {
            const { reviewDate, date, status } = item;
            return {
              ...item,
              status: toTitleCase(status),
              date: moment.unix(date).format('YYYY.MM.DD'),
              reviewDate: moment.unix(reviewDate).format('YYYY.MM.DD'),
            };
          });
          dispatch(commonActions.setDashboardData({
            closed: {
              ...responseData, items: formattedItems
            }
          }));
        })
        .finally(() => dispatch(commonActions.setDashboardTableLoading(false)));
    }
  }, [page, size, defaultDataset.agentConfigId, showActive]);

  const exportCSV = () => {
    ExportFile(totalItems).then((response: AxiosResponse) => {
      downloadFile(response.data);
    });
  };

  const disabledPrev = loading || page === 1;
  const disabledNext = loading || page >= totalPages;
  const startIndex = (page - 1) * size + 1;
  const endIndex = page !== totalPages ? page * size : totalItems;

  return (
    <>
      {totalItems ? (
        <div className='transactions-table-pagination'>
          {!showActive ? <div className="export-button-container">
            <SingleButton
              onClick={exportCSV}
              textButton={'reg.table.export-reviewed'}
              sizeButton={BUTTON_STYLE.BIG}
              radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
              variant={BUTTON_STYLE.OUTLINE_PRIMARY}
              LeftIcon={DownloadButtonIcon}
            />
          </div> : null}
          <Pagination>
            <Pagination.Item disabled className="displayText">
              {`${t('reg.table.pagination.text.displaying')}
              ${startIndex}-${endIndex} ${t('reg.table.pagination.text.outOf')} ${totalItems}`}
            </Pagination.Item>
            <div className="btnCollection">
              <Pagination.Prev
                className={`bttArrow ${disabledPrev ? '' : 'active'}`}
                onClick={onChange(page - 1)}
                disabled={disabledPrev}
              />
              <Pagination.Next
                className={`bttArrow ${disabledNext ? '' : 'active'}`}
                onClick={onChange(page + 1)}
                disabled={disabledNext}
              />
            </div>
          </Pagination>
        </div>)
        : null}
    </>
  );
};

export default TablePagination;
