import React from 'react';
import { useTranslation } from 'react-i18next';

const LivenessContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="scrub-div">
        <p className="scrub-text-style">{t('reg.liveness.modal.body.text')}</p>
      </div>
    </>
  );
};
export default LivenessContent;