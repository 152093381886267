export enum SpecimenActions {
  setIsSpecimensLoaded = 'SET_IS_SPECIMENS_LOADED',
  setSpecimens = 'SET_SPECIMENS'
}

export interface ISpecimen {
  duid: string;
  extraDetail: string;
  externalSource: boolean;
  front?: string;
  back?: string;
}

export interface ISpecimenState {
  isSpecimensLoaded: boolean;
  specimens: ISpecimen[];
}

export type TSpecimenActions =
  { type: SpecimenActions.setIsSpecimensLoaded; value: boolean } |
  { type: SpecimenActions.setSpecimens; value: ISpecimen[] };