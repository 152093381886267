import { Parser } from 'xml2js';

import { getDocumentTypeData } from 'helper/xmlParser';
import { IPackageData, ISubDocData, IZipContent } from 'store/features/zipContent/types';

export const getIsMultipleDoc = (data: IPackageData) => typeof data.subDocs[1] !== 'undefined';

export const extractXMLDocument = (xml: string) => new Promise((resolve, reject) => {
  const parser = new Parser({ explicitArray: false });
  parser.parseString(xml, (err, result) => {
    if (err) {
      reject(err);
    } else {
      resolve(result);
    }
  });
});

export const extractXMLData = (subDocs: any) => {
  const promises = [];
  for (const key in subDocs) {
    if (Object.prototype.hasOwnProperty.call(subDocs, key)) {
      promises.push(extractXMLDocument(subDocs[key].dataXML));
    }
  }
  return promises;
};

export const getSubDocsData = (subDocs: ISubDocData[]) => (
  Object.values(subDocs).map((subDoc) => {
    return {
      ...subDoc,
      reviewed: false,
      documentTypeData: getDocumentTypeData(subDoc.dataXML),
    };
  })
);

export const locateZipContent = (data: IPackageData, newState: IZipContent) => {
  newState.isMultipleDoc = getIsMultipleDoc(data);
  newState.subDocsData = getSubDocsData(data.subDocs);
  newState.avatar = data.commonData.avatar;
  newState.video = data.commonData.video;
  newState.trCode = data.code;
  newState.customerData = data.customerData;
};
