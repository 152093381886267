import { ICheckResult } from 'store/features/checkResult/types';
import { getStorageData } from 'util/storageHelper';

export default (): ICheckResult => {
  const data: string | null = getStorageData('state');
  const storageData = data ? JSON.parse(data) : {};
  return storageData.checkResult
    || {
      faceComparingData: {},
      comparingData: {},
      livenessData: {},
      documentData: [],
      expiryDateData: [],
      filesData: [],
      dataValidation: [],
    };
};
