import { combineReducers, createStore } from 'redux';
import { enableBatching } from 'redux-batched-actions';

import {
  clearLocalStoragePartly,
  clearStorageData,
  removeStorageDataByKey,
} from 'util/storageHelper';
import { checkResultReducer } from 'store/features/checkResult/reducer';
import { zipContentReducer } from 'store/features/zipContent/reducer';
import { userReducer } from 'store/features/user/reducer';
import { dataSetsReducer } from 'store/features/dataSets/reducer';
import { commonReducer } from 'store/features/common/reducer';
import { popupInfoReducer } from 'store/features/popupInfo/reducer';
import { specimenReducer } from 'store/features/specimen/reducer';
import { rootActions } from 'store/types';
import { LOCAL_STORAGE_LNG } from 'constants/Static';
import { customerDataCheckReducer } from 'store/features/customerDataCheck/reducer';

export const appReducer = combineReducers({
  checkResult: checkResultReducer,
  common: commonReducer,
  zipContent: zipContentReducer,
  dataSets: dataSetsReducer,
  popupInfo: popupInfoReducer,
  userInfo: userReducer,
  specimen: specimenReducer,
  customerDataCheck: customerDataCheckReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === rootActions.reset) {
    clearStorageData();
    clearLocalStoragePartly([LOCAL_STORAGE_LNG]);
    return appReducer(undefined, action);
  }

  if (action.type === rootActions.resetSession) {
    removeStorageDataByKey('state');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default createStore(
  enableBatching(rootReducer),
  (process.env?.NODE_ENV === 'development')
  && (window as any).__REDUX_DEVTOOLS_EXTENSION__
  && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
);
