import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/dashboardModal/Modal';
import ModalHeader from 'components/dashboardModal/ModalHeader';
import ModalBody from 'components/dashboardModal/ModalBody';
import { ReactComponent as CloseLogo } from 'images/close.svg';
import { IStyledText } from 'types';
import StyledText from 'components/styledText/StyledText';
import { KEY_CODE } from 'constants/Static';

const NewTransactionModal = (
  props: { close: Function; message: Array<IStyledText>; code: string }) => {
  const { t } = useTranslation();
  const { close, message, code } = props;
  const modalFocus = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalFocus.current) {
      modalFocus.current.focus();
    }
  }, []);

  const enterPressed = (event: any) => {
    const trCode = event.keyCode || event.which;
    if (trCode === KEY_CODE.ENTER) {
      close(false);
    }
  };

  return (
    <Modal>
      <ModalHeader>
        <div className="dashboard-modal-title" ref={modalFocus}>
          <p>{t('reg.dashboard.modal.new-transaction-review')}</p>
        </div>
        <div
          className=" button close-modal-button"
          role="button"
          onKeyDown={(e) => enterPressed(e)}
          onClick={() => close(false)}
          tabIndex={0}
        >
          <CloseLogo />
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="no-transaction-found">
          <div>
            {code ? <StyledText data={message} isTranslated={true}/> : (
              <p className="no-transaction-found-text">
                <span>{t('reg.dashboard.modal.no-transaction')}</span>
              </p>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default NewTransactionModal;
