import { ISpecimen, SpecimenActions } from 'store/features/specimen/types';

export const setIsSpecimensLoaded = (value: boolean) => ({
  type: SpecimenActions.setIsSpecimensLoaded,
  value,
});

export const setSpecimens = (value: ISpecimen[]) => ({
  type: SpecimenActions.setSpecimens,
  value,
});
