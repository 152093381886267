import React from 'react';
import { useTranslation } from 'react-i18next';

import ListFromResources from 'components/accordionMenu/ListFromResources';

const FaceContent = () => {
  const { t } = useTranslation();
  const leftSideContent = [
    [t('reg.face.left-menu.task-help.message1'),
      {
        text: t('reg.face.left-menu.task-help.see-manual'),
        type: 'link',
        url: '',
      }
    ],
    [t('reg.face.left-menu.task-help.message2')],
    [t('reg.face.left-menu.task-help.message3')],
    [t('reg.face.left-menu.task-help.message4')],
    [t('reg.face.left-menu.task-help.message5')],
    [t('reg.face.left-menu.task-help.message6')]
  ];

  return (
    <ListFromResources resource={leftSideContent} />
  );
};

export default FaceContent;
