import { Questions, TDrivingCategory } from 'store/features/common/types';
import { IReviewResult } from 'types';
import { EDocumentType, EImageSide, EZone } from 'enums';
import { ISpecimen } from 'store/features/specimen/types';

export enum CheckResultActions {
  setDataValidation = 'SET_DATA_VALIDATION',
  setLivenessData = 'SET_LIVENESS_DATA',
  setDocumentData = 'SET_DOCUMENT_DATA',
  setFilesData = 'SET_FILES_DATA',
  setExpiryDateData = 'SET_EXPIRY_DATE_DATA',
  setComparingData = 'SET_COMPARING_DATA',
  setFaceComparingData = 'SET_FACE_COMPARING_DATA',
}

export interface ICheckResult {
  faceComparingData: IReviewResult;
  comparingData: IReviewResult;
  livenessData: IReviewResult;
  expiryDateData: IExpireDateData[];
  documentData: IDocumentData[][];
  filesData: IFilesData[][];
  dataValidation: IDataValidation[],
}

export interface IQuickCheckResultKey {
  allowExpiredDocument: string;
  subDocIndex: number;
}

export interface IDocumentData {
  subDocIndex: number;
  imageIndex: number;
  imageSide: EImageSide;
  summaryTextKey: string;
  textKey: string;
  question: Questions;
  reviewResult: IReviewResult;
  availableImageIndexes?: number[];
  hasSpecimen?: boolean;
  specimen?: ISpecimen;
  entryName?: string;
}

export interface IFilesData {
  imageSide: EImageSide;
  pageSideKey: string;
  country: string;
  documentType: EDocumentType;
  isImageCropped: boolean;
  reviewResult: IReviewResult;
  extraDetail: string;
}

export interface IExpireDateData {
  subDocIndex: number;
  imageIndex: number;
  availableImageIndexes: number[]
  expirationDate: string;
  reviewResult: IReviewResult;
}

export interface IFieldData {
  side: EImageSide;
  value: string;
  dlValue?: TDrivingCategory[];
  extractedValue: string;
  modified: boolean;
  optional: boolean;
  disabled: boolean;
  id: number;
  exists: boolean;
  equals: boolean;
}

export type TFieldZoneProps = {
  [zone in EZone]: IFieldData;
}

export type TFieldProps = {
  [field in string]: TFieldZoneProps;
}

export interface IDataValidation {
  subDocIndex: number;
  imageIndex: number;
  frontImage?: {
    index: number;
    availableImageIndexes: number[];
  },
  backImage?: {
    index: number;
    availableImageIndexes: number[];
  },
  duid: string;
  zones: EZone[];
  fields: TFieldProps;
}

export type TCheckResultActions =
  { type: CheckResultActions.setDataValidation; value: IDataValidation[] } |
  { type: CheckResultActions.setLivenessData; value: IReviewResult } |
  { type: CheckResultActions.setExpiryDateData; value: IExpireDateData[] } |
  { type: CheckResultActions.setFilesData; value: IFilesData[][] } |
  { type: CheckResultActions.setComparingData; value: IReviewResult } |
  { type: CheckResultActions.setFaceComparingData; value: IReviewResult } |
  { type: CheckResultActions.setDocumentData; value: IDocumentData[][] };
