import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import RoutePaths from 'constants/RoutePaths';
import { EPage } from 'enums';

interface ICheckTypeRoutes {
  checkType: string;
  checkSubType: string;
}

const CheckSubTypeButtons = (props: ICheckTypeRoutes) => {
  const { t } = useTranslation();
  const { checkType, checkSubType } = props;

  const ButtonGroupElements = RoutePaths[checkType as EPage]?.map((item, index: number) => {
    const style = checkSubType === item.name && index !== 0 ?
      'active-sub-type check-sub-type-buttons' : 'inactive-sub-type';
    return <NavLink
      key={`${item}-${index}`}
      to={`/${checkType}/${item}`}
      className={`check-button ${style}`}
      onClick={(e) => e.preventDefault()}
    >
      <span className="button-text">{t(item.navTitle)}</span>
    </NavLink>;
  });

  return (
    <div className="check-button-group">
      {ButtonGroupElements}
    </div>
  );
};

export default CheckSubTypeButtons;