import React from 'react';
import { IFrameWrapper } from 'types';

const FrameWrapper = (props: IFrameWrapper) => {
  const { url } = props;

  if (!url) return null;

  return (
    <iframe
      id="myFrame"
      src={url}
      frameBorder="0"
      title={'frameWrapper'}
      style={{
        maxHeight: 340,
        width: '100%',
        height: '340px',
        overflow: 'auto',
      }}
    ></iframe>
  );
};

export default FrameWrapper;