import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { IRootState } from 'store/types';
import ListFromResources from 'components/accordionMenu/ListFromResources';
import { Questions, TQuestion } from 'store/features/common/types';

const LivenessContent = () => {
  const { t } = useTranslation();
  let leftSideContent;
  const state = useSelector((rootState: IRootState) => rootState);
  const question: TQuestion = state.common?.question || Questions.documentPage;

  switch (question) {
  case Questions.quality:
    leftSideContent = [
      [t('reg.liveness.quality.left-menu.task-help.message1')],
      [
        t('reg.liveness.quality.left-menu.task-help.message2'),
        {
          text: t('reg.liveness.quality.left-menu.task-help.message3'),
          type: 'link',
          url: ''
        }
      ],
      [t('reg.liveness.quality.left-menu.task-help.message4')],
    ];
    break;
  case Questions.fraud:
    leftSideContent = [
      [t('reg.liveness.fraud.left-menu.task-help.message1')],
      [
        t('reg.liveness.fraud.left-menu.task-help.message2'),
        {
          text: t('reg.liveness.fraud.left-menu.task-help.message3'),
          type: 'link',
          url: ''
        }
      ],
      [t('reg.liveness.fraud.left-menu.task-help.message4')],
    ];
    break;
  case Questions.unsure:
    leftSideContent = [
      [t('reg.liveness.unsure.left-menu.task-help.message1')],
      [
        t('reg.liveness.unsure.left-menu.task-help.message2'),
        {
          text: t('reg.liveness.unsure.left-menu.task-help.message3'),
          type: 'link',
          url: ''
        }
      ],
      [t('reg.liveness.unsure.left-menu.task-help.message4')],
    ];
    break;
  default:
    break;
  }

  return (
    <ListFromResources resource={leftSideContent} />
  );
};

export default LivenessContent;
