import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PATH } from 'constants/Static';
import { isAuthorized } from 'util/authService';
import { IRootState } from 'store/types';
import { hasDefaultProps } from 'helper/tools/commonHelper';

const { DATASET, DASHBOARD } = PATH;

const PublicRoute = (props: any) => {
  const { children, path } = props;
  const state = useSelector((rootState: IRootState) => rootState);
  const datasets = state.dataSets || [];
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const tid = urlParams.get('tid');
  const queryParams: string = tid ? `?tid=${tid}` : '';

  const redirectUrl = hasDefaultProps(datasets) ?
    `${DASHBOARD}${queryParams}` : `${DATASET}${queryParams}`;

  return (
    <Route path={path} exact>
      {isAuthorized()
        ? <Redirect to={redirectUrl} push={false} /> : (
          <>
            {children}
          </>
        )}
    </Route>
  );
};

export default PublicRoute;
