import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Modal from 'components/modal/Modal';
import VideoPlayer from 'components/videoPlayer/VideoPlayer';
import LivenessModalBody from 'components/modal/modalContent/LivenessContent';
import { IRootState } from 'store/types';
import { IZipContent } from 'store/features/zipContent/types';
import { PATH } from 'constants/Static';

const LivenessContainer = () => {
  const [isModalShowing, setIsModalShowing] = useState(true);
  const state = useSelector((rootState: IRootState) => rootState);
  const livenessPopupShowAgain = state.popupInfo?.livenessPopupShowAgain;
  const { trCode = '' } = state.zipContent as IZipContent;
  const { video = { entryName: '' } } = state.zipContent || {};
  const { entryName = '', } = video;

  return (
    <div className="liveness-container">
      <div className="video-player-section">
        <VideoPlayer entryName={entryName} trCode={trCode} path={PATH.FRAUD_CHECK_LIVENESS} />
        {livenessPopupShowAgain && isModalShowing
          && (
            <Modal
              ModalBody={LivenessModalBody}
              showAgainType={'liveness'}
              setIsModalShowing={setIsModalShowing}
              isModalShowing={isModalShowing}
            />
          )}
      </div>
    </div>
  );
};

export default LivenessContainer;
