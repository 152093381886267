import { ECountry, EDocumentType, EField, EImageSide } from 'enums';
import { IGenericProps } from 'types';

export const PERMIT = 'permit';
export const SOMETHING = 'something';
export const UNKNOWN = 'Unknown';
export const PASS = 'pass';
export const LOCAL_STORAGE_LNG = 'i18nextLng';
export const SUMMARY = 'summary';
export const DEFAULT_SLIDER_PATH = '0/0';
export const DEFAULT_EXPIRE_VALUE = '**********';

export const ANSWERS_KEYS = {
  NO: 'no',
  YES: 'yes',
  SELECT: 'select',
  CONFIRM: 'confirm',
  GOOD_TO_USE: 'goodToUse',
  NOT_FOUND: 'not-found',
  FRAUD: 'fraud',
  UNSURE: 'unsure',
  LOOKS_REAL: 'looksReal',
  IS_CORRECT: 'is-correct',
  CONFIRM_ALL: 'confirmAll',
  REJECTED: 'rejected',
  ACCEPT: 'accept',
  NEXT: 'next',
  NOT_IN_LIST: 'not-in-list',
  APPROVED: 'approved',
  DIFFERENT: 'different',
  SAME: 'same',
  OTHER: 'other',
};

export const OTHER_IMAGE_SIDES = [
  EImageSide.other,
  EImageSide.signature,
];

export const IMAGE_RESOLUTION = {
  MIN: 1080,
  MAX: 1920,
}

export const PATH = {
  SIGN_IN: '/sign-in',
  FORGOTTEN_PASSWORD: '/forgotten-password',
  SET_PASSWORD: '/set-password',
  LOG_OUT: '/log-out',
  DASHBOARD: '/dashboard',
  DATASET: '/dataset',
  TA_BOARDING: '/ta-boarding',
  QUICK_CHECK_EXPIRY_DATE: '/quick-check/expiryDate',
  QUICK_CHECK_FILES: '/quick-check/files',
  QUICK_CHECK_FACE: '/quick-check/face',
  QUICK_CHECK_DATA: '/quick-check/data',
  QUICK_CHECK_TEMPLATE: '/quick-check/template',
  FRAUD_CHECK_LIVENESS: '/fraud-check/liveness',
  FRAUD_CHECK_DOCUMENT: '/fraud-check/document',
  DATA_CHECK_PAGE: '/data-check/document-data-validation',
  SUMMARY_QUICK_CHECK: '/summary/quick-check',
  SUMMARY_FRAUD_CHECK: '/summary/fraud-check',
  SUMMARY_DATA_CHECK: '/summary/data-check',
  SUMMARY_CUSTOMER_DATA_CHECK: '/summary/user-data-check',
  CDC_SELF_DECLARATION: '/user-data-check/self-declaration',
  CDC_ADDRESS: '/user-data-check/address',
  CDC_DOCUMENT_SCAN: '/user-data-check/document-scan',
};

export const SUMMARY_STATUS = {
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  MODIFIED: 'modified',
};

export const BUTTON_TYPE = {
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  OPTION_BUTTON: 'optionButton',
  RADIO_BUTTON: 'radioButton',
  SELECT_OPTION: 'selectOption',
};

export const BUTTON_STYLE = {
  OUTLINE_PRIMARY: 'outline-primary',
  PRIMARY: 'primary',
  OUTLINE_SECONDARY: 'outline-secondary',
  SECONDARY: 'secondary',
  BIG: 'big',
  OUTLINE_BIG: 'big-outline',
  INLINE_BIG: 'big-inline',
  CIRCLE_BUTTON: 'circle-button',
  CIRCLE_GROUP_BUTTONS: 'circle-group-buttons',
  CIRCLE_BUTTON_START: 'circle-group-buttons-start',
  CIRCLE_BUTTON_END: 'circle-group-buttons-end',
};

export const KEY_CODE = {
  TAB: 9,
  ENTER: 13,
  SPACE: 32,
};

export const SELF_DECLARATION_EXTRACTED_DIFFERENCE: IGenericProps = {
  dateOfBirth: 'birthdate',
  documentCountry: 'countryCode',
}

export const MRZ_INFO_MAPPING: IGenericProps = {
  lastName: 'primaryIdentifier',
  firstName: 'secondaryIdentifier',
  expirationDate: 'dateOfExpiry',
  documentCountry: 'issuingState',
}

export const DRIVING_FIELDS = ['value', 'issuingDate', 'restrictions', 'expiryDate'];

export const DEFAULT_DRIVING_FIELDS = {
  value: {
    extractedValue: '',
    reviewedValue: '',
    optional: false,
  },
  issuingDate: {
    extractedValue: '',
    reviewedValue: '',
    optional: false,
  },
  expiryDate: {
    extractedValue: '',
    reviewedValue: '',
    optional: true,
  },
  restrictions:  {
    extractedValue: '',
    reviewedValue: '',
    optional: true,
  },
}

export const DUID_LABEL_MAPPING: IGenericProps = {
  'che-driving_licence-2003': 'drivingLicence-che-v2003',
  'che-driving_licence-2023': 'drivingLicence-che-v2023',
  'che-permit-2008-non_eu': 'plastic-permit-che-before2018',
  'che-permit-2010-non_eu': 'plastic-permit-che-before2018',
  'che-permit-2018-non_eu': 'plastic-permit-che-before2018',
  'che-permit-2019-eu': 'plastic-permit-che-after2018',
  'che-permit-2020-non_eu': 'plastic-permit-che-after2018',
  'che-permit-2022-asylum': 'plastic-permit-che-after2018',
  'deu-permit-2011': 'plastic-permit-deu-v2011',
  'deu-permit-2019': 'plastic-permit-deu-v2019',
  'che-id-2003': 'id-che-v2003',
  'che-id-2005': 'id-che-v2003',
  'fra-id-1994': 'id-fra-v1994',
  'fra-id-2021': 'id-fra-v2021',
}

export const COMPARE_LIST = [
  EField.lastName,
  EField.firstName,
  EField.documentNumber,
];

export const LOADING_DURATION = 5 * 1000; // 5 seconds

export const REQUIRED_FIELDS = [
  EField.lastName,
  EField.firstName,
  EField.dateOfBirth,
];

export const SUPPORTED_EXTRA_DETAILS = [
  `${EDocumentType.plasticPermit}-${ECountry.che}`,
  `${EDocumentType.paperPermit}-${ECountry.che}`,
  `${EDocumentType.id}-${ECountry.can}`,
  `${EDocumentType.id}-${ECountry.usa}`,
  `${EDocumentType.drivingLicence}-${ECountry.can}`,
  `${EDocumentType.drivingLicence}-${ECountry.usa}`,
  `${EDocumentType.healthCard}-${ECountry.can}`,
];