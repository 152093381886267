import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { HeaderButtons } from 'constants/CheckTypeButtons';
import { ANSWERS_KEYS, BUTTON_STYLE } from 'constants/Static';
import Answers from 'components/footer/Answers';
import * as commonActions from 'store/features/common/actions';
import * as customerDataCheckActions from 'store/features/customerDataCheck/actions';
import { IRootState } from 'store/types';
import { Questions } from 'store/features/common/types';
import { TScreenName } from 'store/features/customerDataCheck/types';
import { PATH } from 'constants/Static';
import { EPage } from 'enums';
import { IAnswerOption } from 'types';

const RejectOptions = () => {
  const { SUMMARY_CUSTOMER_DATA_CHECK } = PATH;
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((rootState: IRootState) => rootState);
  const { customerDataCheck } = state;
  const page = HeaderButtons[EPage.userDataCheck]['document-scan'][Questions.reject];
  const { documentScan } = customerDataCheck;
  const [selectedValue, setValue] = useState([]) as any;
  const activeIndexes: number[] = [];

  useEffect(() => {
    documentScan?.reasons?.forEach((reason: string) => {
      setValue((oldArray: any) => [...oldArray, { value: reason }]);
    });
  }, []);

  page?.answers?.forEach((answer: any, index: number) => {
    if (selectedValue.length && !activeIndexes.includes(page?.answers.length - 1)) {
      activeIndexes.push(page?.answers.length - 1);
    }
    if (selectedValue.find((item: any) => item.value === answer.text)) {
      activeIndexes.push(index);
    }
  });

  const back = () => {
    dispatch(commonActions.setQuestion(Questions.approve));
  };

  const submitAnswer = (answer: IAnswerOption) => {
    const isExist = selectedValue.find((item: any) => item.value === answer.text);
    if (isExist) {
      setValue(selectedValue.filter((item: any) => item.value !== answer.text));
    } else {
      setValue((oldArray: any) => [...oldArray, { value: answer.text }]);
    }
    if (answer.key === ANSWERS_KEYS.CONFIRM) {
      const reasonData = selectedValue.map((item: any)=> item.value);
      dispatch(customerDataCheckActions.setCDCResult({
        key: TScreenName.documentScan,
        list: [{ reasons: reasonData }],
      }));
      dispatch(commonActions.setForcedRejectedInfo({
        isForcedRejected: true,
        location: location.pathname,
        question: Questions.reject,
      }));
      history.replace(SUMMARY_CUSTOMER_DATA_CHECK);
    }
  };
  return (
    <Answers
      back={back}
      answers={page?.answers}
      activeIndexes={activeIndexes}
      disabled={!selectedValue.length ? [(page?.answers || []).length - 1] : []}
      submitAnswer={submitAnswer}
      styled={BUTTON_STYLE.OUTLINE_SECONDARY}
    />
  );
};

export default RejectOptions;
