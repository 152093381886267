import { EDocumentType } from 'enums';
import { IZipContent } from 'store/features/zipContent/types';
import { getStorageData } from 'util/storageHelper';

export default (): IZipContent => {
  const state: string | null = getStorageData('state');
  const storageData = state ? JSON.parse(state) : {};

  return {
    isMultipleDoc: storageData.isMultipleDoc || false,
    avatar: '',
    customerData: {},
    subDocsData: [{
      files: [],
      dataXML: {},
      reviewed: false,
      avatar: '',
      documentTypeData: {
        classifiedDocument: {
          front: '',
          back: '',
        },
        documentType: '' as EDocumentType,
        documentCountry: '',
        documentSubtype: '',
      },
      videos: [],
    }],
    trCode: storageData.trCode || '',
    video: {
      entryName: '',
    },
    zipContentLoaded: true,
  };
};
