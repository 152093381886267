const config = {
  defaultDataset: [
    {
      Header: 'reg.dataset.defaultDataset.header',
      columns: [
        {
          Header: 'reg.dataset.defaultDataset.columns.customer',
          accessor: 'customer',
        },
        {
          Header: 'reg.dataset.defaultDataset.columns.application',
          accessor: 'application',
        },
        {
          Header: 'reg.dataset.defaultDataset.columns.check',
          accessor: 'check',
        },
        // {
        //   Header: 'Admin Contact',
        //   accessor: 'contact',
        // },
        {
          Header: '',
          accessor: 'setDefault',
        },
      ],
    },
  ],
  closed: [
    {
      Header: 'reg.dashboard.history.header',
      columns: [
        {
          Header: 'reg.dashboard.history.date',
          accessor: 'date',
        },
        {
          Header: 'reg.dashboard.history.tid',
          accessor: 'code',
        },
        {
          Header: 'reg.dashboard.history.status',
          accessor: 'result',
        },
        {
          Header: 'reg.dashboard.history.review-date',
          accessor: 'reviewDate',
        },
        {
          Header: 'reg.dashboard.history.reviewed-by',
          accessor: 'reviewedBy',
        },
        {
          Header: 'reg.dashboard.history.comments',
          accessor: 'comments',
        },
      ],
    },
  ],
  active: [
    {
      Header: 'reg.dashboard.history.header',
      columns: [
        {
          Header: 'reg.dashboard.active.date',
          accessor: 'date',
        },
        {
          Header: 'reg.dashboard.active.tid',
          accessor: 'code',
        },
        {
          Header: 'reg.dashboard.active.status',
          accessor: 'result',
        },
      ],
    },
  ],
  switchDataset: [
    {
      Header: 'reg.dataset.switchDataset.columns.customer',
      accessor: 'customer',
    },
    {
      Header: 'reg.dataset.switchDataset.columns.application',
      accessor: 'application',
    },
    {
      Header: 'reg.dataset.switchDataset.columns.check',
      accessor: 'check',
    },
    // {
    //   Header: 'Admin Contact',
    //   accessor: 'contact',
    // },
    {
      Header: '',
      accessor: 'switchTo',
    },
  ],
};

export default config;
