import React, {
  useRef,
  useState,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Pause } from 'images/pause.svg';
import { ReactComponent as Play } from 'images/play.svg';
import { ReactComponent as Fullscreen } from 'images/full.svg';
import Loader from 'components/loader/Loader';
import { isAuthorized } from 'util/authService';
import { LOADING_DURATION, PATH } from 'constants/Static';
import { getUserAgent } from 'helper/tools/commonHelper';
import { GetVideoContent } from 'helper/api/route';

interface IVideoPlayer {
  trCode: string;
  entryName: string;
  path: string;
}

const VideoPlayer = (props: IVideoPlayer) => {
  const { entryName = '', trCode = '', path = '' } = props;
  const history = useHistory();
  const { isFirefox } = getUserAgent();
  const videoRef = useRef() as React.MutableRefObject<HTMLVideoElement>;
  const progressRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [paused, setPaused] = useState(true);
  const [videoDuration, setVideoDuration] = useState(0);
  const [previewState, setPreviewState] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [videoSrc, setVideoSrc] = useState('');
  const extension = entryName.split('.').pop();

  const minValue = 0;
  const maxValue = videoDuration || 0;

  useEffect(() => {
    GetVideoContent(trCode, entryName).then((response) => {
      if (response.data) {
        setVideoSrc(response.data);
      } else {
        const interval = setInterval(() => {
          if (!isVideoLoaded && window.location.pathname === path) window.location.reload();
          clearInterval(interval);
        }, LOADING_DURATION);
      }
    }).catch(() => setIsVideoLoaded(true));
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current?.load();
    }
  }, [videoRef]);

  const active = 'rgba(233, 66, 94, 0.94)';
  const inactive = 'rgba(0, 0, 0, 0.0)';
  const isFullScreenEnabled = typeof videoRef?.current?.requestFullscreen === 'function';

  useEffect(()=> {
    if (videoRef.current && videoDuration && videoDuration !== Infinity) {
      videoRef.current.currentTime = videoDuration / 2;
    }
  }, [videoDuration]);

  useEffect(() => {
    if (videoRef !== null && videoRef.current !== null) {
      videoRef.current.onloadedmetadata = () => {
        if (!isFirefox || !videoRef.current.onloadedmetadata) {
          videoRef.current.currentTime = Number.MAX_SAFE_INTEGER;
        }
        videoRef.current.ontimeupdate = function () {
          this.ontimeupdate = () => {
            if (videoDuration !== videoRef.current?.duration) {
              setVideoDuration(videoRef.current?.duration);
            }
            return setTimeout(() => setIsVideoLoaded(true), 1000);
          }
          setVideoDuration(videoRef.current?.duration);
          if (isFirefox && videoRef.current.duration !== Infinity
            && Math.floor(videoRef.current.duration)
          ) {
            setIsVideoLoaded(true);
          }
          return;
        }
      };
      const interval = setInterval(() => {
        if(!previewState) {
          setCurrentTime(videoRef.current.currentTime);
        } else if (isFirefox && videoRef.current.duration !== videoDuration) {
          setVideoDuration(videoRef.current?.duration);
        }
        if (!paused && videoRef.current.currentTime === videoDuration) {
          setPaused(true);
        }
      });
      setPaused(videoRef.current.paused);
      return () => {
        clearInterval(interval);
      };
    }
  });

  const changeVideoState = () => {
    if (videoRef.current?.readyState) {
      setPaused(!paused);
      paused ? videoRef.current.play() : videoRef.current.pause();
    }
  };

  const fullscreen = () => {
    if (videoRef.current && isFullScreenEnabled) {
      if (previewState) videoRef.current.currentTime = 0;
      return videoRef.current.requestFullscreen();
    }
  };

  const handleChangeProgress = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    const progress = (value / maxValue) * 100 + '%';
    const newBackgroundStyle = `linear-gradient(90deg, ${active} 0% ${progress}%,
      ${inactive} ${progress}% 100%)`;
    progressRef.current.style.background = newBackgroundStyle;
    videoRef.current.currentTime = value;
    setCurrentTime(value);
  };

  const progress = (currentTime / maxValue) * 100 + '%';
  const styleInput = {
    background: `linear-gradient(90deg, ${active} 0% ${progress},   ${inactive} ${progress} 100%)`,
  };

  return (
    <div className='video-player'>
      <>
        {!isVideoLoaded && <div className="loader-container"><Loader /></div>}
        <div className="video-cont">
          {<video
            className="video-block"
            ref={videoRef}
            id="video"
            src={`data:video/${extension};base64,${videoSrc}`}
          />}
        </div>
        <div className="figcaption">
          <div className="pause-play-fullscreen-section">
            <div className="fill-white">
              {paused ?
                (<Play
                  onClick={() => {
                    if (videoRef.current && previewState) videoRef.current.currentTime = 0;
                    setPreviewState(false);
                    if (isAuthorized()) {
                      changeVideoState();
                    } else {
                      history.replace(PATH.LOG_OUT);
                    }
                  }}
                  id="play"
                />) :
                (<Pause
                  onClick={() => changeVideoState()}
                  id="pause"
                />)
              }
            </div>
          </div>
          <div className="progress-bar" >
            <input className="inputR"
              step="0.0001"
              type="range"
              id="progress"
              ref={progressRef}
              value={currentTime}
              min={minValue}
              max={maxValue}
              onChange={(e) => handleChangeProgress(e)}
              style={styleInput}
            />
            <div className="back-cover"></div>
          </div>
          <div className="pause-play-fullscreen-section">
            <Fullscreen
              onClick={() => { fullscreen() }}
              id="fullscreen"
              className={`${isFullScreenEnabled ? 'fullscreen-enable' : 'fullscreen-disable'}`}
            />
          </div>
        </div>
      </>
    </div>
  );
};

export default VideoPlayer;
