import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { HeaderButtons } from 'constants/CheckTypeButtons';
import { ANSWERS_KEYS, BUTTON_STYLE, DEFAULT_SLIDER_PATH } from 'constants/Static';
import Answers from 'components/footer/Answers';
import * as commonActions from 'store/features/common/actions';
import { Questions } from 'store/features/common/types';
import { PATH } from 'constants/Static';
import { IAnswerOption } from 'types';
import { EDirection, EPage } from 'enums';
import { getNext } from 'helper/tools/quickCheck';
import { IRootState } from 'store/types';
import { ICheckResult } from 'store/features/checkResult/types';
import { batchActions } from 'redux-batched-actions';

const DocumentCompare = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const page = HeaderButtons[EPage.quickCheck].files[Questions.comparing];
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const { filesData = [] } = checkResult;

  const submitAnswer = (answer: IAnswerOption) => {
    if (answer.key === ANSWERS_KEYS.NO) {
      batchActions([
        dispatch(commonActions.setForcedRejectedInfo({
          isForcedRejected: true,
          location: location.pathname,
          question: Questions.comparing,
        })),
        dispatch(commonActions.setActiveSlidePath('')),
        dispatch(commonActions.setPrevPagePath('')),
      ]);
      return history.replace(PATH.SUMMARY_QUICK_CHECK);
    }
    if (answer.key === ANSWERS_KEYS.YES) {
      const nextPath = getNext(filesData, 0, -1, EDirection.right, Questions.documentPage);
      dispatch(commonActions.setActiveSlidePath(nextPath || DEFAULT_SLIDER_PATH));
      dispatch(commonActions.setQuestion(Questions.documentPage));
    }
  };

  const back = () => {
    dispatch(commonActions.setQuestion(Questions.countryDocType));
  };

  return (
    <Answers
      back={back}
      answers={page?.answers}
      activeIndexes={[]}
      submitAnswer={submitAnswer}
      styled={BUTTON_STYLE.OUTLINE_PRIMARY}
    />
  );
};

export default DocumentCompare;