import {
  CheckResultActions,
  IDocumentData,
  IFilesData,
  IExpireDateData,
  IDataValidation,
} from 'store/features/checkResult/types';
import { IReviewResult } from 'types';

export const setLivenessData = (value: IReviewResult) => ({
  type: CheckResultActions.setLivenessData,
  value,
});

export const setDocumentData = (value: IDocumentData[][]) => ({
  type: CheckResultActions.setDocumentData,
  value,
});

export const setFilesData = (value: IFilesData[][]) => ({
  type: CheckResultActions.setFilesData,
  value,
});

export const setExpiryDateData = (value: IExpireDateData[]) => ({
  type: CheckResultActions.setExpiryDateData,
  value,
});

export const setComparingData = (value: IReviewResult) => ({
  type: CheckResultActions.setComparingData,
  value,
});

export const setFaceComparingData = (value: IReviewResult) => ({
  type: CheckResultActions.setFaceComparingData,
  value,
});

export const setDataValidation = (value: IDataValidation[]) => ({
  type: CheckResultActions.setDataValidation,
  value,
});

