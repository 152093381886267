import React, { useRef, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { getImageFromBase64 } from 'util/common';
import { ReactComponent as ZoomIn } from 'images/zoom-in.svg';
import { ReactComponent as ZoomOut } from 'images/zoom-out.svg';
import Loader from 'components/loader/Loader';

interface ISpecimenNotFoundProps {
  image: string;
}

const NotFound = (props: ISpecimenNotFoundProps) => {
  const { image } = props;
  const imageRef = useRef() as React.MutableRefObject<HTMLImageElement>;
  const containerRef = useRef() as React.MutableRefObject<HTMLImageElement>;
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const setupZoom = (rest: any) => {
    rest.doubleClick.disabled = true;
    rest.pan.paddingSize = 0;
    rest.options.minScale = 1;
    rest.options.maxScale = 3;
  };

  return <div className="template-not-found-container">
    <TransformWrapper
      defaultScale={1}
      zoomIn={{
        step: 15
      }}
      zoomOut={{
        step: 15
      }}
    >
      {({zoomIn, zoomOut, wheel, ...rest }: any) => {
        wheel.disabled = true;
        setupZoom(rest);
        const disabledZoomIn = (rest.scale === rest.options.maxScale) ? 'is-disabled' : '';
        const disabledZoomOut = (rest.scale === rest.options.minScale) ? 'is-disabled' : '';
        return (
          <div className='transform-component'>
            <div className="image-container" ref={containerRef}>
              {!isImageLoaded && <div className="image-loader-container"><Loader /></div>}
              <TransformComponent>
                <img
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                  src={getImageFromBase64(image)}
                  onLoad={() => setIsImageLoaded(true)}
                  ref={imageRef}
                  alt="template"
                />
              </TransformComponent>
            </div>
            <div className="tools">
              <div className={`zoom ${disabledZoomOut}`} onClick={zoomOut}><ZoomOut /></div>
              <div className={`zoom ${disabledZoomIn}`} onClick={zoomIn}><ZoomIn /></div>
            </div>
          </div>
        );
      }}
    </TransformWrapper>
  </div>;
};

export default NotFound;
