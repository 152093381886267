import React, {useMemo} from 'react';
import { useSelector } from 'react-redux';

import { getImageFromBase64 } from 'util/common';
import { IRootState } from 'store/types';
import { ICheckResult, IFilesData } from 'store/features/checkResult/types';
import { isUnknownDocument, getActiveImagePath } from 'helper/tools/commonHelper';

const getImageIndexes = (packageResult: IFilesData[]) => (
  packageResult.reduce((indexes: number[], item: IFilesData, imageIndex: number) => {
    if (!isUnknownDocument(item)) {
      indexes.push(imageIndex);
    }
    return indexes;
  }, []) || []
);

const CompareDocuments = () => {
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const { filesData = [] } = checkResult;
  const subDocsData = state.zipContent?.subDocsData || [];
  const filesSliderPath = state.common?.filesSliderPath || '';
  const { subDocIndex } = useMemo(() => getActiveImagePath(filesSliderPath), [filesSliderPath]);
  const packageResult = filesData[subDocIndex];

  return (
    <div className="files-compare-container">
      <div className="images-section">
        { getImageIndexes(packageResult).map((imageIndex: number) => {
          const image: any = Object.values(subDocsData[subDocIndex].files[imageIndex])[0];
          return (
            <div key={`image-${imageIndex}`} className="image-content">
              <img
                src={getImageFromBase64(image)}
                alt={`content-${imageIndex}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CompareDocuments;
