import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Back } from 'images/arrow-left.svg';
import { ReactComponent as Next } from 'images/arrow-right.svg';
import { ReactComponent as BackButton } from 'images/right.svg';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import { ANSWERS_KEYS, BUTTON_STYLE, BUTTON_TYPE } from 'constants/Static';
import { IRootState } from 'store/types';
import { Questions } from 'store/features/common/types';
import { ICheckResult, IDocumentData } from 'store/features/checkResult/types';
import { ISpecimen, ISpecimenState } from 'store/features/specimen/types';
import { EDirection, EPage } from 'enums';
import SingleButton from 'components/buttonTypes/SingleButton';
import ButtonTypes from 'components/buttonTypes/ButtonTypes';
import { IAnswerOption } from 'types';
import * as commonActions from 'store/features/common/actions';
import * as checkResultActions from 'store/features/checkResult/actions';
import { getPackagePosition, getUpdatedData } from 'helper/tools/fraudCheck';

const SLIDER_SIZE = 4;

const Selection = () => {
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const prevPagePath = state.common?.prevPagePath || '';
  const filesSliderPath = state.common?.filesSliderPath || '';
  const page = HeaderButtons[EPage.fraudCheck].document[Questions.select];
  const { specimens = [], isSpecimensLoaded = false } = state.specimen as ISpecimenState;
  const { documentData = [] } = checkResult;

  const {
    itemIndex,
    packageIndex,
  } = useMemo(() => (
    getPackagePosition(filesSliderPath, prevPagePath, documentData)
  ), [filesSliderPath, prevPagePath]);
  const { specimen = ({} as ISpecimen), hasSpecimen } = documentData[packageIndex][itemIndex];
  const { duid } = specimen;
  const currentIndex = specimens.findIndex((item: ISpecimen) => item.duid === specimen.duid);
  const [sliderTab, setSliderTab] = useState(Math.floor(currentIndex / SLIDER_SIZE) + 1);

  useEffect(() => setSliderTab(Math.floor(currentIndex / SLIDER_SIZE) + 1), [currentIndex]);

  const handleSpecimenSelection = (answer: IAnswerOption) => {
    const selectedItem = specimens.find((item: ISpecimen) => item.duid === answer.key);
    let updateDocumentData = getUpdatedData(documentData, packageIndex, itemIndex, {
      specimen: selectedItem
    });
    const secondSideIndex = documentData[packageIndex].findIndex((item: IDocumentData, index) => (
      index > itemIndex && item.question === Questions.imageSource
    ));
    if (secondSideIndex !== -1) {
      updateDocumentData = getUpdatedData(updateDocumentData, packageIndex, secondSideIndex, {
        specimen: selectedItem
      });
    }
    dispatch(checkResultActions.setDocumentData(updateDocumentData));
  };

  const handleClick = (answer: IAnswerOption) => {
    if (answer.key === ANSWERS_KEYS.NOT_FOUND) {
      dispatch(commonActions.setQuestion(Questions.notFound));
    }
    if (answer.key === ANSWERS_KEYS.SELECT) {
      dispatch(commonActions.setQuestion(Questions.imageSource));
    }
  }

  const back = () => {
    dispatch(commonActions.setQuestion(Questions.imageSource));
  };

  const specimenAnswers = specimens.map((item: ISpecimen, index: number) => ({
    text: item.duid?.split('-')[2] || '',
    key: item?.duid || '',
    buttonType: specimens.length === 1 ? BUTTON_TYPE.OPTION_BUTTON : BUTTON_TYPE.RADIO_BUTTON,
    radius: specimens.length === 1 ? BUTTON_STYLE.CIRCLE_BUTTON :
      index === 0 ? BUTTON_STYLE.CIRCLE_BUTTON_START : index === specimens.length - 1
        ? BUTTON_STYLE.CIRCLE_BUTTON_END : BUTTON_STYLE.CIRCLE_BUTTON,
  }));

  const tabSize = Math.ceil(specimenAnswers.length / SLIDER_SIZE);

  const startIndex = sliderTab === tabSize ?
    specimenAnswers.length - SLIDER_SIZE : (sliderTab - 1) * SLIDER_SIZE;

  const filteredAnswers = specimenAnswers.length <= SLIDER_SIZE ?
    specimenAnswers : specimenAnswers.splice(startIndex, SLIDER_SIZE);

  if (!isSpecimensLoaded) return <></>;

  const selectionAnswers = hasSpecimen ? page?.answers :
    page?.answers.filter((answer: IAnswerOption) => answer.key === ANSWERS_KEYS.NOT_FOUND) || [];

  return (
    <div className="buttons-component">
      <div className="back-button">
        <SingleButton
          textButton={'reg.files.back'}
          onClick={back}
          variant={BUTTON_STYLE.OUTLINE_PRIMARY}
          radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
          LeftIcon={BackButton}
          isDisabled={false}
        />
      </div>
      <div className="main-buttons">
        {specimens.length > SLIDER_SIZE && (
          <div
            className={`arrow-container ${sliderTab === 1 ? 'disabled' : ''}`}
            role='button'
            onClick={() => sliderTab > 1 && setSliderTab(sliderTab + EDirection.left)}
          >
            <Back />
          </div>
        )}
        {hasSpecimen && (
          <ButtonTypes
            answers={filteredAnswers}
            styled={BUTTON_STYLE.OUTLINE_SECONDARY}
            activeIndexes={[filteredAnswers.findIndex((item: IAnswerOption) => item.key === duid)]}
            handleClick={handleSpecimenSelection}
          />
        )}
        {specimens.length > SLIDER_SIZE && (
          <div
            className={`arrow-container ${sliderTab === tabSize ? 'disabled' : ''}`}
            role='button'
            onClick={() => (sliderTab < tabSize) && setSliderTab(sliderTab + EDirection.right)}
          >
            <Next />
          </div>
        )}
        <ButtonTypes
          answers={selectionAnswers}
          styled={BUTTON_STYLE.OUTLINE_SECONDARY}
          activeIndexes={[(selectionAnswers || [])?.length - 1]}
          handleClick={handleClick}
        />
      </div>
    </div>
  );
};

export default Selection;
