import React from 'react';
import { useTranslation } from 'react-i18next';

import ListFromResources from 'components/accordionMenu/ListFromResources';

const CustomerDataCheckContent = () => {
  const { t } = useTranslation();
  const leftSideContent = [
    [
      {
        text: t('user-data-check.left-menu.task-help.front-side'),
        type: 'bold'
      },
      t('user-data-check.left-menu.task-help.message1')
    ],
    [
      t('user-data-check.left-menu.task-help.message2'),
      {
        text: t('user-data-check.left-menu.task-help.front-side'),
        type: 'bold'
      }, '.'
    ],
    [
      {
        text: t('user-data-check.left-menu.task-help.back-side'),
        type: 'bold'
      },
      t('user-data-check.left-menu.task-help.message3')
    ],
    [t('user-data-check.left-menu.task-help.message4')],
    [t('user-data-check.left-menu.task-help.message5')],
    [t('user-data-check.left-menu.task-help.message6')],
  ];

  return (
    <ListFromResources resource={leftSideContent} />
  );
};

export default CustomerDataCheckContent;