import React, { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import { HeaderButtons } from 'constants/CheckTypeButtons';
import { ANSWERS_KEYS, BUTTON_STYLE } from 'constants/Static';
import Answers from 'components/footer/Answers';
import { getNextPage, getQuestion } from 'helper/navigation';
import * as commonActions from 'store/features/common/actions';
import * as checkResultActions from 'store/features/checkResult/actions';
import { IRootState } from 'store/types';
import { Questions, TQuestion } from 'store/features/common/types';
import { IAnswerOption } from 'types';
import { EPage } from 'enums';
import { ICheckResult } from 'store/features/checkResult/types';


const VideoQualityOptions = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult: any = state.checkResult;
  const { livenessData } = checkResult as ICheckResult;
  const question: TQuestion = state.common?.question || Questions.unsure;
  const page = HeaderButtons[EPage.fraudCheck].liveness[question];
  const [selectedOptions, setSelectedOptions] = useState(livenessData.reasons || []);

  const activeIndexes = useMemo(() => (
    page?.answers.reduce((acc: number[], answer: IAnswerOption, index: number) => {
      if (selectedOptions.length && index === page.answers.length - 1) {
        acc.push(page.answers.length - 1);
      }
      if (selectedOptions.find((item: string) => item === answer.text)) {
        acc.push(index);
      }
      return acc;
    }, [])
  ), [selectedOptions]);

  const submitAnswer = (answer: any) => {
    if (answer.key === ANSWERS_KEYS.CONFIRM) {
      dispatch(checkResultActions.setLivenessData({
        reason: page?.reason,
        key: question,
        quality: livenessData.quality,
        reasons: selectedOptions,
      }));
      const next = getNextPage(state, location.pathname);
      const nextQuestion = getQuestion(next);
      batchActions([
        dispatch(commonActions.setQuestion(nextQuestion)),
        dispatch(commonActions.setPrevPagePath('')),
        dispatch(commonActions.setActiveSlidePath('')),
      ]);
      history.replace(next);
    } else {
      const isExist = selectedOptions.find((item: string) => item === answer.text);
      if (isExist) {
        setSelectedOptions(selectedOptions.filter((item: string) => item !== answer.text));
      } else {
        setSelectedOptions((oldArray: string[]) => [...oldArray, ...[answer.text]]);
      }
    }
  };

  const back = () => {
    dispatch(commonActions.setQuestion(Questions.quality));
  };

  return (
    <Answers
      back={back}
      answers={page?.answers}
      activeIndexes={activeIndexes}
      submitAnswer={submitAnswer}
      disabled={!selectedOptions.length ? [(page?.answers || []).length - 1] : []}
      styled={BUTTON_STYLE.OUTLINE_SECONDARY}
    />
  );
};
export default VideoQualityOptions;
