import produce from 'immer';

import getInitialState from 'store/features/customerDataCheck/initialState';
import { setStorageDataItemByKey } from 'util/storageHelper';
import {
  ICustomerDataCheck,
  CustomerDataCheckActions,
  TCustomerDataCheckActions,
} from 'store/features/customerDataCheck/types';
import { ANSWERS_KEYS } from 'constants/Static';

export const customerDataCheckReducer = (
  state: ICustomerDataCheck = getInitialState(),
  action: TCustomerDataCheckActions,
) => {
  const nextState = produce(state, (draftState: ICustomerDataCheck) => {
    switch (action.type) {
    case CustomerDataCheckActions.setCDCResult: {
      const { list, key } = action.value;
      let currentValue: any = draftState[key] || {};
      list.forEach((item: any) => {
        if (item.result && item.result === ANSWERS_KEYS.APPROVED) {
          delete currentValue.reasons;
        }
        currentValue = { ...currentValue, ...item };
      });
      draftState[key] = currentValue;
      setStorageDataItemByKey('customerDataCheck', draftState);
      break;
    }
    case CustomerDataCheckActions.setExtractedResult: {
      const {
        subDocIndex, data, result, reasons,
      } = action.value;
      const currentValue: any = draftState.selfDeclaration || {
        extractedData: {},
      };
      currentValue.extractedData = {
        ...currentValue.extractedData,
        [subDocIndex]: {
          data: data || currentValue.extractedData[subDocIndex].data,
          result,
          ...(result === ANSWERS_KEYS.REJECTED && {
            reasons:
                reasons || currentValue.extractedData?.[subDocIndex]?.reasons,
          }),
        },
      };
      draftState.selfDeclaration = currentValue;
      setStorageDataItemByKey('customerDataCheck', draftState);
      break;
    }
    case CustomerDataCheckActions.setSelectedImage: {
      const { imageName } = action.value;
      const currentValue: any = draftState.address || {
        image: {},
      };
      currentValue.image = {};
      currentValue.image.imageName = imageName;
      draftState.address = currentValue;
      setStorageDataItemByKey('customerDataCheck', draftState);
      break;
    }
    case CustomerDataCheckActions.setAdditionalImage: {
      const { imageName } = action.value;
      const currentValue: any = draftState.documentScan || {
        image: {},
      };
      currentValue.image = {};
      currentValue.image.imageName = imageName;
      draftState.documentScan = currentValue;
      setStorageDataItemByKey('customerDataCheck', draftState);
      break;
    }
    }
    return draftState;
  });
  return nextState;
};
