import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Dataset from 'pages/dataset/Dataset';
import Loader from 'components/loader/Loader';
import { ReactComponent as VerticalDots } from 'images/verticalDotsIcon.svg';
import { PATH, BUTTON_STYLE } from 'constants/Static';
import * as rootActions from 'store/actions';
import { IRootState } from 'store/types';
import { ISingleButtonProps } from 'types';
import {
  SetDefaultDataset,
  UpdateTransactionState,
} from 'helper/api/route';
import { getStorageData } from 'util/storageHelper';
import SingleButton from 'components/buttonTypes/SingleButton';
import * as dataSetActions from 'store/features/dataSets/actions';
import { IDataSet } from 'store/features/dataSets/types';
import { getDefaultDataset } from 'helper/tools/commonHelper';
import { ETransactionState } from 'enums';

const { DASHBOARD } = PATH;

const DatasetContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const name = state.userInfo?.name || '';
  const [loader, setLoader] = useState(false);
  const datasets = state.dataSets || [];
  const defaultTransactionCode = state.common?.defaultTransactionCode || '';
  const defaultDataset: IDataSet = getDefaultDataset(datasets);
  const [agentConfigId, setAgentConfigId] = useState(defaultDataset.agentConfigId || 1);
  const isActive = Boolean(defaultDataset.agentConfigId);

  useEffect(() => {
    const storageData: string | null = getStorageData('state');
    const stateData = storageData ? JSON.parse(storageData) : {};
    const { resetCode, trCode } = stateData;

    if (resetCode && trCode) {
      UpdateTransactionState({ code: trCode, state: ETransactionState.needReview });
    }
    if (storageData) {
      dispatch(rootActions.resetSession());
    }
  }, []);

  const BigButtonProps: ISingleButtonProps = {
    textButton: 'reg.dataset.use-the-default',
    onClick: () => {
      if (isActive && !loader) {
        setLoader(true);
        SetDefaultDataset(agentConfigId).then((response) => {
          const { data } = response;
          const { configData = [] } = data;
          dispatch(dataSetActions.setDataSet(configData));
          const searchParams = defaultTransactionCode ? `?tid=${defaultTransactionCode}` :'';
          history.replace(`${DASHBOARD}${searchParams}`);
        });
      }
    },
    sizeButton: BUTTON_STYLE.BIG,
    radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
    variant: BUTTON_STYLE.OUTLINE_SECONDARY,
    isActive: isActive && !loader,
    isDisabled: loader || !datasets?.length,
  };

  return (
    <div className="datasets-container">
      <div className="datasets-content">
        <div className="datasets-text-container top">
          <p className="title">{`${t('reg.dataset.title.hi')} ${name}`}</p>
          <p className="default-transaction">{t('reg.dataset.default-transaction')}</p>
          <p className="offer-text">{t('reg.dataset.offer-text')}</p>
        </div>
        <Dataset agentConfigId={agentConfigId} setAgentConfigId={setAgentConfigId}/>
        <div className="datasets-text-container bottom">
          <p>
            <span>{t('reg.dataset.change-the-default')}</span>
            <VerticalDots />
            <span>{t('reg.dataset.settings-application')}</span>
          </p>
        </div>
        {!datasets?.length &&
        <h4 className='missing-dataset-error'>
          {t('reg.dataset.switchDataset.error')}
        </h4>}
      </div>
      <div className="button-container">
        <SingleButton {...BigButtonProps} />
        <div className="loader-container">
          {loader && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default DatasetContainer;
