import produce from 'immer';

import { setStorageDataItemByKey } from 'util/storageHelper';
import {
  ICheckResult,
  TCheckResultActions,
  CheckResultActions,
} from 'store/features/checkResult/types';
import getInitialState from 'store/features/checkResult/initialState';

export const checkResultReducer = (
  state: ICheckResult = getInitialState(),
  action: TCheckResultActions,
) => {
  const nextState = produce(state, (draftState: ICheckResult) => {
    switch (action.type) {
    case CheckResultActions.setExpiryDateData: {
      draftState.expiryDateData = action.value;
      setStorageDataItemByKey('checkResult', draftState);
      break;
    }
    case CheckResultActions.setLivenessData: {
      draftState.livenessData = action.value;
      setStorageDataItemByKey('checkResult', draftState);
      break;
    }
    case CheckResultActions.setDocumentData: {
      draftState.documentData = action.value;
      setStorageDataItemByKey('checkResult', draftState);
      break;
    }
    case CheckResultActions.setFilesData: {
      draftState.filesData = action.value;
      setStorageDataItemByKey('checkResult', draftState);
      break;
    }
    case CheckResultActions.setComparingData: {
      draftState.comparingData = action.value;
      setStorageDataItemByKey('checkResult', draftState);
      break;
    }
    case CheckResultActions.setFaceComparingData: {
      draftState.faceComparingData = action.value;
      setStorageDataItemByKey('checkResult', draftState);
      break;
    }
    case CheckResultActions.setDataValidation: {
      draftState.dataValidation = action.value;
      setStorageDataItemByKey('checkResult', draftState);
      break;
    }
    }

    return draftState;
  });

  return nextState;
};
