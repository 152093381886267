import React, { useMemo, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Comparison from 'components/comparison/Comparison';
import { IRootState } from 'store/types';
import { ICheckResult } from 'store/features/checkResult/types';
import { EImageSide } from 'enums';
import { ISpecimen } from 'store/features/specimen/types';
import { getNotFoundImage, getPackagePosition, getUpdatedData } from 'helper/tools/fraudCheck';

import { oneSideDocument } from 'helper/xmlParser';
import * as checkResultActions from 'store/features/checkResult/actions';
import { useTranslation } from 'react-i18next';

const FraudPages = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const subDocsData = state.zipContent?.subDocsData || [];
  const checkResult = state.checkResult as ICheckResult;
  const { documentData = [], filesData = [] } = checkResult;
  const filesSliderPath = state.common?.filesSliderPath || '';
  const prevPagePath = state.common?.prevPagePath || '';

  const {
    itemIndex,
    packageIndex,
  } = useMemo(() => (
    getPackagePosition(filesSliderPath, prevPagePath, documentData)
  ), [filesSliderPath, prevPagePath]);
  const {
    imageSide,
    availableImageIndexes = [],
    specimen,
    imageIndex,
    subDocIndex,
    hasSpecimen,
  } = documentData[packageIndex][itemIndex];
  const { documentType } = filesData[subDocIndex][imageIndex];
  const { back = '', front = '', externalSource = false } = specimen as ISpecimen;
  const specimenImage = oneSideDocument(documentType)
    ? (front || back) : imageSide === EImageSide.back ? back : front;

  const setSelectedImage =(imageName: string) => {
    const imageIndex = subDocsData[subDocIndex].files.findIndex((file) => (
      Object.keys(file)[0] === imageName
    ))
    const updateDocumentData = getUpdatedData(documentData, packageIndex, itemIndex, {
      imageIndex,
    });
    dispatch(checkResultActions.setDocumentData(updateDocumentData));
  }

  return (
    <div className="document-container">
      <Comparison
        specimenImage={hasSpecimen && specimenImage
          ? specimenImage : getNotFoundImage(i18n.language)
        }
        imageData={subDocsData[subDocIndex].files[imageIndex]}
        setSelectedImage={setSelectedImage}
        images={subDocsData[subDocIndex].files.filter((_, index) => (
          availableImageIndexes.includes(index))
        )}
      />
      {externalSource && <div className='copyright-content'>
        <span className='copyright-text'>
          {t('reg.document.specimen.copyright.span1')}
        </span>
        <button
          className='copyright-external-link'
          onClick={(e: SyntheticEvent) => {
            e.preventDefault();
            window.open(specimenImage, '_blank');
          }}
        >
          {t('reg.document.specimen.copyright.link')}
        </button>
        <span className='copyright-text'>
          {t('reg.document.specimen.copyright.span2')}
        </span>
      </div>}
    </div>
  );
};

export default FraudPages;
