import React, { useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import { HeaderButtons } from 'constants/CheckTypeButtons';
import { IAnswerOption } from 'types';
import { ANSWERS_KEYS, BUTTON_STYLE, PATH } from 'constants/Static';
import Answers from 'components/footer/Answers';
import { IRootState } from 'store/types';
import { Questions, TQuestion } from 'store/features/common/types';
import { EDirection, EPage } from 'enums';
import { ICheckResult } from 'store/features/checkResult/types';
import { updatedFilesData, getNext, detectForcedRejection } from 'helper/tools/quickCheck';
import * as checkResultActions from 'store/features/checkResult/actions';
import * as commonActions from 'store/features/common/actions';
import { getNextPage, getQuestion } from 'helper/navigation';
import { getActiveImagePath } from 'helper/tools/commonHelper';

const QualityOptions = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const state = useSelector((rootState: IRootState) => rootState);
  const question: TQuestion = state.common?.question || Questions.unsure;
  const page = HeaderButtons[EPage.quickCheck].files[question];
  const checkResult = state.checkResult as ICheckResult;
  const { filesData = [] } = checkResult;
  const filesSliderPath = state.common?.filesSliderPath || '';
  const {
    subDocIndex,
    imageIndex,
  } = useMemo(() => getActiveImagePath(filesSliderPath), [filesSliderPath]);
  const { reviewResult } = filesData[subDocIndex][imageIndex];
  const [selectedOptions, setSelectedOptions] = useState(reviewResult.reasons || []);

  const activeIndexes = useMemo(() => (
    page?.answers.reduce((acc: number[], answer: IAnswerOption, index: number) => {
      if (selectedOptions.length && index === page.answers.length - 1) {
        acc.push(page.answers.length - 1);
      }
      if (selectedOptions.find((item: string) => item === answer.text)) {
        acc.push(index);
      }
      return acc;
    }, [])
  ), [selectedOptions]);

  const forceReject = () => {
    batchActions([
      dispatch(commonActions.setForcedRejectedInfo({
        isForcedRejected: true,
        location: location.pathname,
        question,
        filesSliderPath,
      })),
      dispatch(commonActions.setActiveSlidePath('')),
      dispatch(commonActions.setPrevPagePath('')),
    ]);
    return history.replace(PATH.SUMMARY_QUICK_CHECK);
  };

  const submitAnswer = (answer: IAnswerOption) => {
    if (answer.key === ANSWERS_KEYS.CONFIRM) {
      const updatedResult = updatedFilesData(filesData, subDocIndex, [imageIndex], {
        reviewResult: {
          reason: page?.reason,
          key: question,
          quality: reviewResult.quality,
          reasons: selectedOptions,
        }
      });
      dispatch(checkResultActions.setFilesData(updatedResult));
      if (detectForcedRejection(updatedResult)) {
        return forceReject();
      }
      const nextPath = getNext(
        filesData,
        subDocIndex,
        imageIndex,
        EDirection.right,
        Questions.quality,
      );
      if (nextPath) {
        batchActions([
          dispatch(commonActions.setActiveSlidePath(nextPath)),
          dispatch(commonActions.setQuestion(Questions.quality)),
        ]);
      } else {
        const nextPage = getNextPage(state, location.pathname);
        const nextQuestion = getQuestion(nextPage);
        dispatch(commonActions.setQuestion(nextQuestion));
        return history.replace(nextPage);
      }
    } else {
      const isExist = selectedOptions.find((item: string) => item === answer.text);
      if (isExist) {
        setSelectedOptions(selectedOptions.filter((item: string) => item !== answer.text));
      } else {
        setSelectedOptions((oldArray: string[]) => [...oldArray, ...[answer.text]]);
      }
    }
  };

  const back = () => {
    dispatch(commonActions.setQuestion(Questions.quality));
  };

  return (
    <Answers
      back={back}
      answers={page?.answers}
      activeIndexes={activeIndexes}
      submitAnswer={submitAnswer}
      disabled={!selectedOptions.length ? [(page?.answers || []).length - 1] : []}
      styled={BUTTON_STYLE.OUTLINE_SECONDARY}
    />
  );
};

export default QualityOptions;