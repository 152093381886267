import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { IRootState } from 'store/types';
import ListFromResources from 'components/accordionMenu/ListFromResources';
import { Questions, TQuestion } from 'store/features/common/types';

const FraudPagesContent = () => {
  const { t } = useTranslation();
  let leftSideContent;
  const state = useSelector((rootState: IRootState) => rootState);
  const question: TQuestion = state.common?.question || Questions.documentPage;

  switch (question) {
  case Questions.imageSource:
    leftSideContent = [
      [t('reg.fraudCheck.page.imageSource.left-menu.task-help.message1')],
      [
        t('reg.fraudCheck.page.imageSource.left-menu.task-help.message2'),
        {
          text: t('reg.fraudCheck.page.imageSource.left-menu.task-help.message3'),
          type: 'link',
          url: ''
        }
      ],
      [t('reg.fraudCheck.page.imageSource.left-menu.task-help.message4')],
    ];
    break;
  case Questions.fraud:
    leftSideContent = [
      [t('reg.fraudCheck.page.fraud.left-menu.task-help.message1')],
      [
        t('reg.fraudCheck.page.fraud.left-menu.task-help.message2'),
        {
          text: t('reg.fraudCheck.page.fraud.left-menu.task-help.message3'),
          type: 'link',
          url: ''
        }
      ],
      [t('reg.fraudCheck.page.fraud.left-menu.task-help.message4')],
    ];
    break;
  case Questions.unsure:
    leftSideContent = [
      [t('reg.fraudCheck.page.unsure.left-menu.task-help.message1')],
      [
        t('reg.fraudCheck.page.unsure.left-menu.task-help.message2'),
        {
          text: t('reg.fraudCheck.page.unsure.left-menu.task-help.message3'),
          type: 'link',
          url: ''
        }
      ],
      [t('reg.fraudCheck.page.unsure.left-menu.task-help.message4')],
    ];
    break;
  case Questions.idRecording:
  case Questions.idRecordingFraud:
  case Questions.idRecordingUnsure:
    leftSideContent = [
      [t('reg.liveness.quality.left-menu.task-help.message1')],
      [
        t('reg.liveness.quality.left-menu.task-help.message2'),
        {
          text: t('reg.liveness.quality.left-menu.task-help.message3'),
          type: 'link',
          url: ''
        }
      ],
      [t('reg.liveness.quality.left-menu.task-help.message4')],
    ];
    break;
  case Questions.comparing:
  case Questions.notFound:
  case Questions.select:
    leftSideContent = [
      [t('reg.template.left-menu.task-help.message1')],
      [
        t('reg.template.left-menu.task-help.message2'),
        {
          text: t('reg.template.left-menu.task-help.show-page'),
          type: 'link',
          url: '',
        }
      ],
      [t('reg.template.left-menu.task-help.message3')],
    ];
    break;
  default:
    break;
  }

  return (
    <ListFromResources resource={leftSideContent} />
  );
};

export default FraudPagesContent;