export enum EExtractedDataPriorityFields {
  'firstName',
  'lastName',
  'maidenName',
  'gender',
  'dateOfBirth',
  'nationality',
  'address',
  'documentCountry',
}

export enum EAddressPriorityFields {
  'firstName',
  'lastName',
  'address',
  'documentCountry',
}

export enum EDirection {
  left = -1,
  right = 1,
}

export enum ESelfDeclarationPriorityFields {
  'firstName',
  'lastName',
  'maidenName',
  'gender',
  'street',
  'houseNumber',
  'addressLine2',
  'zipCode',
  'city',
  'country',
  'nationality',
  'birthdate',
  'email',
  'countryCode',
  'phone',
}

export enum EReturnCodes {
  EError_IdNotInList = 'EError_IdNotInList',
  EError_ImageFromScreen = 'EError_ImageFromScreen',
  EError_ImageInjection = 'EError_ImageInjection',
  EError_DocumentDataMismatch = 'EError_DocumentDataMismatch',
  EError_IdNoData = 'EError_IdNoData',
  EError_IdFaceImageCaptureFailed = 'EError_IdFaceImageCaptureFailed',
  EError_IdMatchingFailed = 'EError_IdMatchingFailed',
  EError_IdBadMrzFields = 'EError_IdBadMrzFields',
  EError_IdBadMrzFieldBirthDay = 'EError_IdBadMrzFieldBirthDay',
  EError_IdBadMrzFieldCompositCheckDigit = 'EError_IdBadMrzFieldCompositCheckDigit',
  EError_IdBadMrzFieldExpiryDay = 'EError_IdBadMrzFieldExpiryDay',
  EError_IdBadMrzFieldDocumentNumber = 'EError_IdBadMrzFieldDocumentNumber',
  EError_IdBadMrzFieldGender = 'EError_IdBadMrzFieldGender',
  EError_IdBadMrzFieldCountry = 'EError_IdBadMrzFieldCountry',
  EError_IdBadMrzFieldNationality = 'EError_IdBadMrzFieldNationality',
  EError_IdIncompleteData = 'EError_IdIncompleteData',
  EError_IdPageMissing = 'EError_IdPageMissing',
  EError_ImageDocumentTooFar = 'EError_ImageDocumentTooFar',
  EError_ImageBlured = 'EError_ImageBlured',
  EError_Reflection = 'EError_Reflection',
  EError_FaceNotRecognised = 'EError_FaceNotRecognised',
  EError_FaceNotVerified = 'EError_FaceNotVerified',
  EError_FaceLivenessFailed = 'EError_FaceLivenessFailed',
  EError_ImageIsGreyscale = 'EError_ImageIsGreyscale',
  EError_LenticularDetectionFailed = 'EError_LenticularDetectionFailed',
  EError_NFC_TagWasLost = 'EError_NFC_TagWasLost',
  EError_NFC_NotConnected = 'EError_NFC_NotConnected',
  EError_NFC_MutualAuthenticationFailedUnknown = 'EError_NFC_MutualAuthenticationFailedUnknown',
  // eslint-disable-next-line max-len
  EError_NFC_MutualAuthenticationFailedNotSatisfied = 'EError_NFC_MutualAuthenticationFailedNotSatisfied',
  EError_NFC_ReadFailed = 'EError_NFC_ReadFailed',
  EError_NFC_UnexpectedException = 'EError_NFC_UnexpectedException',
  EError_NFC_Timeout = 'EError_NFC_Timeout',
  EError_NFC_NotSupported = 'EError_NFC_NotSupported',
  EError_NFC_CertificateValidationFailed = 'EError_NFC_CertificateValidationFailed',
  EError_NFC_TechnicalError = 'EError_NFC_TechnicalError',
  EError_NFC_CLONED_CHIP = 'EError_NFC_CLONED_CHIP',
  EError_NFC_TEMPERED_DATA = 'EError_NFC_TEMPERED_DATA',
  EError_NFC_SOD_VS_COM_INSONSITENCY = 'EError_NFC_SOD_VS_COM_INSONSITENCY',
  EError_NFC_INVALID_HASHES = 'EError_NFC_INVALID_HASHES',
  EError_IdMismatch = 'EError_IdMismatch',
  EError_IdNotDetected = 'EError_IdNotDetected',
  EError_MRZ_NotDetected = 'EError_MRZ_NotDetected',
}

export enum EDocumentType {
  id = 'id',
  passport = 'passport',
  paperPermit = 'paper-permit',
  plasticPermit = 'plastic-permit',
  drivingLicence = 'drivingLicence',
  healthCard = 'healthCard',
  something = 'something',
}

export enum EField {
  gender = 'gender',
  address = 'address',
  birthdate = 'birthdate',
  maidenName = 'maidenName',
  nationality = 'nationality',
  dateOfBirth = 'dateOfBirth',
  dateOfIssue = 'dateOfIssue',
  documentType = 'documentType',
  expirationDate = 'expirationDate',
  dateOfEntry= 'dateOfEntry',
  documentCountry = 'documentCountry',
  documentSubtype = 'documentSubtype',
  drivingCategory = 'drivingCategory',
  firstName = 'firstName',
  lastName = 'lastName',
  personalIdentificationNumber = 'personalIdentificationNumber',
  documentNumber = 'documentNumber',
}

export enum ECountry {
  che = 'CHE',
  can = 'CAN',
  usa = 'USA',
}

export enum EImageSide {
  front = 'front',
  back = 'back',
  signature = 'signature',
  other = 'other',
  empty = '',
}

export enum EZone {
  viz = 'viz',
  mrz = 'mrz',
  nfc = 'nfc',
  barcode = 'barcode',
}

export enum EStatus {
  success = 'success',
  danger = 'danger',
  warning = 'warning',
  modified = 'modified',
  docComparison = 'docComparison',
  docType = 'docType',
  faceFailed = 'faceFailed',
}

export enum EPage {
  quickCheck = 'quick-check',
  fraudCheck = 'fraud-check',
  dataCheck = 'data-check',
  userDataCheck = 'user-data-check'
}

export enum EReason {
  invalidDocType = 'Invalid_document_type',
  frontOfIdMissing = 'Front_of_Id_missing',
  frontOfIdBadQuality = 'Front_of_id_bad_quality',
  backOfIdMissing = 'Back_of_id_missing',
  backOfIdBadQuality = 'Back_of_id_bad_quality',
  badQuality = 'Bad_quality',
  fakeDocument = 'Fake_document',
  expiredDocument = 'Expired_document',
  faceVerificationFail = 'Face_verification_fail',
  videoBadQuality = 'Video_bad_quality',
  videoAttack = 'Video_attack',
  mismatchDocument = 'Mismatch_document',
  other = 'Other',
}

export enum EDrivingCategories {
  m = 'M',
  minusA = '-A',
  a1 = 'A1',
  a = 'A',
  b = 'B',
  b1 = 'B1',
  be = 'BE',
  d1 = 'D1',
  d1e = 'D1E',
  d = 'D',
  de = 'DE',
  c1 = 'C1',
  c1e = 'C1E',
  c = 'C',
  ce = 'CE',
  f = 'F',
  g = 'G',
  czv = 'CZV',
  btp = 'BTP',
}

export enum ETransactionState {
  completed = 'COMPLETED',
  inReview = 'IN_REVIEW',
  reviewed = 'REVIEWED',
  rejected = 'REJECTED',
  needReview = 'NEED_REVIEW',
  deleted = 'DELETED',
  notFound = 'NOT_FOUND',
  inError = 'IN_ERROR',
  checkCompleted = 'CHECK_COMPLETED',
  pxlCheckError = 'PXL_CHECK_ERROR',
}

export enum EHttpStatusCode {
  notFound = 404,
  conflict = 409,
  unauthorized = 401,
  forbidden = 403,
  internalServerError = 500,
}

export enum EVersionId {
  v2 = 'v2',
  v3 = 'v3',
}

export enum EImageName {
  firstImage = 'firstImage',
  secondImage = 'secondImage',
  fullFrameFirstPage = 'fullFrameFirstPage',
  fullFrameSecondPage = 'fullFrameSecondPage',
}

export enum ETools {
  zoomIn = 'zoomIn',
  zoomOut = 'zoomOut',
  rotate = 'rotate',
  images = 'images',
  highLite = 'highLite',
}

export enum EUnits {
  pixel = 'px',
  percentage = '%',
}