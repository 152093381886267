import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as Logo } from 'images/pxlCheck.svg';
import Input from 'components/input/Input';
import { ISingleButtonProps } from 'types';
import Loader from 'components/loader/Loader';
import SwitchLanguage from 'components/lang/SwitchLanguage';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE, PATH } from 'constants/Static';
import { SetNewPasswordAPI, ValidateTokenAPI } from 'helper/api/route';
import { ReactComponent as CorrectIcon } from 'images/correct.svg';
import { getPasswordRequirements } from 'helper/validation';
import TurnstileWidget from 'components/turnstile/TurnstileWidget';

const NewPassword = () => {
  const { t } = useTranslation();
  const ref = useRef();
  const history = useHistory();
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [agentId, setAgentId] = useState<number | null>(null);
  const [activationToken, setActivationToken] = useState('');
  const passwordRequirements = getPasswordRequirements(password);
  const [turnstileToken, setTurnstileToken] = useState('');
  const validated = passwordRequirements.every(({ valid }) => valid)
    && (confirmPassword === password) && agentId;
  const screenIsApproved = Boolean(validated && turnstileToken);

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('activationToken');
    if (!token) return history.replace('/');
    setActivationToken(token);
    ValidateTokenAPI(token).then((response) => {
      setAgentId(response.data.id);
      setLoader(false);
    }).catch(() => history.replace(PATH.SIGN_IN));
  }, []);

  useEffect(() => {
    if (password && passwordRequirements.some(({ valid }) => !valid)) {
      setErrorMessage('set-password.error-message.bad');
    } else if (password && confirmPassword !== password) {
      setErrorMessage('set-password.error-message.mismatch');
    } else {
      setErrorMessage('');
    }
  }, [password, confirmPassword]);

  const clickHandler = () => {
    if (loader || !agentId || !screenIsApproved) return;
    setLoader(true);
    SetNewPasswordAPI(agentId, password, activationToken, turnstileToken).then(() => {
      return history.replace('/');
    }).catch(() => {
      setErrorMessage('messages.invalid-request');
    });
  };

  const PasswordInput = {
    className: 'input-container',
    handler: setPassword,
    placeholder: t('set-password.input-enter-password'),
    type: 'password',
    label: t('set-password.input-enter-password'),
  };

  const ConfirmPasswordInput = {
    className: 'input-container',
    handler: setConfirmPassword,
    placeholder: t('set-password.input-repeat-password'),
    type: 'password',
    label: t('set-password.input-repeat-password'),
    ref,
  };

  const buttonPropsData: ISingleButtonProps = {
    isActive: screenIsApproved && !loader,
    isDisabled: !screenIsApproved || loader,
    onClick: clickHandler,
    textButton: 'set-password.btn',
    variant: BUTTON_STYLE.OUTLINE_SECONDARY,
    radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
  };

  if (!agentId) return <div className="loader-container"><Loader /></div>;

  return (
    <div className="main-content">
      <div className="container">
        <div className="login-container new-password-container">
          <div className="identity-container"><Logo /></div>
          <p className='set-password-heading'>{t('set-password.heading')}</p>
          <div className="form-container">
            <div className='password-requirements-content'>
              {
                passwordRequirements.map(({ message, valid }, index) => {
                  if (valid) {
                    return (
                      <div key={index} className='valid-option'>
                        <CorrectIcon className='correct-icon-section'/>
                        <span className='pswd-requirements-option'>{t(message)}</span>
                      </div>
                    )
                  }
                  return (
                    <div key={index} className='error-option'>
                      <span className='pswd-requirements-error'>{t(message)}</span>
                    </div>
                  )
                })
              }
            </div>
            <div className="new-pswd-error-container">{t(errorMessage)}</div>
            { Input(PasswordInput) }
            { Input(ConfirmPasswordInput) }
            {validated &&
              <TurnstileWidget
                setToken={setTurnstileToken}
                setErrorMessage={setErrorMessage}
                turnstileToken={turnstileToken}
              />
            }
            <div className="button-container">
              <SingleButton {...buttonPropsData} />
              {loader && <div className="loader-container"><Loader /></div>}
            </div>
          </div>
          <div>
            <hr className="horizontal-line" />
            <div className="signin-from-signin">
              <span>{t('sign-in.have-account')} </span>
              <Link to={PATH.SIGN_IN}>{t('sing-in.link')}</Link>
            </div>
          </div>
        </div>
      </div>
      <SwitchLanguage />
    </div>
  );
};

export default NewPassword;
