import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { batchActions } from 'redux-batched-actions';

import { HeaderButtons } from 'constants/CheckTypeButtons';
import { ANSWERS_KEYS, BUTTON_STYLE } from 'constants/Static';
import Answers from 'components/footer/Answers';
import * as commonActions from 'store/features/common/actions';
import * as checkResultActions from 'store/features/checkResult/actions';
import { IRootState } from 'store/types';
import { Questions, TQuestion } from 'store/features/common/types';
import { ICheckResult } from 'store/features/checkResult/types';
import * as specimenActions from 'store/features/specimen/actions';
import {
  getUpdatedData,
  navigateToNextTab,
  getPackagePosition,
  differentSpecimensSelected,
} from 'helper/tools/fraudCheck';
import { EDirection, EPage } from 'enums';
import { getNextPage, getQuestion } from 'helper/navigation';
import { IAnswerOption } from 'types';

const PagesQualityOptions = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const question: TQuestion = state.common?.question || Questions.fraud;
  const page = HeaderButtons[EPage.fraudCheck].document[question];
  const prevPagePath = state.common?.prevPagePath || '';
  const filesSliderPath = state.common?.filesSliderPath || '';
  const checkResult = state.checkResult as ICheckResult;
  const { documentData = [] } = checkResult;

  const {
    itemIndex,
    packageIndex,
  } = useMemo(() => (
    getPackagePosition(filesSliderPath, prevPagePath, documentData)
  ), [filesSliderPath, prevPagePath]);
  const { reviewResult, question: componentQuestion } = documentData[packageIndex][itemIndex];
  const [selectedOptions, setSelectedOptions] = useState(reviewResult.reasons || []);

  const activeIndexes = useMemo(() => (
    page?.answers.reduce((acc: number[], answer: IAnswerOption, index: number) => {
      if (selectedOptions.length && index === page.answers.length - 1) {
        acc.push(page.answers.length - 1);
      }
      if (selectedOptions.find((item: string) => item === answer.text)) {
        acc.push(index);
      }
      return acc;
    }, [])
  ), [selectedOptions]);

  const submitAnswer = (answerItem: IAnswerOption) => {
    if (answerItem.key === ANSWERS_KEYS.CONFIRM) {
      const updateDocumentData = getUpdatedData(documentData, packageIndex, itemIndex, {
        reviewResult: {
          reason: page?.reason,
          key: question,
          quality: reviewResult.quality,
          reasons: selectedOptions,
        }
      });
      dispatch(checkResultActions.setDocumentData(updateDocumentData));
      const nextData = navigateToNextTab(documentData, packageIndex, itemIndex, EDirection.right);
      if (!Object.keys(nextData).length) {
        if (differentSpecimensSelected(documentData[packageIndex])) {
          dispatch(commonActions.setQuestion(Questions.comparing));
        } else {
          const nextQuestion = getQuestion(location.pathname);
          batchActions([
            dispatch(commonActions.setQuestion(nextQuestion)),
            dispatch(commonActions.setActiveSlidePath('')),
          ]);
          return history.replace(getNextPage(state, location.pathname));
        }
      } else {
        batchActions([
          dispatch(specimenActions.setSpecimens([])),
          dispatch(commonActions.setQuestion(nextData.item?.question || Questions.imageSource)),
          dispatch(commonActions.setActiveSlidePath(nextData.path || '')),
        ]);
      }
    } else {
      const isExist = selectedOptions.find((item: string) => item === answerItem.text);
      if (isExist) {
        setSelectedOptions(selectedOptions.filter((item: string) => item !== answerItem.text));
      } else {
        setSelectedOptions((oldArray: string[]) => [...oldArray, ...[answerItem.text]]);
      }
    }
  };

  const back = () => {
    dispatch(commonActions.setQuestion(componentQuestion));
  };

  return (
    <Answers
      back={back}
      answers={page?.answers}
      activeIndexes={activeIndexes}
      submitAnswer={submitAnswer}
      disabled={!selectedOptions.length ? [(page?.answers || []).length - 1] : []}
      styled={BUTTON_STYLE.OUTLINE_SECONDARY}
    />
  );
};

export default PagesQualityOptions;
