
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import PagesComponent from 'pages/fraudCheck/pages/pageDetection/PagesComponent';
import Loader from 'components/loader/Loader';
import { GetSpecimens } from 'helper/api/route';
import {
  getUpdatedData,
  getDocumentData,
  getPackagePosition,
} from 'helper/tools/fraudCheck';
import { IRootState } from 'store/types';
import { Questions } from 'store/features/common/types';
import * as specimenActions from 'store/features/specimen/actions';
import * as checkResultActions from 'store/features/checkResult/actions';
import * as commonActions from 'store/features/common/actions';
import { ICheckResult } from 'store/features/checkResult/types';
import SpecimenComponent from 'pages/fraudCheck/pages/specimenDetection/SpecimenComponent';
import { ISpecimen } from 'store/features/specimen/types';
import { ANSWERS_KEYS } from 'constants/Static';

const specimenAnswers = [Questions.select, Questions.notFound, Questions.comparing];

const FraudPagesSpecimen = () => {
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const subDocsData = state.zipContent?.subDocsData || [];
  const checkResult = state.checkResult as ICheckResult;
  const { filesData = [] } = checkResult;
  const { specimens = [], isSpecimensLoaded = false } = state.specimen || {};
  const question = state.common?.question || Questions.select;
  const filesSliderPath = state.common?.filesSliderPath || '';
  const prevPagePath = state.common?.prevPagePath || '';
  const documentData = useMemo(() => {
    if (checkResult?.documentData.length) return checkResult?.documentData;
    const initialData = getDocumentData(filesData, subDocsData);
    dispatch(checkResultActions.setDocumentData(initialData));
    return initialData;
  }, [checkResult?.documentData]);

  const {
    itemIndex,
    packageIndex,
  } = useMemo(() => (
    getPackagePosition(filesSliderPath, prevPagePath, documentData)
  ), [filesSliderPath, prevPagePath]);
  const {
    question: initialQuestion,
    hasSpecimen,
    reviewResult,
    subDocIndex,
    imageIndex,
    specimen,
  } = documentData[packageIndex][itemIndex];
  const { country, documentType, extraDetail } = filesData[subDocIndex][imageIndex];

  useEffect(() => {
    if (question === Questions.imageSource && !specimens.length && hasSpecimen) {
      (async () => {
        try {
          const specimens: ISpecimen[] = ((
            await GetSpecimens(documentType, country.toLowerCase())
          ).data).filter((item: ISpecimen) => (
            item?.extraDetail === extraDetail || extraDetail === ANSWERS_KEYS.OTHER
          ));
          if (specimens.length) {
            const { documentTypeData } = subDocsData[subDocIndex];
            const { classifiedDocument } = documentTypeData;
            const { front, back } = classifiedDocument;
            const detectedSpecimen = specimens.find(
              ({ duid }) => duid.includes(front) || duid.includes(back)
            );
            const updateDocumentData = getUpdatedData(documentData, packageIndex, itemIndex, {
              specimen: specimen?.duid ? specimen : detectedSpecimen || specimens[0],
            });
            batchActions([
              dispatch(checkResultActions.setDocumentData(updateDocumentData)),
              dispatch(specimenActions.setIsSpecimensLoaded(true)),
              dispatch(specimenActions.setSpecimens(specimens)),
            ]);
          } else {
            const updateDocumentData = getUpdatedData(documentData, packageIndex, itemIndex, {
              hasSpecimen: false,
              specimen: { duid: '', front: '', back: '', extraDetail: '' },
            });
            batchActions([
              dispatch(checkResultActions.setDocumentData(updateDocumentData)),
              dispatch(specimenActions.setIsSpecimensLoaded(true)),
              dispatch(specimenActions.setSpecimens([])),
            ]);
          }
        } catch {
          const updateDocumentData = getUpdatedData(documentData, packageIndex, itemIndex, {
            hasSpecimen: false,
            specimen: { duid: '', front: '', back: '', extraDetail: '' },
          });
          batchActions([
            dispatch(checkResultActions.setDocumentData(updateDocumentData)),
            dispatch(specimenActions.setIsSpecimensLoaded(true)),
            dispatch(specimenActions.setSpecimens([])),
          ]);
        }
      })();
    }
    if (prevPagePath) {
      batchActions([
        dispatch(commonActions.setQuestion(reviewResult.key || initialQuestion)),
        dispatch(commonActions.setActiveSlidePath(`${packageIndex}/${itemIndex}`)),
        dispatch(commonActions.setPrevPagePath('')),
      ]);
    } else if (!filesSliderPath) {
      const {
        itemIndex,
        packageIndex,
      } = getPackagePosition(filesSliderPath, prevPagePath, documentData);
      dispatch(commonActions.setActiveSlidePath(`${packageIndex}/${itemIndex}`));
    }
  }, [filesSliderPath]);

  if (!isSpecimensLoaded || prevPagePath || !filesSliderPath) {
    return <div className="template-loader-container"><Loader /></div>;
  }

  if (specimenAnswers.includes(question as Questions)) {
    return <SpecimenComponent />;
  }

  return (
    <PagesComponent />
  );
};

export default FraudPagesSpecimen;