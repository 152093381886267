import { ISpecimenState } from 'store/features/specimen/types';
import { getStorageData } from 'util/storageHelper';

export default (): ISpecimenState => {
  const state: string | null = getStorageData('state');
  const storageData = state ? JSON.parse(state) : {};

  return {
    isSpecimensLoaded: storageData.isSpecimensLoaded || false,
    specimens: storageData.specimens || [],
  };
};
