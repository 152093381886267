
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import RightMenu from 'pages/fraudCheck/pages/specimenDetection/RightMenu';
import Specimen from 'pages/fraudCheck/pages/specimenDetection/Specimen';
import NotFound from 'pages/fraudCheck/pages/specimenDetection/NotFound';
import Compare from 'pages/fraudCheck/pages/specimenDetection/Compare';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import InfoBar from 'components/infoBar/InfoBar';
import Footer from 'components/footer/Footer';
import { IRootState } from 'store/types';
import { Questions, TQuestion } from 'store/features/common/types';
import { EPage } from 'enums';
import { ICheckResult } from 'store/features/checkResult/types';
import { getPackagePosition } from 'helper/tools/fraudCheck';
import BurgerMenu from 'components/burgerMenu/BurgerMenu';

const SpecimenComponent = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const question: TQuestion = state.common?.question || Questions.select;
  const page = HeaderButtons[EPage.fraudCheck].document[question];
  const filesSliderPath = state.common?.filesSliderPath || '';
  const prevPagePath = state.common?.prevPagePath || '';
  const checkResult = state.checkResult as ICheckResult;
  const subDocsData = state.zipContent?.subDocsData || [];
  const { documentData = [], filesData = [] } = checkResult;

  const {
    itemIndex,
    packageIndex,
  } = useMemo(() => (
    getPackagePosition(filesSliderPath, prevPagePath, documentData)
  ), [filesSliderPath, prevPagePath]);
  const {
    subDocIndex,
    imageIndex,
  } = documentData[packageIndex][itemIndex];
  const { country, documentType } = filesData[subDocIndex][imageIndex];
  const image = Object.values(subDocsData[subDocIndex].files[imageIndex])[0];

  return <>
    <InfoBar />
    <>
      <BurgerMenu />
      <h3>{t(page?.heading || '')}</h3>
    </>
    <div className="check-content">
      <div className='extra-space-menu-container' />
      {
        question === Questions.notFound ? <NotFound image={image} />
          : question === Questions.comparing ? <Compare/> : <Specimen />
      }
      <RightMenu fileData={filesData[subDocIndex][imageIndex]}/>
    </div>
    <Footer NestedComponent={page?.answerComponent} data={{ country, documentType }}/>
  </>;
};

export default SpecimenComponent;
