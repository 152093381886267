import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';

import ToolTipField from 'components/toolTipField/ToolTipField';
import { ReactComponent as Edit } from 'images/edit.svg';
import { getFieldTranslationKey, getFormattedValue } from 'helper/tools/commonHelper';

const FieldsTable = (props: any) => {
  const { t } = useTranslation();
  const {
    dataList,
    headerName,
    clickable,
    activeType = '',
    showAddInfo = false,
    onRowClickHandler,
    showMissingData = true,
    translateSource = 'data-check',
  } = props;

  return (
    <div className="fields-table">
      <Table responsive>
        <thead>
          <tr>
            <th className="data-check-table-heading-container">
              <p className="data-check-table-heading"> {t(headerName)} </p>
            </th>
          </tr>
        </thead>
        <tbody>
          {dataList.length ? dataList.map((item: any, index: number) => {
            const styleData = `${item.isActive ? activeType : item.inactiveStyle || ''}`;
            return (
              <tr
                key={`${item.field}-${index}`}
                className={styleData}
                onClick={() => clickable && onRowClickHandler(index, item.field, item.value)}
              >
                <td
                  key={`${item.field}-key-${index}`}
                >
                  <p className="table-data-row-text">
                    {t(getFieldTranslationKey(item.field, translateSource))}
                  </p>
                </td>
                <td
                  key={`${item.field}-value-${index}`}
                  className="pr-0"
                >
                  {item.value
                    ? (
                      <ToolTipField
                        tooltipValue={getFormattedValue(item.field, item.value, t)}
                        placement="top"
                      >
                        <p className={
                          `${item.diffColor ? item.diffColor : ''} table-data-row-text td-value`
                        }>
                          {getFormattedValue(item.field, item.value, t)}
                        </p>
                      </ToolTipField>
                    ) : showMissingData ? (
                      <p className={'missing table-data-row-text td-value'}>
                        {t('user-data-check.table.missing.value')}
                      </p>
                    ) : (<p className={'table-data-row-text td-value'}></p>)
                  }
                </td>
                {showAddInfo &&
                  <>
                    <td className="field-status pr-0">
                      <Edit className={`field-hover-${index}`} />
                    </td>
                    {!!item.notEqualList.length && <td
                      key={`equal-value-${item}`}
                      className={'field-status'}
                    >
                      <p className="table-data-row-text equal-style">
                        <span className='equal-icon'>
                          &#8800;
                        </span>{item.notEqualList.map((el: any) => `${el.toUpperCase()} `)}
                      </p>
                    </td>}
                  </>
                }
              </tr>
            );
          }) : (<p className="data-is-missing">{t('user-data-check.table.missing.data')}</p>)
          }
        </tbody>
      </Table>
    </div>
  );
};

export default FieldsTable;
