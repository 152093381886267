import React, {
  useRef,
  useState,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import ToolTip from 'components/toolTipField/ToolTipField';
import DatePickerComponent from 'components/datePicker/DatePicker';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE, DEFAULT_DRIVING_FIELDS } from 'constants/Static';
import { ISelectOption } from 'types';
import * as checkResultActions from 'store/features/checkResult/actions';
import SelectOption from 'components/select/SelectOption';
import { ReactComponent as Plus } from 'images/plus-black.svg';
import { ReactComponent as Minus } from 'images/minus.svg';
import { ReactComponent as BackButton } from 'images/right.svg';
import { IRootState } from 'store/types';
import { EDrivingCategories, EField, EZone } from 'enums';
import {
  getPackageInfo,
  updateFieldValue,
  updateDataValidation,
} from 'helper/tools/dataCheck';
import { ICheckResult } from 'store/features/checkResult/types';
import { TDrivingCategory } from 'store/features/common/types';
import DrivingIcon from 'components/drivingField/DrivingIcon';
import { drivingCategoryValidated, fieldValueValidated } from 'helper/validation';

const customStyles = {
  noOptionsMessage: ((base: any, state: any) => ({
    boxSizing: base.boxSizing,
    textAlign: base.padding.textAlign,
    padding: base.padding,
    color: `${state.theme.colors.neutral40} !important`,
    '&:hover': {
      background: `${state.theme.colors.primary25} !important`,
    },
  })),
  option: (base: any, state: any) => ({
    ...base,
    '&:hover': {
      backgroundColor: state.isFocused ? '#e0e0e0' : 'none',
      cursor: 'pointer',
    },
  }),
  container: (provided: any) => ({
    ...provided,
    width: 90,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    pointerEvents: 'none',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    pointerEvents: 'none',
  }),

  indicatorSeparator: (provided: any) => ({
    ...provided,
    pointerEvents: 'none',
    display: 'none',
    backgroundColor: 'unset',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    pointerEvents: 'none',
  }),
};

const SelectCategory = (props: {
  autoFocus: boolean;
  currentValue: string;
  handler: Function;
  index: number;
  dlValue: TDrivingCategory[];
 }) => {
  const { autoFocus, currentValue, handler, index, dlValue } = props;

  const uniqueValues = Object.values(EDrivingCategories).filter(c =>
    !dlValue.map((dl) => dl.value.reviewedValue).includes(c)
  );

  const dlCategoryProps: ISelectOption = {
    value: {
      label: currentValue,
      value: currentValue,
    },
    handler: (item: ISelectOption) => handler('value', item.value, index),
    placeholder: '',
    options: uniqueValues.map((item: string) => ({
      label: item,
      value: item,
    })),
    isSearchable: true,
    classNamePrefix: 'dl-item',
    autoFocus: autoFocus,
    className: 'valid',
    customStylesSelect: { customStyles },
  };
  return <SelectOption {...dlCategoryProps} />;
};

const DrivingField = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    clickHandlerIsCorrect,
    back,
    onClickHandlerConfirmAll,
    allValidated,
  } = props;
  const state = useSelector((rootState: IRootState) => rootState);
  const filesSliderPath = state.common?.filesSliderPath || '';
  const checkResult = state.checkResult as ICheckResult;
  const { dataValidation = [] } = checkResult;
  const { packageIndex } = useMemo(() => getPackageInfo(filesSliderPath), [filesSliderPath]);
  const { fields } = dataValidation[packageIndex];
  const { dlValue = [] } = fields[EField.drivingCategory][EZone.viz];

  const elementRef = useRef<HTMLButtonElement | null>(null);
  const elementRefDlContainer = useRef() as React.LegacyRef<HTMLDivElement>;
  const detectBorderStyle = (validated: boolean) => validated ? 'default-border' : 'error-border';
  const [focused, setFocused] = useState<string>('');

  const addNewRow = () => {
    const updateDLValue = dlValue.concat([DEFAULT_DRIVING_FIELDS]);
    const updatedFields = updateFieldValue(fields, EField.drivingCategory, EZone.viz, {
      dlValue: updateDLValue,
    });
    const updatedData = updateDataValidation(dataValidation, packageIndex, {
      fields: updatedFields,
    });
    dispatch(checkResultActions.setDataValidation(updatedData));
  };

  const removeRow = (index: number) => {
    const updateDLValue = dlValue.reduce((
      acc: TDrivingCategory[],
      element: TDrivingCategory,
      rowIndex: number,
    ) => {
      if (rowIndex !== index) {
        acc.push(element);
      }
      return acc;
    }, []);
    const updatedFields = updateFieldValue(fields, EField.drivingCategory, EZone.viz, {
      dlValue: updateDLValue,
    });
    const updatedData = updateDataValidation(dataValidation, packageIndex, {
      fields: updatedFields,
    });
    dispatch(checkResultActions.setDataValidation(updatedData));
  };

  const hasModifiedData = (updateDLValue: TDrivingCategory[]) => (
    !updateDLValue.every((item: TDrivingCategory) => (
      item.expiryDate.reviewedValue === item.expiryDate.extractedValue
      && item.issuingDate.reviewedValue === item.issuingDate.extractedValue
      && item.restrictions.reviewedValue === item.restrictions.extractedValue
      && item.value.reviewedValue === item.value.extractedValue
    ))
  );

  const saveFiledValue = (dlField: string, selectedValue: string, index: number) => {
    const updateDLValue = dlValue.reduce((
      acc: TDrivingCategory[],
      element: any,
      rowIndex: number,
    ) => {
      if (rowIndex === index) {
        acc.push({ ...element, ...{
          [dlField]: {
            reviewedValue: selectedValue,
            extractedValue: element[dlField].extractedValue,
            optional: element[dlField].optional,
          }
        } });
      } else {
        acc.push(element);
      }
      return acc;
    }, []);
    const updatedFields = updateFieldValue(fields, EField.drivingCategory, EZone.viz, {
      dlValue: updateDLValue,
      modified: hasModifiedData(updateDLValue),
    });
    const updatedData = updateDataValidation(dataValidation, packageIndex, {
      fields: updatedFields,
    });
    dispatch(checkResultActions.setDataValidation(updatedData));
  }

  const validated = drivingCategoryValidated(dlValue);

  return (
    <>
      <div className="dl-main-component" ref={elementRefDlContainer}>
        <SingleButton
          textButton={'reg.files.back'}
          onClick={back}
          variant={BUTTON_STYLE.OUTLINE_PRIMARY}
          radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
          LeftIcon={BackButton}
          className={'dl-main-component-back'}
        />
        <h5 className="dl-heading">{t('common.drivingField.additionalExtraction')}</h5>
        <div className="dl-hint-text">{t('common.drivingField.addAnotherRow')}</div>
        <div className="item-component">
          <div className="fields">
            {
              dlValue?.map((item, index: number) => (
                <div className="item-line" key={uuid()}>
                  <span className="field-number">9.</span>
                  <div className="select-container-driving-fields">
                    <SelectCategory
                      currentValue={item.value.reviewedValue}
                      autoFocus={!index && !Object.values(dlValue[index]).some(Boolean)}
                      handler={saveFiledValue}
                      index={index}
                      dlValue={dlValue}
                    />
                  </div>
                  <div className="driving-icon">
                    <DrivingIcon value={item.value.reviewedValue} />
                  </div>
                  <span className="field-number">10.</span>
                  <DatePickerComponent
                    field={EField.drivingCategory}
                    fieldValue={item.issuingDate.reviewedValue}
                    setFieldValue={(date: string) => saveFiledValue('issuingDate', date, index)}
                    size="dpSmallSize"
                    refDlContainer={elementRefDlContainer}
                    validated={fieldValueValidated(
                      'drivingCategory_issuingDate',
                      EZone.viz,
                      item.issuingDate.reviewedValue,
                      item.issuingDate.optional,
                    )}
                    key={`issuingDate_${index}`}
                    autoFocus={focused === `issuingDate_${index}`}
                    onFocus={() => setFocused(`issuingDate_${index}`)}
                    onBlur={() => setFocused('')}
                    preventOpenOnFocus
                  />
                  <span className="field-number">11.</span>
                  <DatePickerComponent
                    field={EField.drivingCategory}
                    fieldValue={item.expiryDate.reviewedValue}
                    setFieldValue={(date: string) => saveFiledValue('expiryDate', date, index)}
                    size="dpSmallSize"
                    refDlContainer={elementRefDlContainer}
                    key={`expiryDate_${index}`}
                    autoFocus={focused === `expiryDate_${index}`}
                    validated={fieldValueValidated(
                      'drivingCategory_expiryDate',
                      EZone.viz,
                      item.expiryDate.reviewedValue,
                      item.expiryDate.optional,
                    )}
                    onFocus={() => setFocused(`expiryDate_${index}`)}
                    onBlur={() => setFocused('')}
                    preventOpenOnFocus
                  />
                  <span className="field-number">12.</span>
                  <input
                    value={item.restrictions.reviewedValue}
                    onChange={(e) => saveFiledValue('restrictions', e.target.value, index)}
                    className={`restrictions-input ${detectBorderStyle(fieldValueValidated(
                      'drivingCategory_restrictions',
                      EZone.viz,
                      item.restrictions.reviewedValue,
                      item.restrictions.optional,
                    ))}`}
                    autoFocus={focused === `restrictions_input_${index}`}
                    onFocus={() => setFocused(`restrictions_input_${index}`)}
                    onBlur={() => setFocused('')}
                  />
                  <div className="minus">
                    <Minus
                      onClick={() => dlValue.length > 1 && removeRow(index)}
                      className={'disable'}
                    />
                  </div>
                  {
                    (index === dlValue.length - 1) && (
                      <>
                        <div className="plus">
                          <ToolTip
                            tooltipValue={t('common.drivingField.addAnotherRowPopup')}
                            placement="top"
                            className="dl_tooltip"
                          >
                            <Plus
                              className={!validated ? 'disable' : ''}
                              onClick={() => validated && addNewRow()}
                            />
                          </ToolTip>
                        </div>
                        <div className="button-done">
                          <SingleButton
                            {...{
                              className: 'dl_done',
                              textButton: 'common.drivingField.done',
                              isActive: validated,
                              onClick: () => clickHandlerIsCorrect(),
                              isDisabled: !validated,
                              radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
                              variant: BUTTON_STYLE.OUTLINE_SECONDARY
                            }}
                            buttonRef={elementRef}
                          />
                          <SingleButton
                            className={'dl_confirm_all'}
                            textButton={t('reg.data-check.validation.fields.answers.confirm-all')}
                            isActive={validated && allValidated}
                            onClick={onClickHandlerConfirmAll}
                            isDisabled={!(validated && allValidated)}
                            radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
                            variant={BUTTON_STYLE.OUTLINE_SECONDARY}
                            buttonRef={elementRef}
                          />
                        </div>
                      </>
                    )
                  }
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default DrivingField;