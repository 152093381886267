import {
  IFieldData,
  TFieldProps,
  ICheckResult,
  IDocumentData,
  IDataValidation,
  TFieldZoneProps,
} from 'store/features/checkResult/types';
import { ISubDocData } from 'store/features/zipContent/types';
import { IDocRequiredFields, Questions, TDrivingCategory } from 'store/features/common/types';
import { ECountry, EDirection, EDocumentType, EField, EImageSide, EZone } from 'enums';
import { TCompareFields } from 'types';
import {
  MRZ_INFO_MAPPING,
  DEFAULT_DRIVING_FIELDS,
  DUID_LABEL_MAPPING,
  COMPARE_LIST,
  REQUIRED_FIELDS,
} from 'constants/Static';
import { getXMLDLDataFromJSON, getXMLValueFromJSON, isPermit } from 'helper/xmlParser';
import { CompareFields } from 'helper/api/route';
import { isFieldOptional, valuesIsSame } from 'helper/validation';

const MRZ_INFO = 'mrzInfo';
const GENERIC = 'generic';

const collectDrivingCategoryValues = (xmlDLData: any[]) => (
  xmlDLData && xmlDLData.length ? xmlDLData?.reduce((result: any[], element: TDrivingCategory) => {
    result.push({
      value: {
        reviewedValue: element.value || '',
        extractedValue: element.value || '',
        optional: false,
      },
      issuingDate: {
        reviewedValue: element.issuingDate || '',
        extractedValue: element.issuingDate || '',
        optional: false,
      },
      expiryDate: {
        reviewedValue: element.expiryDate || '',
        extractedValue: element.expiryDate || '',
        optional: true,
      },
      restrictions: {
        reviewedValue: element.restrictions || '',
        extractedValue: element.restrictions || '',
        optional: true,
      },
    });
    return result;
  }, []) : [DEFAULT_DRIVING_FIELDS]
);

const generateRequiredLabel = (
  docRequiredFields: IDocRequiredFields,
  documentType: EDocumentType,
  country: string,
  duid: string,
) => {
  let label = `${documentType}-${country.toLowerCase()}`;
  const convertedLabel = Object.keys(DUID_LABEL_MAPPING).find((i) => duid.includes(i)) || '';
  if (DUID_LABEL_MAPPING[convertedLabel]) {
    label = DUID_LABEL_MAPPING[convertedLabel];
  }
  if (!docRequiredFields[label]) {
    label = `${documentType}-${GENERIC}`;
  }
  if (!docRequiredFields[label]) {
    return '';
  }
  return label;
}

const sortByRequiredFields = (fields: string[], documentType: EDocumentType) => {
  const requiredFieldsFields: EField[] = [...REQUIRED_FIELDS];
  if (documentType === EDocumentType.healthCard) {
    requiredFieldsFields.push(EField.personalIdentificationNumber);
  } else {
    requiredFieldsFields.push(EField.documentNumber);
  }
  const optionalFields = (fields as EField[]).filter(field => (
    !requiredFieldsFields.includes(field)
  ));
  const result = requiredFieldsFields.sort().concat(optionalFields.sort())
  const drivingCategoryIndex = result.indexOf(EField.drivingCategory);
  if (drivingCategoryIndex !== -1) {
    result.push(result.splice(drivingCategoryIndex, 1)[0]);
  }
  return result;
}

const getDataValidation = (
  checkResult: ICheckResult,
  subDocsData: ISubDocData[],
  docRequiredFields: IDocRequiredFields,
) => (
  checkResult.documentData.reduce((acc: IDataValidation[], doc: IDocumentData[]) => {
    const { filesData = [] } = checkResult;
    const frontData = doc.find((item: IDocumentData) => (
      item.imageSide === EImageSide.front
      && item.question === Questions.imageSource
    ));
    const backData = doc.find((item: IDocumentData) => (
      item.imageSide === EImageSide.back
      && item.question === Questions.imageSource
    ));
    if (!frontData && !backData) return [];
    const result: IDataValidation = ({} as IDataValidation);
    result.zones = [];
    if (frontData) {
      result.subDocIndex = frontData?.subDocIndex;
      result.frontImage = {
        index: frontData.imageIndex,
        availableImageIndexes: frontData.availableImageIndexes || [],
      }
    }
    if (backData) {
      result.subDocIndex = backData?.subDocIndex;
      result.backImage = {
        index: backData.imageIndex,
        availableImageIndexes: backData.availableImageIndexes || [],
      }
    }
    const {
      viz = {},
      mrz = {},
      nfc = {},
      barcode = {},
      nfcBackend = {}
    } = subDocsData?.[result.subDocIndex]?.dataXML?.documentScan || {};
    const extractedNfc = nfc[MRZ_INFO] || nfcBackend[MRZ_INFO] || nfc;
    const { documentTypeData } = subDocsData[result.subDocIndex];
    const duid = frontData?.specimen?.duid || backData?.specimen?.duid || '';
    const imageIndex = frontData ? frontData.imageIndex : backData
      ? backData.imageIndex : undefined;
    if (typeof imageIndex === 'undefined') return [];
    result.imageIndex = imageIndex;
    const { documentType, country } = filesData[result.subDocIndex][imageIndex];
    result.zones = [];
    const label = generateRequiredLabel(docRequiredFields, documentType, country, duid);
    if (!label) return [];
    const requiredFields = docRequiredFields[label];
    const allowedZones = Object.keys(requiredFields) as EZone[];
    if (allowedZones.includes(EZone.viz)) {
      result.zones.push(EZone.viz);
    }
    if (allowedZones.includes(EZone.nfc) && Object.keys(extractedNfc).length) {
      result.zones.push(EZone.nfc);
    } else if (allowedZones.includes(EZone.mrz)) {
      result.zones.push(EZone.mrz);
    } else if (allowedZones.includes(EZone.barcode) && Object.keys(barcode).length) {
      result.zones.push(EZone.barcode);
    }
    const allowedSides = result.zones.reduce((acc: string[], zone) => {
      Object.values(requiredFields[zone]).forEach(side => {
        if (!acc.includes(side)) acc.push(side);
      });
      return acc;
    }, []);
    if (!allowedSides.includes(EImageSide.back)) {
      delete result.backImage;
    }
    if (!allowedSides.includes(EImageSide.front)) {
      delete result.frontImage;
    }
    if (!result.backImage && !result.frontImage) return [];
    result.zones.forEach((zone: EZone, zoneIndex: number) => {
      const nonEditableZone = [EZone.barcode, EZone.nfc].includes(zone as EZone);
      let xmlData: any = {};
      if (zone === EZone.viz) {
        xmlData = viz;
      }
      if (zone === EZone.mrz) {
        xmlData = mrz;
      }
      if (zone === EZone.barcode) {
        xmlData = barcode;
      }
      if (zone === EZone.nfc) {
        xmlData = extractedNfc;
      }
      const fields = result.zones.reduce((acc: string[], zone: EZone) => {
        Object.keys(requiredFields[zone]).forEach((field: string) => {
          if (!acc.includes(field)) acc.push(field);
        })
        return acc;
      }, []);
      const drivingCategoryIndex = fields.indexOf(EField.drivingCategory);
      if (drivingCategoryIndex !== -1) {
        fields.push(fields.splice(drivingCategoryIndex, 1)[0]);
      }
      sortByRequiredFields(fields, documentType).forEach((field: string, fieldIndex: number) => {
        if (!result.fields) result.fields = {} as TFieldProps;
        if (!result.fields[field]) result.fields[field] = {} as TFieldZoneProps;
        const xmlField = !xmlData[field] && MRZ_INFO_MAPPING[field]
          ? MRZ_INFO_MAPPING[field] : field;
        let xmlValue = getXMLValueFromJSON(xmlData[xmlField]) || '';
        let reviewedValue = xmlValue;
        if (!nonEditableZone) {
          if (field === EField.documentType && documentTypeData.documentType) {
            xmlValue = documentTypeData.documentType;
            reviewedValue = documentType;
          }
          const swissPermit = country === ECountry.che && isPermit(documentType);
          if (field === EField.documentSubtype && swissPermit && xmlValue) {
            [,reviewedValue = ''] = xmlValue.split('_');
            [,xmlValue = ''] = xmlValue.split('_');
          }
        }
        if (requiredFields[zone][field]) {
          const side = requiredFields[zone][field];
          const sideNotDetected = side === EImageSide.front
            ? !frontData : side === EImageSide.back ? !backData : false;
          let dlValue;
          if (field === EField.drivingCategory) {
            dlValue = collectDrivingCategoryValues(getXMLDLDataFromJSON(xmlData[xmlField]));
          }
          const disabled = nonEditableZone || sideNotDetected;
          result.fields[field][zone] = {
            side: side,
            value: sideNotDetected ? 'Not Applicable' : reviewedValue,
            dlValue,
            extractedValue: reviewedValue,
            modified: false,
            disabled,
            optional: disabled ? true : isFieldOptional(field as EField, documentType),
            id: (fieldIndex * result.zones.length) + zoneIndex + 1,
            exists: true,
            equals: true,
          }
        } else {
          result.fields[field][zone] = {
            side: EImageSide.empty,
            value: 'Not Applicable',
            extractedValue: '',
            modified: false,
            disabled: true,
            optional: true,
            id: (fieldIndex * result.zones.length) + zoneIndex + 1,
            exists: false,
            equals: false,
          }
        }
      });
    });
    acc.push(result);
    return acc;
  }, [])
);

const getPackageInfo = (filesSliderPath: string) => {
  const [packageIndex = '', field = '', zone = ''] = filesSliderPath.split('/');
  return {
    field,
    zone: zone as EZone,
    packageIndex: Number(packageIndex),
  }
};

const updateFieldValue = (fields: TFieldProps, field: string, zone: EZone, options: any) => ({
  ...fields,
  [field]: {
    ...fields[field],
    [zone]: {
      ...fields[field][zone],
      ...options,
    }
  }
});

const updateDataValidation = (
  dataValidation: IDataValidation[],
  packageIndex: number,
  options: any,
) => (
  dataValidation.reduce(
    (acc: IDataValidation[], element: IDataValidation, elementIndex: number) => {
      if (packageIndex === elementIndex) {
        acc.push({ ...element, ...options });
      } else {
        acc.push(element);
      }
      return acc;
    }, [],
  )
);

const getNextField = (
  dataValidation: IDataValidation[],
  packageIndex: number,
  nextIndex: number,
) => {
  const { zones, fields } = dataValidation[packageIndex];
  let currentField = {
    data: {} as IFieldData,
    path: '',
  };
  Object.keys(fields).forEach((field: string) => {
    zones.forEach((zone: EZone) => {
      if (fields[field][zone].id === nextIndex) {
        currentField = {
          path: `${packageIndex}/${field}/${zone}`,
          data: fields[field][zone],
        };
      }
    });
  });
  return currentField;
}

const navigateToNextTab = (
  dataValidation: IDataValidation[],
  currentActivePath: string,
  direction: EDirection,
  prevPagePath = '',
): string => {
  if (!currentActivePath && !prevPagePath) {
    const [{ zones = [], fields = {} } = {}] = dataValidation;
    const [firstField = ''] = Object.keys(fields);
    const [firstZone] = zones;
    const { disabled = false, value = '' } = fields[firstField][firstZone];
    if (disabled || value) {
      return navigateToNextTab(dataValidation, `0/${firstField}/${firstZone}`, EDirection.right);
    }
    return `0/${firstField}/${firstZone}`;
  }
  if (prevPagePath) {
    const { zones, fields } = dataValidation[dataValidation.length - 1];
    const lastField = Object.keys(fields)[ Object.keys(fields).length - 1];
    const lastZone = zones[zones.length - 1];
    const { disabled = false } = fields[lastField][lastZone];
    if (disabled) {
      return navigateToNextTab(
        dataValidation,
        `${dataValidation.length - 1}/${lastField}/${lastZone}`,
        EDirection.left,
      );
    }
    return `${dataValidation.length - 1}/${lastField}/${lastZone}`;
  }
  const { zone, field, packageIndex } = getPackageInfo(currentActivePath);
  const { zones, fields } = dataValidation[packageIndex];
  const currentField = fields[field][zone];
  if (direction === EDirection.right) {
    const lastItem = currentField.id === Object.keys(fields).length * zones.length;
    if (packageIndex === dataValidation.length - 1 && lastItem) {
      if (currentField.disabled) {
        return navigateToNextTab(dataValidation, currentActivePath, EDirection.left);
      }
      return currentActivePath;
    }
    if (lastItem) {
      const { fields, zones } = dataValidation[packageIndex + 1];
      const firstField = Object.keys(fields)[0];
      const [firstZone] = zones;
      const currentField = fields[firstField][firstZone];
      if (currentField.disabled || currentField.value) {
        return navigateToNextTab(
          dataValidation,
          `${packageIndex + 1}/${firstField}/${firstZone}`,
          EDirection.right,
        );
      }
      return `${packageIndex + 1}/${firstField}/${firstZone}`;
    }
    const nextField = getNextField(dataValidation, packageIndex, currentField.id + direction);
    if (nextField.data.disabled || nextField.data.value) {
      return navigateToNextTab(dataValidation, nextField.path, EDirection.right);
    }
    return nextField.path;
  }
  if (direction === EDirection.left) {
    const firstItem = currentField.id === 1;
    if (!packageIndex && firstItem) {
      if (currentField.disabled) {
        return '';
      }
      return currentActivePath;
    }
    if (firstItem) {
      const { fields, zones } = dataValidation[packageIndex - 1];
      const lastField = Object.keys(fields)[Object.keys(fields).length - 1];
      const lastZone = zones[zones.length - 1];
      const currentField = fields[lastField][lastZone];
      if (!currentField.disabled) {
        return `${packageIndex + 1}/${lastField}/${lastZone}`;
      }
      return navigateToNextTab(
        dataValidation,
        `${packageIndex - 1}/${lastField}/${lastZone}`,
        EDirection.left,
      );
    }
    const nextField = getNextField(dataValidation, packageIndex, currentField.id + direction);
    if (nextField.data.disabled) {
      return navigateToNextTab(dataValidation, nextField.path, EDirection.left);
    }
    return nextField.path;
  }
  return currentActivePath;
};

const getRequestedFields = (initialFields: TFieldProps) => (
  Object.keys(initialFields).reduce((acc: TCompareFields, field) => {
    const initialItem = initialFields[field];
    if (COMPARE_LIST.includes(field as EField) && Object.keys(initialItem).length === 2) {
      const [viz, targetZone] = Object.keys(initialItem) as EZone[];
      const {
        exists: srcExists, value: srcValue, disabled: srcDisabled,
      } = initialItem[viz];
      const {
        exists: dstExists, value: dstValue, disabled: dstDisabled,
      } = initialItem[targetZone];
      if ((srcExists && !srcDisabled) && srcValue && (dstExists && !dstDisabled) && dstValue) {
        acc[field] = {
          unicodeString: srcValue,
          asciiString: dstValue,
        }
      }
    }
    return acc;
  }, {})
)

const fetchCompareResult = async (initialData: IDataValidation[]) => {
  return initialData.reduce(async (result: Promise<TFieldProps[]>, item) => {
    const { fields: initialFields } = item;
    const requestData = getRequestedFields(initialFields);
    const response = await CompareFields(requestData);
    const { data } = response;
    const fields = Object.keys(initialFields).reduce((acc: TFieldProps, field) => {
      const [src, dst] = Object.keys(initialFields[field]) as EZone[];
      if (Object.keys(initialFields[field]).length === 2) {
        const {
          exists: srcExists,
          value: srcValue,
          disabled: srcDisabled,
        } = initialFields[field][src];
        const {
          exists: dstExists,
          value: dstValue,
          disabled: dstDisabled,
        } = initialFields[field][dst];
        const equals = data[field] ? data[field].equals :
          (srcExists && !srcDisabled) && (dstExists && !dstDisabled) ?
            valuesIsSame(srcValue, dstValue, field) : true;
        if (!acc[field]) acc[field] = {} as TFieldZoneProps;
        if (!acc[field][src]) acc[field][src] = {} as IFieldData;
        if (!acc[field][dst]) acc[field][dst] = {} as IFieldData;
        acc[field][src] = { ...initialFields[field][src], equals };
        acc[field][dst] = { ...initialFields[field][dst], equals };
      } else {
        if (!acc[field]) acc[field] = {} as TFieldZoneProps;
        acc[field][src] = { ...initialFields[field][src] };
      }
      return acc;
    }, {});
    (await result).push(fields);
    return result;
  }, Promise.resolve([]));
}

export {
  getPackageInfo,
  updateFieldValue,
  navigateToNextTab,
  getDataValidation,
  updateDataValidation,
  fetchCompareResult,
};