import Files from 'pages/quickCheck/files/FilesComponent';
import Face from 'pages/quickCheck/face/FaceComponent';
import Data from 'pages/quickCheck/data/DataComponent';
import Liveness from 'pages/fraudCheck/liveness/LivenessComponent';
import Pages from 'pages/fraudCheck/pages/FraudPagesSpecimen';
import Check from 'pages/dataCheck/validation/CheckComponent';
import ExpiryDate from 'pages/quickCheck/expiryDate/ExpiryDate';
import DocumentScan from 'pages/customerDataCheck/documentScan/DocumentScanComponent';
import Address from 'pages/customerDataCheck/address/AddressComponent';
import selfDeclaration from 'pages/customerDataCheck/selfDeclaration/SDComponent';
import { PATH } from 'constants/Static';
import { Questions } from 'store/features/common/types';
import { EPage } from 'enums';

export type TRoutePaths = {
  [page in EPage]: Array<{
    name: string;
    navTitle: string;
    pathname?: string;
    component?: (props: any) => React.JSX.Element;
    question?: Questions,
  }>
}

const RoutePaths: TRoutePaths = {
  [EPage.quickCheck]: [
    {
      name: EPage.quickCheck,
      navTitle: 'reg.info-bar.quick-check',
    },
    {
      name: 'files',
      pathname: PATH.QUICK_CHECK_FILES,
      navTitle: 'reg.info-bar.quick-check.files',
      component: Files,
      question: Questions.quality,
    },
    {
      name: 'expiryDate',
      pathname: PATH.QUICK_CHECK_EXPIRY_DATE,
      navTitle: 'reg.info-bar.quick-check.expiry-date',
      component: ExpiryDate,
      question: Questions.comparing,
    },
    {
      name: 'data',
      pathname: PATH.QUICK_CHECK_DATA,
      navTitle: 'reg.info-bar.quick-check.data',
      component: Data,
      question: Questions.comparing,
    },
    {
      name: 'face',
      pathname: PATH.QUICK_CHECK_FACE,
      navTitle: 'reg.info-bar.quick-check.face',
      component: Face,
      question: Questions.comparing,
    },
  ],
  [EPage.fraudCheck]: [
    {
      name: EPage.fraudCheck,
      navTitle: 'reg.info-bar.fraud-check',
    },
    {
      name: 'liveness',
      pathname: PATH.FRAUD_CHECK_LIVENESS,
      navTitle: 'reg.info-bar.fraud-check.liveness',
      component: Liveness,
      question: Questions.quality,
    },
    {
      name: 'document',
      pathname: PATH.FRAUD_CHECK_DOCUMENT,
      navTitle: 'reg.info-bar.fraud-check.document',
      component: Pages,
      question: Questions.imageSource,
    },
  ],
  [EPage.dataCheck]: [
    {
      name: EPage.dataCheck,
      navTitle: 'reg.info-bar.data-check',
    },
    {
      name: 'document-data-validation',
      pathname: PATH.DATA_CHECK_PAGE,
      navTitle: 'reg.info-bar.data-check.document-data-validation',
      component: Check,
      question: Questions.fields,
    },
  ],
  [EPage.userDataCheck]: [
    {
      name: EPage.userDataCheck,
      navTitle: 'reg.info-bar.costumer-data-check',
    },
    {
      name: 'self-declaration',
      pathname: PATH.CDC_SELF_DECLARATION,
      navTitle: 'reg.info-bar.user-data-check.self-declaration',
      component: selfDeclaration,
      question: Questions.approve,
    },
    {
      name: 'address',
      pathname: PATH.CDC_ADDRESS,
      navTitle: 'reg.info-bar.user-data-check.address',
      component: Address,
      question: Questions.approve,
    },
    {
      name: 'document-scan',
      pathname: PATH.CDC_DOCUMENT_SCAN,
      navTitle: 'reg.info-bar.user-data-check.document-scan',
      component: DocumentScan,
      question: Questions.approve,
    },
  ],
};

export default RoutePaths;
