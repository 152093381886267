import { IGenericProps } from 'types';

export enum CustomerDataCheckActions {
  setCDCResult = 'SET_CUSTOMER_DATA_CHECK_RESULT',
  setExtractedResult = 'SET_EXTRACTED_RESULT',
  setSelectedImage = 'SET_SELECTED_IMAGE',
  setAdditionalImage = 'SET_ADDITIONAL_IMAGE'
}

export enum TScreenName {
  selfDeclaration = 'selfDeclaration',
  address = 'address',
  documentScan = 'documentScan'
}

export interface ICustomerDataCheck {
  selfDeclaration: {
    data: IGenericProps;
    extractedData: {
      [key: number]: {
        data: IGenericProps;
        result: string;
        reasons?: string[];
      };
    };
  };
  address: {
    data: IGenericProps;
    result?: string;
    reasons?: string[];
    image: {
      imageName: string;
    };
  };
  documentScan: {
    result: string;
    reasons?: string[];
    image: {
      imageName: string;
    };
  };
}

export interface IResultValue {
  key: TScreenName;
  list: any[];
}

export interface IExtractedDataValue {
  subDocIndex: number;
  data?: any;
  result: string;
  reasons?: string[];
}

export type TCustomerDataCheckActions =
  { type: CustomerDataCheckActions.setCDCResult; value: IResultValue } |
  { type: CustomerDataCheckActions.setExtractedResult; value: any } |
  { type: CustomerDataCheckActions.setSelectedImage; value: any } |
  { type: CustomerDataCheckActions.setAdditionalImage; value: any };
