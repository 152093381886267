import routePaths from 'constants/RoutePaths';
import { PATH, SUMMARY } from 'constants/Static';
import { getDataValidation } from 'helper/tools/dataCheck';
import { getExpireDateData, validateFace } from 'helper/tools/quickCheck';
import { getDocumentData, validateLiveness } from 'helper/tools/fraudCheck';
import { getFlowMode, hasDocumentForCheck } from 'helper/tools/commonHelper';
import {
  validateAddress,
  validateDocumentScan,
  validateSelfDeclaration,
} from 'helper/tools/customerDataCheck';
import { validateSummary } from 'helper/tools/summary';
import { EPage } from 'enums';
import { IRootState } from 'store/types';
import { IZipContent } from 'store/features/zipContent/types';
import { ICheckResult } from 'store/features/checkResult/types';
import { ICommon, IViews } from 'store/features/common/types';

const {
  DASHBOARD,
  QUICK_CHECK_EXPIRY_DATE,
  QUICK_CHECK_FILES,
  QUICK_CHECK_FACE,
  QUICK_CHECK_DATA,
  SUMMARY_QUICK_CHECK,
  FRAUD_CHECK_LIVENESS,
  FRAUD_CHECK_DOCUMENT,
  SUMMARY_FRAUD_CHECK,
  SUMMARY_CUSTOMER_DATA_CHECK,
  DATA_CHECK_PAGE,
  SUMMARY_DATA_CHECK,
  CDC_SELF_DECLARATION,
  CDC_ADDRESS,
  CDC_DOCUMENT_SCAN,
} = PATH;

const getSubRoutesList = () => {
  const subRoutes: Array<string> = [];
  for (const route in routePaths) {
    routePaths[route as EPage].forEach((subRoute: any, i: number) => {
      if (i) subRoutes.push(subRoute.pathname);
      if (i === routePaths[route as EPage].length - 1) {
        subRoutes.push(`/summary/${route}`);
      }
    });
  }
  return subRoutes;
};

const getQuestion = (pathname: string) => {
  const arr: Array<string> = pathname.split('/');
  const [, routePath = '', pathName = ''] = arr;
  if (routePath === SUMMARY) return '';
  return routePaths[routePath as EPage].find((page) => page.name === pathName)?.question || '';
};

const isValid = (state: IRootState, path: string, pathname = '') => {
  const {
    dataSets = [],
    common,
    zipContent,
    checkResult = {} as ICheckResult,
  } = state;
  const { filesData = [] } = checkResult;
  const { video, isMultipleDoc, subDocsData = [] } = zipContent as IZipContent;
  const { entryName = '' } = video;
  const { appConfig, docRequiredFields } = common as ICommon;
  const { views = {} as IViews } = appConfig;
  const isSimplified = getFlowMode(dataSets);
  switch (path) {
  case QUICK_CHECK_FILES:
    return hasDocumentForCheck(subDocsData);
  case QUICK_CHECK_EXPIRY_DATE:
    return getExpireDateData(subDocsData, filesData, views).length;
  case QUICK_CHECK_DATA:
    return isMultipleDoc;
  case QUICK_CHECK_FACE:
    return validateFace(subDocsData, entryName, isSimplified);
  case SUMMARY_QUICK_CHECK:
    return validateSummary(pathname);
  case FRAUD_CHECK_LIVENESS:
    return validateLiveness(entryName, subDocsData, isSimplified);
  case FRAUD_CHECK_DOCUMENT:
    return getDocumentData(filesData, subDocsData).length;
  case SUMMARY_FRAUD_CHECK:
    return validateSummary(pathname);
  case DATA_CHECK_PAGE:
    return getDataValidation(checkResult, subDocsData, docRequiredFields).length;
  case SUMMARY_DATA_CHECK:
    return validateSummary(pathname);
  case CDC_SELF_DECLARATION:
    return validateSelfDeclaration(state);
  case CDC_ADDRESS:
    return validateAddress(state);
  case CDC_DOCUMENT_SCAN:
    return validateDocumentScan(state);
  case SUMMARY_CUSTOMER_DATA_CHECK:
    return validateSummary(pathname);
  default:
    return false;
  }
};

const routeNextList = (path: string) => {
  const subRoutes = getSubRoutesList();
  const index = subRoutes.indexOf(path);
  return subRoutes.slice(index + 1, subRoutes.length);
};

const routePreviousList = (path: string) => {
  const subRoutes = getSubRoutesList();
  const index = subRoutes.indexOf(path);
  return subRoutes.slice(0, index).reverse();
};

const getPreviousPage = (state: IRootState, pathname: string, index = 0): string => {
  const pathList = routePreviousList(pathname);
  if (pathList[index] && isValid(state, pathList[index], pathname)) {
    return pathList[index];
  }
  if (
    !pathList[index]
    || pathList.indexOf(pathList[index]) === pathList.length
  ) {
    return DASHBOARD;
  }
  return getPreviousPage(state, pathname, ++index);
};

const getNextPage = (state: IRootState, pathname: string, index = 0): string => {
  const pathList = routeNextList(pathname);
  if (pathList[index] && isValid(state, pathList[index], pathname)) {
    return pathList[index];
  }
  if (
    !pathList[index]
    || pathList.indexOf(pathList[index]) === pathList.length
  ) {
    return DASHBOARD;
  }
  return getNextPage(state, pathname, ++index);
};

export {
  getNextPage,
  getQuestion,
  getPreviousPage,
}