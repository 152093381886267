import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import ToolTipField from 'components/toolTipField/ToolTipField';
import { IRootState } from 'store/types';
import { ICheckResult } from 'store/features/checkResult/types';
import { EField, EZone } from 'enums';
import DrivingIcon from 'components/drivingField/DrivingIcon';
import { valuesIsSame } from 'helper/validation';
import { DEFAULT_EXPIRE_VALUE } from 'constants/Static';

const DLTable = (props: { packageIndex: number }) => {
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const { dataValidation = [] } = checkResult;
  const { fields } = dataValidation[props.packageIndex];
  const { dlValue = [] } = fields[EField.drivingCategory][EZone.viz];

  const updateDateFormat = (value: string) => (
    value ? moment(value, 'YYYY-MM-DD').format('DD.MM.YYYY') : ''
  );

  if (!dlValue.length) return null;

  return (
    <>
      <table className="result-drivingLicence" >
        <thead className="dl-header">
          <tr className="tab-header">
            <th className="dl-numbers-index"></th>
            <th className="dl-numbers-9">9.</th>
            <th className="dl-numbers-10">10.</th>
            <th className="dl-numbers-11">11.</th>
            <th className="dl-numbers-12">12.</th>
          </tr>
        </thead>
        <tbody className="dl-tbody">
          {dlValue?.map((element, index) => {
            return <tr key={`row-${index}`} className="row-style-dl-table" >
              <td>{`${index+1}.`}</td>
              <td className="item-element-col-9">
                <div>
                  <span
                    className={`text-style
                    ${valuesIsSame(element.value.extractedValue, element.value.reviewedValue)
                      ? 'isEqual' : 'hasDiff'}`
                    }
                  >
                    {element.value.reviewedValue}
                  </span>
                </div>
                <div className="dl-category-icon">
                  <DrivingIcon value={element.value.reviewedValue} />
                </div>
              </td>

              <td className="item-element-col-10">
                <ToolTipField
                  tooltipValue={updateDateFormat(element.issuingDate.reviewedValue)}
                  placement="left"
                >
                  <span
                    className={
                      `text-style
                      ${valuesIsSame(
                        element.issuingDate.extractedValue,
                        element.issuingDate.reviewedValue,
                      ) ? 'isEqual' : 'hasDiff tabele-col-item-cursor-pointer'}`
                    }
                  >
                    {updateDateFormat(element.issuingDate.reviewedValue)}
                  </span>
                </ToolTipField>
              </td>

              <td className="item-element-col-11">
                {element.expiryDate.reviewedValue ?
                  <ToolTipField
                    tooltipValue={updateDateFormat(element.expiryDate.reviewedValue)}
                    placement="left"
                  >
                    <span
                      className={`text-style
                      ${valuesIsSame(
                        element.expiryDate.extractedValue,
                        element.expiryDate.reviewedValue,
                      ) ? 'isEqual' : 'hasDiff'} tabele-col-item-cursor-pointer`
                      }
                    >
                      {updateDateFormat(element.expiryDate.reviewedValue)}
                    </span>
                  </ToolTipField>
                  :
                  <span className="text-style defaultTextColor">
                    <p className="dl-empty-table-data">{DEFAULT_EXPIRE_VALUE}</p>
                  </span>
                }
              </td>

              <td className="item-element-col-12">
                <ToolTipField
                  tooltipValue={element.restrictions.reviewedValue}
                  placement="left"
                >
                  <span
                    className={`text-style tabele-col-item-cursor-pointer
                      ${
                        valuesIsSame(
                          element.restrictions.extractedValue,
                          element.restrictions.reviewedValue,
                        ) ? 'isEqual' : 'hasDiff'
                      }`
                    }
                  >
                    {element.restrictions.reviewedValue}
                  </span>
                </ToolTipField>
              </td>
            </tr>;
          })}
        </tbody>
      </table>
    </>
  );
};

export default DLTable;
