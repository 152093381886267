import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SelectOption from 'components/select/SelectOption';
import { ISelectOption, IOptionType, IFieldsData } from 'types';
import Countries from 'constants/Countries.json';
import Nationalities from 'constants/Nationalities.json';
import DocumentTypes from 'constants/DocumentTypes.json';
import GenderOptions from 'constants/GenderOptions.json';
import DatePickerComponent from 'components/datePicker/DatePicker';
import { BUTTON_STYLE, KEY_CODE } from 'constants/Static';
import SingleButton from 'components/buttonTypes/SingleButton';
import FieldsData from 'constants/FieldsData.json';
import { EField } from 'enums';
import { GetSpecimens } from 'helper/api/route';
import { ISpecimen } from 'store/features/specimen/types';
import { collectExtraDetailOptions } from 'helper/tools/quickCheck';

interface IFieldItemProps {
  field: string
  fieldValue: string;
  setFieldValue: Function;
  validated: boolean;
  documentType: string;
  country: string;
}

const FieldItem = (props: IFieldItemProps) => {
  const { t } = useTranslation();
  const {
    field,
    fieldValue,
    setFieldValue,
    validated,
    documentType,
    country
  } = props;
  const [specimens, setSpecimens] = useState([]);

  useEffect(() => {
    try {
      if (field === EField.documentSubtype) {
        (async () => {
          const { data } = await GetSpecimens(documentType, country.toLowerCase());
          setSpecimens(data.filter((item: ISpecimen) => item.extraDetail));
        })();
      } else {
        setSpecimens([]);
      }
    } catch {
      setSpecimens([]);
    }
  }, [field]);

  const getOptionValue = (data: IOptionType[]) => {
    const elementData = data.find((option) => option.value === fieldValue);
    if (elementData?.label && elementData?.value) {
      return {
        label: `${t(elementData.label)} (${elementData.value})`,
        value: elementData.value,
      };
    }
    if (fieldValue.length) {
      return {
        label: fieldValue,
        value: fieldValue,
      };
    }
    return null;
  };

  const getDocumentSubtypeValue = (specimens: ISpecimen[], value: string) => {
    const extraDetails = collectExtraDetailOptions(specimens, t);
    const extraDetailData = extraDetails.find((option) => option.value === value);
    if (extraDetailData?.value) {
      return {
        label: extraDetailData.label,
        value: extraDetailData.value,
      };
    }
    return null;
  };

  const getDocumentTypeValue = () => {
    const docType = DocumentTypes.find((option) => option.value === fieldValue);
    if (docType?.label && docType?.value) {
      return {
        label: t(docType.label),
        value: docType.value,
      };
    }
    if (fieldValue && fieldValue.length) {
      return {
        label: fieldValue,
        value: fieldValue,
      };
    }
    return null;
  };

  const keyPress: Function = (e: KeyboardEvent) => {
    if (fieldValue && e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
    }
  };

  const borderStyle = validated ? 'default-border' : 'error-border';

  const getOption = (optionKey: string) => {
    const fieldData = FieldsData.find((fd: IFieldsData) => fd.name === optionKey);
    const { title = '' } = fieldData as IFieldsData;
    switch (optionKey) {
    case EField.documentType: {
      const documentTypeProps: ISelectOption = {
        value: getDocumentTypeValue(),
        handler: (selectedOption: IOptionType) => setFieldValue(selectedOption.value),
        placeholder: t(title),
        options: DocumentTypes.map((option) => ({
          label: t(option.label),
          value: option.value,
        })),
        isSearchable: true,
        classNamePrefix: 'document-item',
        autoFocus: true,
        className: 'valid',
      };
      return <SelectOption {...documentTypeProps} />;
    }
    case EField.documentSubtype: {
      const docSubTypeProps: ISelectOption = {
        value: getDocumentSubtypeValue(specimens, fieldValue),
        handler: (selectedOption: IOptionType) => setFieldValue(selectedOption.value),
        placeholder: t(title),
        options: collectExtraDetailOptions(specimens, t),
        isSearchable: true,
        autoFocus: true,
        className: validated ? 'valid' : 'invalid',
      };
      return <SelectOption {...docSubTypeProps} />;
    }
    case EField.nationality: {
      const nationalities = Countries.concat(Nationalities);
      const documentCountryProps: ISelectOption = {
        value: getOptionValue(nationalities),
        handler: (selectedOption: IOptionType) => setFieldValue(selectedOption.value),
        placeholder: t(title),
        options: nationalities.map((option) => ({
          label: `${t(option.label)} (${option.value.toUpperCase()})`,
          value: option.value,
        })),
        isSearchable: true,
        classNamePrefix: 'document-item',
        autoFocus: true,
        className: validated ? 'valid' : 'invalid',
      };
      return <SelectOption {...documentCountryProps} />;
    }
    case EField.documentCountry: {
      const documentCountryProps: ISelectOption = {
        value: getOptionValue(Countries),
        handler: (selectedOption: IOptionType) => setFieldValue(selectedOption.value),
        placeholder: t(title),
        options: Countries.map((option) => ({
          label: `${t(option.label)} (${option.value.toUpperCase()})`,
          value: option.value,
        })),
        isSearchable: true,
        classNamePrefix: 'document-item',
        autoFocus: true,
        className: validated ? 'valid' : 'invalid',
      };
      return <SelectOption {...documentCountryProps} />;
    }
    case EField.dateOfBirth:
    case EField.expirationDate:
    case EField.dateOfEntry:
    case EField.dateOfIssue: {
      return (
        <DatePickerComponent
          field={field}
          fieldValue={fieldValue}
          validated={validated}
          setFieldValue={setFieldValue}
          key={`${optionKey}_datepicker`}
        />
      );
    }
    case EField.gender: {
      return (
        <>
          {GenderOptions.map((item) => {
            return (
              <SingleButton
                onClick={() => setFieldValue(item.value)}
                key={`gender_${item.label}`}
                textButton={item.label}
                isActive={item.value === fieldValue}
                variant={BUTTON_STYLE.SECONDARY}
                radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
              />
            );
          })}
        </>
      );
    }
    default: {
      return (
        <input
          key = {`${optionKey}_key`}
          autoFocus
          value={fieldValue ? fieldValue : ''}
          onChange={(e) => setFieldValue(e.target.value)}
          onKeyDown={(e) => keyPress(e)}
          className={`field-item ${borderStyle}`}
          placeholder={t(title)}
        />
      );
    }
    }
  };


  return (
    <div className="items-container">
      <div className="label-space">
        <div className="mrz-viz">
          <div className="wi">
            { getOption(field) }
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldItem;
