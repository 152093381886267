import React from 'react';
import { useTranslation } from 'react-i18next';

interface IYourSelectionProps {
  title: string;
  value: string;
}

const YourSelection = (props: {selectionData: Array<IYourSelectionProps> }) => {
  const { t } = useTranslation();
  const { selectionData } = props;

  return (
    <div className="country-type-section">
      <span className="your-selection">{t('reg.your.selection.header')}</span>
      <div className="main-section">
        {selectionData?.map( (item, index) => {
          return (
            <div key={`selection-${index}`}>
              <div className="title">{ `${t(item.title)} :`}
                <span className="value">{ item.value }</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default YourSelection;