import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CheckButtons from 'components/infoBar/CheckButtons';
import { ReactComponent as CopyLogo } from 'images/copy.svg';
import { ReactComponent as CloseLogo } from 'images/close.svg';
import { IRootState } from 'store/types';
import { useHistory } from 'react-router-dom';
import { KEY_CODE, PATH } from 'constants/Static';
import { IZipContent } from 'store/features/zipContent/types';


const showMessageTimeout = 1000;
const copyCode = (code: string, toggle: Function) => {
  copy(code);
  toggle(true);
  setTimeout(() => toggle(false), showMessageTimeout);
};

const { DASHBOARD } = PATH;

const InfoBar = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const isReviewState = state.common?.isReviewState;
  const { trCode = '' } = state.zipContent as IZipContent;
  const [isMessageShow, toggleMessage] = useState(false);
  const history = useHistory();

  const enterPressed = (event: React.KeyboardEvent) => {
    const code = event.keyCode || event.which;
    if (code === KEY_CODE.ENTER) {
      copyCode(trCode, toggleMessage);
    }
  };

  return (
    <div className="info-bar">
      <div className="info-bar-item">
        <p className="into_bar_text">
          {t('info-bar.tid')}
          <span className="info_bar_tr_code">
            {' '}
            {trCode}
          </span>
        </p>
        <div
          className="copy-button"
          role="button"
        >
          <CopyLogo
            onKeyDown={(e) => enterPressed(e)}
            onClick={() => { copyCode(trCode, toggleMessage); }}
            tabIndex={5}
          />
          <div className={`${isMessageShow ? 'reg-tooltip' : 'reg-tooltip-hide'}`}>
            <span>
              {t('info-bar.copied')}
              {' '}
              {trCode}
            </span>
          </div>
        </div>
      </div>
      {isReviewState && (
        <div className="info-bar-item" tabIndex={-1}>
          <CheckButtons />
        </div>
      )}
      <div className="info-bar-item status">
        <span>{t('info-bar.status')}</span>
        <div className="review-button">
          <span className="button-text">{t('reg.info-bar.in-review')}</span>
        </div>
        <div
          className="button"
          role="button"
        >
          <CloseLogo
            tabIndex={6}
            onClick={() => history.replace(DASHBOARD)}
            onKeyPress={() => history.replace(DASHBOARD)}
          />
        </div>
      </div>
    </div>
  );
};

export default InfoBar;
