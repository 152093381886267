import produce from 'immer';

import { setStorageDataItemByKey, setStorageDataItem } from 'util/storageHelper';
import {
  ICommon,
  TCommonActions,
  CommonActions,
} from 'store/features/common/types';
import getInitialState from 'store/features/common/initialState';

export const commonReducer = (state: ICommon = getInitialState(), action: TCommonActions) => {
  const nextState = produce(state, (draftState: ICommon) => {
    switch (action.type) {
    case CommonActions.setDashboardData:
      draftState.dashboard = {
        ...draftState.dashboard,
        ...action.value,
        loading: false,
      };
      break;
    case CommonActions.setDashboardMode:
      draftState.dashboard = {
        ...draftState.dashboard,
        showActive: action.value,
      };
      break;
    case CommonActions.setDashboardTableLoading:
      draftState.dashboard = {
        ...draftState.dashboard,
        loading: action.value,
      };
      break;
    case CommonActions.resetDashboardTablePage:
      if (action.value) {
        const { closed } = draftState.dashboard;
        draftState.dashboard = {
          ...draftState.dashboard,
          closed: { ...closed, page: 1 },
        };
      } else {
        const { active } = draftState.dashboard;
        draftState.dashboard = {
          ...draftState.dashboard,
          active: { ...active, page: 1 },
        };
      }
      break;
    case CommonActions.setResetCode:
      draftState.resetCode = action.value;
      setStorageDataItemByKey('resetCode', action.value);
      break;
    case CommonActions.setReviewState:
      draftState.isReviewState = action.value;
      setStorageDataItemByKey('isReviewState', action.value);
      break;
    case CommonActions.setQuestion:
      draftState.question = action.value;
      setStorageDataItemByKey('question', action.value);
      break;
    case CommonActions.setDocRequiredFields:
      draftState.docRequiredFields = action.value;
      setStorageDataItemByKey('docRequiredFields', action.value);
      break;
    case CommonActions.setInstruction:
      draftState.instructionUrl = action.value;
      setStorageDataItemByKey('instructionUrl', action.value);
      break;
    case CommonActions.setForcedRejectedInfo:
      draftState.forcedRejectedInfo = action.value;
      setStorageDataItemByKey('forcedRejectedInfo', action.value);
      break;
    case CommonActions.setActiveSlidePath:
      draftState.filesSliderPath = action.value;
      setStorageDataItemByKey('filesSliderPath', action.value);
      break;
    case CommonActions.setPrevPagePath:
      draftState.prevPagePath = action.value;
      setStorageDataItemByKey('prevPagePath', action.value);
      break;
    case CommonActions.setDefaultTransactionCode:
      draftState.defaultTransactionCode = action.value;
      setStorageDataItem('defaultTransactionCode', action.value);
      break;
    case CommonActions.setAppConfig:
      draftState.appConfig = action.value;
      setStorageDataItem('appConfig', action.value);
      break;
    }
    return draftState;
  });

  return nextState;
};
