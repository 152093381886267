import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { IRootState } from 'store/types';
import { ICheckResult, IDocumentData } from 'store/features/checkResult/types';
import { getPackagePosition } from 'helper/tools/fraudCheck';

const RightMenu = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const { documentData = [] } = checkResult;
  const filesSliderPath = state.common?.filesSliderPath || '';
  const prevPagePath = state.common?.prevPagePath || '';

  const {
    itemIndex,
    packageIndex,
  } = useMemo(() => (
    getPackagePosition(filesSliderPath, prevPagePath, documentData)
  ), [filesSliderPath, prevPagePath]);

  return (
    <>
      <div className="document-right-side-main">
        <div className="data-section">
          <h5 className="right-menu-header">
            {t('reg.files.right-menu.data-section.header')}
          </h5>
          <table className="result-files-list">
            <thead className="fl-header">
              <tr className="tab-header">
                <th className="fl-title text-side">
                  <div className="div-header-titles">
                    {' '}
                    <p>{t('reg.document.right-menu.page')}</p>
                    {' '}
                  </div>
                </th>
                <th className="fl-title text-side">
                  <div className="div-header-titles">
                    {' '}
                    <p>{t('reg.document.right-menu.real')}</p>
                    {' '}
                  </div>
                </th>
              </tr>
            </thead>
            {
              documentData[packageIndex].map((document: IDocumentData, index: number) => (
                <tbody className="fl-tbody" key={`sub-doc-${index}`}>
                  <React.Fragment key={ `${document.imageIndex}${document.imageSide}` } >
                    <tr key={`row-${packageIndex}-${index}`} className="fl-row-style">
                      <td className="item-element text-container">
                        <div className={
                          `${itemIndex === index
                            ? 'active-tab-background-color icon' : ''} fl-empty`}>
                          <p className={ document.textKey ? 'cursorPointer ': ' text-style' }>
                            { t(document.textKey) }
                          </p>
                        </div>
                      </td>
                      <td className="item-element text-container">
                        <div className={`${itemIndex === index
                          ? 'active-tab-background-color' : ''}`}>
                          <p className={
                            document.reviewResult.quality ? 'cursorPointer ':' text-style'
                          } >
                            { t(document.reviewResult.quality) }
                          </p>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                </tbody>
              ))
            }
          </table>
        </div>
      </div>
    </>
  );
};

export default RightMenu;
