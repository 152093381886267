import produce from 'immer';

import { setLocalStorageDataItem } from 'util/storageHelper';
import {
  IUserInfo,
  UserActions,
  TUserActions,
} from 'store/features/user/types';
import getInitialState from 'store/features/user/initialState';

export const userReducer = (state: IUserInfo = getInitialState(), action: TUserActions) => {
  const nextState = produce(state, (draftState: IUserInfo) => {
    const { type, value } = action;
    switch (type) {
    case UserActions.setUser:
      draftState = value;
      setLocalStorageDataItem('userInfo', draftState);
      break;
    }
    return draftState;
  });
  return nextState;
};
