import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getSDKFails } from 'util/helperSDKVerification';
import { parseRoute } from 'util/common';
import { ReactComponent as Shield } from 'images/shield.svg';
import { IRootState } from 'store/types';
import { getActiveImagePath } from 'helper/tools/commonHelper';
import { DEFAULT_SLIDER_PATH } from 'constants/Static';
import { ISubDocData } from 'store/features/zipContent/types';

const RightMenuData = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const filesSliderIndex = state.common?.filesSliderPath || DEFAULT_SLIDER_PATH;
  const subDocsData = state.zipContent?.subDocsData || [];
  const location = useLocation();
  const checkRoutes = parseRoute(location.pathname);
  let failsArr: Array<string> = [];
  const { subDocIndex } = useMemo(() => getActiveImagePath(filesSliderIndex), [filesSliderIndex]);
  if (checkRoutes.checkSubType === 'files'
    || checkRoutes.checkSubType === 'expiryDate'
  ) {
    failsArr = getSDKFails(subDocsData[subDocIndex].dataXML);
  } else {
    failsArr = subDocsData.reduce((fails: any, subDoc: ISubDocData) => {
      const subDocFails: Array<string> = getSDKFails(subDoc.dataXML);
      subDocFails.length && subDocFails.forEach((fail) => {
        if (!fails.includes(fail)) fails.push(fail);
      });
      return fails;
    }, []);
  }

  return (
    <div className="left-menu-content-warning">
      {Boolean(failsArr.length) && (
        <div>
          {
            failsArr.map((fail: string) => {
              return <div className="failed-result" key={`failedResult_${fail}`}>
                <Shield className="shield-icon" />
                <span className="failed-text">
                  {t(fail)}
                </span>
              </div>;
            })
          }
        </div>
      )}
    </div>
  );
};

export default RightMenuData;