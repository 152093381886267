import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import {
  getActiveImagePath,
  isUnknownDocument,
} from 'helper/tools/commonHelper';
import { getImageFromBase64 } from 'util/common';
import { IRootState } from 'store/types';
import * as commonActions from 'store/features/common/actions';
import { Questions } from 'store/features/common/types';
import { ICheckResult, IFilesData } from 'store/features/checkResult/types';

const RightMenu = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const { filesData = [] } = checkResult;
  const subDocsData = state.zipContent?.subDocsData || [];
  const filesSliderPath = state.common?.filesSliderPath || '';
  const {
    subDocIndex,
    imageIndex,
  } = useMemo(() => getActiveImagePath(filesSliderPath), [filesSliderPath]);

  const handleTextClick = (docIndex: number, imgIndex: number) => {
    batchActions([
      dispatch(commonActions.setActiveSlidePath(`${docIndex}/${imgIndex}`)),
      dispatch(commonActions.setQuestion(Questions.documentPage))
    ]);
  };

  return (
    <>
      <div className="right-side-main">
        <div className="data-section">
          <table className="result-files-list">
            <thead className="fl-header">
              <tr className="tab-header">
                <th className="fl-title icon-side">
                  <div className="first-table-header">
                    {' '}
                    <p />
                    {' '}
                  </div>
                </th>
                <th className="fl-title text-side">
                  <div className="div-header-titles">
                    <p>{t('reg.files.right-menu.page')}</p>
                    {' '}
                  </div>
                </th>
                <th className="fl-title text-side">
                  <div className="div-header-titles">
                    {' '}
                    <p>{t('reg.files.right-menu.quality')}</p>
                    {' '}
                  </div>
                </th>
              </tr>
            </thead>
            {
              filesData.map((subDoc: IFilesData[], docIndex: number) => (
                <tbody className="fl-tbody" key={`sub-doc-${docIndex}`}>
                  {subDoc.map((item: IFilesData, imgIndex: number) => {
                    const img = Object.values(subDocsData[subDocIndex].files[imgIndex])[0];
                    const { reviewResult, pageSideKey } = item;
                    return (
                      !isUnknownDocument(item) &&
                      <React.Fragment key={`${docIndex}-${imgIndex}`}>
                        <tr key={`row-${docIndex}-${imgIndex}-9`} className="fl-row-style">
                          <td className="item-element icon-element">
                            <div className={
                              `${imgIndex === imageIndex
                              && docIndex === subDocIndex ? 'image-gf' : ''} fl-image`}>
                              <img src={getImageFromBase64(img)} alt="" />
                            </div>
                          </td>
                          <td className="item-element text-container">
                            <div className={
                              `${imgIndex === imageIndex
                              && docIndex === subDocIndex ? 'active-tab-background-color' : ''}`}>
                              <p
                                className={ pageSideKey ? 'cursorPointer ':' text-style'}
                                onClick={() => pageSideKey && handleTextClick(docIndex, imgIndex)}
                              >
                                {t(pageSideKey)}
                              </p>
                            </div>
                          </td>
                          <td className="item-element text-container">
                            <div className={
                              `${imgIndex === imageIndex
                                && docIndex === subDocIndex ? 'active-tab-background-color' : ''}`}>
                              <p className="text-style">
                                {t(reviewResult.quality)}
                              </p>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                  <tr className="space" />
                </tbody>
              ))
            }
          </table>
        </div>
      </div>
    </>
  );
};

export default RightMenu;
