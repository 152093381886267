import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Countries from 'constants/Countries.json';
import DocumentTypes from 'constants/DocumentTypes.json';
import { getXMLValueFromJSON } from 'helper/xmlParser';
import { IRootState } from 'store/types';

const getRow = (item: string, isLastItem: boolean) => {
  return (
    <div className="divided">
      <p className="text">{item}</p>
      {!isLastItem && <span className="devider"></span>}
    </div>
  );
};

const getData = (data: any) => {
  return (
    <>
      {data.map((item: any, index: number) => {
        const isLast: boolean = index === data.length - 1;
        const className: string = isLast ? 'last' : '';
        return (
          <div className={`data-section ${className}`} key={`dataSection_${index}`}>
            {getRow(item.firstName, isLast)}
            {getRow(item.lastName, isLast)}
            {getRow(item.dateOfBirth, isLast)}
            <p className="doc-type">{item.documentType}</p>
          </div>
        );
      })}
    </>
  );
};

const DataComparison = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const subDocsData = state.zipContent?.subDocsData || [];

  const data = subDocsData.map((subDoc) => {
    const { mrz = {}, viz = {} } = subDoc?.dataXML?.documentScan || {};
    const { documentTypeData } = subDoc;
    const { documentCountry = '', documentType = '' } = documentTypeData;
    const docCountry = Countries.find((option) => option.value === documentCountry);
    const docType = DocumentTypes.find((option) => option.value === documentType);
    const UNKNOWN = t('reg.data.comparing.unknown');
    return {
      firstName: getXMLValueFromJSON(mrz.firstName) || getXMLValueFromJSON(viz.firsName) || UNKNOWN,
      lastName: getXMLValueFromJSON(mrz.lastName) || getXMLValueFromJSON(viz.lastName) || UNKNOWN,
      dateOfBirth: getXMLValueFromJSON(mrz.dateOfBirth)
        || getXMLValueFromJSON(viz.dateOfBirth) || UNKNOWN,
      documentType: docCountry && docType ? `${t(docCountry.label)} ${t(docType.label)}` : UNKNOWN,
    };
  });

  return (
    <div className="data-comparison-container">
      <div className="data-comparison-container-content">
        {getData(data)}
      </div>
    </div>
  );
};

export default DataComparison;
