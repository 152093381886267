import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';
import { useTranslation } from 'react-i18next';

import Answers from 'components/footer/Answers';
import { getPreviousPage, getQuestion } from 'helper/navigation';
import { ANSWERS_KEYS, BUTTON_STYLE } from 'constants/Static';
import * as commonActions from 'store/features/common/actions';
import { IRootState } from 'store/types';
import { PATH } from 'constants/Static';
import { IAnswerOption } from 'types';

interface ISummaryFooterProps {
  span1Text: string;
  link: string;
  clickHandler: Function;
  denyClickHandler: Function;
  buttonText: string;
  isLoading?: boolean | false;
  reject?: boolean | false;
}

const SummaryFooter = (props: ISummaryFooterProps) => {
  const {
    span1Text,
    link,
    clickHandler,
    buttonText,
    denyClickHandler,
    isLoading,
    reject,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const forcedRejectedInfo = state.common?.forcedRejectedInfo;
  const isLastPage: boolean = (link === PATH.DASHBOARD || link === location.pathname);

  const back = () => {
    const previousPage = getPreviousPage(state, location.pathname);
    const previousQuestion = getQuestion(previousPage);
    batchActions([
      dispatch(commonActions.setActiveSlidePath('')),
      dispatch(commonActions.setPrevPagePath(location.pathname)),
      dispatch(commonActions.setQuestion(forcedRejectedInfo?.question || previousQuestion)),
    ]);
    if (forcedRejectedInfo?.isForcedRejected) {
      const { location, question, filesSliderPath } = forcedRejectedInfo
      batchActions([
        dispatch(commonActions.setForcedRejectedInfo({
          question: '',
          location: '',
          isForcedRejected: false,
        })),
        dispatch(commonActions.setActiveSlidePath(filesSliderPath)),
        dispatch(commonActions.setQuestion(question)),
      ])
      return history.replace(location);
    }
    return history.replace(previousPage);
  };

  const submitAnswer = (answer: IAnswerOption) => {
    if (answer.key === ANSWERS_KEYS.REJECTED) {
      return denyClickHandler();
    }
    isLastPage && clickHandler();
    const nextQuestion = getQuestion(link);
    dispatch(commonActions.setQuestion(nextQuestion));
    dispatch(commonActions.setActiveSlidePath(''));
    dispatch(commonActions.setPrevPagePath(''));
    history.replace(link, 'next');
  };

  const answers = [
    {
      text: 'reg.summary.rejectTransaction',
      key: ANSWERS_KEYS.REJECTED,
      buttonType: 'optionButton',
    },
  ];

  let summaryDescription = 'reg.summary.forced-rejected';

  if (!forcedRejectedInfo?.isForcedRejected && !reject) {
    answers.push({
      text: buttonText,
      key: ANSWERS_KEYS.ACCEPT,
      buttonType: 'optionButton',
    });
    summaryDescription = span1Text;
  }

  return (
    <div className="summery-footer">
      <div className='info-container'>
        <span>{t(summaryDescription)}</span>
      </div>
      <div className="answers">
        <Answers
          back={back}
          answers={answers}
          activeIndexes={[1]}
          submitAnswer={submitAnswer}
          styled={BUTTON_STYLE.OUTLINE_SECONDARY}
          isLoading={isLoading ? isLoading : false}
          disabled={isLoading ? answers.map((_, index) => index) : []}
        />
      </div>
    </div>
  );
};

export default SummaryFooter;