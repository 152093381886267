import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import RoutePaths from 'constants/RoutePaths';
import SubButtons from 'components/infoBar/CheckSubButtons';
import { parseRoute } from 'util/common';
import { SUMMARY } from 'constants/Static';
import { EPage } from 'enums';

const CheckButtons = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const checkRoutes = parseRoute(location.pathname);
  const { checkType } = checkRoutes;

  return (
    <div className="check-type-buttons">
      {Object.keys(RoutePaths).filter((item) => item !== SUMMARY).map((tab, index) => {
        if (checkType === tab) return <SubButtons {...checkRoutes} key={`${tab}-${index}`} />;
        return (
          RoutePaths[tab as EPage][0].navTitle &&
          <div
            className={`check-button-group ${checkType !== tab
              ? 'disable' : ''}`} key={`checkGroupButton_${index}`}>
            <NavLink
              key={`navLink_${tab}-${index}`}
              to={`/${checkType}/${checkType}`}
              className="button check-button check-type-button"
              onClick={(e) => e.preventDefault()}
            >
              <span className="button-text">{t(RoutePaths[tab as EPage][0].navTitle)}</span>
            </NavLink>
          </div>
        );
      })}
    </div>
  );
};

export default CheckButtons;