export enum PopupInfoActions {
  setLivenessPopupShowAgain = 'SET_LIVENESS_POP_SHOW_AGAIN',
  setFraudPagesPopupShowAgain = 'SET_FRAUD_PAGES_POP_SHOW_AGAIN',
  setDocumentScanPopupShowAgain = 'SET_DOCUMENT_SCAN_POP_SHOW_AGAIN',
  setAddressPopupShowAgain = 'SET_ADDRESS_POP_UP_SHOW_AGAIN',
}

export interface IPopupInfo {
  livenessPopupShowAgain: boolean;
  fraudPagesPopupShowAgain: boolean;
  documentScanPopupShowAgain: boolean;
  addressPopupShowAgain: boolean;
}

export type TPopupInfoActions =
  { type: PopupInfoActions.setLivenessPopupShowAgain; value: boolean } |
  { type: PopupInfoActions.setFraudPagesPopupShowAgain; value: boolean } |
  { type: PopupInfoActions.setAddressPopupShowAgain; value: boolean } |
  { type: PopupInfoActions.setDocumentScanPopupShowAgain; value: boolean };
