import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import i18n from 'i18next';

import FilesContent from 'components/accordionMenu/content/Files';
import DataCheckContent from 'components/accordionMenu/content/DataCheck';
import CustomerDataCheckContent from 'components/accordionMenu/content/CustomerDataCheck';
import FaceContent from 'components/accordionMenu/content/Face';
import DataContent from 'components/accordionMenu/content/Data';
import LivenessContent from 'components/accordionMenu/content/Liveness';
import FraudPagesContent from 'components/accordionMenu/content/FraudPages';
import ExpiryDateContent from 'components/accordionMenu/content/ExpiryDate';
import FrameWrapper from 'components/frameWrapper/FrameWrapper';
import AccordionField from 'components/accordionField/AccordionField';
import ManCheckReasons from 'components/manCheckReasons/ManCheckReasons';
import AccordionStatus from 'components/accordionField/AccordionStatus';
import { getInstructionUrl } from 'util/common';
import { PATH } from 'constants/Static';
import { IRootState } from 'store/types';

const {
  QUICK_CHECK_FILES,
  QUICK_CHECK_EXPIRY_DATE,
  QUICK_CHECK_FACE,
  QUICK_CHECK_DATA,
  FRAUD_CHECK_LIVENESS,
  FRAUD_CHECK_DOCUMENT,
  DATA_CHECK_PAGE,
  CDC_ADDRESS,
  CDC_SELF_DECLARATION,
  CDC_DOCUMENT_SCAN,
} = PATH;

const AccordionMenu = (
  props: { children?: Array<React.ReactNode>; statusPanels?: any; pathName?: string }) => {
  const { pathName } = props;
  const [statusListAccordionFields, setStatusListAccordionFields]: any = useState([]);
  const [statusAccordionFields, setStatusAccordionFields] = useState(false);
  const accordionProps = [];
  const instructionUrls: any = useSelector(
    (state: IRootState) => state.common?.instructionUrl || {});
  const instructionUrl = instructionUrls[i18n.language] || getInstructionUrl(i18n.language);

  useEffect(() => {
    setStatusListAccordionFields([]);
  }, [statusAccordionFields]);

  const handleAccordionFieldsStatus = (eventKey: string) => {
    const newStatusList: any = [...statusListAccordionFields];
    if (!newStatusList?.includes(eventKey)) {
      newStatusList.push(eventKey);
      setStatusListAccordionFields(newStatusList);
    } else {
      newStatusList.splice(newStatusList.indexOf(eventKey), 1);
      setStatusListAccordionFields(newStatusList);
    }
  };

  switch (pathName) {
  case QUICK_CHECK_FILES:
    accordionProps.push(<AccordionField
      title={'reg.files.left-menu.task-help'}
      body={<FilesContent />}
      className="left-menu-container-accordion-field"
      showStatus={statusAccordionFields}
      eventKey={'1'}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    accordionProps.push(<AccordionField
      title={'common.manCheckReason'}
      body={<ManCheckReasons />}
      className="left-menu-container-accordion-field"
      showStatus={statusAccordionFields}
      eventKey={'2'}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    accordionProps.push(<AccordionField
      title={'common.filesDataToCheck'}
      body={<FrameWrapper url={instructionUrl} />}
      className="left-menu-container-accordion-field"
      eventKey={'3'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    break;
  case QUICK_CHECK_EXPIRY_DATE:
    accordionProps.push(<AccordionField
      title={'reg.date.left-menu.task-help'}
      body={<ExpiryDateContent />}
      className='left-menu-container-accordion-field'
      eventKey={'1'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    accordionProps.push(<AccordionField
      title={'common.manCheckReason'}
      body={<ManCheckReasons />}
      className='left-menu-container-accordion-field'
      eventKey={'2'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    accordionProps.push(<AccordionField
      title={'common.filesDataToCheck'}
      body={<FrameWrapper url={getInstructionUrl(i18n.language)} />}
      className='left-menu-container-accordion-field'
      eventKey={'3'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    break;
  case QUICK_CHECK_FACE:
    accordionProps.push(<AccordionField
      title={'reg.face.left-menu.task-help'}
      body={<FaceContent />}
      className='left-menu-container-accordion-field'
      eventKey={'1'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    accordionProps.push(<AccordionField
      title={'common.manCheckReason'}
      body={<ManCheckReasons />}
      className='left-menu-container-accordion-field'
      eventKey={'2'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    accordionProps.push(<AccordionField
      title={'common.filesDataToCheck'}
      body={<FrameWrapper url={instructionUrl} />}
      className='left-menu-container-accordion-field'
      eventKey={'3'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    break;
  case QUICK_CHECK_DATA:
    accordionProps.push(<AccordionField
      title={'common.TextHelp'}
      body={<DataContent />}
      className="left-menu-container-accordion-field"
      eventKey={'1'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    accordionProps.push(<AccordionField
      title={'common.manCheckReason'}
      body={<ManCheckReasons />}
      className="left-menu-container-accordion-field"
      eventKey={'2'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    accordionProps.push(<AccordionField
      title={'common.filesDataToCheck'}
      body={<FrameWrapper url={instructionUrl} />}
      className="left-menu-container-accordion-field"
      eventKey={'3'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    break;
  case FRAUD_CHECK_LIVENESS:
    accordionProps.push(<AccordionField
      title={'reg.liveness.left-menu.task-help'}
      body={<LivenessContent />}
      className="left-menu-container-accordion-field"
      eventKey={'1'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    accordionProps.push(<AccordionField
      title={'common.manCheckReason'}
      body={<ManCheckReasons />}
      className="left-menu-container-accordion-field"
      eventKey={'2'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    accordionProps.push(<AccordionField
      title={'common.filesDataToCheck'}
      body={<FrameWrapper url={instructionUrl} />}
      className="left-menu-container-accordion-field"
      eventKey={'3'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    break;
  case FRAUD_CHECK_DOCUMENT:
    accordionProps.push(<AccordionField
      title={'reg.fraudCheck.page.left-menu.task-help'}
      body={<FraudPagesContent />}
      className="left-menu-container-accordion-field"
      eventKey={'1'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    accordionProps.push(<AccordionField
      title={'common.manCheckReason'}
      body={<ManCheckReasons />}
      className="left-menu-container-accordion-field"
      eventKey={'2'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    accordionProps.push(<AccordionField
      title={'common.filesDataToCheck'}
      body={<FrameWrapper url={instructionUrl} />}
      className="left-menu-container-accordion-field"
      eventKey={'3'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    break;
  case DATA_CHECK_PAGE:
    accordionProps.push(<AccordionField
      title={'reg.data-check.left-menu.task-help'}
      body={<DataCheckContent />}
      className="left-menu-container-accordion-field"
      eventKey={'1'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    accordionProps.push(<AccordionField
      title={'common.manCheckReason'}
      body={<ManCheckReasons />}
      className="left-menu-container-accordion-field"
      eventKey={'2'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    accordionProps.push(<AccordionField
      title={'common.filesDataToCheck'}
      body={<FrameWrapper url={instructionUrl} />}
      className="left-menu-container-accordion-field"
      eventKey={'3'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    break;
  case CDC_SELF_DECLARATION:
  case CDC_ADDRESS:
  case CDC_DOCUMENT_SCAN:
    accordionProps.push(<AccordionField
      title={'user-data-check.left-menu.task-help'}
      body={<CustomerDataCheckContent />}
      className="left-menu-container-accordion-field"
      eventKey={'1'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    accordionProps.push(<AccordionField
      title={'common.manCheckReason'}
      body={<ManCheckReasons />}
      className="left-menu-container-accordion-field"
      eventKey={'2'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    accordionProps.push(<AccordionField
      title={'common.filesDataToCheck'}
      body={<FrameWrapper url={instructionUrl} />}
      className="left-menu-container-accordion-field"
      eventKey={'3'}
      showStatus={statusAccordionFields}
      setStatusByEventKey={(eventKey: string) => handleAccordionFieldsStatus(eventKey)}
    />);
    break;
  }

  return (
    <>
      <div className='left-menu-accordions-container'>
        {accordionProps?.map((child: React.ReactNode, index: number) =>
          <Fragment key={index} >
            {child}
          </Fragment>
        )}
      </div>
      <AccordionStatus
        statusFields={statusListAccordionFields.length === accordionProps.length}
        handleClick={setStatusAccordionFields}
      />
    </>
  );
};

export default AccordionMenu;
