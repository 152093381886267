import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Table from 'components/table/Table';
import columns from 'constants/TableColumns';
import { IRootState } from 'store/types';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';
import { translateColumnsHeaders } from 'helper/tools/commonHelper';
import { IDataSet } from 'store/features/dataSets/types';

const Dataset = (props: { agentConfigId: number; setAgentConfigId: Function}) => {
  const { t } = useTranslation();
  const { agentConfigId, setAgentConfigId } = props;
  const state = useSelector((rootState: IRootState) => rootState);
  const datasets = state.dataSets || [];

  const newData = datasets.map((dataset: IDataSet) => {
    const isSelected = dataset.agentConfigId === agentConfigId;
    return {
      ...dataset,
      setDefault: <SingleButton {...{
        textButton: 'reg.dataset.set-as-default',
        isActive: isSelected,
        onClick: () => setAgentConfigId(dataset.agentConfigId),
        radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
        variant: BUTTON_STYLE.SECONDARY,
      }}
      />,
      isActive: isSelected,
    };
  });

  return (
    <div className="dataset-container">
      <Table data={newData} columns={translateColumnsHeaders(columns.defaultDataset, t)} />
    </div>
  );
};

export default Dataset;
