import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import initI18n from 'i18n/i18n.config';

import { GetDefaultConfigs } from 'helper/api/route';
import { IRootState } from 'store/types';
import * as commonActions from 'store/features/common/actions';

const LanguageProvider = (props: any) => {
  const dispatch = useDispatch();
  const state: IRootState = useSelector((rootState: IRootState) => rootState);
  const appConfig = state.common?.appConfig || {};
  const { lang: langConfig } = appConfig;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (Object.keys(appConfig).length && langConfig?.langs.length) {
      initI18n();
      setLoaded(true);
    } else {
      GetDefaultConfigs()
        .then((response) => {
          dispatch(commonActions.setAppConfig(response.data));
          initI18n(response?.data?.lang?.default || '');
          setLoaded(true);
        });
    }
  }, []);

  return loaded && props.children;
};

export default LanguageProvider;
