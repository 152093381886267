import React from 'react';

import SummaryTitle from 'components/summary/SummaryTitle';
import SummaryItem from 'components/summary/SummaryItem';

const SummaryComponent = (props: any) => {
  const { summaryResult } = props;

  return (
    <div className="table-container-wrapper">
      <div className='table-container'>
        <SummaryTitle {...{
          iconMid: summaryResult.iconMid,
          title: summaryResult.title,
          description: summaryResult.description}}
        />
        <SummaryItem data={summaryResult.data} />
      </div>
    </div>
  );
};

export default SummaryComponent;
