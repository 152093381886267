import React, { useEffect, useRef } from 'react';

import Modal from 'components/dashboardModal/Modal';
import ModalHeader from 'components/dashboardModal/ModalHeader';
import ModalBody from 'components/dashboardModal/ModalBody';
import DashboardDatasets from 'pages/dataset/DashboardDatasets';
import { ReactComponent as CloseLogo } from 'images/close.svg';
import { KEY_CODE } from 'constants/Static';

const SwitchDataset = (props: {
  close: Function;
  title: string;
}) => {
  const { close, title } = props;
  const modalFocus = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalFocus.current) {
      modalFocus.current.focus();
    }
  }, []);

  const enterPressed = (event: any) => {
    const code = event.keyCode || event.which;
    if (code === KEY_CODE.ENTER) {
      close(false);
    }
  };

  return (
    <Modal>
      <ModalHeader>
        <div
          className="dashboard-modal-title switch-dataset-modal-title"
          ref={modalFocus} tabIndex={0}>
          <p>{title}</p>
        </div>
        <div
          className=" button close-modal-button"
          role="button"
          onKeyDown={(e) => enterPressed(e)}
          onClick={() => close(false)}
          tabIndex={0}
        >
          <CloseLogo />
        </div>
      </ModalHeader>
      <ModalBody className="switch-dataset-modal">
        <DashboardDatasets close={close}/>
      </ModalBody>
    </Modal>
  );
};

export default SwitchDataset;