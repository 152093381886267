import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { CompleteTransaction } from 'helper/api/route';
import { getXMLData } from 'helper/xmlParser';
import BigModal from 'components/bigModal/BigModal';
import * as commonActions from 'store/features/common/actions';
import { IRootState } from 'store/types';
import { PATH } from 'constants/Static';
import { IZipContent } from 'store/features/zipContent/types';
import { EHttpStatusCode, ETransactionState } from 'enums';

const { DASHBOARD } = PATH;

const DenyModal = (props: any) => {
  const { t } = useTranslation();
  const {
    show,
    setShow,
    setHide,
    onSuccess,
    Child,
    sortedFailsList,
    backdrop,
    setOnBackClick,
  } = props;
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const name = state.userInfo?.name || '';
  const { trCode = '' } = state.zipContent as IZipContent;
  const history = useHistory();
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const elementRef = useRef<HTMLButtonElement | null>(null);
  const [isSuccessStatus, setIsSuccessStatus] = useState('success');
  const [postProcessing, setPostProcessing] = useState(false);

  useEffect(() => {
    if (elementRef !== null && elementRef.current !== null) {
      elementRef.current.focus();
    }
  });

  const hideModal = () => {
    setShow(!show);
    if (setHide) setHide();
  };

  const modalBackClickModal = () => {
    if (setOnBackClick) {
      setOnBackClick();
    } else {
      hideModal();
    }
  };

  const ConfirmAssessment = () => {
    if (!loader) {
      setLoader(true);
      if (onSuccess) onSuccess();
      const xmlData = getXMLData(state, ETransactionState.rejected, {
        sortedFailsList,
        message,
        postProcessing,
      });
      CompleteTransaction(xmlData, trCode).then(() => {
        setLoader(false);
        dispatch(commonActions.setResetCode(false));
        history.replace(DASHBOARD);
      })
        .catch((error) => {
          if (error.response.status === EHttpStatusCode.conflict) {
            setLoader(false);
            dispatch(commonActions.setResetCode(false));
            return history.replace(DASHBOARD);
          }
          setIsSuccessStatus('fail');
          setLoader(false);
        });
    }
  };

  return (
    <>
      <BigModal
        isModalNewAttack={false}
        title={`${t('reg.modal.reject.thankYou')}
          ${name} ${t('reg.modal.reject.forReviewing')} ${trCode}`}
        submitButtonText={'reg.modal.reject.confirmRejection'}
        cancelButtonText={'reg.modal.reject.back'}
        body={(
          <div>
            <p className="dicide-text-style">{t('reg.modal.reject.decidedToReject')}</p>
            <p className="assessment-text-style">{t('reg.modal.reject.willBeLogged')}</p>
            { Child ? Child : '' }
            <textarea
              placeholder={t('reg.modal.reject.feedbackPlaceholder')}
              onChange={(e) => setMessage(e.target.value)} />
            <div className='post-processing-section'>
              <div className='post-processing-checkbox'>
                <input
                  type="checkbox"
                  checked={postProcessing}
                  onChange={(event) => setPostProcessing(event.target.checked)}
                />
              </div>
              <span className='post-processing-title'>{t('reject.post-processing.title')}</span>
            </div>
            <p className="modal-confirmation-note">{t('reg.modal.confirmation-note')}</p>
            {isSuccessStatus === 'fail' &&
            <>
              <p>{t('data-check-accept-modal-fail-message')}</p>
              <p>{t('data-check-accept-modal-please-try-again')}</p>
            </>}
          </div>
        )}
        bodyButton={false}
        footerStyle="modal-footer-join"
        onHide={() => !loader && hideModal()}
        onBackClick={() => modalBackClickModal()}
        onSuccess={() => ConfirmAssessment()}
        show={show}
        isLoading={loader}
        isDisabled={loader}
        backdrop={backdrop}
      />
    </>
  );
};

export default DenyModal;
