import produce from 'immer';

import { SpecimenActions, TSpecimenActions } from 'store/features/specimen/types';
import getInitialState from 'store/features/specimen/initialState';
import { setStorageDataItemByKey } from 'util/storageHelper';
import { ISpecimenState } from 'store/features/specimen/types';

export const specimenReducer = (
  state: ISpecimenState = getInitialState(),
  action: TSpecimenActions,
) => {
  const nextState = produce(state, (draftState: ISpecimenState) => {
    switch (action.type) {
    case SpecimenActions.setSpecimens:
      draftState.specimens = action.value;
      setStorageDataItemByKey('specimens', action.value);
      break;
    case SpecimenActions.setIsSpecimensLoaded:
      draftState.isSpecimensLoaded = action.value;
      setStorageDataItemByKey('isSpecimensLoaded', action.value);
      break;
    }
    return draftState;
  });
  return nextState;
};
