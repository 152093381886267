import { Questions } from 'store/features/common/types';
import { ISubDocData } from 'store/features/zipContent/types';
import { IFilesData, IDocumentData } from 'store/features/checkResult/types';
import { EDirection, EImageSide } from 'enums';
import { checkPackageDocumentDifference } from 'helper/tools/quickCheck';
import missingSpecimenEN from 'images/missing_specimen_EN.png';
import missingSpecimenDE from 'images/missing_specimen_DE.png';
import serviceUnavailableEN from 'images/service_unavailable_EN.png';
import serviceUnavailableDE from 'images/service_unavailable_DE.png';

const validateLiveness = (
  video: string,
  subDocsData: ISubDocData[],
  isSimplified: boolean,
) => {
  if (!video) {
    return false;
  }
  const resultLiveness = subDocsData?.map((subDoc: ISubDocData) => {
    const { livenessCheck = {} } = subDoc?.dataXML?.documentScan || {};
    return livenessCheck?.result;
  });
  const filterResult = resultLiveness.filter((result) => !result || result === 'fail');
  return !isSimplified || (isSimplified && Boolean(filterResult?.length));
};

const getDocumentData = (filesData: IFilesData[][], subDocsData: ISubDocData[]) => (
  filesData.map((subDoc: IFilesData[], subDocIndex: number) => {
    const hasSameDocumentData = checkPackageDocumentDifference(subDoc);
    if (!hasSameDocumentData) return [];
    return subDoc.reduce((
      acc: IDocumentData[],
      item: IFilesData,
      imageIndex: number,
    ) => {
      const { imageSide } = item;
      if ([EImageSide.front, EImageSide.back].includes(imageSide)) {
        const textKey = `reg.document.right-menu.${imageSide}`;
        const summaryTextKey = `reg.document.summary.${imageSide}`;
        const sideDataIndex = acc.findIndex((item: IDocumentData) => item.imageSide === imageSide);
        if (sideDataIndex !== -1) {
          if (item.isImageCropped) {
            acc[sideDataIndex].imageIndex = imageIndex;
          }
          acc[sideDataIndex].availableImageIndexes?.push(imageIndex);
        } else {
          acc.push({
            subDocIndex,
            imageIndex,
            imageSide,
            textKey,
            summaryTextKey,
            question: Questions.imageSource,
            availableImageIndexes: [imageIndex],
            hasSpecimen: true,
            specimen: {
              duid: '',
              front: '',
              back: '',
              extraDetail: '',
              externalSource: false,
            },
            reviewResult: {
              quality: '',
            },
          });
        }
        const videoSide = imageSide === EImageSide.front ? 'side1' : 'side2';
        const videoData = subDocsData[subDocIndex].videos.find(
          (item) => item.name.includes(videoSide)
        );
        if (videoData && sideDataIndex === -1) {
          const textKey = `reg.document.right-menu.${imageSide}.recording`;
          const summaryTextKey = `reg.document.summary.${imageSide}.recording`;
          acc.push({
            subDocIndex,
            imageIndex,
            imageSide,
            textKey,
            summaryTextKey,
            question: Questions.idRecording,
            reviewResult: {
              quality: '',
            },
            entryName: videoData.entryName,
          });
        }
      }
      return acc;
    }, []);
  }).filter((item: IDocumentData[]) => item.length)
);

const getPackagePosition = (
  filesSliderPath: string,
  prevPagePath: string,
  documentData: IDocumentData[][],
) => {
  if (filesSliderPath) {
    const [packageIndex, itemIndex] = filesSliderPath.split('/');
    return { packageIndex: Number(packageIndex), itemIndex: Number(itemIndex) };
  }
  if (prevPagePath) {
    const packageIndex = documentData.length - 1;
    const itemIndex = documentData[packageIndex].length - 1;
    return { packageIndex, itemIndex };
  }
  return { packageIndex: 0, itemIndex: 0 };
};

const getUpdatedData = (
  documentData: IDocumentData[][],
  packageIndex: number,
  itemIndex: number,
  options: any,
) => (
  documentData.map((packageData: IDocumentData[], index: number) => {
    if (index !== packageIndex) return packageData;
    return packageData.reduce((
      acc: IDocumentData[],
      element: IDocumentData,
      elementIndex: number,
    ) => {
      if (itemIndex === elementIndex) {
        acc.push({ ...element, ...options });
      } else {
        acc.push(element);
      }
      return acc;
    }, []);
  })
);

const navigateToNextTab = (
  documentData: IDocumentData[][],
  packageIndex: number,
  itemIndex: number,
  direction: EDirection,
) => {
  if (direction === EDirection.right) {
    if (packageIndex === documentData.length - 1
      && itemIndex === documentData[packageIndex].length - 1
    ) {
      return {};
    }
    if (itemIndex === documentData[packageIndex].length - 1
    ) {
      return {
        path: `${packageIndex + 1}/0`,
        item: documentData[packageIndex + 1][0],
      };
    }
    return {
      path: `${packageIndex}/${itemIndex + 1}`,
      item: documentData[packageIndex][itemIndex + 1],
    };
  }
  if (direction === EDirection.left) {
    if (packageIndex === 0 && itemIndex === 0) {
      return {};
    }
    if (itemIndex === 0) {
      return {
        path: `${packageIndex - 1}/${documentData[packageIndex - 1].length - 1}`,
        item: documentData[packageIndex - 1][documentData[packageIndex - 1].length - 1],
      };
    }
    return {
      path: `${packageIndex}/${itemIndex - 1}`,
      item: documentData[packageIndex][itemIndex - 1],
    };
  }
  return {};
}

const generateCurrentActivePath = (documentData: IDocumentData[][], prevPagePath: string) => {
  if (prevPagePath) {
    return {
      subDocIndex: documentData.length - 1,
      imageIndex: documentData[documentData.length - 1].length - 1,
    }
  }
  return {
    subDocIndex: 0,
    imageIndex: 0,
  };
}

const getImageFromPackage = (
  documentData: IDocumentData[],
  subDocsData: ISubDocData[],
  imageSide: EImageSide,
) => {
  const dataBySide = documentData.find((item: IDocumentData) => (
    item.question === Questions.imageSource && imageSide === item.imageSide
  ));
  if (!dataBySide) return '';
  return Object.values(subDocsData[dataBySide.subDocIndex].files[dataBySide.imageIndex])[0];
}

const differentSpecimensSelected = (documentData: IDocumentData[]) => {
  const images = documentData.filter(({ question }) => (question === Questions.imageSource));
  const detected = images.find((image) => image.specimen?.duid);
  if (!detected) return false;
  const { duid } = detected.specimen || {};
  return images.some((element) => element.specimen?.duid && duid !== element.specimen?.duid);
}

const getNotFoundImage = (language: string) => {
  switch (language) {
  case 'en':
    return missingSpecimenEN;
  case 'de':
    return missingSpecimenDE;
  default:
    return missingSpecimenEN;
  }
}

const getServiceUnavailableImage = (language: string) => {
  switch (language) {
  case 'en':
    return serviceUnavailableEN;
  case 'de':
    return serviceUnavailableDE;
  default:
    return serviceUnavailableEN;
  }
}

export {
  getUpdatedData,
  getDocumentData,
  validateLiveness,
  navigateToNextTab,
  getPackagePosition,
  getImageFromPackage,
  generateCurrentActivePath,
  differentSpecimensSelected,
  getServiceUnavailableImage,
  getNotFoundImage,
};