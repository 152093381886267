import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { HeaderButtons } from 'constants/CheckTypeButtons';
import BigModal from 'components/bigModal/BigModal';
import { SendEmail } from 'helper/api/route';
import DenyModal from 'components/finalModal/DenyModal';
import Countries from 'constants/Countries.json';
import DocumentTypes from 'constants/DocumentTypes.json';
import { ANSWERS_KEYS, BUTTON_STYLE } from 'constants/Static';
import DragonDropList from 'components/dragonDropList/DragonDropList';
import { IRootState } from 'store/types';
import { Questions } from 'store/features/common/types';
import Answers from 'components/footer/Answers';
import { EPage } from 'enums';
import * as commonActions from 'store/features/common/actions';
import * as specimenActions from 'store/features/specimen/actions';
import * as checkResultActions from 'store/features/checkResult/actions';
import { getPackagePosition, getUpdatedData } from 'helper/tools/fraudCheck';
import { ICheckResult } from 'store/features/checkResult/types';
import { batchActions } from 'redux-batched-actions';
import { IZipContent } from 'store/features/zipContent/types';
import { generateManualCheckFailReasons } from 'util/helperSDKVerification';

const NotFound = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const { documentData = [], filesData = [] } = checkResult;
  const prevPagePath = state.common?.prevPagePath || '';
  const filesSliderPath = state.common?.filesSliderPath || '';
  const { trCode = '', subDocsData = [] } = state.zipContent as IZipContent;
  const agentEmail = state.userInfo?.email;
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [sortedData, setSortedData] = useState(
    generateManualCheckFailReasons(checkResult, subDocsData)
  );
  const page = HeaderButtons[EPage.fraudCheck].document[Questions.notFound];

  const {
    itemIndex,
    packageIndex,
  } = useMemo(() => (
    getPackagePosition(filesSliderPath, prevPagePath, documentData)
  ), [filesSliderPath, prevPagePath]);
  const { subDocIndex, imageIndex } = documentData[packageIndex][itemIndex];
  const { country, documentType } = filesData[subDocIndex][imageIndex];

  const sendFeedback = () => {
    const docCountry = Countries.find((option) => option.value === country);
    const docType = DocumentTypes.find((option) => option.value === documentType);
    const sendData = {
      country: t(docCountry?.label || ''),
      docType: t(docType?.label || ''),
      trCode,
      agentEmail,
    };
    if (process.env.REACT_APP_ON_PREM !== 'true') SendEmail(sendData);
    const updateDocumentData = getUpdatedData(documentData, packageIndex, itemIndex, {
      hasSpecimen: false,
      specimen: { duid: '', front: '', back: '', extraDetail: '' },
    });
    batchActions([
      dispatch(checkResultActions.setDocumentData(updateDocumentData)),
      dispatch(specimenActions.setSpecimens([])),
      dispatch(commonActions.setQuestion(Questions.imageSource)),
    ]);
  };

  const showModal = (key: string) => {
    if (key === ANSWERS_KEYS.YES) {
      setShowConfirm(!showConfirm);
    }
    if (key === ANSWERS_KEYS.NO) {
      setShowDenyModal(!showDenyModal);
    }
  };

  const confirmModalProps = {
    title: `${t('reg.modal.templateNotFound.yes.title')}`,
    submitButtonText: `${'reg.modal.templateNotFound.yes.confirm'}`,
    cancelButtonText: `${'reg.modal.templateNotFound.yes.back'}`,
    body: (
      <div>
        <p className="message">{t('reg.modal.templateNotFound.yes.modalBody1')}</p>
        <p className="message">{t('reg.modal.templateNotFound.yes.modalBody2')}</p>
      </div>),
    bodyButton: false,
    footerStyle: 'modal-footer-join',
    onHide: () => setShowConfirm(false),
    onSuccess: () => sendFeedback(),
    show: showConfirm,
    isDisabled: false,
  };

  const back = () => {
    dispatch(commonActions.setQuestion(Questions.select));
  };

  return (
    <div>
      <Answers
        back={back}
        answers={page?.answers}
        activeIndexes={[]}
        submitAnswer={(e: any) => showModal(e.key)}
        styled={BUTTON_STYLE.OUTLINE_PRIMARY}
      />
      <BigModal {...confirmModalProps} />
      <DenyModal
        show={showDenyModal}
        setShow={setShowDenyModal}
        setOnBackClick={() => setShowDenyModal(!showDenyModal)}
        Child={<DragonDropList
          sortedData={sortedData}
          setSortedData={setSortedData}
        /> }
        sortedFailsList={sortedData}
      />
    </div>
  );
};

export default NotFound;
