import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DocumentScanContent from 'components/modal/modalContent/DocumentScanContent';
import ImageWrapper from 'components/imageWrapper/ImageWrapper';
import AvailableModalBody from 'components/modal/modalContent/AvailableImagesContent';
import { IRootState } from 'store/types';
import * as customerDataCheckActions from 'store/features/customerDataCheck/actions';
import {
  TFileImage,
  ICustomerDataImage,
  IZipContent,
} from 'store/features/zipContent/types';
import { ETools } from 'enums';

const DocumentScan = () => {
  const state = useSelector((rootState: IRootState) => rootState);
  const dispatch = useDispatch();
  const {
    zipContent,
    customerDataCheck,
  } = state;
  const { customerData = {} } = zipContent as IZipContent;
  const addressPopupShowAgain = state.popupInfo?.documentScanPopupShowAgain;
  const [isModalShowing, setIsModalShowing] = useState(addressPopupShowAgain);
  const additionalDocImages: ICustomerDataImage[] = customerData.additionalDocImages;
  const savedImageName = customerDataCheck.documentScan?.image?.imageName || '';
  const [currentImageName, setCurrentImageName] =
    useState(savedImageName || additionalDocImages[0]?.imageName);
  const [isAvailableModalShowing, setAvailableModalShowing] = useState(false);
  const documentScanPopupShowAgain = state.popupInfo?.documentScanPopupShowAgain;
  const image: ICustomerDataImage = useMemo(() => {
    const imageData = additionalDocImages.find(
      (img: ICustomerDataImage) => img.imageName === currentImageName
    );
    return imageData || { imageName: '', base64: '' };
  }, [currentImageName]);
  const { imageName = '', base64 = '' } = image;

  useEffect(() => {
    dispatch(
      customerDataCheckActions.setAdditionalImage({
        imageName: imageName,
      })
    );
  }, [imageName]);

  const onImageSelect = (imageName: string) => {
    setCurrentImageName(imageName);
    dispatch(customerDataCheckActions.setAdditionalImage({ imageName }));
  };

  const showAvModal = () => {
    if (isModalShowing) return;
    setAvailableModalShowing(true);
  };

  const hideModal = () => {
    if (!isAvailableModalShowing) {
      setIsModalShowing(false);
    } else {
      setAvailableModalShowing(false);
    }
  };

  const images: Array<TFileImage> = additionalDocImages.map(
    (value: ICustomerDataImage) => ({ [value.imageName]: value.base64 })
  );

  const getAvailableBody = () => {
    return <AvailableModalBody
      onSelectImage={onImageSelect}
      images={images}
      selected={currentImageName}
    />;
  };

  const modalProps = [
    {
      ModalBody: getAvailableBody(),
      showAgainType: '',
      setIsModalShowing: hideModal,
      isModalShowing: isAvailableModalShowing,
    },
    {
      ModalBody: DocumentScanContent,
      showAgainType: 'documentScan',
      setIsModalShowing: setIsModalShowing,
      isModalShowing: documentScanPopupShowAgain && isModalShowing,
    },
  ];

  return (
    <div className="main-container">
      { image && <ImageWrapper
        imageSrc={base64}
        onClickChangeImage={showAvModal}
        hasAvailableImages={images.length > 1}
        modalProps={modalProps}
        tools={[ETools.zoomIn, ETools.zoomOut, ETools.rotate, ETools.images]}
      />}
    </div>
  );
};

export default DocumentScan;