import {
  ESelfDeclarationPriorityFields,
  EAddressPriorityFields,
  EExtractedDataPriorityFields,
  EField
} from 'enums';
import { SELF_DECLARATION_EXTRACTED_DIFFERENCE } from 'constants/Static';
import { IRootState } from 'store/types';
import { IZipContent } from 'store/features/zipContent/types';
import { ICheckResult, IDataValidation, TFieldProps } from 'store/features/checkResult/types';
import { valuesIsSame } from 'helper/validation';

const getPriorityFields = (priorityFields: any) => (
  Object.keys(priorityFields).filter((k) => typeof priorityFields[k as any] === 'number')
);

const combineCheckResultData = (
  priorityFields: any,
  fields: TFieldProps,
) => getPriorityFields(priorityFields).reduce((result: { [key in string]: string }, key) => {
  result[key] = fields?.[key]?.viz?.value || fields?.[key]?.mrz?.value || '';
  return result;
}, {});

export const getTableStructure = (
  data: any,
  targetData: any = {},
) => Object.keys(data).reduce((result: any, field: string) => {
  const value = data[field];
  const hasTargetFiled = (targetData?.[field]
    || (SELF_DECLARATION_EXTRACTED_DIFFERENCE[field]
      && targetData?.[SELF_DECLARATION_EXTRACTED_DIFFERENCE[field]])
  );
  let targetField = field;
  if (SELF_DECLARATION_EXTRACTED_DIFFERENCE[field]
    && targetData?.[SELF_DECLARATION_EXTRACTED_DIFFERENCE[field]]
  ) {
    targetField = SELF_DECLARATION_EXTRACTED_DIFFERENCE[field];
  }
  result.push({
    field,
    value,
    isActive:
      field !== EField.address
        ? hasTargetFiled
          ? !valuesIsSame(targetData[targetField], value)
          : !!value
        : false,
  });
  return result;
}, []);

export const getSdDataCheckList = (state: IRootState) => {
  const { zipContent } = state;
  const { customerData = {} } = zipContent as IZipContent;
  const { outputJson = {} } = customerData;
  const { selfDeclaration = {} } = outputJson;
  const initialData = { ...selfDeclaration };
  const { address = {} } = initialData;
  const combinedSelfData = { ...initialData, ...address };
  delete combinedSelfData.address;
  const sortedSelfData = Object.values(ESelfDeclarationPriorityFields).reduce(
    (result: any, item: any) => {
      if (combinedSelfData[item]) {
        result[item] = combinedSelfData[item];
      }
      return result;
    }, {});

  if (!Object.keys(outputJson).length || !Object.keys(sortedSelfData).length) {
    return {};
  }
  const extractedData = state.checkResult?.dataValidation?.reduce(
    (acc: any, item: IDataValidation) => {
      acc.push({
        subDocIndex: item.subDocIndex,
        data: combineCheckResultData(EExtractedDataPriorityFields, item.fields),
      });
      return acc;
    },
    []
  ) || [];

  return { selfDeclaration: sortedSelfData, extractedData };
};

export const validateSelfDeclaration = (state: IRootState) => (
  Object.keys(getSdDataCheckList(state)).length
);

export const validateAddress = (state: IRootState) => {
  const { zipContent } = state;
  const { customerData = {} } = zipContent as IZipContent;
  const { outputJson = {}, utilityImages = [] } = customerData;
  const { selfDeclaration = {} } = outputJson;
  const { address = {} } = selfDeclaration;
  return (
    Object.keys(address).length
    && Boolean(utilityImages?.length)
  );
};

export const validateDocumentScan = (state: IRootState) => (
  Boolean(state?.zipContent?.customerData?.additionalDocImages?.length)
);

export const shouldRenderCDCSummary = (state: IRootState) => (
  validateSelfDeclaration(state)
  || validateAddress(state)
  || validateDocumentScan(state)
)

export const getExtractedData = (checkResult: ICheckResult) => {
  const { dataValidation = [] } = checkResult;
  if (!dataValidation.length) return {};
  const [{ fields }] = dataValidation;
  return combineCheckResultData(EAddressPriorityFields, fields);
};

export const formatOutputData = (data: any) => (
  Object.keys(data).reduce((result: any, item: any) => {
    if (data[item]) {
      result[item] = data[item];
    }
    return result;
  }, {})
);
