import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useFetchZip from 'hooks/useFetchZip';
import Loader from 'components/loader/Loader';
import { IRootState } from 'store/types';
import { IZipContent } from 'store/features/zipContent/types';

const ZipCodeWrapper = (props: any) => {
  const { children } = props;
  const dispatch = useDispatch();
  const state: IRootState = useSelector((rootState: IRootState) => rootState);
  const { trCode = '', subDocsData = [], zipContentLoaded } = state.zipContent as IZipContent;
  useFetchZip(subDocsData, trCode, dispatch);

  if (zipContentLoaded) {
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  }

  return (<>{children}</>);
};

export default ZipCodeWrapper;
