import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { getSdDataCheckList } from 'helper/tools/customerDataCheck';
import * as commonActions from 'store/features/common/actions';
import * as customerDataCheckActions from 'store/features/customerDataCheck/actions';
import { Questions } from 'store/features/common/types';
import { IRootState } from 'store/types';
import Answers from 'components/footer/Answers';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import { ANSWERS_KEYS, BUTTON_STYLE } from 'constants/Static';
import { PATH } from 'constants/Static';
import { EPage } from 'enums';
import { IAnswerOption } from 'types';

const RejectOptions = () => {
  const state = useSelector((rootState: IRootState) => rootState);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const page = HeaderButtons[EPage.userDataCheck]['self-declaration'][Questions.reject];
  const dataCheckList = useMemo(() => getSdDataCheckList(state), []);
  const itemIndex = Number(state.common?.filesSliderPath || 0);
  const subDocIndex = dataCheckList.extractedData[itemIndex]?.subDocIndex || itemIndex;
  const selectedReasons =
    state?.customerDataCheck.selfDeclaration.extractedData?.[subDocIndex]?.reasons || [];
  const [selectedValue, setValue] = useState<any>(
    selectedReasons.map(
      (reason) => ({ value: reason })
    )
  );
  const activeIndexes = selectedValue?.map(
    (reason: any) => {
      return page?.answers?.findIndex((answer: IAnswerOption) => answer.text === reason.value);
    }
  ) || [];


  page?.answers.forEach((answer: any, index: number) => {
    if (selectedValue.length && !activeIndexes.includes(page?.answers.length - 1)) {
      activeIndexes.push(page?.answers.length - 1);
    }
    if (selectedValue.find((item: any) => item.value === answer.text)) {
      activeIndexes.push(index);
    }
  });

  const back = () => {
    dispatch(commonActions.setQuestion(Questions.approve));
  };

  const submitAnswer = (answer: IAnswerOption) => {
    let nextIndex = 0;
    const isExist = selectedValue.find((item: any) => item.value === answer.text);
    if (isExist) {
      setValue(selectedValue.filter((item: any) => item.value !== answer.text));
    } else {
      setValue([{ value: answer.text }]);
    }
    if (answer.key === ANSWERS_KEYS.CONFIRM) {
      const reasonData = selectedValue.map((item: any)=> item.value);
      dispatch(customerDataCheckActions.setExtractedResult({
        subDocIndex,
        result: ANSWERS_KEYS.REJECTED,
        reasons: reasonData
      }));
      if (state?.customerDataCheck?.selfDeclaration?.extractedData) {
        const lastIndex = Object.keys(dataCheckList?.extractedData).length - 1;
        if (itemIndex < lastIndex) {
          nextIndex = itemIndex + 1;
        } else {
          nextIndex = dataCheckList?.extractedData[lastIndex + 1]?.index;
        }
        if (nextIndex) {
          dispatch(commonActions.setActiveSlidePath(String(nextIndex)));
          dispatch(commonActions.setQuestion(Questions.approve));
        } else {
          dispatch(commonActions.setForcedRejectedInfo({
            isForcedRejected: true,
            location: location.pathname,
            question: Questions.reject,
          }));
          history.replace(PATH.SUMMARY_CUSTOMER_DATA_CHECK);
        }
      }
    }
  };

  return (
    <Answers
      back={back}
      answers={page?.answers}
      activeIndexes={activeIndexes}
      submitAnswer={submitAnswer}
      disabled={!selectedValue.length ? [(page?.answers || []).length - 1] : []}
      styled={BUTTON_STYLE.OUTLINE_SECONDARY}
    />
  );
};

export default RejectOptions;
