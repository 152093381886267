
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import RightMenu from 'components/menuSide/RightMenu';
import List from 'components/menuSide/List';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import InfoBar from 'components/infoBar/InfoBar';
import Footer from 'components/footer/Footer';
import Face from 'pages/quickCheck/face/Face';
import { IRootState } from 'store/types';
import { Questions } from 'store/features/common/types';
import { EPage } from 'enums';
import BurgerMenu from 'components/burgerMenu/BurgerMenu';

const FaceComponent = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const subDocsData = state.zipContent?.subDocsData || [];
  const question = state.common?.question || Questions.quality;
  const page = HeaderButtons[EPage.quickCheck].face[question];
  const rightMenuProps: Array<React.ReactNode> = [];
  const rightMenuContent: Array<React.ReactNode> = [];

  subDocsData.map(() => (
    rightMenuContent.push(
      <p><b>{t('reg.face.list-document-photo')}</b>{t('reg.list-item-text-document')}</p>
    )
  ));

  rightMenuContent.push(
    <p>
      <b> {t('reg.list-item-text-avatar')} </b>
      {t('reg.list-item-text-selfie-video')}
    </p>
  );

  rightMenuProps.push(
    <List
      header={t('reg.face.list-header')}
      content={rightMenuContent}
      key={t('reg.face.list-header') + 'face'}
    />
  );

  return <>
    <InfoBar />
    <>
      <BurgerMenu />
      <h3>{t(page?.heading || '')}</h3>
    </>
    <div className="check-content">
      <div className='extra-space-menu-container' />
      <Face />
      <RightMenu children={rightMenuProps} />
    </div>
    <Footer NestedComponent={page?.answerComponent} />
  </>;
};

export default FaceComponent;