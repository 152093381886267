import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Countries from 'constants/Countries.json';
import DocumentTypes from 'constants/DocumentTypes.json';
import YourSelection from 'components/yourSelection/YourSelection';
import { Questions } from 'store/features/common/types';
import List from 'components/menuSide/List';
import { IRootState } from 'store/types';
import { ICheckResult, IDocumentData, IFilesData } from 'store/features/checkResult/types';
import { getPackagePosition } from 'helper/tools/fraudCheck';

interface IRightMenuProps {
  fileData: IFilesData,
}

const RightMenu = (props: IRightMenuProps) => {
  const { fileData } = props;
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult as ICheckResult;
  const { documentData = [], filesData = [] } = checkResult;
  const filesSliderPath = state.common?.filesSliderPath || '';
  const prevPagePath = state.common?.prevPagePath || '';

  const { packageIndex } = useMemo(() => (
    getPackagePosition(filesSliderPath, prevPagePath, documentData)
  ), [filesSliderPath, prevPagePath]);
  const {
    country,
    documentType,
    extraDetail,
    imageSide,
    reviewResult,
  } = fileData;

  documentData[packageIndex]?.map((item: IDocumentData, index: number) => {
    return (<span key={index}>
      {`(${imageSide.toUpperCase()}) - ${t(reviewResult.quality)} \n`}
      {extraDetail
        && `${t('reg.your.selection.title.subtype')} - ${extraDetail}`}
    </span>);
  });

  const getCountry = () => {
    const countryValue = Countries.find((el) => el.value === country);
    if (!countryValue) return '';
    return `${countryValue.value} - ${t(countryValue.label)}`;
  };

  const getDocumentType = () => {
    const docType = DocumentTypes.find((el) => el.value === documentType);
    if (!docType) return '';
    return t(docType.label);
  };

  const yourSelectionProps = [
    { title: 'reg.your.selection.title.country', value: getCountry() },
    { title: 'reg.your.selection.title.type', value: getDocumentType() },
    { title: 'reg.your.selection.title.subtype', value: extraDetail },
  ];

  const images = documentData[packageIndex].filter(({ question }) => (
    question === Questions.imageSource
  ));

  const listContent: Array<React.ReactNode> = images?.map((subDoc, index) => {
    const { subDocIndex, imageIndex } = subDoc;
    const { pageSideKey, reviewResult } = filesData[subDocIndex][imageIndex];
    return (
      <span key={index}>
        {`${t(pageSideKey)} - ${t(reviewResult.quality)}`}
      </span>
    );
  });

  return <div className="right-menu-container">
    <div className="right-menu-content template-right-menu">
      <div className='menu-image-container'>
        <div className='right-menu-list-container'>
          <List
            header={t('reg.template.list.header')}
            content={listContent}
          />
        </div>
      </div>
      <div className="your-selection-container">
        <YourSelection selectionData={yourSelectionProps} />
      </div>
    </div>
  </div>;
};

export default RightMenu;
