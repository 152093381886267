import React from 'react';
import { useTranslation } from 'react-i18next';

import { TFileImage } from 'store/features/zipContent/types';
import { getImageFromBase64 } from 'util/common';

interface IAvailableImagesContentProps {
  onSelectImage: Function;
  images: TFileImage[];
  selected?: string;
}

const AvailableImagesContent = (props: IAvailableImagesContentProps) => {
  const { t } = useTranslation();
  const {
    images,
    selected = '',
    onSelectImage,
  } = props;

  return (
    <>
      <div className="image-slider-header">
        <p className="scrub-text-style">{t('reg.comparation.modal.availableTitle')}</p>
      </div>
      <div className="available-image-container">
        {images.map((imageItem) => {
          const imageName = String(Object.keys(imageItem)[0]);
          const imageSrc = String(Object.values(imageItem)[0]);
          return (
            <div
              className={`image-container ${selected === imageName ? 'selected' : ''}`}
              key={imageName}
              onClick={() => onSelectImage(imageName)}
            >
              <img
                className="available-image"
                alt={t('reg.comparation.modal.availableImg')}
                src={getImageFromBase64(imageSrc)}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AvailableImagesContent;
