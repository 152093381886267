import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const AccordionStatus = (props: any) => {
  const { handleClick: sendStatus, statusFields: statusListAccordionFields } = props;
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    if (statusListAccordionFields) {
      setIsShow(!isShow);
      sendStatus(isShow);
    }
  }, [statusListAccordionFields]);

  return (
    <div className="left-menu-container-hide-panels">
      {
        isShow ?
          <p
            className="hide-panels"
            onClick={() => {
              sendStatus(isShow);
              setIsShow(false);
            }}>
            {t('reg.left-menu.accordion-show')}
          </p> :
          <p className="hide-panels"
            onClick={() => {
              sendStatus(isShow);
              setIsShow(true);
            }}>
            {t('reg.left-menu.accordion-hide')}
          </p>
      }
    </div>
  );
};

export default AccordionStatus;