import React from 'react';
import { useTranslation } from 'react-i18next';

import { getImageFromBase64 } from 'util/common';
import NoImage from 'images/no-image.png';
import ToolTipField from 'components/toolTipField/ToolTipField';
import { IDrawFilledDataWithImagesProps } from 'types';

const DrawFilledDataWithImages = (props: IDrawFilledDataWithImagesProps) => {
  const { t } = useTranslation();
  const {
    imageTitle = '',
    image = '',
    pageIndex = '',
    icon = null,
    status = '',
    rightBlockTitleStyle = '',
    optionTitleStyle = '',
    optionTitle = '',
    options = [],
    type = '',
    subDocsData = [],
    avatarImage = '',
  } = props;

  const getImageBlock = () => {
    if (type === 'face' && subDocsData.length > 0) {
      const faceImages = subDocsData.map((subDoc: any, index: number) => {
        const faceImage: string = (subDoc && subDoc.avatar) || '';
        const label = `${t('reg.summary.photo')} ${index + 1}`;
        return <div className="photo-section" key={index}>
          <span className="text-section">{label}</span>
          <img
            className="face-image-style"
            src={faceImage ? getImageFromBase64(faceImage) : NoImage}
            alt="img" />
        </div>;
      });
      return (
        <>
          {faceImages}
          <div className="photo-section">
            <span className="text-section">{t('reg.summary.avatar')}</span>
            <img
              className="face-image-style"
              src={avatarImage}
              alt="img" />
          </div>
        </>
      );
    } else if (image) {
      return (
        <>
          <div className="image-container-quick-check">
            <img className="image-style" src={image} alt="img" />
          </div>
          {imageTitle && <p className="title">{t(imageTitle)}</p>}
        </>
      );
    }
    return <></>;
  };

  return (
    <div className="accordion-sub-body-row row" key={`accSubBody_${pageIndex}`}>
      <div className="image-block">
        {getImageBlock()}
      </div>
      <div className={`title ${optionTitleStyle}`}>
        {optionTitleStyle && <span className="reasons-label">{optionTitle}</span>}
        {
          typeof options === typeof [] && options.length > 0
          && <div className={rightBlockTitleStyle}>
            {
              options?.map((reason: string, index: number) => (
                <div className={status} key={`${status}_${index}`}>
                  <ToolTipField
                    tooltipValue={t(reason)}
                    placement="top"
                    className="field-text"
                  >
                    <div className="field-text">{t(reason)}</div>
                  </ToolTipField>
                </div>
              ))
            }
          </div>
        }
        {icon && <span className="right-icon">{icon}</span>}
      </div>
    </div >
  );
};

export default DrawFilledDataWithImages;
