import DocumentPage from 'pages/quickCheck/files/answers/DocumentPage';
import DocumentQuality from 'pages/quickCheck/files/answers/DocumentQuality';
import QualityOptions from 'pages/quickCheck/files/answers/QualityOptions';
import DocumentCountry from 'pages/quickCheck/files/answers/DocumentCountry';
import DocumentCompare from 'pages/quickCheck/files/answers/DocumentCompare';
import Comparing from 'pages/quickCheck/face/answers/Comparing';
import ReasonOptions from 'pages/quickCheck/face/answers/ReasonOptions';
import ComparingFields from 'pages/quickCheck/data/answers/ComparingFields';
import Selection from 'pages/fraudCheck/pages/specimenDetection/answers/Selection';
import NotFound from 'pages/fraudCheck/pages/specimenDetection/answers/NotFound';
import Compare from 'pages/fraudCheck/pages/specimenDetection/answers/Compare';
import VideoQuality from 'pages/fraudCheck/liveness/answers/VideoQuality';
import VideoQualityOptions from 'pages/fraudCheck/liveness/answers/VideoQualityOptions';
import PagesQuality from 'pages/fraudCheck/pages/pageDetection/answers/PagesQuality';
import PagesQualityOptions from 'pages/fraudCheck/pages/pageDetection/answers/PagesQualityOptions';
import CheckFields from 'pages/dataCheck/validation/answers/CheckFields';
import CompareDate from 'pages/quickCheck/expiryDate/answers/CompareDate';
import SDCheckApprove from 'pages/customerDataCheck/selfDeclaration/answers/Approve';
import SDCheckRejectOptions from 'pages/customerDataCheck/selfDeclaration/answers/RejectOptions';
import AddressApprove from 'pages/customerDataCheck/address/answers/Approve';
import AddressRejectOptions from 'pages/customerDataCheck/address/answers/RejectOptions';
import DocumentScanApprove from 'pages/customerDataCheck/documentScan/answers/Approve';
import DocumentScanRejectOptions from 'pages/customerDataCheck/documentScan/answers/RejectOptions';
import { ANSWERS_KEYS, BUTTON_TYPE } from 'constants/Static';
import { getExpiryDate } from 'helper/tools/commonHelper';
import { EDocumentType, EImageSide, EPage, EReason } from 'enums';
import { Questions } from 'store/features/common/types';
import { IAnswerOption } from 'types';

export type THeaderButtons = {
  [page in EPage]: {
    [subPage in string]: {
      [question in Questions]?: {
        heading: string;
        question?: string;
        reason?: EReason;
        description: Function;
        answers: IAnswerOption[];
        answerComponent: (props: any) => React.JSX.Element;
      }
    }
  }
}

export const HeaderButtons: THeaderButtons = {
  [EPage.quickCheck]: {
    files: {
      [Questions.countryDocType]: {
        heading: 'reg.files.countryDocType.heading',
        question: 'reg.files.countryDocType.question',
        description: () => [{ text: 'reg.files.countryDocType.description' }],
        answers: [{
          text: 'reg.files.countryDocType.answers.not-in-list',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.NOT_IN_LIST,
        }, {
          text: 'reg.files.countryDocType.answers.next',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.NEXT,
        }],
        answerComponent: DocumentCountry,
      },
      [Questions.documentPage]: {
        heading: 'reg.files.documentPage.heading',
        question: 'reg.files.documentPage.question',
        description: () => [{ text: 'reg.files.documentPage.description' }],
        answers: [{
          text: 'reg.files.documentPage.answers.front',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: `${EImageSide.front}-0`,
          allowedDocumentTypes: [
            EDocumentType.id,
            EDocumentType.plasticPermit,
            EDocumentType.drivingLicence,
          ],
        }, {
          text: 'reg.files.documentPage.answers.back',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: `${EImageSide.back}-1`,
          allowedDocumentTypes: [
            EDocumentType.id,
            EDocumentType.plasticPermit,
            EDocumentType.drivingLicence,
          ],
        }, {
          text: 'reg.files.documentPage.answers.data',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: `${EImageSide.back}-2`,
          allowedDocumentTypes: [EDocumentType.paperPermit, EDocumentType.healthCard],
        }, {
          text: 'reg.files.documentPage.answers.data',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: `${EImageSide.front}-3`,
          allowedDocumentTypes: [EDocumentType.passport],
        }, {
          text: 'reg.files.documentPage.answers.photo',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: `${EImageSide.front}-4`,
          allowedDocumentTypes: [EDocumentType.paperPermit],
        }, {
          text: 'reg.files.documentPage.answers.signature',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: `${EImageSide.signature}-5`,
          allowedDocumentTypes: [],
        }, {
          text: 'reg.files.documentPage.answers.other',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: `${EImageSide.other}-6`,
          allowedDocumentTypes: [],
        }, {
          text: 'reg.files.documentPage.answers.confirm',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.CONFIRM,
          allowedDocumentTypes: [],
        }],
        answerComponent: DocumentPage,
      },
      [Questions.quality]: {
        heading: 'reg.files.quality.heading',
        question: 'reg.files.quality.question',
        description: () => [{ text: 'reg.files.quality.description' }],
        answers: [{
          text: 'reg.files.quality.answers.fraud',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: Questions.fraud,
        }, {
          text: 'reg.files.quality.answers.unsure',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: Questions.unsure,
        }, {
          text: 'reg.files.quality.answers.good-to-use',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.GOOD_TO_USE,
        }],
        answerComponent: DocumentQuality,
      },
      [Questions.fraud]: {
        heading: 'reg.files.fraud.heading',
        question: 'reg.files.fraud.question',
        description: () => [{ text: 'reg.files.fraud.description' }],
        reason: EReason.other,
        answers: [{
          text: 'reg.files.fraud.answers.pasted-photo',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-0',
        }, {
          text: 'reg.files.fraud.answers.manipulate-data',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-1',
        }, {
          text: 'reg.files.fraud.answers.printed',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-2',
        }, {
          text: 'reg.files.fraud.answers.screen-capture',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-3',
        }, {
          text: 'reg.files.fraud.answers.wired-holograms',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-4',
        }, {
          text: 'reg.files.fraud.answers.i-can-not-describe',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-5',
        }, {
          text: 'reg.files.fraud.answers.confirm',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.CONFIRM,
        }],
        answerComponent: QualityOptions,
      },
      [Questions.unsure]: {
        heading: 'reg.files.unsure.heading',
        question: 'reg.files.unsure.question',
        reason: EReason.other,
        description: () => [{ text: 'reg.files.unsure.description' }],
        answers: [{
          text: 'reg.files.unsure.answers.to-blurry',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-0',
        }, {
          text: 'reg.files.unsure.answers.bad-light',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-1',
        }, {
          text: 'reg.files.unsure.answers.bad-angle',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-2',
        }, {
          text: 'reg.files.unsure.answers.top-cutter',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-3',
        }, {
          text: 'reg.files.unsure.answers.different-document',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-4',
        }, {
          text: 'reg.files.unsure.answers.not-a-document',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-5',
        }, {
          text: 'reg.files.unsure.answers.confirm',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.CONFIRM,
        }],
        answerComponent: QualityOptions,
      },
      [Questions.comparing]: {
        heading: 'reg.files.compareDocuments.heading',
        question: 'reg.files.compareDocuments.question',
        description: () => [{}],
        answers: [{
          text: 'reg.files.compareDocuments.answers.yes',
          key: ANSWERS_KEYS.YES,
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
        }, {
          text: 'reg.files.compareDocuments.answers.no',
          key: ANSWERS_KEYS.NO,
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
        }],
        answerComponent: DocumentCompare,
      },
    },
    expiryDate: {
      [Questions.comparing]: {
        heading: 'reg.date.heading',
        question: 'reg.date.comparing.question',
        description: (state: any) => [{
          text: 'reg.date.comparing.description.message1',
        }, {
          text: `${getExpiryDate(state)}.`,
          type: 'bold',
        }, {
          text: 'reg.date.comparing.description.message2',
        }, {
          text: 'reg.date.comparing.description.message3',
          type: 'link',
        }, {
          text: 'reg.date.comparing.description.message4',
        }],
        answers: [{
          text: 'reg.date.answers.no',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.NO,
        }, {
          text: 'reg.date.answers.yes',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.YES,
        }],
        answerComponent: CompareDate,
      },
    },
    face: {
      [Questions.comparing]: {
        heading: 'reg.face.comparing.heading',
        question: 'reg.face.comparing.question',
        description: () => [{
          text: 'reg.face.comparing.description.condition',
        }, {
          text: 'reg.face.comparing.description.different',
          type: 'bold',
        }],
        answers: [{
          text: 'reg.face.answers.no',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: Questions.fraud,
        }, {
          text: 'reg.face.answers.notSure',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: Questions.unsure,
        }, {
          text: 'reg.face.answers.yes',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.YES,
        }],
        answerComponent: Comparing,
      },
      [Questions.unsure]: {
        heading: 'reg.face.unsure.heading',
        question: 'reg.face.unsure.question',
        description: () => [{ text: 'reg.face.unsure.description' }],
        reason: EReason.faceVerificationFail,
        answers: [{
          text: 'reg.face.unsure.answers.partly-covered-face',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-0',
        }, {
          text: 'reg.face.unsure.answers.covid-mask',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-1',
        }, {
          text: 'reg.face.unsure.answers.look-has-changed',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-2',
        }, {
          text: 'reg.face.unsure.answers.can-not-describe',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-3',
        }, {
          text: 'reg.face.unsure.answers.get-2nd-opinion',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-4',
        }, {
          text: 'reg.face.unsure.answers.confirm',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.CONFIRM,
        }],
        answerComponent: ReasonOptions,
      },
      [Questions.fraud]: {
        heading: 'reg.face.fraud.heading',
        question: 'reg.face.fraud.question',
        description: () => [{ text: 'reg.face.fraud.description' }],
        reason: EReason.faceVerificationFail,
        answers: [{
          text: 'reg.face.fraud.answers.user-is-masked',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-0',
        }, {
          text: 'reg.face.fraud.answers.paper-mask',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-1',
        }, {
          text: 'reg.face.fraud.answers.photo-is-tucked',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-2',
        }, {
          text: 'reg.face.fraud.answers.different-persons',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-3',
        }, {
          text: 'reg.face.fraud.answers.can-not-describe',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-4',
        }, {
          text: 'reg.face.fraud.answers.get-2nd-opinion',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-5',
        }, {
          text: 'reg.face.fraud.answers.confirm',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.CONFIRM,
        }],
        answerComponent: ReasonOptions,
      },
    },
    data: {
      [Questions.comparing]: {
        heading: 'reg.data.comparing.heading',
        question: 'reg.data.comparing.question',
        description: () => [{
          text: 'reg.data.comparing.description.condition',
        },
        {
          text: 'reg.data.comparing.description.same',
          type: 'bold',
        }],
        reason: EReason.other,
        answers: [{
          text: 'reg.data.comparing.answers.different',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.DIFFERENT,
        }, {
          text: 'reg.data.comparing.answers.same',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.SAME,
        }],
        answerComponent: ComparingFields,
      },
    },
  },
  [EPage.fraudCheck]: {
    liveness: {
      [Questions.quality]: {
        heading: 'reg.liveness.quality.heading',
        question: 'reg.liveness.quality.question',
        description: () => [{ text: 'reg.liveness.quality.description' }],
        answers: [{
          text: 'reg.liveness.quality.answers-is-fraud',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: Questions.fraud,
        }, {
          text: 'reg.liveness.quality.not-sure',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: Questions.unsure,
        }, {
          text: 'reg.liveness.quality.looks-real',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.LOOKS_REAL,
        }],
        answerComponent: VideoQuality,
      },
      [Questions.fraud]: {
        heading: 'reg.liveness.fraud.heading',
        question: 'reg.liveness.fraud.question',
        description: () => [{ text: 'reg.liveness.fraud.description' }],
        reason: EReason.videoAttack,
        answers: [{
          text: 'reg.liveness.fraud.answers.paper-mask',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-0'
        }, {
          text: 'reg.liveness.fraud.answers.is-masked',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-1'
        }, {
          text: 'reg.liveness.fraud.answers.video-capture',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-2'
        }, {
          text: 'reg.liveness.fraud.answers.print-capture',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-3'
        }, {
          text: 'reg.liveness.fraud.answers.no-human-face',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-4'
        }, {
          text: 'reg.liveness.fraud.answers.can-not-describe',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-5'
        }, {
          text: 'reg.liveness.fraud.answers.confirm',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.CONFIRM,
        }],
        answerComponent: VideoQualityOptions,
      },
      [Questions.unsure]: {
        heading: 'reg.liveness.unsure.heading',
        question: 'reg.liveness.unsure.question',
        description: () => [{ text: 'reg.liveness.unsure.description' }],
        reason: EReason.videoBadQuality,
        answers: [{
          text: 'reg.liveness.unsure.answers.blurry',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-0'
        }, {
          text: 'reg.liveness.unsure.answers.bad-lightning',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-1'
        }, {
          text: 'reg.liveness.unsure.answers.covid-Mask',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-2'
        }, {
          text: 'reg.liveness.unsure.answers.partially-covered',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-3'
        }, {
          text: 'reg.liveness.unsure.answers.video-not-playing',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-4'
        }, {
          text: 'reg.liveness.unsure.answers.can-not-describe',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-5'
        }, {
          text: 'reg.liveness.unsure.answers.confirm',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.CONFIRM,
        }],
        answerComponent: VideoQualityOptions,
      },
    },
    document: {
      [Questions.imageSource]: {
        heading: 'fraud-check.document.imageSource.quality.heading',
        question: 'fraud-check.document.imageSource.quality.question',
        description: () => [{ text: 'fraud-check.document.imageSource.quality.description' }],
        answers: [{
          text: 'fraud-check.document.imageSource.quality.answers.fraud',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: Questions.fraud,
        }, {
          text: 'fraud-check.document.imageSource.quality.answers.unsure',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: Questions.unsure,
        }, {
          text: 'fraud-check.document.imageSource.quality.answers.looksReal',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.LOOKS_REAL,
        }],
        answerComponent: PagesQuality,
      },
      [Questions.fraud]: {
        heading: 'fraud-check.document.imageSource.fraud.heading',
        question: 'fraud-check.document.imageSource.fraud.question',
        description: () => [{ text: 'fraud-check.document.imageSource.fraud.description' }],
        reason: EReason.other,
        answers: [{
          text: 'fraud-check.document.imageSource.fraud.answers.option-1',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-0',
        }, {
          text: 'fraud-check.document.imageSource.fraud.answers.option-2',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-1',
        }, {
          text: 'fraud-check.document.imageSource.fraud.answers.option-3',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-2',
        }, {
          text: 'fraud-check.document.imageSource.fraud.answers.option-4',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-3',
        }, {
          text: 'fraud-check.document.imageSource.fraud.answers.confirm',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.CONFIRM,
        }],
        answerComponent: PagesQualityOptions,
      },
      [Questions.unsure]: {
        heading: 'fraud-check.document.imageSource.unsure.heading',
        question: 'fraud-check.document.imageSource.unsure.question',
        description: () => [{ text: 'fraud-check.document.imageSource.unsure.description' }],
        reason: EReason.other,
        answers: [{
          text: 'fraud-check.document.imageSource.unsure.answers.option-1',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-0',
        }, {
          text: 'fraud-check.document.imageSource.unsure.answers.option-2',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-1',
        }, {
          text: 'fraud-check.document.imageSource.unsure.answers.option-3',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-2',
        }, {
          text: 'fraud-check.document.imageSource.unsure.answers.option-4',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-3',
        }, {
          text: 'fraud-check.document.imageSource.unsure.answers.confirm',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.CONFIRM,
        }],
        answerComponent: PagesQualityOptions,
      },
      [Questions.idRecording]: {
        heading: 'fraud-check.document.id-recording.quality.heading',
        question: 'fraud-check.document.id-recording.quality.question',
        description: () => [{ text: 'fraud-check.document.id-recording.quality.description' }],
        answers: [{
          text: 'fraud-check.document.id-recording.answers.fraud',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: Questions.idRecordingFraud,
        }, {
          text: 'fraud-check.document.id-recording.answers.unsure',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: Questions.idRecordingUnsure,
        }, {
          text: 'fraud-check.document.id-recording.answers.looksReal',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.LOOKS_REAL,
        }],
        answerComponent: PagesQuality,
      },
      [Questions.idRecordingFraud]: {
        heading: 'fraud-check.document.id-recording.fraud.heading',
        question: 'fraud-check.document.id-recording.fraud.question',
        description: () => [{ text: 'fraud-check.document.id-recording.fraud.description' }],
        reason: EReason.other,
        answers: [{
          text: 'fraud-check.document.id-recording.fraud.answers.option-1',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-0',
        }, {
          text: 'fraud-check.document.id-recording.fraud.answers.option-2',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-1',
        }, {
          text: 'fraud-check.document.id-recording.fraud.answers.option-3',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-2',
        }, {
          text: 'fraud-check.document.id-recording.fraud.answers.option-4',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-3',
        }, {
          text: 'fraud-check.document.id-recording.fraud.confirm',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.CONFIRM,
        }],
        answerComponent: PagesQualityOptions,
      },
      [Questions.idRecordingUnsure]: {
        heading: 'fraud-check.document.id-recording.unsure.heading',
        question: 'fraud-check.document.id-recording.unsure.question',
        description: () => [{ text: 'fraud-check.document.id-recording.unsure.description' }],
        reason: EReason.other,
        answers: [{
          text: 'fraud-check.document.id-recording.unsure.answers.option-1',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-0',
        }, {
          text: 'fraud-check.document.id-recording.unsure.answers.option-2',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-1',
        }, {
          text: 'fraud-check.document.id-recording.unsure.answers.option-3',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-2',
        }, {
          text: 'fraud-check.document.id-recording.unsure.answers.option-4',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-3',
        }, {
          text: 'fraud-check.document.id-recording.unsure.answers.confirm',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.CONFIRM,
        }],
        answerComponent: PagesQualityOptions,
      },
      [Questions.select]: {
        heading: 'reg.template.subType.heading',
        question: 'reg.template.subType.question',
        description: () => [{ text: 'reg.template.subType.description' }],
        answers: [
          {
            text: 'reg.template.subType.not-fount',
            buttonType: BUTTON_TYPE.OPTION_BUTTON,
            key: ANSWERS_KEYS.NOT_FOUND,
          },
          {
            text: 'reg.template.subType.select',
            buttonType: BUTTON_TYPE.OPTION_BUTTON,
            key: ANSWERS_KEYS.SELECT,
          },
        ],
        answerComponent: Selection,
      },
      [Questions.notFound]: {
        heading: 'reg.template.quality.heading',
        question: 'reg.template.quality.question',
        description: () => [{ text: 'reg.template.quality.description' }],
        answers: [
          {
            text: 'reg.template.quality.no',
            buttonType: BUTTON_TYPE.OPTION_BUTTON,
            key: ANSWERS_KEYS.NO,
          },
          {
            text: 'reg.template.quality.yes',
            buttonType: BUTTON_TYPE.OPTION_BUTTON,
            key: ANSWERS_KEYS.YES,
          },
        ],
        answerComponent: NotFound,
      },
      comparing: {
        heading: 'reg.files.compareTemplate.heading',
        question: 'reg.files.compareTemplate.question',
        description: () => [],
        answers: [{
          text: 'reg.files.compareTemplate.answers.reject',
          key: ANSWERS_KEYS.REJECTED,
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
        }],
        answerComponent: Compare,
      },
    },
  },
  [EPage.dataCheck]: {
    'document-data-validation': {
      [Questions.fields]: {
        heading: 'reg.data-check.validation.fields.heading',
        description: () => [{}],
        answers: [{
          text: 'reg.data-check.validation.fields.answers.is-correct',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.IS_CORRECT,
        }, {
          text: 'reg.data-check.validation.fields.answers.confirm-all',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.CONFIRM_ALL,
        }],
        answerComponent: CheckFields,
      },
    },
  },
  [EPage.userDataCheck]: {
    'self-declaration': {
      [Questions.approve]: {
        heading: 'user-data-check.self-declaration.approve.heading',
        question: 'user-data-check.self-declaration.approve.question',
        description: () => [{ text: 'user-data-check.self-declaration.approve.description' }],
        answers: [{
          text: 'user-data-check.self-declaration.approve.answers.reject',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.REJECTED,
        }, {
          text: 'user-data-check.self-declaration.approve.answers.continue',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.APPROVED,
        }],
        answerComponent: SDCheckApprove,
      },
      [Questions.reject]: {
        heading: 'user-data-check.self-declaration.reject.heading',
        question: 'user-data-check.self-declaration.reject.question',
        description: () => [{ text: 'user-data-check.self-declaration.reject.description' }],
        reason: EReason.other,
        answers: [{
          text: 'user-data-check.self-declaration.reject.answers.option-1',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-1',
        }, {
          text: 'user-data-check.self-declaration.reject.answers.option-2',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-2',
        }, {
          text: 'user-data-check.self-declaration.reject.answers.option-3',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-3',
        }, {
          text: 'user-data-check.self-declaration.reject.answers.option-4',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-4',
        }, {
          text: 'user-data-check.self-declaration.reject.answers.confirm',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.CONFIRM,
        }],
        answerComponent: SDCheckRejectOptions,
      },
    },
    address: {
      [Questions.approve]: {
        heading: 'user-data-check.address.approve.heading',
        question: 'user-data-check.address.approve.question',
        description: () => [{ text: 'user-data-check.address.approve.description' }],
        answers: [{
          text: 'user-data-check.address.approve.answers.reject',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.REJECTED,
        }, {
          text: 'user-data-check.address.approve.answers.continue',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.APPROVED,
        }],
        answerComponent: AddressApprove,
      },
      [Questions.reject]: {
        heading: 'user-data-check.address.reject.heading',
        question: 'user-data-check.address.reject.question',
        description: () => [{ text: 'user-data-check.address.reject.description' }],
        answers: [{
          text: 'user-data-check.address.reject.answers.option-1',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-1',
        }, {
          text: 'user-data-check.address.reject.answers.option-2',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-2',
        }, {
          text: 'user-data-check.address.reject.answers.option-3',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-3',
        }, {
          text: 'user-data-check.address.reject.answers.option-4',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-4',
        }, {
          text: 'user-data-check.address.reject.answers.confirm',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.CONFIRM,
        }],
        answerComponent: AddressRejectOptions,
      },
    },
    'document-scan': {
      [Questions.approve]: {
        heading: 'user-data-check.document-scan.approve.heading',
        question: 'user-data-check.document-scan.approve.question',
        description: () => [{ text: 'user-data-check.document-scan.approve.description' }],
        answers: [{
          text: 'user-data-check.document-scan.approve.answers.reject',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.REJECTED,
        }, {
          text: 'user-data-check.document-scan.approve.answers.continue',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.APPROVED,
        }],
        answerComponent: DocumentScanApprove,
      },
      [Questions.reject]: {
        heading: 'user-data-check.document-scan.reject.heading',
        question: 'user-data-check.document-scan.reject.question',
        description: () => [{ text: 'user-data-check.document-scan.reject.description' }],
        answers: [{
          text: 'user-data-check.document-scan.reject.answers.option-1',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-0',
        }, {
          text: 'user-data-check.document-scan.reject.answers.option-2',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-1',
        }, {
          text: 'user-data-check.document-scan.reject.answers.option-3',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-2',
        }, {
          text: 'user-data-check.document-scan.reject.answers.option-4',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: 'option-3',
        }, {
          text: 'user-data-check.document-scan.reject.answers.confirm',
          buttonType: BUTTON_TYPE.OPTION_BUTTON,
          key: ANSWERS_KEYS.CONFIRM,
        }],
        answerComponent: DocumentScanRejectOptions,
      },
    },
  },
};
