
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { IRootState } from 'store/types';
import VideoPlayer from 'components/videoPlayer/VideoPlayer';
import Modal from 'components/modal/Modal';
import LivenessModalBody from 'components/modal/modalContent/LivenessContent';
import { ICheckResult } from 'store/features/checkResult/types';
import { getPackagePosition } from 'helper/tools/fraudCheck';
import { IZipContent } from 'store/features/zipContent/types';
import { PATH } from 'constants/Static';

const IdRecordingComponent = () => {
  const state = useSelector((rootState: IRootState) => rootState);
  const { trCode = '' } = state.zipContent as IZipContent;
  const [isModalShowing, setIsModalShowing] = useState(true);
  const livenessPopupShowAgain = state.popupInfo?.livenessPopupShowAgain;
  const checkResult = state.checkResult as ICheckResult;
  const { documentData = [] } = checkResult;
  const filesSliderPath = state.common?.filesSliderPath || '';
  const prevPagePath = state.common?.prevPagePath || '';

  const {
    itemIndex,
    packageIndex,
  } = useMemo(() => (
    getPackagePosition(filesSliderPath, prevPagePath, documentData)
  ), [filesSliderPath, prevPagePath]);
  const { entryName = '' } = documentData[packageIndex][itemIndex];

  return (
    <>
      <div className="recording-container">
        <div className="video-player-section">
          <VideoPlayer entryName={entryName} trCode={trCode} path={PATH.FRAUD_CHECK_DOCUMENT} />
          {livenessPopupShowAgain && isModalShowing
            && (
              <Modal
                ModalBody={LivenessModalBody}
                showAgainType={'liveness'}
                setIsModalShowing={setIsModalShowing}
                isModalShowing={isModalShowing}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default IdRecordingComponent;