import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ISingleButtonProps } from 'types';
import { getSDKFails, getDocType } from 'util/helperSDKVerification';
import * as commonActions from 'store/features/common/actions';
import { IRootState } from 'store/types';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE, PATH } from 'constants/Static';
import {
  hasDocumentForCheck,
  translateDocTypeCountry,
} from 'helper/tools/commonHelper';
import { getStorageData } from 'util/storageHelper';
import { ReactComponent as BackButton } from 'images/right.svg';
import Loader from 'components/loader/Loader';
import { Questions } from 'store/features/common/types';
import { IZipContent } from 'store/features/zipContent/types';

const {
  DASHBOARD,
  QUICK_CHECK_FILES,
  FRAUD_CHECK_LIVENESS,
} = PATH;

const TABoarding = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((rootState: IRootState) => rootState);
  const { trCode = '' } = state.zipContent as IZipContent;
  const name = state.userInfo?.name || '';
  const subDocsData = state.zipContent?.subDocsData || [];
  const isReviewState = state.common?.isReviewState;
  const [isActiveStartButton, setIsActiveStartButton] = useState(true);
  const docTypes: any[] = subDocsData.map((subDoc) => (
    subDoc.dataXML && getDocType(subDoc.documentTypeData)));
  const data: string | null = getStorageData('state');
  const storageData = data ? JSON.parse(data) : {};

  useEffect(() => {
    if (isReviewState && !storageData.isReviewState) {
      dispatch(commonActions.setReviewState(false));
    }
  }, []);

  const clickHandler = () => {
    setTimeout(() => {
      dispatch(commonActions.setReviewState(true));
      if (hasDocumentForCheck(subDocsData)) {
        return history.replace(QUICK_CHECK_FILES);
      }
      dispatch(commonActions.setQuestion(Questions.quality));
      return history.replace(FRAUD_CHECK_LIVENESS);
    }, 200);
  };

  const BigButtonProps: ISingleButtonProps = {
    textButton: 'reg.ta-boarding.button',
    onClick: () => {setIsActiveStartButton(false); clickHandler();},
    isActive: isActiveStartButton,
    isDisabled: !isActiveStartButton,
    sizeButton: BUTTON_STYLE.BIG,
    radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
    variant: BUTTON_STYLE.OUTLINE_SECONDARY,
  };

  const fails = subDocsData.reduce((failsData: any[], subDoc: any) => {
    const subDocFails: Array<string> = getSDKFails(subDoc.dataXML);
    subDocFails.length && subDocFails.forEach((fail) => {
      if (!failsData.includes(fail)) failsData.push(fail);
    });
    return failsData;
  }, []);

  const back = () => {
    history.replace(DASHBOARD);
  };

  return (
    <div className="ta-boarding">
      <div className="ta-boarding-info">
        <p className="ta-boarding-title">
          {t('reg.ta-boarding.hi')}
          {' '}
          {name}
          {' '}
          {t('reg.ta-boarding.please review')}
          {' '}
          { trCode }
        </p>
        {Boolean(fails.length)
        && (
          <>
            <p className="ta-failed-text">{t('reg.ta-boarding.reasons')}</p>
            <ul className="ta-failed-reason">
              {fails.map((fail) => <li key={`TAFailedReason_${fail}`}>{t(fail)}</li>)}
            </ul>
          </>
        )}
        <p>{t('reg.ta-boarding.transaction-contains')}</p>
        <ul className="ta-swiss">
          {docTypes.map((docType: string[], index: number) => (
            <li key={`${`TADocType${docType[0]}`}-${index.toString()}`}>
              {translateDocTypeCountry(docType, t)}
            </li>
          ))}
        </ul>
        <p>{t('reg.ta-boarding.required-steps')}</p>
        <p className="ta-check-type">{t('reg.ta-boarding.ta-check-type')}</p>
      </div>
      <div className="ta-boarding-buttons-container">
        <div className="ta-boarding-back-button-container">
          <SingleButton
            textButton={'reg.files.back'}
            onClick={back}
            variant={BUTTON_STYLE.OUTLINE_PRIMARY}
            radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
            LeftIcon={BackButton}
          />
        </div>
        <div className="start-transaction-button-ta-boarding">
          <SingleButton {...BigButtonProps} />
          <div className="ta-borading-loader-container">
            {!isActiveStartButton && (<Loader />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TABoarding;
