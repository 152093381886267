import React from 'react';

const Modal = (props: {children: React.ReactNode}) => {
  return (
    <div className="modal-wrapper">
      {props.children}
    </div>

  );
};

export default Modal;
