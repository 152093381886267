import React from 'react';
import NoImage from 'images/no-image.png';

const FaceItem = (props: {image?: string; label: string}) => {
  const { label, image } = props;

  return (
    <div className='image-item-container'>
      <img
        alt={label}
        src={image? image : NoImage}
      />
    </div>
  );
};

export default FaceItem;