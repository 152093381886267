import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Answers from 'components/footer/Answers';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import { ANSWERS_KEYS, BUTTON_STYLE } from 'constants/Static';
import { Questions } from 'store/features/common/types';
import { IRootState } from 'store/types';
import { TScreenName } from 'store/features/customerDataCheck/types';
import * as customerDataCheckActions from 'store/features/customerDataCheck/actions';
import * as commonActions from 'store/features/common/actions';
import { getNextPage, getQuestion, getPreviousPage } from 'helper/navigation';
import { EPage } from 'enums';
import { IAnswerOption } from 'types';
import { IZipContent } from 'store/features/zipContent/types';

const Approve = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((rootState: IRootState) => rootState);
  const {
    zipContent,
    customerDataCheck,
  } = state;
  const { customerData = {} } = zipContent as IZipContent;
  const { outputJson = {} } = customerData;
  const { selfDeclaration = {} } = outputJson;
  const page = HeaderButtons[EPage.userDataCheck].address[Questions.approve];
  const { address: savedAddress } = customerDataCheck;
  const foundedIndex = page?.answers.findIndex(
    (answer: IAnswerOption) => answer.key === savedAddress?.result
  );
  const activeIndexes = foundedIndex === -1 ? [] : [foundedIndex];

  const submitAnswer = (answer: IAnswerOption) => {
    dispatch(customerDataCheckActions.setCDCResult({
      key: TScreenName.address,
      list: [
        { data: selfDeclaration.address },
        { result: answer.key },
      ]
    }));
    if (answer.key === ANSWERS_KEYS.REJECTED) {
      return dispatch(commonActions.setQuestion(Questions.reject));
    }
    const next = getNextPage(state, location.pathname);
    const nextQuestion = getQuestion(next);
    dispatch(commonActions.setQuestion(nextQuestion));
    return history.replace(next);
  };

  const back = () => {
    const previous = getPreviousPage(state, location.pathname);
    const previousQuestion = getQuestion(previous);
    dispatch(commonActions.setQuestion(previousQuestion));
    dispatch(commonActions.setActiveSlidePath(''));
    dispatch(commonActions.setPrevPagePath(location.pathname));
    history.replace(previous);
  };

  return (
    <Answers
      back={back}
      answers={page?.answers}
      activeIndexes={activeIndexes}
      submitAnswer={submitAnswer}
      styled={BUTTON_STYLE.OUTLINE_PRIMARY}
    />
  );
};

export default Approve;