import produce from 'immer';

import { setLocalStorageDataItem } from 'util/storageHelper';
import {
  IDataSet,
  TDataSetActions,
  DataSetActions,
} from 'store/features/dataSets/types';
import getInitialState from 'store/features/dataSets/initialState';

export const dataSetsReducer = (state: IDataSet[] = getInitialState(), action: TDataSetActions) => {
  const nextState = produce(state, (draftState: IDataSet[]) => {
    const { type, value } = action;
    switch (type) {
    case DataSetActions.setDataSet:
      draftState = value;
      setLocalStorageDataItem('datasets', draftState);
      break;
    }
    return draftState;
  });
  return nextState;
};
