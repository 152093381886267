import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { batchActions } from 'redux-batched-actions';
import { useHistory } from 'react-router-dom';

import { GetZipContent } from 'helper/api/route';
import { extractXMLData } from 'util/stateHelper';
import * as commonActions from 'store/features/common/actions';
import * as zipContentActions from 'store/features/zipContent/actions';
import { ISubDocData } from 'store/features/zipContent/types';

const useFetchZip = (subDocsData: ISubDocData[], trCode: string, dispatch: Function) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tid = urlParams.get('tid');
    if(trCode) {
      if ((!subDocsData[0].files || !subDocsData[0].files.length) && !tid) {
        GetZipContent(trCode).then((response) => {
          const { subDocs } = response.data;
          Promise.all(extractXMLData(subDocs)).then((data) => {
            const modifiedResponseData = { ...response.data };
            for (let index = 0; index < data.length; index++) {
              modifiedResponseData.subDocs[index].dataXML = data[index];
            }
            batchActions([
              dispatch(commonActions.setResetCode(true)),
              dispatch(zipContentActions.setZipContent(modifiedResponseData)),
              dispatch(zipContentActions.setZipContentLoader(false)),
            ]);
          });
        }).catch(() => history.replace('/'));
      }
    } else {
      history.replace('/');
    }
  }, []);
};

export default useFetchZip;
