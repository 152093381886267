import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ListFromResources from 'components/accordionMenu/ListFromResources';
import { IRootState } from 'store/types';
import { Questions, TQuestion } from 'store/features/common/types';

const FilesContent = () => {
  const { t } = useTranslation();
  let leftSideContent;
  const state = useSelector((rootState: IRootState) => rootState);
  const question: TQuestion = state.common?.question || Questions.documentPage;

  switch (question) {
  case Questions.countryDocType:
  case Questions.comparing:
    leftSideContent = [
      [
        {
          text: t('reg.files.type.left-menu.task-help.doc-compare-1.fraud'),
          type: 'bold',
        },
        ` ${t('reg.files.type.left-menu.task-help.doc-compare-1.definition-according')}`,
        {
          text: t('reg.files.quality.left-menu.task-help.doc-compare-1.compliance-guidelines'),
          type: 'link',
          url: ''
        },
      ],
      [
        {
          text: t('reg.files.type.left-menu.task-help.doc-compare-2.printed'),
          type: 'bold',
        },
        ` ${t('reg.files.type.left-menu.task-help.doc-compare-2.message')}`,
        {
          text: t('reg.files.fraud.left-menu.task-help.doc-compare-2.examples'),
          type: 'link',
          url: ''
        },
      ],
      [
        {
          text: t('reg.files.type.left-menu.task-help.doc-compare-2.screen-capture'),
          type: 'bold',
        },
        ` ${t('reg.files.fraud.left-menu.task-help.doc-compare-2.message2')}`,
        {
          text: t('reg.files.fraud.left-menu.task-help.doc-compare-2.examples'),
          type: 'link',
          url: ''
        },
      ],
      [
        {
          text: t('reg.files.fraud.left-menu.task-help.doc-compare-2.examples'),
          type: 'link',
          url: ''
        },
        ` ${t('reg.files.fraud.left-menu.task-help.doc-compare-2.message3')}`,
      ],
    ];
    break;
  case Questions.documentPage:
    leftSideContent = [
      [
        {
          text: t('reg.files.page.left-menu.task-help.page-1'),
          type: 'bold'
        },
        `- ${t('reg.files.page.left-menu.task-help.page-1-contains')}`
      ], [
        t('reg.files.page.left-menu.task-help.passport-page'),
        {
          text: `${t('reg.files.page.left-menu.task-help.page-1')}.`,
          type: 'bold',
        }
      ], [
        {
          text: t('reg.files.page.left-menu.task-help.page-2'),
          type: 'bold'
        },
        `- ${t('reg.files.page.left-menu.task-help.page-2-contains')}`
      ], [
        t('reg.files.page.left-menu.task-help.also-choose'),
        {
          text: t('reg.files.page.left-menu.task-help.something'),
          type: 'bold'
        },
        t('reg.files.page.left-menu.task-help.hard-to-see')
      ], [
        t('reg.files.page.left-menu.task-help.no-document'),
        {
          text: `${t('reg.files.page.left-menu.task-help.something')}.`,
          type: 'bold'
        },
      ],

    ];
    break;
  case Questions.quality:
    leftSideContent = [
      [
        t('reg.files.quality.left-menu.task-help.message1'),
        {
          text: `${t('reg.files.quality.left-menu.task-help.fraud')}.`,
          type: 'bold'
        }
      ], [
        {
          text: t('reg.files.quality.left-menu.task-help.fraud-lower-case'),
          type: 'bold',
        },
        t('reg.files.quality.left-menu.task-help.definition-according'),
        {
          text: t('reg.files.quality.left-menu.task-help.compliance-guidelines'),
          type: 'link',
          url: ''
        },
      ], [
        t('reg.files.quality.left-menu.task-help.message2'),
        {
          text: t('reg.files.quality.left-menu.task-help.not-useful'),
          type: 'bold'
        }
      ], [
        t('reg.files.quality.left-menu.task-help.images'),
        {
          text: t('reg.files.quality.left-menu.task-help.good-to-use'),
          type: 'bold'
        },
        t('reg.files.quality.left-menu.task-help.message3')
      ]
    ];
    break;
  case Questions.fraud:
    leftSideContent = [
      [
        {
          text: t('reg.files.fraud.left-menu.task-help.fraud'),
          type: 'bold'
        },
        t('reg.files.fraud.left-menu.task-help.definition-according'),
        {
          text: t('reg.files.fraud.left-menu.task-help.compliance-guidelines'),
          type: 'link',
          url: ''
        },
      ], [
        {
          text: t('reg.files.fraud.left-menu.task-help.printed'),
          type: 'bold'
        },
        t('reg.files.fraud.left-menu.task-help.message1'),
        {
          text: t('reg.files.fraud.left-menu.task-help.examples'),
          type: 'link',
          url: ''
        }
      ], [
        {
          text: t('reg.files.fraud.left-menu.task-help.screen-capture'),
          type: 'bold'
        },
        t('reg.files.fraud.left-menu.task-help.message2'),
        {
          text: t('reg.files.fraud.left-menu.task-help.examples'),
          type: 'link',
          url: ''
        }
      ], [
        {
          text: t('reg.files.fraud.left-menu.task-help.examples'),
          type: 'link',
          url: ''
        },
        t('reg.files.fraud.left-menu.task-help.message3'),
      ]
    ];
    break;
  case Questions.unsure:
    leftSideContent = [
      [
        {
          text: t('reg.files.unsure.left-menu.task-help.blurry'),
          type: 'bold'
        },
        t('reg.files.unsure.left-menu.task-help.message1')
      ], [
        {
          text: t('reg.files.unsure.left-menu.task-help.bad-light'),
          type: 'bold'
        },
        t('reg.files.unsure.left-menu.task-help.message2')
      ], [
        {
          text: t('reg.files.unsure.left-menu.task-help.bad-angle'),
          type: 'bold'
        },
        t('reg.files.unsure.left-menu.task-help.message3')
      ], [
        {
          text: t('reg.files.unsure.left-menu.task-help.too-cuttet'),
          type: 'bold'
        },
        t('reg.files.unsure.left-menu.task-help.message4')
      ], [
        t('reg.files.unsure.left-menu.task-help.message5'),
        {
          text: t('reg.files.unsure.left-menu.task-help.different-document'),
          type: 'bold'
        }
      ]
    ];
    break;
  default:
    break;
  }

  return (
    <ListFromResources resource={leftSideContent} />
  );
};

export default FilesContent;
