
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import RightMenu from 'components/menuSide/RightMenu';
import List from 'components/menuSide/List';
import { HeaderButtons } from 'constants/CheckTypeButtons';
import InfoBar from 'components/infoBar/InfoBar';
import Footer from 'components/footer/Footer';
import LivenessContainer from 'pages/fraudCheck/liveness/LivenessContainer';
import { IRootState } from 'store/types';
import { Questions } from 'store/features/common/types';
import ManCheckReasons from 'components/manCheckReasons/ManCheckReasons';
import { PASS } from 'constants/Static';
import { EPage } from 'enums';
import BurgerMenu from 'components/burgerMenu/BurgerMenu';

const LivenessComponent = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const question = state.common?.question || Questions.quality;
  const page = HeaderButtons[EPage.fraudCheck].liveness[question];
  const subDocsData = state.zipContent?.subDocsData || [];

  const rightMenuProps: Array<React.ReactNode> = [];
  const rightMenuContent: Array<React.ReactNode> = [];

  const resultLiveness: any = [];
  const resultFace: any = [];

  subDocsData?.forEach(element => {
    const { dataXML } = element;
    const { livenessCheck, faceVerification } = dataXML?.documentScan || {};
    livenessCheck && resultLiveness.push(livenessCheck?.result);
    faceVerification && resultFace.push(faceVerification?.result);
  });

  rightMenuContent.push(
    <p>
      <b>{t('reg.liveness.list-item-selfie')}</b> {t('reg.liveness.list-item-selfie-video')}
    </p>
  );

  resultFace?.length && rightMenuContent.push(
    <div>
      <b>{t('reg.liveness.list-item-face-verification')}</b>
      <ul className="m-0">
        {resultFace.map((result: any, index: number) =>
          result &&
          <li key={index}>
            {result === PASS
              ? t('reg.liveness.list-item-value-pass') : t('reg.liveness.list-item-value-fail')}
          </li>
        )}
      </ul>
    </div>
  );

  resultLiveness?.length && rightMenuContent.push(
    <div>
      <b>{t('reg.liveness.list-item-liveness-detection')}</b>
      <ul className="m-0" key="livenessList2">
        {resultLiveness.map((result: any, index: number) =>
          result &&
          <li key={index}>
            {result === PASS
              ? t('reg.liveness.list-item-value-pass') : t('reg.liveness.list-item-value-fail')}
          </li>
        )}
      </ul>
    </div>
  );

  rightMenuProps.push(
    <List
      header={t('reg.liveness.list-header')}
      content={rightMenuContent}
      key={t('reg.liveness.list-header') + 'liveness'}
    />
  );

  rightMenuProps.push(<ManCheckReasons key={'liveness-prop'} />);

  return (
    <>
      <InfoBar />
      <>
        <BurgerMenu />
        <h3>{t(page?.heading || '')}</h3>
      </>
      <div className="check-content">
        <div className='extra-space-menu-container' />
        <LivenessContainer />
        <RightMenu children={rightMenuProps} />
      </div>
      <Footer NestedComponent={page?.answerComponent} />
    </>
  );
};

export default LivenessComponent;