import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { HeaderButtons } from 'constants/CheckTypeButtons';
import Answers from 'components/footer/Answers';
import { getNextPage, getQuestion, getPreviousPage } from 'helper/navigation';
import * as commonActions from 'store/features/common/actions';
import * as checkResultActions from 'store/features/checkResult/actions';
import { IRootState } from 'store/types';
import { BUTTON_STYLE } from 'constants/Static';
import { Questions } from 'store/features/common/types';
import { ICheckResult } from 'store/features/checkResult/types';
import { EPage } from 'enums';
import { IAnswerOption } from 'types';
import { batchActions } from 'redux-batched-actions';

const Comparing = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const checkResult = state.checkResult;
  const page = HeaderButtons[EPage.quickCheck].data[Questions.comparing];
  const { comparingData } = checkResult as ICheckResult;

  const submitAnswer = (answer: IAnswerOption) => {
    dispatch(checkResultActions.setComparingData({
      quality: answer.text,
      key: answer.key,
      reason: page?.reason,
    }));
    const next = getNextPage(state, location.pathname);
    const nextQuestion = getQuestion(next);
    batchActions([
      dispatch(commonActions.setQuestion(nextQuestion)),
      dispatch(commonActions.setPrevPagePath('')),
      dispatch(commonActions.setActiveSlidePath('')),
    ]);
    history.replace(next);
  };

  const back = () => {
    const previous = getPreviousPage(state, location.pathname);
    const previousQuestion = getQuestion(previous);
    batchActions([
      dispatch(commonActions.setQuestion(previousQuestion)),
      dispatch(commonActions.setPrevPagePath(location.pathname)),
      dispatch(commonActions.setActiveSlidePath('')),
      dispatch(checkResultActions.setComparingData({ quality: '' })),
    ]);
    return history.replace(previous);
  };

  return (
    <Answers
      back={back}
      answers={page?.answers}
      activeIndexes={[
        page?.answers.findIndex((item: IAnswerOption) => item.text === comparingData?.quality)
      ]}
      submitAnswer={submitAnswer}
      styled={BUTTON_STYLE.OUTLINE_PRIMARY}
      changeLocation={true}
    />
  );
};

export default Comparing;
