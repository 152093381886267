import { getStorageData } from 'util/storageHelper';
import { ICommon, Questions } from 'store/features/common/types';

export default (): ICommon => {
  const data: string | null = getStorageData('state');
  const configData: string | null = getStorageData('appConfig');
  const defaultTransactionCode = getStorageData('defaultTransactionCode');
  const storageData = data ? JSON.parse(data) : {};
  const appConfigData = configData ? JSON.parse(configData) : {};
  return {
    resetCode: !!storageData.resetCode || false,
    isReviewState: !!storageData.isReviewState || false,
    question: storageData.question || Questions.countryDocType,
    docRequiredFields: storageData.docRequiredFields || {},
    forcedRejectedInfo: storageData.forcedRejectedInfo || {
      question: '',
      location: '',
      isForcedRejected: false,
    },
    filesSliderPath: storageData.filesSliderPath || '',
    prevPagePath: storageData.prevPagePath || '',
    instructionUrl: storageData.instructionUrl || {},
    defaultTransactionCode: defaultTransactionCode || '',
    dashboard: {
      showActive: true,
      loading: false,
      active: {
        items: [],
        page: 1,
        size: 0,
        totalItems: 0,
      },
      closed: {
        items: [],
        page: 1,
        size: 0,
        totalItems: 0,
      },
    },
    appConfig: appConfigData || {},
  };
};
